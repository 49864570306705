import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import { Circle } from "@mui/icons-material";
import { Button, FormControl } from "@mui/material";
import { CrossIcon } from "@100mslive/react-icons";
import {
  Box,
  config as cssConfig,
  Flex,
  Input,
  Text,
} from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import FullPageProgress from "../FullPageProgress";
import { Logo } from "../Header/HeaderComponents";
import { ToastManager } from "../Toast/ToastManager";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { SESSION_AUTH } from "../../common/auth";
import { OTHER_SERVICES, USER_SERVICES } from "../../services";
import { SIDE_PANE_OPTIONS } from "../../common/constants";

export const WidgetAboutScreen = () => {
  const navigate = useNavigate();
  const toggleWidget = useSidepaneToggle(SIDE_PANE_OPTIONS.ABOUT);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const dataFetched = useRef(false);
  const isMobile = useMedia(cssConfig.media.md);

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    }
    return () => null;
  }, [navigate, isUserSession]);

  useEffect(() => {
    if (!isUserSession || dataFetched?.current) return;
    dataFetched.current = true;
    try {
      USER_SERVICES.speakers({
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
        emails: [isUserSession?.email],
      })
        .then(data => {
          if (data.code === 200) {
            if (data?.data?.[0]?.phoneNumber) {
              setPhoneNumber(data?.data?.[0]?.phoneNumber);
            }
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  }, [isUserSession, dataFetched, LOGOUT_USER_SESSION]);

  const demoRequest = () => {
    if (!phoneNumber) {
      return ToastManager.addToast({
        title: "Enter your phone number",
      });
    }
    setLoading(true);
    OTHER_SERVICES.demoRequestMeeting({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      email: isUserSession?.email,
      phoneNumber: phoneNumber,
      currentUrl: window.location.href,
    })
      .then(data => {
        if (data.code === 200) {
          ToastManager.addToast({ title: data?.message });
          toggleWidget();
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data?.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err?.message });
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Fragment>
      {loading && <FullPageProgress fixed />}
      <Flex direction="column" css={{ size: "100%" }}>
        <Flex align="center" css={{ w: "100%", mb: "$10" }}>
          {/* <Text css={{ fontWeight: "$semiBold", mr: "$4" }}>6ix</Text> */}
          <div className="preview-widget-menu">
            <div className="preview-widget-menu-link active ">6ix</div>
          </div>
          {isMobile && (
            <IconButton
              onClick={toggleWidget}
              css={{ w: "$11", h: "$11", ml: "auto" }}
            >
              <CrossIcon />
            </IconButton>
          )}
        </Flex>
        <Box
          css={{
            color: "$textMedEmp",
            mr: "-15px",
            overflowY: "auto",
            pr: "15px",
          }}
        >
          <div className="preview-widget-about">
            <div className="preview-widget-about-logo">
              <Logo />
            </div>

            <Text className="preview-widget-about-heading text-primary text-center">
              Need to attract capital?
            </Text>
            <Text className="preview-widget-about-heading">
              Reach investors, attract capital and build reputation with 6ix.
            </Text>
            <div className="preview-widget-about-list">
              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  Virtual event hosting
                </div>
              </div>
              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  Event promotion
                </div>
              </div>
              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  Event moderation
                </div>
              </div>
              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  Post-event meeting facilitation
                </div>
              </div>
              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  Meeting software provision
                </div>
              </div>
            </div>

            <Text className="preview-widget-about-heading">Why 6ix?</Text>

            <div className="preview-widget-about-list">
              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  <b className="text-white">End-to-End Solution: </b>From event
                  hosting to post-event follow-up, we provide a comprehensive
                  service that covers all aspects of virtual investor relations.
                </div>
              </div>

              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  <b className="text-white">Expertise: </b>Our team brings a
                  wealth of experience in investor relations, ensuring a
                  professional and impactful event experience.
                </div>
              </div>

              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  <b className="text-white">Efficiency: </b>Our integrated
                  software and processes streamline the scheduling and
                  management of investor meetings, saving you time and
                  resources.
                </div>
              </div>

              <div className="preview-widget-about-list-row">
                <Circle className="text-primary preview-widget-about-list-icon" />
                <div className="preview-widget-about-list-text">
                  <b className="text-white">Reach: </b>Leverage our extensive
                  network and marketing capabilities to maximize your event's
                  reach and impact.
                </div>
              </div>
            </div>

            <FormControl className="custom-form-group">
              <label
                htmlFor="meetingPhone"
                className="custom-form-label big-label"
              >
                Enter your phone number and press request a meeting
              </label>
              <Input
                id="meetingPhone"
                name="meetingPhone"
                className="custom-form-input"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                autoComplete="none"
              />
            </FormControl>

            <Button
              fullWidth
              variant="outlined"
              type="button"
              className="events6ix-btn events6ix-btn-primary"
              onClick={demoRequest}
            >
              REQUEST A 6ix DEMO
            </Button>
          </div>
        </Box>
      </Flex>
    </Fragment>
  );
};
