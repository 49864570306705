import { apiCall } from "./servicesCall";

export const ROOM_SERVICES = {
  createRoom(data) {
    return apiCall("/room/create", data);
  },
  getRecordings(data) {
    return apiCall("/room/recordings", data);
  },
  recordingDownload(data) {
    return apiCall("/room/recordings/download", data);
  },
  createToken(data) {
    return apiCall("/room/token", data);
  },
};
