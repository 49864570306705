import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import EventHeader from "../components/Event/EventHeader";
import EventHeaderSidebar from "../components/Event/EventHeader/EventHeaderSidebar";
import { useGlobalValue } from "../GlobalContext";

const EventsLayout = () => {
  const { openSidebar } = useGlobalValue();
  const scrollableContentRef = useRef(null);

  const { pathname } = useLocation();

  useEffect(() => {
    scrollableContentRef.current.scrollTo(0, 0);
    return () => null;
  }, [pathname]);

  return (
    <React.Fragment>
      <EventHeader />
      <div className="events6ix-layout-wrapper">
        {openSidebar && <EventHeaderSidebar />}
        <div
          className="events6ix-layout-wrapper-content"
          ref={scrollableContentRef}
        >
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventsLayout;
