import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from "@mui/material";
import { Box, Flex, Label, Text } from "@100mslive/roomkit-react";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../services";

const AccountLeaveTeam = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { onUpdate } = props;
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [leaveTeam, setLeaveTeam] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  const onClose = () => {
    setLeaveTeam(false);
  };
  const onLeave = () => {
    setLoading(true);
    ACCOUNT_SERVICES.leaveTeam({
      userId: userData.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          onClose();
          onUpdate();
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
          navigate("/dashboard");
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {loading && <FullPageProgress fixed={true} />}
      <Box className="events-page-account-setting-content-box">
        <div className="events-page-account-setting-content-box-title">
          Leave Team
        </div>
        <FormControl className="events-page-account-setting-content-box-control">
          <Label className="events-page-account-setting-content-box-lable">
            Revoke your access to this Team. Any resources you've added to the
            Team will remain.
          </Label>
        </FormControl>

        <div className="events-page-account-setting-content-box-line"></div>

        <Flex
          align="center"
          className="events-page-account-setting-content-box-row"
        >
          <Text
            css={{
              flex: "1 1 0",
            }}
            className="events-page-account-setting-content-box-text"
          />
          <Button
            color="error"
            variant="outlined"
            className="events6ix-btn events6ix-btn-danger"
            type="button"
            onClick={() => setLeaveTeam(true)}
          >
            LEAVE TEAM
          </Button>
        </Flex>
      </Box>

      {leaveTeam && (
        <Dialog
          open={leaveTeam}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-dialog eventAiPopup"
        >
          {loading && <FullPageProgress fixed={true} />}

          <DialogTitle className="text-center">Leave Team</DialogTitle>
          <DialogContent>
            <DialogContentText className="text-center">
              Are You sure ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="custom-dialog-action">
            <Button
              onClick={onClose}
              variant="outlined"
              color="secondary"
              className="events6ix-btn events6ix-btn-light"
              type="button"
            >
              Cancel
            </Button>

            <Button
              onClick={onLeave}
              variant="outlined"
              color="primary"
              className="events6ix-btn events6ix-btn-primary"
              type="button"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default AccountLeaveTeam;
