import React from "react";

const SixAboutGlance = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-landing-section-space events-page-about-section-glance">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-about-section-header">
            <div className="events-page-about-section-header-heading text-center">
              6ix at a glance
            </div>
            <div className="events-page-about-section-glance-row">
              <div className="events-page-about-section-glance-row-box">
                <div className="events-page-about-section-glance-row-box-number">
                  445
                </div>
                <div className="events-page-about-section-glance-row-box-text">
                  Hosts
                </div>
              </div>
              <div className="events-page-about-section-glance-row-box">
                <div className="events-page-about-section-glance-row-box-number">
                  1,117
                </div>
                <div className="events-page-about-section-glance-row-box-text">
                  Events
                </div>
              </div>
              <div className="events-page-about-section-glance-row-box">
                <div className="events-page-about-section-glance-row-box-number">
                  36,342
                </div>
                <div className="events-page-about-section-glance-row-box-text">
                  Investors
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixAboutGlance;
