import React from "react";
import { Box, Flex } from "@100mslive/roomkit-react";
import { Cross, DownLoadIcon } from "../../icons";
import ReplayViewerPlayers from "./ReplayViewerPlayers";

export function ReplayViewer(props) {
  const { close, replayData } = props;

  return (
    <>
      <Flex
        align="center"
        direction="column"
        className="eventsPopup replay-viewer"
        onClick={() => {
          close(false);
        }}
      >
        <Box
          className="eventsPopup-inner"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <>
            <div
              className="eventsPopup-close"
              onClick={() => {
                close(false);
              }}
            >
              <Cross />
            </div>

            <div className="eventsPopup-content">
              {replayData?.replay?.link ? (
                <>
                  {/* <ReactPlayer
                  height="calc(100vh - 260px)"
                  width="auto"
                  className="replay-viewer-player"
                  playing
                  light={
                    <img
                      src={replayData?.eventCover}
                      alt=""
                      className="replay-viewer-poster"
                    />
                  }
                  controls
                  url={replayData?.replay?.link}
                  onError={onError}
                /> */}

                  <>
                    <ReplayViewerPlayers replayData={replayData} />
                    <div className="replay-download">
                      <a
                        className="replay-download-btn"
                        href={replayData?.replay?.link}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DownLoadIcon /> Download Replay
                      </a>
                    </div>
                  </>
                </>
              ) : (
                <div className="text-center">Error : Replay link not found</div>
              )}
            </div>
          </>
        </Box>
      </Flex>
    </>
  );
}
