import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../services";

const AccountMemberRemoveAccess = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { open, onClose, member, onUpdate } = props;
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      navigate("/");
    }
  }, [isUserSession, navigate]);

  const onSave = async () => {
    setLoading(true);
    try {
      const data = await ACCOUNT_SERVICES.removeMemberAccess({
        userId: userData?.userId,
        org: userData?.org?.id,
        id: member.userId,
      });
      if (data.code === 200) {
        onClose();
        onUpdate();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog eventAiPopup"
    >
      {loading && <FullPageProgress fixed={true} />}

      <DialogTitle className="text-center">
        Remove Access <br />
        <small>{member?.profile?.email}</small>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="text-center">
          Are You sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          Cancel
        </Button>

        <Button
          onClick={onSave}
          variant="outlined"
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AccountMemberRemoveAccess;
