import React from "react";
export function DownLoadIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3 11.7V14.1C15.3 14.4183 15.1525 14.7235 14.89 14.9485C14.6274 15.1736 14.2713 15.3 13.9 15.3H4.1C3.72869 15.3 3.3726 15.1736 3.11005 14.9485C2.8475 14.7235 2.7 14.4183 2.7 14.1V11.7"
        stroke="#C0C0C0"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.40001 8.10001L9.00001 11.7L12.6 8.10001"
        stroke="#C0C0C0"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.7V2.70001"
        stroke="#C0C0C0"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default DownLoadIcon;
