import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link, useParams } from "react-router-dom";
import { Box } from "@100mslive/roomkit-react";
import SEO from "../Common/SEO";
import ExploreList from "../Explore/ExploreList";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { COMPANY_SERVICES } from "../../services";

const CompanyEvents = props => {
  const { orgId, orgName } = props;
  const { ref: loadMoreItemsBox, inView: loadMoreItemsBoxVisible } = useInView({
    threshold: 0,
  });
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { orgSlug, listType } = useParams();
  const eventTabs = {
    all: "All",
    upcoming: "Upcoming",
    "on-demand": "On Demand",
    "get-notified-of-replays": "Get Notified of Replays",
  };
  const [ToggleState, setToggleState] = useState(
    eventTabs?.[listType] ? listType : "all"
  );
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const limit = 20;
  const [loading, setLoading] = useState(false);
  const dataFetchedRef = useRef(false);
  const [listData, setListData] = useState([]);

  const changeTabs = item => {
    if (item !== ToggleState && !loading) {
      setToggleState(item);
      setHasMore(false);
      setPage(1);
      dataFetchedRef.current = false;
    }
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    setLoading(true);
    if (page === 1) {
      setListData([]);
    }
    if (ToggleState === "all") {
      COMPANY_SERVICES.events({
        limit: 20,
        page: page,
        id: orgId,
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (ToggleState === "upcoming") {
      COMPANY_SERVICES.eventUpcoming({
        id: orgId,
        limit: 20,
        page: page,
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (ToggleState === "on-demand") {
      COMPANY_SERVICES.eventDemand({
        id: orgId,
        limit: 20,
        page: page,
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (ToggleState === "get-notified-of-replays") {
      COMPANY_SERVICES.eventNotified({
        id: orgId,
        limit: 20,
        page: page,
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (ToggleState === "live") {
      COMPANY_SERVICES.eventLive({
        id: orgId,
        limit: 20,
        page: page,
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            updateData(data.data);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(err => {
          ToastManager.addToast({ title: err.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [page, ToggleState, orgId, isUserSession, LOGOUT_USER_SESSION]);

  const updateData = data => {
    if (data && data?.length > 0) {
      setListData(old => [...old, ...data]);
      setHasMore(data.length === limit);
    } else {
      setHasMore(false);
    }
  };
  useEffect(() => {
    if (!loadMoreItemsBoxVisible) return;
    loadMoreContent();
  }, [loadMoreItemsBoxVisible]);

  const loadMoreContent = () => {
    dataFetchedRef.current = false;
    setPage(page => page + 1);
  };
  return (
    <React.Fragment>
      <div className="events6ix-tabs mt-0">
        {Object.keys(eventTabs).map((item, key) => (
          <Link
            to={`/company/${orgSlug}/events/${item}`}
            key={key}
            className={`events6ix-tabs-item ${
              item === ToggleState ? "active" : ""
            }`}
            onClick={e => {
              if (loading) {
                e.preventDefault();
              } else {
                changeTabs(item);
              }
            }}
          >
            {eventTabs[item]}
            {item === ToggleState && (
              <SEO
                title={`${orgName || "Profile"} | ${
                  eventTabs[item]
                } events on 6ix Events`}
              />
            )}
          </Link>
        ))}
      </div>
      <div className="new-event-page-tabs-data">
        <ExploreList
          loading={loading}
          listData={listData}
          ToggleState={ToggleState}
        />
        {loading ? (
          <Box>
            <FullPageProgress />
          </Box>
        ) : hasMore ? (
          <div className="eventsLoadMoreProgrss" ref={loadMoreItemsBox}></div>
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
};

export default CompanyEvents;
