import React, { useEffect } from "react";
import {
  selectAppData,
  selectIsConnectedToRoom,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Box } from "@100mslive/roomkit-react";
import { Chat } from "../components/Chat/Chat";
import { Widgets } from "../components/Footer/Widgets";
import { ParticipantList } from "../components/Header/ParticipantList";
import { StreamingLanding } from "../components/Streaming/StreamingLanding";
import { VBPicker } from "../components/VirtualBackground/VBPicker";
import { WidgetAboutScreen } from "../components/Widget/WidgetAboutScreen";
import { WidgetScreen } from "../components/Widget/WidgetScreen";
import { useWidgetState } from "../components/AppData/useUISettings";
import { APP_DATA, SIDE_PANE_OPTIONS } from "../common/constants";

const SidePane = ({
  css = {},
  starData,
  likeData,
  deleteMsgData,
  hasWidget,
}) => {
  const sidepane = useHMSStore(selectAppData(APP_DATA.sidePane));
  const { widgetView, setWidgetView } = useWidgetState();
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const commonCss = {
    position: "relative",
    w: "$100",
    h: "100%",
    p: "$10",
    bg: "$surface_default",
    r: "$1",
    top: 0,
    zIndex: 10,
    boxShadow: "$md",
    border: "1px solid $borderDefault",
    ...css,
    "@lg": {
      w: "100%",
      position: "fixed",
      bottom: 0,
      right: 0,
      left: "0 !important",
      height: "unset",
      ...(css["@lg"] || {}),
    },
  };

  let RightComponent;
  if (sidepane === SIDE_PANE_OPTIONS.PARTICIPANTS) {
    RightComponent = ParticipantList;
  } else if (sidepane === SIDE_PANE_OPTIONS.CHAT) {
    RightComponent = Chat;
  } else if (sidepane === SIDE_PANE_OPTIONS.STREAMING) {
    RightComponent = StreamingLanding;
  } else if (sidepane === SIDE_PANE_OPTIONS.WIDGET) {
    RightComponent = WidgetScreen;
  } else if (sidepane === SIDE_PANE_OPTIONS.ABOUT) {
    RightComponent = WidgetAboutScreen;
  } else if (sidepane === SIDE_PANE_OPTIONS.VB) {
    RightComponent = VBPicker;
  }
  useEffect(() => {
    if (!isConnectedToRoom) setWidgetView();
    return () => null;
  }, [isConnectedToRoom, setWidgetView]);

  return (
    <>
      {RightComponent && (
        <Box
          css={{
            ...commonCss,
            right: "$10",
            "@lg": { ...commonCss["@lg"] },
          }}
        >
          <RightComponent
            starData={starData}
            likeData={likeData}
            deleteMsgData={deleteMsgData}
            hasWidget={hasWidget}
          />
        </Box>
      )}
      {widgetView && (
        <Box
          css={{
            ...commonCss,
            left: "$10",
            position: "absolute",
            "@lg": { ...commonCss["@lg"] },
          }}
        >
          <Widgets />
        </Box>
      )}
    </>
  );
};

export default SidePane;
