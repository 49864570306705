import React from "react";
import { Flex } from "@100mslive/roomkit-react";
import EventFooterAbout6ix from "./EventFooterAbout6ix";
import EventFooterDisclamer from "./EventFooterDisclamer";

const EventFooterContentRight = () => {
  return (
    <Flex className="events6ix-layout-footer-content-right">
      <EventFooterAbout6ix />
      <EventFooterDisclamer />
    </Flex>
  );
};

export default EventFooterContentRight;
