import React from "react";
export function PersonsIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_513_5603)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.09835 11.5984C1.80161 10.8951 2.75544 10.5 3.75 10.5H9.75C10.7446 10.5 11.6984 10.8951 12.4017 11.5984C13.1049 12.3016 13.5 13.2554 13.5 14.25V15.75C13.5 16.1642 13.1642 16.5 12.75 16.5C12.3358 16.5 12 16.1642 12 15.75V14.25C12 13.6533 11.7629 13.081 11.341 12.659C10.919 12.2371 10.3467 12 9.75 12H3.75C3.15326 12 2.58097 12.2371 2.15901 12.659C1.73705 13.081 1.5 13.6533 1.5 14.25V15.75C1.5 16.1642 1.16421 16.5 0.75 16.5C0.335786 16.5 0 16.1642 0 15.75V14.25C0 13.2554 0.395088 12.3016 1.09835 11.5984Z"
          fill="#8C8E95"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75061 3C5.50797 3 4.50061 4.00736 4.50061 5.25C4.50061 6.49264 5.50797 7.5 6.75061 7.5C7.99325 7.5 9.00061 6.49264 9.00061 5.25C9.00061 4.00736 7.99325 3 6.75061 3ZM3.00061 5.25C3.00061 3.17893 4.67954 1.5 6.75061 1.5C8.82168 1.5 10.5006 3.17893 10.5006 5.25C10.5006 7.32107 8.82168 9 6.75061 9C4.67954 9 3.00061 7.32107 3.00061 5.25Z"
          fill="#8C8E95"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2746 11.1603C14.3782 10.7593 14.7872 10.5181 15.1883 10.6217C15.9928 10.8294 16.7055 11.2984 17.2146 11.9551C17.7236 12.6118 18.0002 13.4189 18.0008 14.2498L18.0008 15.7503C18.0008 16.1645 17.665 16.5003 17.2508 16.5003C16.8366 16.5003 16.5008 16.1645 16.5008 15.7503L16.5008 14.2509C16.5008 14.2508 16.5008 14.251 16.5008 14.2509C16.5004 13.7524 16.3344 13.268 16.0291 12.8741C15.7236 12.4801 15.296 12.1987 14.8133 12.074C14.4122 11.9705 14.1711 11.5614 14.2746 11.1603Z"
          fill="#8C8E95"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2736 2.16181C11.3764 1.76054 11.7849 1.51853 12.1862 1.62127C12.9928 1.82781 13.7078 2.29693 14.2184 2.95469C14.7289 3.61245 15.0061 4.42143 15.0061 5.25409C15.0061 6.08675 14.7289 6.89572 14.2184 7.55348C13.7078 8.21124 12.9928 8.68037 12.1862 8.8869C11.7849 8.98964 11.3764 8.74763 11.2736 8.34637C11.1709 7.9451 11.4129 7.53652 11.8142 7.43377C12.2981 7.30985 12.7271 7.02838 13.0334 6.63372C13.3398 6.23907 13.5061 5.75368 13.5061 5.25409C13.5061 4.75449 13.3398 4.2691 13.0334 3.87445C12.7271 3.47979 12.2981 3.19832 11.8142 3.0744C11.4129 2.97166 11.1709 2.56308 11.2736 2.16181Z"
          fill="#8C8E95"
        />
      </g>
      <defs>
        <clipPath id="clip0_513_5603">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default PersonsIcon;
