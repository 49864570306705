import { apiCall } from "./servicesCall";

export const AI_SERVICES = {
  textGenerate(data) {
    return apiCall("/ai", data);
  },
  imageGenerate(data) {
    return apiCall("/ai/image", data);
  },
};
