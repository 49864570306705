import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  selectIsConnectedToRoom,
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectPermissions,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Tooltip } from "@100mslive/roomkit-react";
import { ToastManager } from "./Toast/ToastManager";
import IconButton from "../IconButton";
import { OffStageIcon, OnStageIcon } from "../icons";

const StageOffOnToggle = () => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const { role } = useParams();
  const hmsActions = useHMSActions();
  const permissions = useHMSStore(selectPermissions);
  const localPeerId = useHMSStore(selectLocalPeerID);
  const localPeerRole = useHMSStore(selectLocalPeerRoleName);
  const [isOnStage, setIsOnStage] = useState(false);

  const changeStageRole = async () => {
    let newRole = isOnStage ? "admin" : role === "host" ? role : "speaker";

    try {
      await hmsActions.changeRole(localPeerId, newRole, true);
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  };
  useEffect(() => {
    setIsOnStage(localPeerRole === "admin" ? false : true);
  }, [localPeerRole]);

  if (!isConnected) {
    return null;
  }
  if (!permissions?.changeRole) {
    return null;
  }

  return role && (role === "admin" || role === "host" || role === "speaker") ? (
    <Tooltip title={`${isOnStage ? "Off " : "On "} Stage`}>
      <div className="preview_tile_footer_frame">
        <IconButton
          onClick={changeStageRole}
          data-testid="stage_off_on_btn"
          className="preview_tile_footer_btn"
        >
          {isOnStage ? <OnStageIcon /> : <OffStageIcon />}
        </IconButton>
        <span className="preview_tile_footer_indicator">Spotlight</span>
      </div>
    </Tooltip>
  ) : (
    <></>
  );
};

export default StageOffOnToggle;
