import React from "react";
import SixSlideSteps from "./SixSlideSteps";
import WhyJoinSix from "./WhyJoinSix";
import WhySixAccess from "./WhySixAccess";
// import WhySixAttract from "./WhySixAttract";
// import WhySixExpertise from "./WhySixExpertise";
// import WhySixInvestment from "./WhySixInvestment";
import "./style.scss";

const WhySix = () => {
  return (
    <React.Fragment>
      <div className="event-page-whysix-slide active">
        <WhySixAccess />
        <SixSlideSteps />
      </div>

      <WhyJoinSix />
    </React.Fragment>
  );
};

export default WhySix;
