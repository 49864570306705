import React from "react";
import { Edit, EmailIcon, SearchIcon, UpIcon } from "../../icons";

const LandingHowWork = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-landing-howWork">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-section-header">
          <h2 className="events-page-landing-section-header-heading">
            How 6ix Events Works
          </h2>
          <div className="events-page-landing-section-header-text">
            Follow this playbook to get investor attention, attract capital and
            grow your reputation
          </div>
        </div>
        <div className="events-page-landing-howWork-content">
          <div className="events-page-landing-howWork-content-item">
            <div className="events-page-landing-howWork-content-item-title">
              <SearchIcon />
            </div>
            <div className="events-page-landing-howWork-content-item-text">
              Create an event for an earnings call, investor day or digital
              roadshow
            </div>
          </div>
          <div className="events-page-landing-howWork-content-item">
            <div className="events-page-landing-howWork-content-item-title">
              <Edit />
            </div>
            <div className="events-page-landing-howWork-content-item-text">
              Promote your event to existing and prospective investors
            </div>
          </div>
          <div className="events-page-landing-howWork-content-item">
            <div className="events-page-landing-howWork-content-item-title">
              <EmailIcon />
            </div>
            <div className="events-page-landing-howWork-content-item-text">
              Survey investors for their interest before, during and after your
              event
            </div>
          </div>
          <div className="events-page-landing-howWork-content-item">
            <div className="events-page-landing-howWork-content-item-title">
              <UpIcon />
            </div>
            <div className="events-page-landing-howWork-content-item-text">
              Analyze the data and respond to inbound meeting requests
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingHowWork;
