import React from "react";
import Logo from "../../../assets/images/6ixlogo.png";

const SixAboutContent = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-landing-section-space events-page-about-section-about">
        <div className="events-page-about-section-about-content">
          <div className="events-page-about-section-header">
            <div className="events-page-about-section-header-heading text-center">
              About 6ix
            </div>
          </div>

          <div className="events-page-about-section-header-line">
            <div className="events-page-about-section-about-content-logo">
              <img src={Logo} alt="logo" />
            </div>
          </div>

          <div className="events-page-about-section-header-text text-center mt-0">
            Our mission is to unlock social mobility on a global scale by
            empowering anyone from anywhere to invest capital, attract capital
            and share expertise — all from one stage.
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixAboutContent;
