import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "@100mslive/roomkit-react";
import EventFooterLanguage from "./EventFooterLanguage";

const EventFooterMenuCMS = () => {
  return (
    <Flex className="events6ix-layout-footer-cmsmenu">
      <Flex className="events6ix-layout-footer-cmsmenu-inner">
        <div className="events6ix-layout-footer-cmsmenu-link">
          <Link
            className="events6ix-layout-footer-cmsmenu-link-btn"
            to="/disclaimer"
          >
            Disclaimer
          </Link>
        </div>
        <div className="events6ix-layout-footer-cmsmenu-link">
          <Link
            className="events6ix-layout-footer-cmsmenu-link-btn"
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
        </div>
        <div className="events6ix-layout-footer-cmsmenu-link">
          <Link
            className="events6ix-layout-footer-cmsmenu-link-btn"
            to="/terms"
          >
            Terms of Service
          </Link>
        </div>
        <div className="events6ix-layout-footer-cmsmenu-link">
          <EventFooterLanguage />
        </div>
      </Flex>
    </Flex>
  );
};

export default EventFooterMenuCMS;
