import React from "react";
import { Outlet } from "react-router-dom";

const EventsLayoutFull = () => {
  return (
    <React.Fragment>
      <div className="events6ix-layout-wrapper events6ix-layout-wrapper-full">
        <div className="events6ix-layout-wrapper-content">
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EventsLayoutFull;
