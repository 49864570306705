import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Container, Draggable } from "@smooth-dnd/react";
import { arrayMoveImmutable } from "array-move";
import { Flex } from "@100mslive/roomkit-react";
import { Cross, DocIcon, LevelIcon, LogoIcon, MoveIcon } from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { ACCOUNT_SERVICES } from "../../services";

const EventHostedAdd = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { hostedBy, setHostedBy, currentStep, createEvent, setOrgAccountList } =
    props;

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [orgList, setOrgList] = useState([]);
  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData(null);
    }
    return () => null;
  }, [isUserSession]);

  useEffect(() => {
    if (userData && !dataFetched) {
      setDataFetched(true);
      setLoading(true);
      try {
        ACCOUNT_SERVICES.getList({
          userId: userData?.userId,
          org: userData?.org?.id,
        })
          .then(data => {
            if (data.code === 200) {
              if (data?.data && data?.data?.length > 0) {
                if (setOrgAccountList) {
                  setOrgAccountList(data?.data || []);
                }
                setOrgList(data?.data || []);
                if (createEvent) {
                  let selectedRow = data?.data.filter(item => {
                    return item.accountId === userData?.org?.id;
                  });
                  if (selectedRow?.[0]) {
                    selectedRow[0]["allow"] = true;
                    selectedRow[0]["analytics"] = true;
                    setHostedBy(selectedRow);
                  }
                }
              }
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            } else {
              ToastManager.addToast({ title: data.message });
            }
          })
          .catch(err => {
            ToastManager.addToast({ title: err.message });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        ToastManager.addToast({ title: err.message });
      }
    }
  }, [userData, dataFetched, LOGOUT_USER_SESSION, createEvent, setHostedBy]);

  const isSelectedList = value => {
    return hostedBy.find(c => c.accountId === value);
  };
  const handleSelectOrg = value => {
    let list = [...hostedBy];

    var chek = list.find(c => c.accountId === value);
    if (chek) {
      let lists = list.filter(x => {
        return x.accountId !== value;
      });
      setHostedBy(lists);
    } else {
      let selectedRow = orgList.filter(item => {
        return item?.accountId === value;
      });

      if (selectedRow?.[0]) {
        selectedRow[0]["allow"] = true;
        selectedRow[0]["analytics"] = true;
        list.push(selectedRow[0]);
      }
      setHostedBy(list);
    }
  };

  const onMoveSpeakers = ({ removedIndex, addedIndex }) => {
    setHostedBy(hostedBy =>
      arrayMoveImmutable(hostedBy, removedIndex, addedIndex)
    );
  };
  const removeHostedBy = index => {
    let d = [...hostedBy];
    d.splice(index, 1);
    setHostedBy(d);
  };

  const updateItem = (index, key, value) => {
    let sd = [...hostedBy];
    sd[index][key] = value;
    setHostedBy(sd);
  };

  return (
    <>
      {loading && <FullPageProgress fixed={true} />}

      <FormControl className="custom-form-group eventsSpeakers">
        <label htmlFor="event-hosted-add" className="custom-form-label">
          Hosted By
        </label>

        <Select
          id="event-hosted-add"
          name="eventHostedAdd"
          className="custom-select"
          onChange={e => {
            handleSelectOrg(e.target.value);
          }}
          value=""
          displayEmpty
          readOnly={currentStep !== "event"}
          renderValue={selected => {
            if (selected.length === 0) {
              return <span>Select</span>;
            }
            return selected.join(", ");
          }}
        >
          <MenuItem value="">Select</MenuItem>
          {orgList.map((item, key) => {
            return (
              <MenuItem key={key} value={item?.accountId}>
                <Checkbox
                  size="small"
                  checked={isSelectedList(item?.accountId) ? true : false}
                />
                {item?.orgName}
              </MenuItem>
            );
          })}
        </Select>

        {hostedBy?.length > 0 ? (
          <>
            <Flex className="eventsSpeakers-list">
              <div className="eventsSpeakers-list-items">
                <Container
                  dragHandleSelector=".eventsSpeakers-list-items-row-dragHandle"
                  lockAxis="y"
                  onDrop={onMoveSpeakers}
                >
                  {hostedBy.map((item, i) => {
                    return (
                      <Draggable key={i}>
                        <div
                          className={`eventsSpeakers-list-items-row ${
                            hostedBy?.length - 1 === i ? "last" : ""
                          } `}
                        >
                          <div
                            className="eventsSpeakers-list-items-row-dragHandle"
                            title="Move Hosted By"
                          >
                            <MoveIcon />
                          </div>

                          <div className="eventsSpeakers-list-items-row-profile">
                            {item?.orgLogo ? (
                              <img src={item?.orgLogo} alt="" />
                            ) : (
                              <LogoIcon />
                            )}
                          </div>
                          <div className="eventsSpeakers-list-items-row-text">
                            {item?.orgName}
                          </div>
                          <div className="eventsSpeakers-list-items-row-action">
                            <button
                              type="button"
                              title="Remove"
                              className="eventsSpeakers-list-items-row-action-remove"
                              onClick={() => {
                                removeHostedBy(i);
                              }}
                            >
                              <Cross />
                            </button>
                          </div>
                          <div className="eventsSpeakers-list-items-row-role">
                            <Tooltip
                              placement="top"
                              arrow
                              title="Control whether the organization logo is visible on the event"
                            >
                              <button
                                className={`eventsSpeakers-list-items-row-role-btn ${
                                  item?.allow ? " active" : ""
                                } `}
                                onClick={() => {
                                  updateItem(i, "allow", !item?.allow);
                                }}
                                type="button"
                              >
                                <DocIcon />
                              </button>
                            </Tooltip>

                            <Tooltip
                              placement="top"
                              arrow
                              title="Control whether the organization has access to the event analytics"
                            >
                              <button
                                className={`eventsSpeakers-list-items-row-role-btn ${
                                  item?.analytics ? " active" : ""
                                } `}
                                onClick={() => {
                                  updateItem(i, "analytics", !item?.analytics);
                                }}
                                type="button"
                              >
                                <LevelIcon />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </Draggable>
                    );
                  })}
                </Container>
              </div>
            </Flex>
          </>
        ) : null}
      </FormControl>
    </>
  );
};

export default EventHostedAdd;
