import React from "react";
import { CheckMark } from "../../icons";

const LandingPlatform = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-landing-platform">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-platform-content">
          <h2 className="events-page-landing-platform-content-title">
            6ix Events is the only platform with...
          </h2>
          <div className="events-page-landing-platform-content-row">
            <div className="events-page-landing-platform-content-row-title">
              <CheckMark /> Comprehensive Investor Profiles
            </div>
            <div className="events-page-landing-platform-content-row-text">
              Instead of guessing which investors are attending your events, and
              trying to figure out who they are based on their email addresses,
              on 6ix.com, you get their full name, phone number, company name,
              shareholder status, investor sentiment, average cheque size,
              portfolio size and more.
            </div>
          </div>
          <div className="events-page-landing-platform-content-row">
            <div className="events-page-landing-platform-content-row-title">
              <CheckMark /> Inbound Investor Meetings
            </div>
            <div className="events-page-landing-platform-content-row-text">
              Investors are able to request a meeting with management at any
              time, and you are able to receive their request, evaluate whether
              the meeting should happen and accept the meeting.
            </div>
          </div>

          <div className="events-page-landing-platform-content-row">
            <div className="events-page-landing-platform-content-row-title">
              <CheckMark /> Data-Driven Investor Reports
            </div>
            <div className="events-page-landing-platform-content-row-text">
              At a glance, understand your investor audience based on
              information like investor type, cheque size, shareholder status,
              investor sentiment and more, with an ability to see the aggregate
              or to drill down into the individual investor profile.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingPlatform;
