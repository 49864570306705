import React from "react";
export function OnStageIcon() {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.98846 0.61495C4.68225 0.223165 4.11276 0.15065 3.75348 0.494346C2.97654 1.2376 2.33725 2.11578 1.86811 3.08858C1.2683 4.33234 0.962707 5.69742 0.974964 7.07823C0.987221 8.45904 1.317 9.81857 1.9388 11.0516C2.42513 12.0161 3.07991 12.8829 3.86992 13.6125C4.23524 13.9498 4.80337 13.8674 5.10257 13.4703C5.40177 13.0732 5.31762 12.5124 4.96084 12.1661C4.38712 11.609 3.90861 10.9589 3.5467 10.2412C3.04926 9.25475 2.78544 8.16713 2.77563 7.06248C2.76583 5.95783 3.01031 4.86576 3.49015 3.87075C3.83927 3.14684 4.30616 2.48842 4.86991 1.92145C5.22048 1.56888 5.29466 1.00674 4.98846 0.61495Z"
        fill="#E9E9E9"
      />
      <path
        d="M14.9607 13.3811C15.2669 13.7728 15.8364 13.8454 16.1957 13.5017C16.9727 12.7584 17.612 11.8802 18.0811 10.9074C18.6809 9.66368 18.9865 8.2986 18.9743 6.91779C18.962 5.53698 18.6322 4.17746 18.0104 2.94439C17.5241 1.97996 16.8693 1.1131 16.0793 0.383563C15.714 0.0462088 15.1459 0.128679 14.8467 0.525761C14.5475 0.922843 14.6316 1.4836 14.9884 1.82998C15.5621 2.38699 16.0406 3.03714 16.4025 3.75483C16.9 4.74128 17.1638 5.8289 17.1736 6.93355C17.1834 8.03819 16.9389 9.13026 16.4591 10.1253C16.1099 10.8492 15.643 11.5076 15.0793 12.0746C14.7287 12.4271 14.6545 12.9893 14.9607 13.3811Z"
        fill="#E9E9E9"
      />
      <path
        d="M7.54011 2.78336C7.30267 2.37237 6.77181 2.22694 6.40085 2.52302C5.89831 2.92411 5.46527 3.40823 5.12148 3.95635C4.62492 4.74801 4.32964 5.64912 4.26138 6.58111C4.19312 7.5131 4.35394 8.44767 4.72984 9.30332C4.99009 9.89574 5.34797 10.4379 5.7867 10.908C6.11055 11.255 6.65693 11.1886 6.95171 10.8167C7.2465 10.4447 7.17475 9.90877 6.87385 9.54165C6.64326 9.2603 6.45117 8.94787 6.3038 8.61241C6.04067 8.01345 5.92809 7.35925 5.97587 6.70686C6.02366 6.05447 6.23036 5.42369 6.57794 4.86953C6.77262 4.55915 7.00818 4.27808 7.27731 4.03339C7.62847 3.71409 7.77755 3.19435 7.54011 2.78336Z"
        fill="#E9E9E9"
      />
      <path
        d="M13.0425 10.7871C13.3413 11.1559 13.8884 11.2166 14.2084 10.8661C14.642 10.3914 14.994 9.84546 15.2478 9.25031C15.6143 8.39072 15.7649 7.4545 15.6865 6.52329C15.6081 5.59208 15.303 4.69416 14.7979 3.90781C14.4481 3.36337 14.0099 2.88387 13.503 2.48813C13.1288 2.19601 12.5996 2.34706 12.3666 2.76055C12.1337 3.17403 12.2884 3.69216 12.643 4.00771C12.9148 4.24954 13.1534 4.52809 13.3515 4.83638C13.7051 5.38683 13.9186 6.01538 13.9735 6.66723C14.0284 7.31907 13.9229 7.97442 13.6664 8.57614C13.5226 8.91315 13.334 9.2276 13.1065 9.51138C12.8096 9.88167 12.7437 10.4183 13.0425 10.7871Z"
        fill="#E9E9E9"
      />
      <path
        d="M11.5656 6.857C11.5656 7.8158 10.7883 8.59305 9.8295 8.59305C8.87069 8.59305 8.09342 7.8158 8.09342 6.857C8.09342 5.8982 8.87069 5.12095 9.8295 5.12095C10.7883 5.12095 11.5656 5.8982 11.5656 6.857Z"
        fill="#E9E9E9"
      />
    </svg>
  );
}
export default OnStageIcon;
