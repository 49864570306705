import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/6ixlogo.png";

const HeaderLogo = () => {
  return (
    <React.Fragment>
      <div className="events6ix-layout-header-logo">
        <Link title="6ix Events" to="/">
          <img
            className="events6ix-layout-header-logo-img"
            src={logo}
            alt="logo"
            height="24"
            width="35"
          />
        </Link>
      </div>
    </React.Fragment>
  );
};

export default HeaderLogo;
