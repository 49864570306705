import React from "react";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";

const OnBoardingIntro = props => {
  const { onNext } = props;
  return (
    <div className="events-page-onboarding-steps-body">
      <div className="events-page-onboarding-steps-body-title">
        Ready to setup your org account?
      </div>

      <div className="events-page-onboarding-steps-body-text">
        By creating an org account, you will be able to:
      </div>
      <ul className="events-page-onboarding-steps-body-list events-page-onboarding-steps-body-text">
        <li>Collaborate with AI</li>
        <li>Host Events</li>
        <li>Launch ad campaigns</li>
      </ul>
      <div className="events-page-onboarding-steps-body-text">
        All of your investor data will be synced with your org account.
      </div>

      <Button
        color="primary"
        variant="outlined"
        fullWidth
        className="events6ix-btn events6ix-btn-primary"
        type="button"
        onClick={() => onNext(2)}
        endIcon={<ArrowForward />}
        sx={{ textTransform: "none" }}
      >
        Continue
      </Button>
    </div>
  );
};

export default OnBoardingIntro;
