import React from "react";

export function CalenderIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9139 3.33203H4.24723C3.32676 3.33203 2.58057 4.07822 2.58057 4.9987V16.6654C2.58057 17.5858 3.32676 18.332 4.24723 18.332H15.9139C16.8344 18.332 17.5806 17.5858 17.5806 16.6654V4.9987C17.5806 4.07822 16.8344 3.33203 15.9139 3.33203Z"
        stroke="#E3C050"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4126 1.66797V5.0013"
        stroke="#E3C050"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74854 1.66797V5.0013"
        stroke="#E3C050"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.58057 8.33203H17.5806"
        stroke="#E3C050"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CalenderIcon;
