import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Flex, VerticalDivider } from "@100mslive/roomkit-react";
import { Logo, SpeakerTag } from "./HeaderComponents";
import { ParticipantCount } from "./ParticipantList";
import { RequestADemo } from "./RequestADemo";
import { StreamActions } from "./StreamActions";
import { SESSION_AUTH } from "../../common/auth";
export const ConferencingHeader = ({ isPreview }) => {
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  const { role } = useParams();

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    }
    return () => null;
  }, [navigate, isUserSession]);
  return (
    <Flex
      justify="between"
      align="center"
      css={{ position: "relative", height: "100%" }}
      translate="no"
    >
      <Flex align="center" css={{ position: "absolute", left: "$10" }}>
        <Link to="/dashboard">
          <Logo />
        </Link>

        <VerticalDivider css={{ ml: "$8" }} />
        {!isPreview ? <SpeakerTag /> : null}
      </Flex>

      <Flex
        align="center"
        css={{
          position: "absolute",
          right: "$10",
          gap: "$4",
        }}
      >
        <StreamActions />
        {role && (role === "admin" || role === "host" || role === "speaker") ? (
          <ParticipantCount />
        ) : null}
        <RequestADemo />
      </Flex>
    </Flex>
  );
};
