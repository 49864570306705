import React, { useEffect, useState } from "react";
import { Box, Flex } from "@100mslive/roomkit-react";
import { ArrowLeft, Cross } from "../../../icons";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import LoginWithMagic from "./LoginWithMagic";
import SignupWithPassword from "./SignupWithPassword";
import { SESSION_AUTH } from "../../../common/auth";
import "./Login.scss";
export function LoginPopup(props) {
  const { isUserSession } = SESSION_AUTH();
  const { handleLogin, dropOut, cancel } = props;
  const [passwordLogin, setPasswordLogin] = useState(0);
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (isUserSession) {
      handleLogin();
    }
  }, [isUserSession, handleLogin]);

  return (
    <>
      <Flex
        align="center"
        direction="column"
        className="eventsPopup eventsPopup-xs userLogin"
        onClick={() => {
          if (dropOut) {
            handleLogin();
          }
        }}
      >
        <Box
          className="eventsPopup-inner"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <>
            <div className="eventsPopup-header">
              <div className="eventsPopup-header-heading">
                {passwordLogin === 1 ? (
                  <>Continue with Email </>
                ) : passwordLogin === 2 ? (
                  <>Sign up with password </>
                ) : passwordLogin === 3 ? (
                  <>Password recovery</>
                ) : (
                  <>Enter your email</>
                )}
              </div>
              <div className="eventsPopup-header-desc">
                {passwordLogin === 2 ? (
                  <>If you don't have an account, please register here</>
                ) : passwordLogin === 3 ? (
                  <>
                    Enter your email and we will send you a link to recover your
                    password
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {cancel && (
              <div className="eventsPopup-close" onClick={handleLogin}>
                <Cross />
              </div>
            )}
            <div className="eventsPopup-content">
              {passwordLogin === 1 ? (
                <>
                  <LoginWithMagic
                    email={email}
                    setEmail={setEmail}
                    handleLogin={handleLogin}
                    setPasswordLogin={setPasswordLogin}
                  />
                </>
              ) : passwordLogin === 2 ? (
                <>
                  <SignupWithPassword
                    setPasswordLogin={setPasswordLogin}
                    handleLogin={handleLogin}
                  />
                  <Flex className="userLogin-email-footer">
                    <div
                      onClick={() => setPasswordLogin(0)}
                      className="userLogin-email-link userLogin-email-footer-col"
                    >
                      <ArrowLeft /> Go Back
                    </div>
                    <div className="userLogin-email-footer-col">
                      Already have an account?
                      <span
                        className="userLogin-email-link"
                        onClick={() => {
                          setPasswordLogin(1);
                        }}
                      >
                        &nbsp; Log in
                      </span>
                    </div>
                  </Flex>
                </>
              ) : passwordLogin === 3 ? (
                <>
                  <ForgotPassword
                    email={email}
                    setEmail={setEmail}
                    setPasswordLogin={setPasswordLogin}
                  />
                </>
              ) : (
                <Login
                  setPasswordLogin={setPasswordLogin}
                  handleLogin={handleLogin}
                  setEmail={setEmail}
                  email={email}
                />
              )}
            </div>
          </>
        </Box>
      </Flex>
    </>
  );
}
