import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useMedia } from "react-use";
import { Box, config as cssConfig, Flex } from "@100mslive/roomkit-react";
import { useGlobalValue } from "../../../GlobalContext";

const EventHeaderSidebar = () => {
  const { setOpenSidebar } = useGlobalValue();
  const [activeClass, setActiveClass] = useState("");
  const isMobile = useMedia(cssConfig.media.md);

  useEffect(() => {
    setActiveClass("active");
    return () => null;
  }, [isMobile]);

  return isMobile ? (
    <React.Fragment>
      <Box className={`events6ix-layout-sidebar  ${activeClass}`}>
        <Flex direction="column" className="events6ix-layout-sidebar-inner">
          <Box className="events6ix-layout-sidebar-body">
            <Box className="events6ix-layout-sidebar-header">
              Research Companies
            </Box>
            <NavLink
              to="/explore"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Explore the Marketplace
            </NavLink>
            <NavLink
              to="https://ai.6ix.com"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Chat with 6ix AI
            </NavLink>
            <NavLink
              to="/request-meeting"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Book 1:1 Meetings
            </NavLink>
            <div className="events6ix-layout-sidebar-divider"></div>

            <Box className="events6ix-layout-sidebar-header">
              Attract Capital
            </Box>

            <NavLink
              to="https://leads.6ix.com"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Get Leads
            </NavLink>
            <NavLink
              to="/create"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Host Events
            </NavLink>
            <NavLink
              to="https://meetings.6ix.com"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Schedule Meetings
            </NavLink>
            <div className="events6ix-layout-sidebar-divider"></div>

            <Box className="events6ix-layout-sidebar-header">
              Share Expertise
            </Box>
            <NavLink
              to="/broadcast"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Broadcast Interviews
            </NavLink>
            <div className="events6ix-layout-sidebar-divider"></div>
            <NavLink
              to="/support"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              Contact sales
            </NavLink>
            <NavLink
              to="/demo"
              className="events6ix-layout-sidebar-link"
              onClick={() => {
                setOpenSidebar(false);
              }}
            >
              View demo
            </NavLink>
          </Box>
        </Flex>
      </Box>

      <div
        className="events6ix-layout-sidebar-overlay"
        onClick={() => setOpenSidebar(false)}
      ></div>
    </React.Fragment>
  ) : null;
};

export default EventHeaderSidebar;
