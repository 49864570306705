import React from "react";
export function GlobeIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17C13.1421 17 16.5 13.6421 16.5 9.49997C16.5 5.35783 13.1421 1.99997 9 1.99997C4.85786 1.99997 1.5 5.35783 1.5 9.49997C1.5 13.6421 4.85786 17 9 17Z"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 9.49979H16.5"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 1.99997C10.876 4.05373 11.9421 6.71899 12 9.49997C11.9421 12.2809 10.876 14.9462 9 17C7.12404 14.9462 6.05794 12.2809 6 9.49997C6.05794 6.71899 7.12404 4.05373 9 1.99997V1.99997Z"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default GlobeIcon;
