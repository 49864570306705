import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { Box } from "@100mslive/roomkit-react";
import { AngleIcon } from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import MailChimpListPopup from "./MailChimpListPopup";
import { SESSION_AUTH } from "../../common/auth";
import {
  ANALYTICS_SERVICES,
  CONSTANTCONTACT_SERVICES,
  HUBSPOT_SERVICES,
  MAILCHIMP_SERVICES,
} from "../../services";
import { SET_PUBLIC_SESSION } from "../../common/constants";

export function AnalyticsCSV(props) {
  const { isSubscription } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { eventId } = useParams();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const MAILCHIMP_C = params.get("MAILCHIMP_C");
  const [syncListOpen, setSyncListOpen] = useState("");
  const [syncListData, setSyncListData] = useState("");
  const [syncListSelected, setSyncListSelected] = useState("");

  const CONSTANTCONACT_C = params.get("CONSTANTCONACT_C");
  const HUBSPOT_C = params.get("HUBSPOT_C");

  const [actionBoxEl, setActionBoxEl] = useState(null);
  const openActionDrop = Boolean(actionBoxEl);
  const handleActionDrop = event => {
    setActionBoxEl(actionBoxEl ? null : event.currentTarget);
  };

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);

    if (MAILCHIMP_C) {
      getMailChimpList();
    } else if (CONSTANTCONACT_C) {
      getConstantContactList();
    } else if (HUBSPOT_C) {
      getHubSpotSync();
    }

    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userData,
    MAILCHIMP_C,
    dataFetched,
    LOGOUT_USER_SESSION,
    navigate,
    CONSTANTCONACT_C,
    HUBSPOT_C,
  ]);

  const getMailChimpList = () => {
    if (!isSubscription) {
      navigate("/account/setting/billing");
      return;
    }
    setLoading(true);
    MAILCHIMP_SERVICES.list({
      userId: userData.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setSyncListData(data.data || []);
          setSyncListOpen("MailChimp");
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else if (data.code === 401) {
          requestMailchimpConnect();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const requestMailchimpConnect = async () => {
    if (!isSubscription) {
      navigate("/account/setting/billing");
      return;
    }

    setLoading(true);

    try {
      const data = await MAILCHIMP_SERVICES.connect({
        userId: userData.userId,
        org: userData?.org?.id,
      });
      if (data.code === 200) {
        SET_PUBLIC_SESSION("E6IX_MAILCHIMP", "/event/analytics/" + eventId);
        window.location.href = data.url;
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
        setLoading(false);
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
  };
  const requestExportToCsv = () => {
    if (isSubscription) {
      setLoading(true);
      try {
        ANALYTICS_SERVICES.csv({
          userId: userData.userId,
          org: userData?.org?.id,
          id: eventId,
        })
          .then(data => {
            if (data.code === 200) {
              ToastManager.addToast({ title: data.message });
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            } else {
              ToastManager.addToast({ title: data.message });
            }
          })
          .catch(error => {
            ToastManager.addToast({ title: error.message });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        ToastManager.addToast({ title: error.message });
      }
    } else {
      navigate("/account/setting/billing");
    }
  };

  const requestConstantContactConnect = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);

    try {
      const data = await CONSTANTCONTACT_SERVICES.connect({
        userId: userData.userId,
        org: userData?.org?.id,
      });
      if (data.code === 200) {
        SET_PUBLIC_SESSION("E6IX_MAILCHIMP", "/event/analytics/" + eventId);
        window.location.href = data.url;
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
        setLoading(false);
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
  };

  const getConstantContactList = () => {
    if (!isSubscription) {
      navigate("/account/setting/billing");
      return;
    }
    setLoading(true);
    CONSTANTCONTACT_SERVICES.list({
      userId: userData.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setSyncListData(data.data || []);
          setSyncListOpen("Constant Contact");
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else if (data.code === 401) {
          requestConstantContactConnect();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const startSYNCData = () => {
    if (!syncListSelected) {
      ToastManager.addToast({ title: `Choose ${syncListOpen} list` });
      return;
    }

    if (syncListOpen === "Constant Contact") {
      setLoading(true);
      setSyncListOpen("");
      CONSTANTCONTACT_SERVICES.syncAnalytics({
        userId: userData.userId,
        org: userData?.org?.id,
        id: eventId,
        list: syncListSelected,
      })
        .then(data => {
          if (data.code === 200) {
            ToastManager.addToast({ title: data.message });
            setSyncListSelected("");
            navigate("/event/analytics/" + eventId, { replace: true });
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (syncListOpen === "MailChimp") {
      setLoading(true);
      setSyncListOpen("");
      MAILCHIMP_SERVICES.syncAnalytics({
        userId: userData.userId,
        id: eventId,
        list: syncListSelected,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            ToastManager.addToast({ title: data.message });
            setSyncListSelected("");
            navigate("/event/analytics/" + eventId, { replace: true });
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const requestHubSpotConnect = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);

    try {
      const data = await HUBSPOT_SERVICES.connect({
        userId: userData.userId,
        org: userData?.org?.id,
      });
      if (data.code === 200) {
        SET_PUBLIC_SESSION("E6IX_MAILCHIMP", "/event/analytics/" + eventId);
        window.location.href = data.url;
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
        setLoading(false);
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
  };
  const getHubSpotSync = () => {
    if (!isSubscription) {
      navigate("/account/setting/billing");
      return;
    }
    setLoading(true);

    HUBSPOT_SERVICES.syncAnalytics({
      userId: userData.userId,
      org: userData?.org?.id,
      id: eventId,
    })
      .then(data => {
        if (data.code === 200) {
          ToastManager.addToast({ title: data.message });
          setSyncListSelected("");
          navigate("/event/analytics/" + eventId, { replace: true });
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else if (data.code === 401) {
          requestHubSpotConnect();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      {loading && <FullPageProgress fixed={true} />}

      <Box className="relationship-container-inner-content-action">
        <button
          type="button"
          className={`relationship-container-inner-content-action-btn ${
            openActionDrop ? "active" : ""
          }`}
          aria-controls={openActionDrop ? "analytics-dropdown-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openActionDrop ? "true" : undefined}
          onClick={handleActionDrop}
        >
          Export <AngleIcon />
        </button>
        <Menu
          id="analytics-dropdown-menu"
          className="relationship-container-inner-content-action-dropdown"
          aria-labelledby="analytics-dropdown-menu"
          anchorEl={actionBoxEl}
          open={openActionDrop}
          onClose={handleActionDrop}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() => {
              handleActionDrop();
              requestExportToCsv();
            }}
            className="relationship-container-inner-content-action-dropdown-item"
          >
            Export to CSV
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleActionDrop();
              getMailChimpList();
            }}
            className="relationship-container-inner-content-action-dropdown-item"
          >
            Export to Mailchimp
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleActionDrop();
              getConstantContactList();
            }}
            className="relationship-container-inner-content-action-dropdown-item"
          >
            Export to Constant Contact
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleActionDrop();
              getHubSpotSync();
            }}
            className="relationship-container-inner-content-action-dropdown-item"
          >
            Export to HubSpot
          </MenuItem>
        </Menu>
      </Box>

      {syncListOpen && (
        <MailChimpListPopup
          listData={syncListData}
          syncListSelected={syncListSelected}
          setSyncListSelected={setSyncListSelected}
          setSyncListOpen={setSyncListOpen}
          startSYNCData={startSYNCData}
          syncListOpen={syncListOpen}
        />
      )}
    </React.Fragment>
  );
}
