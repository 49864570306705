import React from "react";
export function MicIcon({ on }) {
  return on ? (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1185_69037)">
        <path
          d="M8 1.16663C7.46957 1.16663 6.96086 1.37734 6.58579 1.75241C6.21071 2.12748 6 2.63619 6 3.16663V8.49996C6 9.03039 6.21071 9.5391 6.58579 9.91417C6.96086 10.2892 7.46957 10.5 8 10.5C8.53043 10.5 9.03914 10.2892 9.41421 9.91417C9.78929 9.5391 10 9.03039 10 8.49996V3.16663C10 2.63619 9.78929 2.12748 9.41421 1.75241C9.03914 1.37734 8.53043 1.16663 8 1.16663V1.16663Z"
          stroke="#E9E9E9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6663 7.16663V8.49996C12.6663 9.73764 12.1747 10.9246 11.2995 11.7998C10.4243 12.675 9.23735 13.1666 7.99967 13.1666C6.762 13.1666 5.57501 12.675 4.69984 11.7998C3.82467 10.9246 3.33301 9.73764 3.33301 8.49996V7.16663"
          stroke="#E9E9E9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 13.1666V15.8333"
          stroke="#E9E9E9"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.3335 15.8334H10.6668"
          stroke="#E9E9E9"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1185_69037">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1185_68608)">
        <path
          d="M0.666992 1.16663L15.3337 15.8333"
          stroke="#E64135"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 6.72669V3.16669C10.0005 2.67071 9.81669 2.19223 9.48425 1.82415C9.15181 1.45607 8.69447 1.22465 8.201 1.1748C7.70753 1.12496 7.21314 1.26025 6.81381 1.55441C6.41448 1.84858 6.1387 2.28063 6.04 2.76669M6 6.50002V8.50002C6.00035 8.89532 6.11783 9.28166 6.33762 9.61024C6.55741 9.93881 6.86964 10.1949 7.23487 10.3461C7.60011 10.4973 8.00197 10.5369 8.3897 10.4599C8.77743 10.3829 9.13363 10.1927 9.41333 9.91335L6 6.50002Z"
          stroke="#E64135"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6665 7.16663V8.49996C12.6662 8.77492 12.6417 9.04932 12.5931 9.31996M11.3331 11.8C10.6823 12.4642 9.84788 12.9189 8.93687 13.1057C8.02586 13.2925 7.07982 13.2028 6.22013 12.8481C5.36044 12.4935 4.62631 11.8901 4.11191 11.1154C3.59752 10.3407 3.32633 9.42989 3.33313 8.49996V7.16663L11.3331 11.8Z"
          stroke="#E64135"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 13.1666V15.8333"
          stroke="#E64135"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33301 15.8334H10.6663"
          stroke="#E64135"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1185_68608">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default MicIcon;
