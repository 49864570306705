import React from "react";
import { ConferencingFooter } from "./Footer/ConferencingFooter";
import { StreamingFooter } from "./Footer/StreamingFooter";
import { isStreamingKit } from "../common/utils";

export const Footer = props => {
  const { hasWidget, hasChatQA } = props;
  return isStreamingKit() ? (
    <StreamingFooter />
  ) : (
    <ConferencingFooter hasWidget={hasWidget} hasChatQA={hasChatQA} />
  );
};
