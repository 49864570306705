import React from "react";
import { CareerListIcon } from "../../../icons";

const SixCareersAt = () => {
  return (
    <section className="events-page-landing-section events-page-careers-section-at">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-careers-section-header text-center">
          <div className="events-page-careers-section-header-heading">
            A Career at 6ix
          </div>
          <div className="events-page-careers-section-header-text">
            Live for a time like others won't for a chance to live forever like
            others can't
          </div>
        </div>
        <div className="events-page-careers-section-at-row">
          <div className="events-page-careers-section-at-row-col">
            <div className="events-page-careers-section-at-row-col-heading">
              Our Committment to <span>You</span>
            </div>

            <div className="events-page-careers-section-at-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-icon">
                <CareerListIcon type="star" />
              </div>
              <div className="events-page-careers-section-at-row-col-list-content">
                <div className="events-page-careers-section-at-row-col-list-heading">
                  Change The World For The Better
                </div>
                <div className="events-page-careers-section-at-row-col-list-text">
                  We believe that people should be part of building the future,
                  rather than feeling like its something that happens to them.
                  At 6ix, you’ll be part of building the future of investment by
                  promoting the free flow of opportunities, ideas, and capital.
                </div>
              </div>
            </div>
            <div className="events-page-careers-section-at-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-icon">
                <CareerListIcon type="hands" />
              </div>
              <div className="events-page-careers-section-at-row-col-list-content">
                <div className="events-page-careers-section-at-row-col-list-heading">
                  Radical Transparency
                </div>
                <div className="events-page-careers-section-at-row-col-list-text">
                  We commit ourselves to being transparent, organization wide,
                  about our investment thesis and goals. Success and failures
                  will be communicated honestly and openly.
                </div>
              </div>
            </div>

            <div className="events-page-careers-section-at-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-icon">
                <CareerListIcon type="marketing" />
              </div>
              <div className="events-page-careers-section-at-row-col-list-content">
                <div className="events-page-careers-section-at-row-col-list-heading">
                  Massive Opportunities for Career Growth
                </div>
                <div className="events-page-careers-section-at-row-col-list-text">
                  Power comes from necessity. Leadership at 6ix is earned
                  through performance, not seniority. At 6ix, you can grow
                  beyond even your own expectations.
                </div>
              </div>
            </div>
          </div>
          <div className="events-page-careers-section-at-row-col">
            <div className="events-page-careers-section-at-row-col-heading">
              Your Committment to <span>Us</span>
            </div>

            <div className="events-page-careers-section-at-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-icon">
                <CareerListIcon type="work" />
              </div>
              <div className="events-page-careers-section-at-row-col-list-content">
                <div className="events-page-careers-section-at-row-col-list-heading">
                  Your Life's Work
                </div>
                <div className="events-page-careers-section-at-row-col-list-text">
                  Do the best work of your career here. Push yourself to the
                  height of your craft. And then fly higher.
                </div>
              </div>
            </div>
            <div className="events-page-careers-section-at-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-icon">
                <CareerListIcon type="what" />
              </div>
              <div className="events-page-careers-section-at-row-col-list-content">
                <div className="events-page-careers-section-at-row-col-list-heading">
                  You Are What You Do
                </div>
                <div className="events-page-careers-section-at-row-col-list-text">
                  Own your functional area. Take pride in your work and how your
                  work shapes your identity.
                </div>
              </div>
            </div>
            <div className="events-page-careers-section-at-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-icon">
                <CareerListIcon type="ideas" />
              </div>
              <div className="events-page-careers-section-at-row-col-list-content">
                <div className="events-page-careers-section-at-row-col-list-heading">
                  Your Best Ideas
                </div>
                <div className="events-page-careers-section-at-row-col-list-text">
                  Be obsessed with the problems you’re trying to solve. Let your
                  best ideas win.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SixCareersAt;
