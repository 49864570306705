import React from "react";
export function PlayIcon() {
  return (
    <svg
      width="64"
      height="65"
      viewBox="0 0 64 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.800049"
        width="64"
        height="64"
        rx="32"
        fill="#08090D"
        fillOpacity="0.8"
      />
      <g clipPath="url(#clip0_3496_162988)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.5827 22.5575L28.6493 22.6002L39.7187 29.7162L39.7813 29.7562C40.5133 30.2268 41.1547 30.6388 41.604 31.0322C42.0787 31.4468 42.5347 32.0068 42.5347 32.8002C42.5347 33.5935 42.0787 34.1535 41.604 34.5682C41.1547 34.9615 40.512 35.3735 39.7813 35.8442L39.7187 35.8842L28.6493 43.0002L28.5827 43.0428C27.7333 43.5895 27.0013 44.0602 26.3933 44.3348C25.7667 44.6175 24.9853 44.8268 24.2147 44.4055C23.4453 43.9855 23.1987 43.2148 23.0973 42.5348C23 41.8748 23 41.0055 23 39.9948V25.6842V25.6055C23 24.5948 23 23.7255 23.0973 23.0655C23.1987 22.3855 23.4453 21.6148 24.2147 21.1948C24.9853 20.7735 25.7667 20.9828 26.3933 21.2668C27.0013 21.5415 27.7333 22.0108 28.5827 22.5575ZM25.14 22.9602C25.14 22.9602 25.144 22.9575 25.1573 22.9575L25.14 22.9602ZM25.1787 22.9588C25.232 22.9628 25.3507 22.9895 25.5707 23.0895C26.0267 23.2948 26.6333 23.6815 27.568 24.2828L38.636 31.3988C39.4493 31.9215 39.9627 32.2548 40.2867 32.5375C40.44 32.6708 40.5027 32.7548 40.5267 32.7948L40.5307 32.8002L40.5267 32.8055C40.4597 32.9026 40.3789 32.9893 40.2867 33.0628C39.9627 33.3468 39.4507 33.6788 38.636 34.2028L27.568 41.3175C26.6333 41.9188 26.0267 42.3042 25.5707 42.5108C25.4474 42.5741 25.3152 42.6182 25.1787 42.6415C25.1243 42.5141 25.0897 42.3793 25.076 42.2415C25.0027 41.7468 25 41.0282 25 39.9162V25.6842C25 24.5735 25.0027 23.8535 25.076 23.3588C25.112 23.1188 25.1533 23.0068 25.1787 22.9588ZM25.1387 42.6402L25.1573 42.6428C25.144 42.6428 25.1373 42.6415 25.1387 42.6402ZM25.1893 42.6602C25.1973 42.6668 25.2013 42.6735 25.2 42.6735C25.2 42.6735 25.196 42.6708 25.1893 42.6602ZM25.1893 22.9402C25.196 22.9295 25.2 22.9268 25.2 22.9268C25.2 22.9268 25.1973 22.9335 25.1893 22.9402Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3496_162988">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(16 16.8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default PlayIcon;
