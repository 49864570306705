import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "@100mslive/roomkit-react";

const EventFooterMenu = () => {
  return (
    <Flex className="events6ix-layout-footer-menu">
      <Link className="events6ix-layout-footer-menu-link" to="/">
        Home
      </Link>
      <Link className="events6ix-layout-footer-menu-link" to="/about">
        About
      </Link>
      <Link className="events6ix-layout-footer-menu-link" to="/careers">
        Careers
      </Link>
      <Link className="events6ix-layout-footer-menu-link" to="/security">
        Security
      </Link>
    </Flex>
  );
};

export default EventFooterMenu;
