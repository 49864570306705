import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TablePagination } from "@mui/material";
import Moment from "moment";
import { Box, Flex } from "@100mslive/roomkit-react";
import { AscendingIcon, DescendingIcon } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { ANALYTICS_SERVICES } from "../../../services";

const AnalyticsInvitedTable = props => {
  const { eventId } = useParams();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const { totalRows } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [sorting, setSorting] = useState(false);
  const [sortedField, setSortedField] = React.useState(null);
  const [sortDirection, setSortDirection] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [dataFetched, setDataFetched] = useState(false);
  const [listData, setListData] = useState([]);
  const columns = [
    { lable: "Name", target: "name" },
    { lable: "Email", target: "email" },
    { lable: "Invited Date", target: "inviteDate" },
    { lable: "Registered Date", target: "registerDate" },
    { lable: "Attended Date", target: "attendDate" },
  ];

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!sorting || !sortDirection || !sortedField) return;
    setSorting(false);
    let listDataArray = [...listData];
    listDataArray.sort((a, b) => {
      let aa = a?.[sortedField.target];
      let bb = b?.[sortedField.target];
      aa = aa || "";
      bb = bb || "";

      if (aa < bb) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (aa > bb) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setListData(listDataArray);
  }, [sorting, listData, sortDirection, sortedField]);

  const sortRequest = item => {
    setSortDirection(
      sortedField?.target === item?.target
        ? sortDirection === "descending"
          ? "ascending"
          : "descending"
        : "ascending"
    );

    setSortedField(item);
    setSorting(true);
  };

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoading(true);
    try {
      ANALYTICS_SERVICES.invitedList({
        userId: userData.userId,
        org: userData?.org?.id,
        page: page,
        limit: limit,
        id: eventId,
      })
        .then(data => {
          if (data.code === 200) {
            setListData(data?.data?.list || []);
            setSorting(true);
            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [
    userData,
    dataFetched,
    LOGOUT_USER_SESSION,
    limit,
    page,
    eventId,
    navigate,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setDataFetched(false);
  };
  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value);
    setPage(0);
    setDataFetched(false);
  };

  return (
    <>
      {loading && <FullPageProgress fixed={true} />}
      <div className="relationship">
        <Box className="relationship-usertable" style={{ marginTop: "20px" }}>
          <table className="relationship-usertable-table">
            <thead>
              <tr>
                {columns.map((item, key) => (
                  <th
                    className={
                      item?.target && sortedField?.target === item?.target
                        ? "highlight"
                        : null
                    }
                    key={key}
                    onClick={() => {
                      if (item?.target) {
                        sortRequest(item);
                      }
                    }}
                  >
                    <div>
                      {item?.lable}
                      {item?.target ? (
                        sortDirection === "descending" &&
                        sortedField?.target === item?.target ? (
                          <DescendingIcon />
                        ) : (
                          <AscendingIcon />
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listData.map((item, key) => (
                <tr key={key}>
                  <td>
                    <Flex style={{ cursor: "pointer" }}>
                      <span>{item?.name || "-"}</span>
                    </Flex>
                  </td>
                  <td>{item?.email}</td>

                  <td>
                    {item?.inviteDate ? (
                      <>
                        {Moment(item?.inviteDate).format(
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.registerDate ? (
                      <>
                        {Moment(item?.registerDate).format(
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {item?.attendDate ? (
                      <>
                        {Moment(item?.attendDate).format(
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        {totalRows ? (
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showLastButton={true}
            showFirstButton={true}
            rowsPerPageOptions={[50, 100, 250, 500, 1000]}
          />
        ) : null}
      </div>
    </>
  );
};

export default AnalyticsInvitedTable;
