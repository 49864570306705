import React from "react";
export function Heart(props) {
  return props.active ? (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2168_65129)">
        <path
          d="M14.3941 3.07309C14.0536 2.73242 13.6493 2.46218 13.2043 2.27781C12.7593 2.09343 12.2824 1.99854 11.8007 1.99854C11.3191 1.99854 10.8421 2.09343 10.3972 2.27781C9.95219 2.46218 9.5479 2.73242 9.2074 3.07309L8.50073 3.77975L7.79406 3.07309C7.10627 2.38529 6.17342 1.99889 5.20073 1.99889C4.22804 1.99889 3.29519 2.38529 2.6074 3.07309C1.9196 3.76088 1.5332 4.69373 1.5332 5.66642C1.5332 6.63911 1.9196 7.57196 2.6074 8.25975L3.31406 8.96642L8.50073 14.1531L13.6874 8.96642L14.3941 8.25975C14.7347 7.91925 15.005 7.51496 15.1893 7.06999C15.3737 6.62502 15.4686 6.14808 15.4686 5.66642C15.4686 5.18476 15.3737 4.70782 15.1893 4.26285C15.005 3.81788 14.7347 3.41359 14.3941 3.07309Z"
          fill="#E64135"
          stroke="#E64135"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2168_65129">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8941 3.07309C13.5536 2.73242 13.1493 2.46218 12.7043 2.27781C12.2593 2.09343 11.7824 1.99854 11.3007 1.99854C10.8191 1.99854 10.3421 2.09343 9.89716 2.27781C9.45219 2.46218 9.0479 2.73242 8.7074 3.07309L8.00073 3.77975L7.29406 3.07309C6.60627 2.38529 5.67342 1.99889 4.70073 1.99889C3.72804 1.99889 2.79519 2.38529 2.1074 3.07309C1.4196 3.76088 1.0332 4.69373 1.0332 5.66642C1.0332 6.63911 1.4196 7.57196 2.1074 8.25975L2.81406 8.96642L8.00073 14.1531L13.1874 8.96642L13.8941 8.25975C14.2347 7.91925 14.505 7.51496 14.6893 7.06999C14.8737 6.62502 14.9686 6.14808 14.9686 5.66642C14.9686 5.18476 14.8737 4.70782 14.6893 4.26285C14.505 3.81788 14.2347 3.41359 13.8941 3.07309V3.07309Z"
        stroke="#8C8E95"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default Heart;
