import React from "react";

const SixCareersBuild = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-careers-section-build">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-careers-section-build-row">
          <div className="events-page-careers-section-build-content">
            <div className="events-page-careers-section-header">
              <div className="events-page-careers-section-header-heading">
                Why build your career at 6ix?
              </div>
            </div>
          </div>

          <div className="events-page-careers-section-build-content">
            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Transform the investment landscale
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                Be part of creating a unified platform that sits at the center
                of all investment activities. We're not just changing how
                investments are made; we're fundamentally altering who gets to
                participate in the world's economic growth.
              </div>
            </div>

            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Global impact
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                Our platform serves retail, accredited & institutional
                investors, public and private companies, and industry experts
                across the world. Your work will directly impact how capital
                flows and how investment decisions are made on a global scale.
              </div>
            </div>

            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Innovative Technology
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                Work with cutting-edge technologies including AI-powered
                matching algorithms, blockchain integration, and advanced data
                analytics to create a more efficient and equitable global
                financial system.
              </div>
            </div>

            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Rapid growth
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                Join a company at the forefront of a massive market opportunity,
                connecting every investor, every company and every expert.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SixCareersBuild;
