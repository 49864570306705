import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Box, Flex, Label, Switch, Text } from "@100mslive/roomkit-react";
import { InfoIcon } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../services";

const AccountWidget = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { widget, isEditAccess } = props;
  const [script, setScript] = useState("");
  const [status, setStatus] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setScript(widget?.script);
    setStatus(widget?.status || 0);
  }, [widget]);

  const onSave = () => {
    if (status && !script) {
      return ToastManager.addToast({ title: "Enter widget script" });
    }
    setLoading(true);
    ACCOUNT_SERVICES.widgetSave({
      userId: isUserSession.userId,
      org: isUserSession?.org?.id,
      script: script.trim(),
      status: status ? 1 : 0,
    })
      .then(data => {
        if (data.code === 200) {
          ToastManager.addToast({ title: data.message });
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="events-page-account-setting-content-row">
      {loading && <FullPageProgress fixed={true} />}
      <Box className="events-page-account-setting-content-box">
        <div className="events-page-account-setting-content-box-title">
          Stock Widget
        </div>

        <FormControl className="events-page-account-setting-content-box-control">
          <Label
            className="events-page-account-setting-content-box-lable"
            css={{
              cursor: "pointer",
              gap: "$8",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Switch
              checked={status}
              onCheckedChange={() => {
                if (isEditAccess) {
                  setStatus(!status);
                }
              }}
            />
            Add Widget
            <Tooltip
              placement="top"
              arrow
              title="Add Widget: Select whether you want to have a custom panel where you can embed 3rd party content such as a PDF download, 3D virtual reality presentation, Web3 Wallet or anything else."
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Label>
        </FormControl>
        {status ? (
          <div className="home-page-layout-content-box-des">
            <TextareaAutosize
              placeholder="Enter script"
              value={script}
              readOnly={!isEditAccess}
              onChange={e => {
                if (isEditAccess) {
                  setScript(e.target.value);
                }
              }}
              className="custom-form-input custom-form-input-sm"
              minRows={2}
              maxRows={5}
            />
          </div>
        ) : null}

        {isEditAccess && (
          <>
            <div className="events-page-account-setting-content-box-line"></div>

            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Text
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text"
              >
                Stock widget for event meeting.
              </Text>
              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                onClick={() => onSave()}
              >
                Save
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </div>
  );
};

export default AccountWidget;
