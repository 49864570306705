import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Label } from "@100mslive/roomkit-react";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { accountTeamRoleList } from "../../../common/utils";
import { ACCOUNT_SERVICES } from "../../../services";
const AccountMemberManageRole = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { open, onClose, member, onUpdate } = props;
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setRole(member?.role);
  }, [member]);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      navigate("/");
    }
  }, [isUserSession, navigate]);

  const onSave = async () => {
    setLoading(true);
    try {
      const data = await ACCOUNT_SERVICES.updateMemberRole({
        userId: userData?.userId,
        org: userData?.org?.id,
        role: role,
        id: member.userId,
      });
      if (data.code === 200) {
        onClose();
        onUpdate();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog eventAiPopup"
    >
      {loading && <FullPageProgress fixed={true} />}

      <DialogTitle className="text-center">
        Manage Role <br /> <small>{member?.profile?.email}</small>
      </DialogTitle>
      <DialogContent>
        <FormControl className="custom-form-group">
          <Label
            htmlFor="invite-role"
            className="events-page-account-setting-content-box-lable"
          >
            Role
          </Label>
          <Select
            id="invite-role"
            className="custom-select"
            sx={{
              height: "45px",
              background: "#131313",
              borderRadius: "8px",
              width: "auto",
            }}
            value={role}
            onChange={e => setRole(e.target.value)}
            displayEmpty
          >
            {Object.keys(accountTeamRoleList).map((item, index) => (
              <MenuItem key={index} value={accountTeamRoleList[item].value}>
                {accountTeamRoleList[item].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          Cancel
        </Button>
        {member?.role !== role && (
          <Button
            onClick={onSave}
            variant="outlined"
            color="primary"
            className="events6ix-btn events6ix-btn-primary"
            type="button"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AccountMemberManageRole;
