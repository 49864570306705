import React from "react";
import { Link } from "react-router-dom";
import { useMedia } from "react-use";
import { Button } from "@mui/material";
import { config as cssConfig } from "@100mslive/roomkit-react";
import frame1 from "../../../assets/images/six/frame1-2.webp";
import frame2 from "../../../assets/images/six/frame2-2.webp";

const WhySixAccess = () => {
  const isMobile = useMedia(cssConfig.media.sm);
  return (
    <React.Fragment>
      <div className="event-page-whysix-slide-access">
        {!isMobile && (
          <div className="event-page-whysix-slide-access-frame">
            <img src={frame1} alt="6ix frame" width="540" height="360" />
          </div>
        )}
        <div className="event-page-whysix-slide-access-content">
          <div className="event-page-whysix-slide-access-content-top">
            believe in capitalism again
          </div>
          <div className="event-page-whysix-slide-access-content-heading">
            6ix is your all-access pass to the investment world!
          </div>
          <div className="event-page-whysix-slide-access-content-bottom">
            Invest, attract capital and share expertise — all from one digital
            stage.
          </div>
          <div className="event-page-whysix-slide-access-content-text">
            Whether you’re building your investment portfolio,
            <br />
            raising a fresh round of capital or aspiring to share your
            knowledge,
            <br /> 6ix is your all-access pass to the investment world!
          </div>
          <div className="event-page-whysix-slide-access-content-buttons">
            <Button
              component={Link}
              to="/create"
              color="primary"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary"
            >
              GET STARTED
            </Button>
          </div>
        </div>
        {!isMobile && (
          <div className="event-page-whysix-slide-access-frame">
            <img src={frame2} alt="6ix frame2" width="540" height="360" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default WhySixAccess;
