import React, { useEffect, useState } from "react";
import { FormControl, Tooltip } from "@mui/material";
import { Container, Draggable } from "@smooth-dnd/react";
import { arrayMoveImmutable } from "array-move";
import { Flex, Input } from "@100mslive/roomkit-react";
import {
  CheckmarkCicle,
  Colorchecked,
  Cross,
  DocIcon,
  LevelIcon,
  MoveIcon,
  PencilIcon,
  UserIcon,
} from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import EventSpeakerEditProfile from "./EventSpeakerEditProfile";
import { SESSION_AUTH } from "../../common/auth";
import { isValidEmail } from "../../common/utils";
import { USER_SERVICES } from "../../services";

const EventSpeakerAdd = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { speakersData, setSpeakersData, editEvent, createEvent, currentStep } =
    props;

  const [editProfile, setEditProfile] = useState(false);

  const [profileToEdit, setProfileToEdit] = useState("");
  const [refreshProfileData, setRefreshProfileData] = useState("");
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData(null);
    }
    return () => null;
  }, [isUserSession]);

  useEffect(() => {
    if (userData && createEvent && !dataFetched) {
      setDataFetched(true);
      submitSpeakers(userData?.email);
    }
  }, [userData, createEvent, dataFetched]);

  const submitSpeakers = async email => {
    setLoading(true);
    try {
      const data = await USER_SERVICES.speakers({
        emails: email ? [email] : emails,
        userId: userData?.userId,
        org: userData?.org?.id,
      });
      setEmails([]);
      if (data.code === 200) {
        let d = data?.data;
        setSpeakersData([...speakersData, ...d]);
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      }
      if (data.message) {
        ToastManager.addToast({ title: data.message });
      }
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
    setLoading(false);
  };

  const updateSpeakers = (index, key, value) => {
    let sd = [...speakersData];
    sd[index][key] = value;
    setSpeakersData(sd);
  };

  const enterSpeakerEmail = e => {
    if (e.code === "Enter") {
      let hasemail = speakersData.some(speaker => speaker["email"] === email);
      if (!isValidEmail(email)) {
        ToastManager.addToast({
          title: `Enter valid email address`,
        });
        return;
      } else if (hasemail) {
        ToastManager.addToast({ title: `${email} Already exist in speakers` });
        return;
      } else {
        submitSpeakers(email);
      }
      setEmail("");
    }
  };

  const removeSpeaker = index => {
    let d = [...speakersData];
    d.splice(index, 1);
    setSpeakersData(d);
  };

  const speakerProfileAction = async index => {
    setProfileToEdit(index);
    setEditProfile(true);
    setRefreshProfileData("");
    if (
      editEvent &&
      speakersData?.[index]?.name &&
      speakersData?.[index]?.email
    ) {
      setLoading(true);
      try {
        const data = await USER_SERVICES.speakers({
          emails: [speakersData?.[index]?.email],
          userId: userData?.userId,
          org: userData?.org?.id,
        });

        if (data.code === 200) {
          if (data?.data?.[0]) {
            setRefreshProfileData(data?.data?.[0] || "");
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
        if (data.message) {
          ToastManager.addToast({ title: data.message });
        }
      } catch (e) {
        ToastManager.addToast({ title: "Error Something went wrong" });
      }
      setLoading(false);
    }
  };

  const onMoveSpeakers = ({ removedIndex, addedIndex }) => {
    setSpeakersData(speakersData =>
      arrayMoveImmutable(speakersData, removedIndex, addedIndex)
    );
  };
  return (
    <>
      {loading && <FullPageProgress fixed={true} />}
      <FormControl className="custom-form-group eventsSpeakers">
        <label htmlFor="event-speaker-add" className="custom-form-label">
          Speakers
        </label>
        <Input
          id="event-speaker-add"
          className="custom-form-input"
          name="eventSpeakerAdd"
          value={email}
          onChange={e => {
            setEmail(e.target?.value.toLowerCase());
          }}
          readOnly={currentStep !== "event"}
          onKeyDown={enterSpeakerEmail}
          placeholder="Enter an email and press Enter"
        />
        {speakersData?.length > 0 ? (
          <>
            <Flex className="eventsSpeakers-list">
              <div className="eventsSpeakers-list-items">
                <Container
                  dragHandleSelector=".eventsSpeakers-list-items-row-dragHandle"
                  lockAxis="y"
                  onDrop={onMoveSpeakers}
                >
                  {speakersData.map((item, i) => {
                    return (
                      <Draggable key={i}>
                        {item?.email ===
                        process.env.REACT_APP_SUPPORT_EMAIL ? null : (
                          <div
                            className={`eventsSpeakers-list-items-row ${
                              speakersData?.length - 1 === i ? "last" : ""
                            } `}
                          >
                            <div
                              className="eventsSpeakers-list-items-row-dragHandle"
                              title="Move Speaker"
                            >
                              <MoveIcon />
                            </div>
                            <div className="eventsSpeakers-list-items-row-badge">
                              {item?.userId ? (
                                <Colorchecked />
                              ) : (
                                <CheckmarkCicle />
                              )}
                            </div>

                            <div className="eventsSpeakers-list-items-row-profile">
                              {item?.profileImage ? (
                                <img src={item?.profileImage} alt="" />
                              ) : (
                                <UserIcon />
                              )}
                            </div>
                            <div className="eventsSpeakers-list-items-row-text">
                              {item?.email}
                            </div>
                            <div className="eventsSpeakers-list-items-row-action">
                              <button
                                type="button"
                                className={`eventsSpeakers-list-items-row-action-btn ${
                                  !item?.name && !item?.userId ? "active" : null
                                }`}
                                onClick={() => {
                                  speakerProfileAction(i);
                                }}
                              >
                                {item?.name ? "Edit Profile" : "Create Profile"}
                              </button>
                              {!item?.isCreator && (
                                <button
                                  type="button"
                                  title="Remove"
                                  className="eventsSpeakers-list-items-row-action-remove"
                                  onClick={() => {
                                    removeSpeaker(i);
                                  }}
                                >
                                  <Cross />
                                </button>
                              )}
                            </div>
                            <div className="eventsSpeakers-list-items-row-role">
                              <Tooltip
                                placement="top"
                                arrow
                                title="List the user on the landing page as a speaker"
                              >
                                <button
                                  className={`eventsSpeakers-list-items-row-role-btn ${
                                    item?.isVisible ? " active" : ""
                                  } `}
                                  onClick={() => {
                                    updateSpeakers(
                                      i,
                                      "isVisible",
                                      !item?.isVisible
                                    );
                                  }}
                                  type="button"
                                >
                                  <DocIcon />
                                </button>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                arrow
                                title="Allow the user to edit the event"
                              >
                                <button
                                  className={`eventsSpeakers-list-items-row-role-btn ${
                                    item?.isEdit ? " active" : ""
                                  } `}
                                  onClick={() => {
                                    if (!item?.isCreator || !item?.isEdit)
                                      updateSpeakers(
                                        i,
                                        "isEdit",
                                        !item?.isEdit
                                      );
                                  }}
                                  type="button"
                                >
                                  <PencilIcon />
                                </button>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                arrow
                                title="Allow the user to access the event analytics"
                              >
                                <button
                                  className={`eventsSpeakers-list-items-row-role-btn ${
                                    item?.analyticsAccess ? " active" : ""
                                  } `}
                                  onClick={() => {
                                    if (
                                      !item?.isCreator ||
                                      !item?.analyticsAccess
                                    )
                                      updateSpeakers(
                                        i,
                                        "analyticsAccess",
                                        !item?.analyticsAccess
                                      );
                                  }}
                                  type="button"
                                >
                                  <LevelIcon />
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </Container>
              </div>
            </Flex>
          </>
        ) : null}
      </FormControl>
      {editProfile ? (
        <EventSpeakerEditProfile
          speakersData={speakersData}
          updateSpeakers={updateSpeakers}
          editProfile={editProfile}
          setEditProfile={setEditProfile}
          profileToEdit={profileToEdit}
          refreshProfileData={refreshProfileData}
        />
      ) : null}
    </>
  );
};

export default EventSpeakerAdd;
