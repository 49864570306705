import React from "react";

const OnBoardingHeader = props => {
  const { currentStep, totalStep } = props;

  return (
    <div className="events-page-onboarding-steps-header">
      <div className="events-page-onboarding-steps-header-title">
        Step {currentStep} of {totalStep?.length}
      </div>
      <div className="events-page-onboarding-steps-header-line">
        {totalStep.map((i, key) => (
          <div
            key={key}
            className={`events-page-onboarding-steps-header-line-col ${
              i <= currentStep ? " active " : ""
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default OnBoardingHeader;
