import React from "react";

export function Fire() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8601 1.61924C13.9765 1.4281 13.9762 1.19295 13.8591 1.00213C13.7421 0.811324 13.5262 0.693848 13.2925 0.693848C12.9855 0.693848 12.6885 0.706152 12.4015 0.730708C12.3933 0.731417 12.385 0.732136 12.3767 0.732865C12.3744 0.733071 12.3721 0.733278 12.3697 0.733485C12.1575 1.09531 12.1614 1.55009 12.3811 1.90847C12.3812 1.90855 12.3812 1.90863 12.3813 1.90871C12.5961 2.25881 12.9556 2.42759 13.2925 2.42759C12.6928 2.42759 12.1673 2.48211 11.7072 2.58121M13.8601 1.61924L6.7994 7.45086C6.77294 7.42283 6.7459 7.39556 6.71833 7.36905C6.73929 5.93958 6.99252 3.80721 8.51388 2.3297C9.45825 1.41253 10.7525 0.877396 12.3697 0.733491C12.2874 0.871644 11.8875 1.57044 11.7072 2.58121M13.8601 1.61924C13.8208 1.68502 13.3522 2.49352 13.3526 3.57041L13.8601 1.61924ZM11.7072 2.58121C10.8245 2.77134 10.183 3.12553 9.72178 3.57342L9.72177 3.57343C9.10784 4.16966 8.7663 4.97851 8.59468 5.87581C8.42231 6.77698 8.44452 7.63588 8.46337 8.25079C8.46337 8.25081 8.46337 8.25082 8.46337 8.25084L8.46351 8.25523M11.7072 2.58121C11.6537 2.88128 11.6196 3.20885 11.6188 3.55741L10.3088 3.54758C10.2943 5.4753 11.1386 7.98754 14.3544 9.83339L14.3544 9.8334C14.7826 10.0791 15.4336 10.6092 15.9644 11.3154C16.4968 12.0237 16.7905 12.749 16.7905 13.4014C16.7905 16.2868 14.2684 18.8071 10.9566 18.8071C8.62091 18.8071 6.62183 17.4633 5.68744 15.6574C6.05803 15.7693 6.44767 15.8259 6.84681 15.8259C7.75027 15.8259 8.73262 15.5592 9.44989 14.7547L8.46351 8.25523M8.46351 8.25523L9.77223 8.21538L9.77283 8.23503C9.77686 8.36698 9.78137 8.51486 9.78137 8.6461C9.78137 9.27196 9.8942 9.909 10.0263 10.6262L10.0263 10.6263C10.0309 10.6508 10.0354 10.6756 10.0401 10.7008C10.135 11.2144 10.2549 11.8641 10.2552 12.4713C10.2554 13.1598 10.1067 14.0181 9.44996 14.7546L8.46351 8.25523Z"
        fill="#E3C050"
        stroke="#E3C050"
        strokeWidth="3.46748"
      />
    </svg>
  );
}
export default Fire;
