import React from "react";
import { Button } from "@mui/material";
import { CopyIcon } from "../../../icons";
import { ToastManager } from "../../Toast/ToastManager";

const SixSecurityContacting = () => {
  const pgpkey =
    "mQENBGH1O8sBCAC8UKMZqu16FL0mJfR5C/OIENVORhHAqYjQPt5qlse16pnDuVTDD1+p/4/+se+OeTdiI3G4xL8KNVvrwqKaad2FAfVXpyYTFsiFskCjJXusGkYHuNHLfqnwKUsX+Vb3le29ab5Cac7iaRRFe7c98E6EkPJZlY1bXabyGipq130JSdPQotYk+K64/PK7kOP3gyzNg6g/sZgbULqGOItxwl/iPrX7gYn/Rs4UvbG07fNT5q0Qb1GZNVML3jSHcWDgCJnZS+5/ts9HCcDrJQ44TXRgwfh5Lfdyj+gX0G2Mxo7HieolqNf6x7FCDphLr2YJhA4D1vPY7dtACVqRwxr8qKc9ABEBAAG0EHNlY3VyaXR5QDZpeC5jb22JARwEEAECAAYFAmH1O8sACgkQNKpO80HboBHteAgArKdgLv6kfO/gQO4wEuqpC9+VErvOYXctrw+lk9MToz5RcpBsZaTAjbxcoY0PAYJAils+Car3DesldIOGMolY8oC4RBuMOI5RIZ4eoRmus3qq7yUi0JP5lg1rz52zu1YcVqTrn1vqwoRZ4af38Otm89F2rBzuQvw3hUGHTclFOcpe9bah3Y8eCAk9JxVNYjo/Shfnk2ZNw6echo1zseKptq41Mq4HWyOw6riNojMHtuCthZgryKtZyx3K3xn2y868h08W5BLxf3RdgQN8SMZ6Tvat3J0d9dCcIvKgi8QbE55ZB+GRmuYop5RYOmH42NQHKBqKMtmtmNOixqtgfDLWNg===wf/g";
  const CopyText = () => {
    navigator.clipboard.writeText(pgpkey);
    ToastManager.addToast({ title: "Copied!" });
  };
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Contacting Security
            </h2>
            <div class="events-page-landing-section-header-text">
              Please send all general security queries to security@6ix.com. To
              encrypt your communications, please use our PGP public key:
            </div>
          </div>

          <div className="events-page-security-section-contacting">
            <div className="events-page-security-section-contacting-text">
              -----BEGIN PGP PUBLIC KEY BLOCK----- <br /> Version: BCPG C#
              v1.6.1.0 <br />
              <br />
              {pgpkey}
              <br />
              <br />
              -----END PGP PUBLIC KEY BLOCK-----
            </div>
            <div className="events-page-security-section-contacting-action">
              <Button
                onClick={CopyText}
                variant="contained"
                startIcon={<CopyIcon />}
              >
                Copy
              </Button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityContacting;
