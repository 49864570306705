import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useMedia } from "react-use";
import {
  selectIsLocalVideoEnabled,
  selectLocalPeer,
  selectVideoTrackByID,
  useAVToggle,
  useHMSStore,
  usePreviewJoin,
} from "@100mslive/react-sdk";
import { SettingsIcon } from "@100mslive/react-icons";
import {
  Avatar,
  Button,
  config as cssConfig,
  Flex,
  flexCenter,
  Loading,
  styled,
  StyledVideoTile,
  Text,
  textEllipsis,
  useBorderAudioLevel,
  useTheme,
  Video,
} from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import { AudioVideoToggle } from "../AudioVideoToggle";
import TileConnection from "../Connection/TileConnection";
import SettingsModal from "../Settings/SettingsModal";
import { VBToggle } from "../VirtualBackground/VBToggle.jsx";
import PreviewName from "./PreviewName";
import { useUISettings } from "../AppData/useUISettings";
import {
  defaultPreviewPreference,
  UserPreferencesKeys,
  useUserPreferences,
} from "../hooks/useUserPreferences";
import { FeatureFlags } from "../../services/FeatureFlags";
import { UI_SETTINGS } from "../../common/constants";

const PreviewJoin = ({
  token,
  onJoin,
  env,
  skipPreview,
  profile,
  editProfile,
}) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );

  const [name, setName] = useState(profile?.name || previewPreference.name);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [previewError, setPreviewError] = useState(false);
  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
      speakerAutoSelectionBlacklist: ["Yeti Stereo Microphone"],
    },
    captureNetworkQualityInPreview: true,
    metadata: profile,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
  });
  const savePreferenceAndJoin = useCallback(() => {
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });

    join();
    onJoin && onJoin();
  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    name,
    setPreviewPreference,
    onJoin,
  ]);
  useEffect(() => {
    if (token) {
      if (skipPreview) {
        savePreferenceAndJoin();
      } else {
        preview();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, skipPreview]);
  return (
    <Container>
      <Text variant="h4" css={{ wordBreak: "break-word", textAlign: "center" }}>
        Get Started
      </Text>
      <Text
        css={{ c: "$textMedEmp", my: "$6", textAlign: "center" }}
        variant="body1"
      >
        Setup your audio and video before joining
      </Text>
      <Flex
        align="center"
        justify="center"
        css={{
          "@sm": { width: "100%" },
          flexDirection: "column",
        }}
      >
        <PreviewTile
          editProfile={editProfile}
          name={name}
          error={previewError}
        />
        <PreviewControls
          enableJoin={enableJoin}
          savePreferenceAndJoin={savePreferenceAndJoin}
        />

        <PreviewName
          name={name}
          onChange={setName}
          enableJoin={enableJoin}
          onJoin={savePreferenceAndJoin}
        />
      </Flex>
    </Container>
  );
};

const Container = styled("div", {
  width: "100%",
  ...flexCenter,
  flexDirection: "column",
  px: "$10",
});

const PreviewTile = ({ editProfile, name, error }) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const mirrorLocalVideo = useUISettings(UI_SETTINGS.mirrorLocalVideo);
  const trackSelector = selectVideoTrackByID(localPeer?.videoTrack);
  const track = useHMSStore(trackSelector);
  const [profileImage, setProfileImage] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyLogoColor, setCompanyLogoColor] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [work, setWork] = useState("");

  const {
    aspectRatio: { width, height },
  } = useTheme();

  useEffect(() => {
    if (!localPeer || !localPeer?.metadata) return;
    let metadata = JSON.parse(localPeer?.metadata);
    setProfileImage(metadata?.profileImage);
    setCompanyLogo(metadata?.companyLogo);
    setCompanyLogoColor(metadata?.companyLogoColor);
    setJobTitle(metadata?.jobTitle);
    setWork(metadata?.work);
  }, [localPeer]);
  return (
    <StyledVideoTile.Container
      css={{
        bg: "$surfaceDefault",
        aspectRatio: width / height,
        width: "unset",
        height: "min(360px, 60vh)",
        mt: "$12",
        "@sm": {
          height: "unset",
          width: "min(360px, 100%)",
          maxWidth: "100%",
        },
      }}
      className="preview_tile_box"
      ref={borderAudioRef}
    >
      {localPeer ? (
        <>
          <TileConnection name={name} peerId={localPeer.id} hideLabel={true} />
          <Video
            mirror={track?.facingMode !== "environment" && mirrorLocalVideo}
            trackId={localPeer.videoTrack}
            data-testid="preview_tile"
          />
          {companyLogo ? (
            <img
              src={companyLogo}
              alt="Logo"
              className="preview_tile_logo"
              data-testid="preview_logo_tile"
              style={{ backgroundColor: companyLogoColor }}
            />
          ) : null}

          {!isVideoOn ? (
            <StyledVideoTile.AvatarContainer style={{ width: "100%" }}>
              {profileImage ? (
                <>
                  <img
                    src={profileImage}
                    alt="preview avatar"
                    className="preview_tile_avatar"
                    data-testid="preview_avatar_tile"
                  />
                </>
              ) : (
                <Avatar name={name} data-testid="preview_avatar_tile" />
              )}
              <Text
                css={{ ...textEllipsis("75%"), marginTop: "5px" }}
                variant="body2"
              >
                {name}
              </Text>
              <Text style={{ fontSize: "75%", marginTop: "0" }} variant="body2">
                {jobTitle} at {work}
              </Text>
              {editProfile && (
                <Button
                  style={{
                    fontSize: "75%",
                    marginTop: "10px",
                    borderRadius: "50px",
                    padding: "4px 10px",
                  }}
                  outlined="true"
                  variant="standard"
                  onClick={() => {
                    editProfile(true);
                  }}
                >
                  Edit profile
                </Button>
              )}
            </StyledVideoTile.AvatarContainer>
          ) : (
            <div className="preview_tile_bottom">
              <Text css={{ ...textEllipsis("75%") }} variant="body2">
                {name}
              </Text>
              <Text style={{ fontSize: "75%", marginTop: "0" }} variant="body2">
                {jobTitle} at {work}
              </Text>
            </div>
          )}
        </>
      ) : !error ? (
        <Loading size={100} />
      ) : null}
    </StyledVideoTile.Container>
  );
};

const PreviewControls = () => {
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <Flex
      justify="between"
      css={{
        width: "100%",
        mt: "$8",
      }}
    >
      <Flex css={{ gap: "$4" }}>
        <AudioVideoToggle compact />
        {!isMobile && FeatureFlags.enableVirtualBackground ? (
          <VBToggle />
        ) : null}
      </Flex>
      <PreviewSettings />
    </Flex>
  );
};

const PreviewSettings = React.memo(() => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <div className="preview_tile_footer_frame">
        <IconButton
          data-testid="preview_setting_btn"
          onClick={() => setOpen(value => !value)}
          className="preview_tile_footer_btn"
        >
          <SettingsIcon />
        </IconButton>
        <div className="preview_tile_footer_indicator">Setting</div>
      </div>
      {open && <SettingsModal open={open} onOpenChange={setOpen} />}
    </Fragment>
  );
});

export default PreviewJoin;
