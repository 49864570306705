import React from "react";

export function GoogleIcon(props) {
  return props?.type === "white" ? (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.50365 9.01813L3.50396 9.00348L3.5034 8.98884C3.39411 6.11145 5.93766 3.42925 8.81594 3.39478L8.83423 3.39456L8.85245 3.393C10.1345 3.28366 11.4065 3.70943 12.4708 4.46592C12.1787 4.78061 11.8839 5.08814 11.5806 5.38433C10.5284 4.8031 9.21097 4.36053 7.8836 4.82101C5.59727 5.48168 4.22277 8.15339 5.06175 10.4019C5.77623 12.7452 8.59256 14.011 10.8254 13.0391C12.033 12.599 12.7854 11.502 13.1081 10.3963L13.291 9.76937L12.638 9.75636C12.0942 9.74552 11.5701 9.74277 11.0501 9.74005C10.5732 9.73754 10.0998 9.73506 9.61798 9.72638C9.61661 9.36034 9.61562 8.99524 9.61587 8.63C9.66624 8.62991 9.71661 8.62983 9.76699 8.62975C11.3441 8.62713 12.9204 8.62452 14.4965 8.63572C14.5364 10.1299 14.2454 11.6094 13.3445 12.7217L13.3444 12.7216L13.3382 12.7296C11.9753 14.4836 9.41 15.0342 7.29083 14.3223L7.28763 14.3212C5.07233 13.5936 3.45559 11.3495 3.50365 9.01813Z"
        fill="white"
        stroke="#C0C0C0"
      />
    </svg>
  ) : (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.984649 8.01022C0.827983 3.88565 4.44099 0.0740559 8.57 0.024604C10.6744 -0.154875 12.7218 0.662291 14.3144 1.99507C13.6611 2.71333 12.9964 3.4233 12.2864 4.08796C10.8844 3.23621 9.19632 2.58711 7.55772 3.16359C4.9148 3.91608 3.31424 7.03639 4.29228 9.62172C5.10224 12.3219 8.3867 13.8037 10.9608 12.6694C12.2937 12.1922 13.1725 10.9625 13.5581 9.64109C12.0305 9.61066 10.5026 9.62968 8.97498 9.58749C8.97117 8.67868 8.96737 7.77333 8.97118 6.86452C11.5186 6.86072 14.0699 6.85311 16.6212 6.87593C16.7779 9.10265 16.4493 11.4857 15.0016 13.2729C13.0193 15.824 9.35679 16.5726 6.37736 15.5719C3.21533 14.5334 0.915827 11.348 0.984649 8.01022Z"
        fill="#C0C0C0"
      />
    </svg>
  );
}
export default GoogleIcon();
