import { apiCall } from "./servicesCall";

export const OTHER_SERVICES = {
  contact(data) {
    return apiCall("/6ix/contact", data);
  },
  demoRequest(data) {
    return apiCall("/6ix/demo", data);
  },
  demoRequestMeeting(data) {
    return apiCall("/6ix/demo/meeting", data);
  },
};
