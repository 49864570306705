import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";

const SixSecurityStorage = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Investment Discovery Platform
            </h2>
          </div>
          <div className="events-page-security-section-features">
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> Non-Custodial By Design: We never hold
                any of your assets, including but not limited to: cash, bonds,
                equities, cryptocurrencies, NFTs, etc.
              </div>

              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                No Transactions: We do not earn commissions or fees related to
                your investment decisions, and we are not an exempt market
                dealer.
              </div>
            </div>
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                No Commitment: Your use of 6ix is at-will and can stop at any
                time you choose.
              </div>

              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> No Investment Advice: We do not provide
                investment advice, you must do your own due diligence.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> Free To Use: We provide our platform
                as-is, on a free-to-use basis, and do not ask for your credit
                card details.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityStorage;
