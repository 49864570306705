import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const PricingContent = () => {
  return (
    <div className="event-page-pricing-section-row">
      <div className="event-page-pricing-section-media">
        <iframe
          src="https://videos.6ix.com/embed/661964bda988b9ebbedf2ec0"
          title="6ix Pricing 12 Apr 2024 | 6ix Videos"
          frameBorder="0"
          autoPlay
          allowFullScreen
          muted
        ></iframe>
      </div>
      <div className="event-page-pricing-section-content">
        <div className="event-page-pricing-section-content-label">
          6ix for COMPANIES
        </div>
        <div className="event-page-pricing-section-content-price">
          Pricing starts at $0 per month and scales with usage.
        </div>
        <div className="event-page-pricing-section-content-investor">
          Reach investors, attract capital and grow your reputation — without
          losing control of the narrative.
        </div>
        <div className="event-page-pricing-section-content-line"></div>
        <div className="event-page-pricing-section-content-popular">
          <div className="event-page-pricing-section-content-popular-heading">
            Popular Features
          </div>
          <div className="event-page-pricing-section-content-popular-feature">
            Host investor events
          </div>
          <div className="event-page-pricing-section-content-popular-feature">
            Send investor emails
          </div>
          <div className="event-page-pricing-section-content-popular-feature">
            Launch investor ad campaigns
          </div>
          <div className="event-page-pricing-section-content-popular-feature">
            And much, much more!
          </div>
        </div>
        <Button
          variant="outlined"
          to="/account/setting/billing"
          component={Link}
          color="primary"
          fullWidth={true}
          className="events6ix-btn events6ix-btn-primary"
        >
          MANAGE BILLING
        </Button>
      </div>
    </div>
  );
};

export default PricingContent;
