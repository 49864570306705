import { apiCall } from "./servicesCall";

export const HUBSPOT_SERVICES = {
  connect(data) {
    return apiCall("/hubspot/connect", data);
  },
  accesstoken(data) {
    return apiCall("/hubspot/accesstoken/generate", data);
  },
  syncAnalytics(data) {
    return apiCall("/hubspot/sync/analytics", data);
  },
  syncInvestors(data) {
    return apiCall("/hubspot/sync/investors", data);
  },
};
