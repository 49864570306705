import React from "react";
//const PreviewScreen = React.lazy(() => import("./components/PreviewScreen"));
import EventFooter from "../components/Event/EventFooter";
import PreviewScreen from "../components/PreviewScreen";

const EventPreviewPage = props => {
  return (
    <React.Fragment>
      <PreviewScreen {...props} />
      <EventFooter />
    </React.Fragment>
  );
};

export default EventPreviewPage;
