import { apiCall } from "./servicesCall";

export const CONSTANTCONTACT_SERVICES = {
  connect(data) {
    return apiCall("/constantcontact/connect", data);
  },
  list(data) {
    return apiCall("/constantcontact/list", data);
  },
  accesstoken(data) {
    return apiCall("/constantcontact/accesstoken/generate", data);
  },
  syncAnalytics(data) {
    return apiCall("/constantcontact/sync/analytics", data);
  },
  syncInvestors(data) {
    return apiCall("/constantcontact/sync/investors", data);
  },
};
