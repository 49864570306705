import React from "react";
export function Edit() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8731 2.86832C11.0214 2.71997 11.1975 2.60229 11.3914 2.522C11.5852 2.44171 11.793 2.40039 12.0028 2.40039C12.2126 2.40039 12.4203 2.44171 12.6141 2.522C12.808 2.60229 12.9841 2.71997 13.1325 2.86832C13.2808 3.01668 13.3985 3.1928 13.4788 3.38663C13.5591 3.58046 13.6004 3.78821 13.6004 3.99801C13.6004 4.20782 13.5591 4.41557 13.4788 4.6094C13.3985 4.80323 13.2808 4.97935 13.1325 5.12771L5.50704 12.7531L2.40039 13.6004L3.24766 10.4937L10.8731 2.86832Z"
        stroke="white"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default Edit;
