import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TagsInput from "react-tagsinput";
import { Button, Checkbox, FormControl, MenuItem, Select } from "@mui/material";
import { saveAs } from "file-saver";
import { Box, Flex, Input } from "@100mslive/roomkit-react";
import { Cross, Edit, UploadFIle } from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { CSVToJSONConvert, reminderScheduleList } from "../../common/utils";
import { PROMOTION_SERVICES, SENDGRID_SERVICES } from "../../services";
import "react-tagsinput/react-tagsinput.css";
const Promoteventpopup = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { setPromotionPopup, isShare, eventDetail } = props;
  const [inviteCSV, setInviteCSV] = useState("");
  const [emailSender, setEmailSender] = useState("");
  const dataFeatched = useRef();
  const emailTagesInput = useRef();
  const sendButtonTagesInput = useRef();
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [senderList, setSenderList] = useState([
    { value: 1, label: "Custom Official Email" },
  ]);
  const [userData, setUserData] = useState(null);

  const scheduleList = reminderScheduleList();
  const [scheduleSelected, setScheduleSelected] = useState(["now"]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      navigate(0);
    }
    return () => null;
  }, [isUserSession, navigate]);

  const addEmails = tags => {
    let unique = [...new Set(tags)];
    setEmails(unique);
  };

  const sendPromotion = () => {
    if (!emails[0] && !emailTagesInput?.current?.state?.tag) {
      ToastManager.addToast({
        title: "Please enter email",
      });
      return false;
    }

    if (!emails[0] && emailTagesInput?.current?.state?.tag) {
      setEmails([emailTagesInput?.current?.state?.tag]);
      setTimeout(function () {
        sendButtonTagesInput.current.click();
      }, 500);
      return false;
    }

    promotionsMessage();
  };

  useEffect(() => {
    if (inviteCSV) {
      setLoading(true);
      try {
        setEmails([]);
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          if (event?.target?.result) {
            const csvData = CSVToJSONConvert(event.target.result);
            if (csvData && csvData.length > 0) {
              let email = csvData
                .map(p => p?.email_id.toLowerCase())
                .filter(notUndefined => notUndefined !== undefined);
              let unique = [...new Set(email)];
              if (unique?.length <= 0) {
                ToastManager.addToast({
                  title:
                    "Formatting error, please correct as per the sample CSV",
                });
              } else if (unique.length > 10000) {
                ToastManager.addToast(
                  "Max records limit is 10000, upload upto 10000 records CSV"
                );
              } else {
                setEmails(unique);
              }
            }
          } else {
            ToastManager.addToast({
              title: "Formatting error, please correct as per the sample CSV",
            });
          }
        };
        fileReader.readAsText(inviteCSV);
      } catch (err) {
        ToastManager.addToast({
          title: "Formatting error, please correct as per the sample CSV",
        });
      }
      setLoading(false);
    } else {
      setEmails([]);
    }
    return () => null;
  }, [inviteCSV, setEmails]);

  const onSelectCSV = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setInviteCSV("");
      return;
    }
    setInviteCSV(e.target.files[0]);
  };

  const donwloadSampleInvite = () => {
    saveAs(
      "https://6ix-events-pro.s3.amazonaws.com/6ixvideo/file/sample-invite-emails.csv",
      "sample-invite-emails.csv"
    );
  };

  const promotionsMessage = async () => {
    if (!emails[0]) {
      return false;
    }
    if (!scheduleSelected || scheduleSelected?.length <= 0) {
      ToastManager.addToast({ title: "Select at least one schedule" });
      return;
    }
    setLoading(true);

    try {
      ToastManager.addToast({
        title:
          "Email invites are being sent - do not close this page. Depending on the number of invites, this process may take some time",
      });

      const data = await PROMOTION_SERVICES.eventPromotion({
        userId: userData.userId,
        org: userData?.org?.id,
        id: eventDetail?._id,
        emails: emails,
        senderVia: emailSender,
        schedule: scheduleSelected,
      });
      if (data && data.code === 200) {
        ToastManager.addToast({ title: data.message });
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
      }
      setPromotionPopup(false);
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!userData?.userId || dataFeatched.current) return;
    dataFeatched.current = true;
    SENDGRID_SERVICES.senderList({
      userId: userData.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data && data.code === 200) {
          if (data?.data?.from_email) {
            setSenderList([
              {
                value: data?.data?.from_email,
                label: `${data?.data?.name} - ${data?.data?.from_name} <${data?.data?.from_email}>`,
              },
            ]);
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: "Error Something went wrong" });
      });
  }, [userData, dataFeatched, LOGOUT_USER_SESSION]);

  const handleSendVia = value => {
    if (value === 1) {
      ToastManager.addToast({
        title: "Please connect your Sendgrid Account",
      });
      setPromotionPopup(false);
      navigate("/account/setting");
    }
    setEmailSender(value);
  };
  return (
    <Flex
      align="center"
      direction="column"
      className="eventsPopup eventsPopup-sm"
    >
      <Box className="eventsPopup-inner">
        {loading && <FullPageProgress fixed={true} />}
        <div className="eventsPopup-header">
          <div className="eventsPopup-header-heading">
            {isShare ? "Share" : "Promote"} the event
          </div>
          <div className="eventsPopup-header-desc">Invite friends</div>
        </div>
        <div
          className="eventsPopup-close"
          onClick={() => setPromotionPopup(false)}
        >
          <Cross />
        </div>
        <div className="eventsPopup-content">
          <FormControl className="custom-form-group">
            <label htmlFor="promotion-emails" className="custom-form-label">
              Enter an email(s)
            </label>
            <div className="custom-form-tags">
              <TagsInput
                id="promotion-emails"
                ref={emailTagesInput}
                addKeys={[188, 9, 13, 49, 32]}
                value={emails}
                onChange={e => {
                  addEmails(e);
                }}
                inputProps={{ placeholder: "Enter email and press Enter" }}
              />
            </div>
          </FormControl>

          <div className="custom-form-label big-label  mb10">
            Send bulk invites
            <span
              className="text-link text-primary ml-5"
              onClick={donwloadSampleInvite}
            >
              Download Sample CSV
            </span>
          </div>

          <div className="custom-form-group">
            <label className="custom-form-file mt-10" translate="no">
              <Input
                type="file"
                id="event-logo"
                accept=".csv"
                placeholder="Hosted by the Wonderful"
                className="custom-form-file-input"
                onChange={onSelectCSV}
              />

              {inviteCSV ? (
                <>
                  <Edit /> <span>{inviteCSV?.name}</span>
                </>
              ) : (
                <>
                  <UploadFIle /> <span translate="yes">Attach CSV</span>
                </>
              )}
            </label>
          </div>

          {userData?.userId ? (
            <FormControl className="custom-form-group">
              <label htmlFor="send-via-email" className="custom-form-label">
                Send via
              </label>

              <Select
                id="send-via-email"
                className="custom-select"
                sx={{
                  height: "45px",
                  background: "#131313",
                  borderRadius: "8px",
                  width: "auto",
                  marginBottom: "10px",
                }}
                value={emailSender}
                onChange={e => handleSendVia(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">6ix Events</MenuItem>
                {senderList.map((listItem, key) => {
                  return (
                    <MenuItem key={key} value={listItem.value}>
                      {listItem.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : null}
          <FormControl className="custom-form-group">
            <label htmlFor="schedule-via-email" className="custom-form-label">
              Schedule
            </label>

            <Select
              id="schedule-via-email"
              className="custom-select"
              multiple={true}
              sx={{
                height: "45px",
                background: "#131313",
                borderRadius: "8px",
                width: "auto",
                marginBottom: "10px",
              }}
              value={scheduleSelected}
              onChange={event => setScheduleSelected(event.target.value)}
              displayEmpty
            >
              {scheduleList.map((listItem, key) => {
                return (
                  <MenuItem key={key} value={listItem.value}>
                    <Checkbox
                      size="small"
                      checked={scheduleSelected.indexOf(listItem.value) > -1}
                    />
                    {listItem.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="eventsPopup-footer">
          <Button
            color="secondary"
            variant="outlined"
            className="events6ix-btn events6ix-btn-secondary"
            type="button"
            onClick={() => setPromotionPopup(false)}
          >
            &nbsp; Cancel &nbsp;
          </Button>
          <Button
            color="primary"
            variant="outlined"
            className="events6ix-btn events6ix-btn-primary ml-20"
            type="button"
            onClick={() => {
              sendPromotion();
            }}
          >
            &nbsp; Send &nbsp;
          </Button>
        </div>
      </Box>
    </Flex>
  );
};
export default Promoteventpopup;
