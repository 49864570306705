import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { saveAs } from "file-saver";
import Moment from "moment";
import { Flex } from "@100mslive/roomkit-react";
import { DownLoadIcon } from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { ROOM_SERVICES } from "../../services";

export function DashboardEventDownload(props) {
  const { selectedEvent, selectDownloadEvent } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [recordingsList, setRecordingsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [next, setNext] = useState("");
  const [downloadType, setDownloadType] = useState("room-composite");

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;

    setDataFetched(true);
    try {
      setLoading(true);

      ROOM_SERVICES.getRecordings({
        status: "completed",
        userId: userData?.userId,
        org: userData?.org?.id,
        room_id: selectedEvent.eventMeetingRoomID,
        type: downloadType,
        next: next,
      })
        .then(data => {
          if (data.code === 200) {
            setRecordingsList(data?.data?.list || []);
            setNext(data?.data?.next);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  }, [
    dataFetched,
    userData,
    downloadType,
    selectedEvent,
    LOGOUT_USER_SESSION,
    next,
  ]);

  const downloadRecordings = async item => {
    setLoading(true);

    try {
      ROOM_SERVICES.recordingDownload({
        object_id: item.id,
        userId: userData?.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            if (data?.data?.url) {
              if (
                data?.data?.url.indexOf("?") > -1 &&
                downloadType === "chat"
              ) {
                saveAs(data?.data?.url.split("?")[0]);
              } else {
                saveAs(data?.data?.url);
              }
            }
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  };
  const changeDownload = type => {
    setDownloadType(type);
    setNext("");
    setDataFetched(false);
  };
  return loading ? (
    <FullPageProgress zindex={999999999} fixed={true} />
  ) : (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog download-recording-popup"
    >
      <DialogTitle className="text-center">
        {selectedEvent.eventName}
      </DialogTitle>
      <Flex
        gap={2}
        css={{ "justify-content": "center", gap: "10px", margin: "20px" }}
      >
        <Button
          color="light"
          variant="outlined"
          className={`events6ix-btn events6ix-btn-round events6ix-btn-light ${
            downloadType === "room-composite" ? "active" : ""
          }`}
          sx={{ textTransform: "none" }}
          onClick={() => changeDownload("room-composite")}
        >
          Live Recordings
        </Button>
        {selectedEvent?.eventChatQA && (
          <Button
            color="light"
            variant="outlined"
            className={`events6ix-btn events6ix-btn-round events6ix-btn-light ${
              downloadType === "chat" ? "active" : ""
            }`}
            sx={{ textTransform: "none" }}
            onClick={() => changeDownload("chat")}
          >
            Live Chats
          </Button>
        )}
      </Flex>
      <DialogContent>
        {recordingsList && recordingsList?.length > 0 ? (
          recordingsList.map((item, key) => {
            return (
              <div
                key={key}
                className="download-recording-popup-row"
                onClick={() => {
                  downloadRecordings(item);
                }}
              >
                <div className="download-recording-popup-row-heading">
                  {downloadType === "chat" ? "Chat History" : "Recording"}{" "}
                  {key + 1}
                </div>
                {downloadType !== "chat" && (
                  <div className="download-recording-popup-row-duration">
                    Duration : {(item?.duration / 60)?.toFixed(2)}
                    &nbsp; Resolution : {
                      item?.metadata?.resolution?.height
                    } X {item?.metadata?.resolution?.width}
                  </div>
                )}
                {item?.created_at && (
                  <div className="download-recording-popup-row-duration">
                    Date :
                    {Moment(item?.created_at).format("MMM DD, YYYY : hh:mm A")}
                  </div>
                )}
                <div className="download-recording-popup-row-icon">
                  <DownLoadIcon />
                </div>
              </div>
            );
          })
        ) : (
          <DialogContentText className="text-center">
            {downloadType === "chat" ? "Chat" : "Recording"} Not Available
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions className="custom-dialog-action text-center">
        {next && (
          <Button
            variant="outlined"
            onClick={() => setDataFetched(false)}
            color="primary"
            className="events6ix-btn events6ix-btn-primary"
            type="button"
          >
            Next
          </Button>
        )}

        <Button
          variant="outlined"
          color="secondary"
          onClick={() => selectDownloadEvent("")}
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
