import React from "react";
import SixAboutContent from "./SixAboutContent";
import SixAboutCreated from "./SixAboutCreated";
import SixAboutGlance from "./SixAboutGlance";
import SixAboutJourney from "./SixAboutJourney";
import "./style.scss";

const SixAbout = () => {
  return (
    <React.Fragment>
      <SixAboutContent />
      <SixAboutGlance />
      <SixAboutCreated />
      <SixAboutJourney />
    </React.Fragment>
  );
};

export default SixAbout;
