import React from "react";
export function VerifedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.32806 0.609634C9.70926 0.263778 10.2907 0.263778 10.6719 0.609634L12.4337 2.20802C12.6276 2.38393 12.8827 2.47679 13.1443 2.46666L15.5213 2.37465C16.0356 2.35474 16.481 2.7285 16.5507 3.23848L16.8729 5.59533C16.9083 5.85471 17.0441 6.08984 17.251 6.25024L19.131 7.70765C19.5378 8.023 19.6388 8.59564 19.3644 9.03111L18.0962 11.0436C17.9566 11.2651 17.9095 11.5325 17.9649 11.7884L18.4683 14.1133C18.5772 14.6163 18.2864 15.1199 17.7963 15.2771L15.5312 16.0036C15.2819 16.0836 15.0739 16.2581 14.9519 16.4897L13.8431 18.5942C13.6032 19.0496 13.0568 19.2485 12.5803 19.0539L10.3781 18.1544C10.1358 18.0554 9.86425 18.0554 9.62189 18.1544L7.41972 19.0539C6.94322 19.2485 6.39681 19.0496 6.15689 18.5942L5.04808 16.4897C4.92605 16.2581 4.71806 16.0836 4.46878 16.0036L2.20367 15.2771C1.71355 15.1199 1.42282 14.6163 1.53174 14.1133L2.03511 11.7884C2.09051 11.5325 2.04336 11.2651 1.90379 11.0436L0.635614 9.03111C0.361207 8.59564 0.462179 8.023 0.868975 7.70765L2.749 6.25024C2.9559 6.08984 3.09166 5.85471 3.12711 5.59533L3.44926 3.23848C3.51896 2.7285 3.96439 2.35474 4.47872 2.37465L6.85571 2.46666C7.11731 2.47679 7.37244 2.38392 7.56633 2.20802L9.32806 0.609634Z"
        fill="#E3C050"
      />
      <path
        d="M14 7L8.5 12.5L6 10"
        stroke="black"
        strokeWidth="1.40571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default VerifedIcon;
