import { apiCall } from "./servicesCall";

export const EXPLORE_SERVICES = {
  allList(data) {
    return apiCall("/explore", data);
  },
  upcomingList(data) {
    return apiCall("/explore/upcoming", data);
  },
  onDemandList(data) {
    return apiCall("/explore/demand", data);
  },
  notifiedList(data) {
    return apiCall("/explore/notified", data);
  },
  liveList(data) {
    return apiCall("/explore/live", data);
  },
  statics(data) {
    return apiCall("/explore/statics", data);
  },
};
