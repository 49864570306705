import React from "react";

export function CalenderOne() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_76)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1085 8.25796H13.2562L13.9855 4.76451C14.0444 4.40763 13.9437 4.04515 13.7099 3.77041C13.4753 3.49444 13.1317 3.33623 12.7675 3.33623H1.23636C0.872224 3.33623 0.52856 3.49444 0.29394 3.77041C0.0601455 4.04517 -0.0405194 4.40766 0.0216535 4.78174L0.74766 8.25796H2.89543C3.12241 8.25796 3.30608 8.44143 3.30608 8.66807C3.30608 8.89478 3.12241 9.07825 2.89543 9.07825H0.747715L0.0184529 12.5717C-0.0404921 12.9285 0.0601455 13.291 0.29394 13.5658C0.52856 13.8418 0.872247 14 1.23636 14H12.7675C13.1317 14 13.4753 13.8418 13.7099 13.5658C13.9437 13.291 14.0444 12.9285 13.9822 12.5544L13.2563 9.07825H11.1085C10.8815 9.07825 10.6978 8.89478 10.6978 8.66807C10.6978 8.44143 10.8815 8.25796 11.1085 8.25796ZM7.00274 9.89863C7.00274 10.8034 6.26606 11.5391 5.36014 11.5391C4.66556 11.5391 4.04276 11.0998 3.81138 10.4453C3.73557 10.2318 3.84746 9.99756 4.06162 9.9222C4.27456 9.84613 4.50998 9.95867 4.58537 10.1722C4.70126 10.499 5.01284 10.7188 5.36014 10.7188C5.81289 10.7188 6.18144 10.3508 6.18144 9.89863C6.18144 9.44637 5.81289 9.07833 5.36014 9.07833H4.84964C4.62307 9.07833 4.43899 8.89486 4.43899 8.66815C4.43899 8.44151 4.62307 8.25804 4.84964 8.25804H5.36014C5.81289 8.25804 6.18144 7.88993 6.18144 7.43774C6.18144 6.98555 5.81289 6.61745 5.36014 6.61745C5.01284 6.61745 4.70126 6.83735 4.58534 7.16421C4.50995 7.37809 4.27415 7.49024 4.0616 7.4141C3.84746 7.33881 3.73557 7.10455 3.81134 6.89102C4.04276 6.23654 4.66556 5.79716 5.36014 5.79716C6.26606 5.79716 7.00274 6.53293 7.00274 7.43774C7.00274 7.92718 6.78699 8.36739 6.44533 8.66815C6.78699 8.96898 7.00274 9.40919 7.00274 9.89863ZM9.4647 11.5391C9.6917 11.5391 9.8754 11.3557 9.8754 11.129V6.20726C9.8754 6.05586 9.79192 5.91688 9.65837 5.84557C9.52521 5.77428 9.36284 5.78227 9.23693 5.86599L8.00491 6.68628C7.81608 6.81206 7.76515 7.06639 7.89107 7.25507C8.01659 7.44329 8.27124 7.49376 8.46054 7.36878L9.05408 6.97346V11.129C9.05408 11.3557 9.2377 11.5391 9.4647 11.5391Z"
          fill="#8C8E95"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.885254 1.22974V2.46017H13.2048V1.22974C13.2048 0.551254 12.6522 -0.000686646 11.9728 -0.000686646H2.11721C1.43786 -0.000686646 0.885254 0.551254 0.885254 1.22974ZM11.153 0.819685C11.3798 0.819685 11.5636 1.00327 11.5636 1.22983C11.5636 1.45634 11.3798 1.63997 11.153 1.63997C10.9262 1.63997 10.7423 1.45634 10.7423 1.22983C10.7423 1.00327 10.9262 0.819685 11.153 0.819685ZM3.34991 1.22983C3.34991 1.00327 3.16605 0.819685 2.93926 0.819685C2.71247 0.819685 2.52861 1.00327 2.52861 1.22983C2.52861 1.45634 2.71247 1.63997 2.93926 1.63997C3.16605 1.63997 3.34991 1.45634 3.34991 1.22983Z"
          fill="#8C8E95"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_76">
          <rect
            width="14"
            height="13.9996"
            fill="white"
            transform="translate(0 0.000289917)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CalenderOne;
