import React from "react";
export function MoveIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.5"
        y="1.79999"
        width="2.7"
        height="2.7"
        rx="0.9"
        fill="#575757"
      />
      <rect
        x="10.7998"
        y="1.79999"
        width="2.7"
        height="2.7"
        rx="0.9"
        fill="#575757"
      />
      <rect
        x="4.5"
        y="7.20001"
        width="2.7"
        height="2.7"
        rx="0.9"
        fill="#575757"
      />
      <rect
        x="10.7998"
        y="7.20001"
        width="2.7"
        height="2.7"
        rx="0.9"
        fill="#575757"
      />
      <rect x="4.5" y="12.6" width="2.7" height="2.7" rx="0.9" fill="#575757" />
      <rect
        x="10.7998"
        y="12.6"
        width="2.7"
        height="2.7"
        rx="0.9"
        fill="#575757"
      />
    </svg>
  );
}
export default MoveIcon;
