import React from "react";
import { styled, Text, textEllipsis } from "@100mslive/roomkit-react";
import { ConnectionIndicator } from "./ConnectionIndicator";

const TileConnection = ({ name, peerId, hideLabel, width, work, jobTitle }) => {
  return (
    <Wrapper>
      {!hideLabel ? (
        <Text
          css={{
            c: "$textHighEmp",
            ...textEllipsis(width - 60),
          }}
          variant="sm"
        >
          {name}
          {width >= 200 && (work || jobTitle) ? (
            <>
              <br />
              <em>
                {jobTitle} at {work}
              </em>
            </>
          ) : null}
        </Text>
      ) : null}
      <ConnectionIndicator isTile peerId={peerId} />
    </Wrapper>
  );
};

const Wrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "absolute",
  bottom: "$2",
  left: "$2",
  backgroundColor: "$surface_default",
  borderRadius: "$1",
  zIndex: 1,
  "& p,span": {
    p: "$2 $3",
  },
});

export default TileConnection;
