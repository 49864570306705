import { Fragment, useEffect, useRef, useState } from "react";
import {
  HMSVBPlugin,
  HMSVirtualBackgroundTypes,
} from "@100mslive/hms-virtual-background";
import { BlurOn as BlurPersonHighIcon } from "@mui/icons-material";
import {
  selectIsAllowedToPublish,
  selectIsLocalVideoEnabled,
  selectIsLocalVideoPluginPresent,
  selectLocalPeerRole,
  selectLocalVideoTrackID,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { CrossCircleIcon, CrossIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import { VBCollection } from "./VBCollection";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../common/constants";
import { defaultMedia } from "./constants";

const iconDims = { height: "40px", width: "40px" };

export const VBPicker = () => {
  const toggleVB = useSidepaneToggle(SIDE_PANE_OPTIONS.VB);
  const [isVBSupported, setIsVBSupported] = useState(false);
  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
  const isVBPresent = useHMSStore(selectIsLocalVideoPluginPresent("HMSVB"));
  const localPeerVideoTrackID = useHMSStore(selectLocalVideoTrackID);
  const mediaList = defaultMedia;
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);

  const hmsActions = useHMSActions();
  const role = useHMSStore(selectLocalPeerRole);
  const virtualBackground = useRef(window.HMS.virtualBackground || null);

  async function createPlugin() {
    if (!virtualBackground.current) {
      virtualBackground.current = new HMSVBPlugin(
        HMSVirtualBackgroundTypes.NONE,
        HMSVirtualBackgroundTypes.NONE
      );
    }
  }
  useEffect(() => {
    if (!isVideoOn) {
      toggleVB();
    }
  }, [isVideoOn, toggleVB]);
  useEffect(() => {
    if (!localPeerVideoTrackID) {
      return;
    }

    createPlugin().then(() => {
      const pluginSupport = hmsActions.validateVideoPluginSupport(
        virtualBackground.current
      );
      setIsVBSupported(pluginSupport.isSupported);
    });
  }, [hmsActions, localPeerVideoTrackID]);

  const getBackground = () => {
    let background = virtualBackground?.current?.background;
    return background?.src || background;
  };
  const [activeBackground, setActiveBackground] = useState(
    getBackground() || HMSVirtualBackgroundTypes.NONE
  );

  const removeEffects = async () => {
    try {
      await removePlugin();
      setActiveBackground(HMSVirtualBackgroundTypes.NONE);
    } catch (e) {
      //
    }
  };
  const setBlur = async () => {
    try {
      await virtualBackground.current.setBackground(
        HMSVirtualBackgroundTypes.BLUR,
        HMSVirtualBackgroundTypes.BLUR
      );
      setActiveBackground(HMSVirtualBackgroundTypes.BLUR);
      addPlugin();
    } catch (e) {
      //
    }
  };

  const setBackground = async mediaURL => {
    const img = document.createElement("img");
    let retries = 0;
    const MAX_RETRIES = 3;
    img.alt = "VB";
    img.src = mediaURL;
    setActiveBackground(mediaURL);
    try {
      await virtualBackground?.current?.setBackground(
        img,
        HMSVirtualBackgroundTypes.IMAGE
      );
      await addPlugin();
    } catch (e) {
      if (retries++ < MAX_RETRIES) {
        await virtualBackground?.current?.setBackground(
          img,
          HMSVirtualBackgroundTypes.IMAGE
        );
        await addPlugin();
      }
    }
  };
  if (!isAllowedToPublish.video || !isVBSupported) {
    return null;
  }
  const addPlugin = async () => {
    window.HMS.virtualBackground = virtualBackground.current;
    if (!isVBPresent) {
      await hmsActions.addPluginToVideoTrack(
        virtualBackground.current,
        Math.floor(role.publishParams.video.frameRate / 2)
      );
    }
  };
  const removePlugin = async () => {
    await hmsActions.removePluginFromVideoTrack(virtualBackground.current);
    new HMSVBPlugin(
      HMSVirtualBackgroundTypes.NONE,
      HMSVirtualBackgroundTypes.NONE
    );
  };
  return (
    <Fragment>
      <Flex direction="column" css={{ size: "100%" }}>
        <Flex align="center" css={{ w: "100%", mb: "$10" }}>
          <Text css={{ fontWeight: "$semiBold", mr: "$4" }}>
            Virtual Background
          </Text>

          <IconButton
            onClick={toggleVB}
            css={{ w: "$11", h: "$11", ml: "auto" }}
          >
            <CrossIcon />
          </IconButton>
        </Flex>
        <Box
          css={{
            overflowY: "auto",
            flex: "1 1 0",
            mr: "-$10",
            pr: "$10",
          }}
        >
          <VBCollection
            title="Effects"
            options={[
              {
                title: "No effect",
                icon: <CrossCircleIcon style={iconDims} />,
                value: HMSVirtualBackgroundTypes.NONE,
                onClick: async () => {
                  await removeEffects();
                },
              },
              {
                title: "Blur",
                icon: <BlurPersonHighIcon style={iconDims} />,
                value: HMSVirtualBackgroundTypes.BLUR,
                onClick: async () => {
                  setBlur();
                },
              },
            ]}
            activeBackground={activeBackground}
          />

          <VBCollection
            title="Backgrounds"
            options={mediaList.map(mediaURL => ({
              mediaURL,
              value: mediaURL,
              onClick: async () => {
                setBackground(mediaURL);
              },
            }))}
            activeBackground={activeBackground}
          />
        </Box>
      </Flex>
    </Fragment>
  );
};
