import React from "react";
import { Flex } from "@100mslive/roomkit-react";
import EventFooterContentLeft from "./EventFooterContentLeft";
import EventFooterContentRight from "./EventFooterContentRight";

const EventFooterContent = () => {
  return (
    <Flex className="events6ix-layout-footer-content">
      <EventFooterContentLeft />
      <EventFooterContentRight />
    </Flex>
  );
};

export default EventFooterContent;
