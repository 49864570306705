import React from "react";
import { Button, FormControl } from "@mui/material";
import { Box, Flex, Input, Label, Text } from "@100mslive/roomkit-react";
import { Edit, UploadFIle } from "../../../icons";
import ImageCropper from "../../Common/ImageCropper";

const AccountBanner = props => {
  const {
    onSelectOrgBanner,
    orgBannerPreview,
    selectBanner,
    cropOrgBanner,
    setCropOrgBanner,
    setOrgBanner,
    isEditAccess,
    saveSetting,
    setSelectBanner,
  } = props;

  return (
    <>
      <div className="events-page-account-setting-content-row">
        <Box className="events-page-account-setting-content-box">
          <div className="events-page-account-setting-content-box-title">
            Organization Banner
          </div>
          <FormControl className="events-page-account-setting-content-box-control">
            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Label
                css={{ flex: 1 }}
                className="events-page-account-setting-content-box-lable"
              >
                This is your organization cover banner.
              </Label>

              <label
                htmlFor="org-banner"
                className="custom-form-file events-page-account-setting-content-box-logo"
                translate="no"
              >
                {isEditAccess && (
                  <Input
                    type="file"
                    id="org-banner"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                    className="custom-form-file-input"
                    onChange={onSelectOrgBanner}
                  />
                )}

                {orgBannerPreview ? (
                  <>
                    <div className="custom-form-file-preview ">
                      <img src={orgBannerPreview} alt="banner" />
                    </div>
                    {isEditAccess && <Edit />}
                  </>
                ) : isEditAccess ? (
                  <>
                    <UploadFIle /> <span translate="yes">Upload Banner</span>
                  </>
                ) : null}
              </label>
            </Flex>
          </FormControl>
          {isEditAccess && (
            <>
              <div className="events-page-account-setting-content-box-line"></div>

              <Flex
                align="center"
                className="events-page-account-setting-content-box-row"
              >
                <Text
                  css={{
                    flex: "1 1 0",
                  }}
                  className="events-page-account-setting-content-box-text"
                >
                  A cover banner is optional but strongly recommended.
                </Text>
                {selectBanner ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    className="events6ix-btn events6ix-btn-primary"
                    type="button"
                    onClick={() => saveSetting("orgBanner")}
                  >
                    Save
                  </Button>
                ) : null}
              </Flex>
            </>
          )}
        </Box>
      </div>
      {cropOrgBanner && (
        <ImageCropper
          open={true}
          size="xl"
          data={{
            image: cropOrgBanner,
            title: "Organization Banner",
            minWidth: 1110,
            minHeight: 360,
            ratio: 1110 / 360,
            feature: ["square", "freedom"],
          }}
          onCancel={() => {
            setCropOrgBanner("");
            onSelectOrgBanner();
          }}
          onUpdate={image => {
            setOrgBanner(image);
            setCropOrgBanner("");
            setSelectBanner(true);
          }}
        />
      )}
    </>
  );
};

export default AccountBanner;
