import React from "react";
export function BuildingIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3789 15.0385H15.2176V4.41271C15.2176 4.09205 14.9577 3.83206 14.637 3.83206H10.2821V2.38045C10.2821 2.05979 10.0222 1.7998 9.70148 1.7998H5.67325C5.35259 1.7998 5.0926 2.05979 5.0926 2.38045V3.83206H4.62083C4.30017 3.83206 4.04018 4.09205 4.04018 4.41271V15.0385H2.87889C2.55823 15.0385 2.29825 15.2985 2.29825 15.6192C2.29825 15.9398 2.55823 16.1998 2.87889 16.1998H16.3789C16.6996 16.1998 16.9595 15.9398 16.9595 15.6192C16.9595 15.2985 16.6996 15.0385 16.3789 15.0385ZM6.25389 2.96109H9.12083V3.83206H6.25389V2.96109ZM8.4676 15.0385V12.5708H10.7902V15.0385H8.4676ZM11.9515 15.0385V12.5708H12.2055C12.5262 12.5708 12.7862 12.3108 12.7862 11.9901C12.7862 11.6695 12.5262 11.4095 12.2055 11.4095C10.1858 11.4095 8.92297 11.4095 7.05228 11.4095C6.73162 11.4095 6.47164 11.6695 6.47164 11.9901C6.47164 12.3108 6.73162 12.5708 7.05228 12.5708H7.30631V15.0385H5.20147V4.99335H14.0563V15.0385H11.9515ZM8.79422 7.45384C8.79422 7.7745 8.53423 8.03448 8.21357 8.03448H7.56035C7.23968 8.03448 6.9797 7.7745 6.9797 7.45384C6.9797 7.13318 7.23968 6.87319 7.56035 6.87319H8.21357C8.53423 6.87319 8.79422 7.13318 8.79422 7.45384ZM12.2418 7.45384C12.2418 7.7745 11.9819 8.03448 11.6612 8.03448H11.0079C10.6872 8.03448 10.4273 7.7745 10.4273 7.45384C10.4273 7.13318 10.6872 6.87319 11.0079 6.87319H11.6612C11.9819 6.87319 12.2418 7.13318 12.2418 7.45384ZM8.79422 9.63126C8.79422 9.95192 8.53423 10.2119 8.21357 10.2119H7.56035C7.23968 10.2119 6.9797 9.95192 6.9797 9.63126C6.9797 9.3106 7.23968 9.05061 7.56035 9.05061H8.21357C8.53423 9.05061 8.79422 9.3106 8.79422 9.63126ZM12.2418 9.63126C12.2418 9.95192 11.9819 10.2119 11.6612 10.2119H11.0079C10.6872 10.2119 10.4273 9.95192 10.4273 9.63126C10.4273 9.3106 10.6872 9.05061 11.0079 9.05061H11.6612C11.9819 9.05061 12.2418 9.3106 12.2418 9.63126Z"
        fill="#8C8E95"
      />
    </svg>
  );
}
export default BuildingIcon;
