import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Moment from "moment";
import { CalenderIcon, ClockIcon } from "../../icons";

const ExploreListItem = props => {
  const {
    hostedBy,
    eventHostBy,
    eventMeetingLink,
    eventName,
    eventCover,
    eventDate,
  } = props;

  return (
    <>
      <Box className="event-page-explore-content-items-box">
        <Link
          to={eventMeetingLink}
          className="event-page-explore-content-items-box-inner"
        >
          <div
            className="event-page-explore-content-items-box-media"
            title={eventName}
          >
            <img src={eventCover} alt={eventName} />
          </div>
          <div className="event-page-explore-content-items-box-content">
            <div className="event-page-explore-content-items-box-hosted">
              {hostedBy
                ? hostedBy?.map((item, index) => (
                    <React.Fragment key={index}>
                      {item?.orgName}
                      {index !== hostedBy.length - 1 && ", "}
                    </React.Fragment>
                  ))
                : eventHostBy}
            </div>
            <div
              className="event-page-explore-content-items-box-heading"
              title={eventName}
            >
              {eventName}
            </div>

            <div className="event-page-explore-content-items-box-date">
              <div className="event-page-explore-content-items-box-date-text">
                <CalenderIcon />
                {eventDate
                  ? Moment(eventDate).format("ddd, MMM DD, YYYY")
                  : "TBD"}
              </div>

              <div className="event-page-explore-content-items-box-date-text">
                <ClockIcon />
                {Moment(eventDate).format("hh:mm A")}
              </div>
            </div>
          </div>
        </Link>
      </Box>
    </>
  );
};

export default ExploreListItem;
