import React from "react";
export function ArrowRight() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 9H14.25"
        stroke="white"
        strokeWidth="1.503"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3.74985L14.25 8.99985L9 14.2498"
        stroke="white"
        strokeWidth="1.503"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ArrowRight;
