import React from "react";
export function UpIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12.5498V20.5498C4 21.0802 4.21071 21.5889 4.58579 21.964C4.96086 22.3391 5.46957 22.5498 6 22.5498H18C18.5304 22.5498 19.0391 22.3391 19.4142 21.964C19.7893 21.5889 20 21.0802 20 20.5498V12.5498"
        stroke="#E3C050"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 6.5498L12 2.5498L8 6.5498"
        stroke="#E3C050"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.5498V15.5498"
        stroke="#E3C050"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default UpIcon;
