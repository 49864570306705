import React from "react";
export function FacebookIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58437 1.55476C8.48483 0.654308 9.70611 0.148438 10.9795 0.148438H13.4095C13.8246 0.148438 14.161 0.484895 14.161 0.899937V4.13994C14.161 4.55498 13.8246 4.89144 13.4095 4.89144H10.9795C10.964 4.89144 10.9492 4.8976 10.9382 4.90857C10.9272 4.91954 10.921 4.93442 10.921 4.94994V6.62844H13.4095C13.641 6.62844 13.8595 6.73505 14.0019 6.91745C14.1443 7.09986 14.1947 7.3377 14.1386 7.5622L13.3286 10.8022C13.245 11.1367 12.9444 11.3714 12.5995 11.3714H10.921V17.0999C10.921 17.515 10.5846 17.8514 10.1695 17.8514H6.92955C6.51451 17.8514 6.17805 17.515 6.17805 17.0999V11.3714H4.49955C4.0845 11.3714 3.74805 11.035 3.74805 10.6199V7.37994C3.74805 6.96489 4.0845 6.62844 4.49955 6.62844H6.17805V4.94994C6.17805 3.6765 6.68392 2.45522 7.58437 1.55476ZM10.9795 1.65144C10.1047 1.65144 9.26574 1.99896 8.64716 2.61755C8.02857 3.23613 7.68105 4.07512 7.68105 4.94994V7.37994C7.68105 7.79498 7.34459 8.13144 6.92955 8.13144H5.25105V9.86844H6.92955C7.34459 9.86844 7.68105 10.2049 7.68105 10.6199V16.3484H9.41805V10.6199C9.41805 10.2049 9.75451 9.86844 10.1695 9.86844H12.0128L12.447 8.13144H10.1695C9.75451 8.13144 9.41805 7.79498 9.41805 7.37994V4.94994C9.41805 4.5358 9.58256 4.13863 9.8754 3.84579C10.1682 3.55295 10.5654 3.38844 10.9795 3.38844H12.658V1.65144H10.9795Z"
        fill="#C0C0C0"
      />
    </svg>
  );
}
export default FacebookIcon;
