import React from "react";

const SixSecurityCrypto = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-landing-section-space-top">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Platform Overview
            </h2>
          </div>

          <div className="events-page-landing-privacy-text">
            6ix Inc. is a financial technology company on a mission to unlock
            social mobility on a global scale by empowering anyone, from
            anywhere, to invest in anything.
          </div>
          <div className="events-page-landing-privacy-text">
            Our flagship product - 6ix.com - combines the power of community and
            technology by making it easy for anyone to attend, host, and market
            virtual investment summits. Virtual investment summits are live,
            interactive events delivered over the internet by video, and are
            specifically designed around the unique capital markets needs of
            investors, analysts and companies. Virtual investment summits are
            powerful because they function as time-based events that bring
            people together at the exact moment when decisions are being made.
          </div>
          <div className="events-page-landing-privacy-text">
            6ix.com is provisioned into two main configurations: investors use
            the 6ix Investment Discovery Platform to connect with issuers, and
            issuers use the 6ix Investor Experience Platform to connect with
            investors.
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityCrypto;
