import React from "react";

const SixPrivacy = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Privacy Policy
            </h2>
            <div class="events-page-landing-section-header-text">
              Disclaimer of Liability
            </div>
          </div>
          <div className="events-page-landing-privacy-text">
            6ix Inc. (“6ix”) licenses software to publicly traded issuers of
            securities (“Issuers” and each an “Issuer”) which can be used by
            Issuers to communicate with current and prospective investors
            (“Investors” and each an “Investor”) on the online platform
            maintained by 6ix Inc. at www.6ix.com (the “Platform”). Each Issuer
            is solely responsible for all content relating to the Issuer on the
            6ix Platform, including without limitation all investor summits
            presented by the Issuer and all summary information about the Issuer
            on the 6ix Platform.
          </div>

          <div className="events-page-landing-privacy-text">
            All content provided on the 6ix Platform is for informational and
            educational purposes only and should not be construed as investment
            advice or an offer or solicitation in respect to any products or
            services. The content presented on the 6ix Platform should not be
            used as the basis for any investment decision, and does not purport
            to provide any legal, tax or accounting advice. There are inherent
            risks involved with investing in Issuers, as set out in the public
            disclosure record of each Issuer. Issuers are not responsible for
            revising or updating any information that they present on the 6ix
            Platform.
          </div>

          <div className="events-page-landing-privacy-text">
            Investors should refer to the continuous disclosure documents filed
            by each Issuer under applicable securities laws, including risk
            factors and warnings regarding “forward looking information”.
            Issuers are solely responsible for compliance with applicable
            securities laws, and 6ix makes no representations and provides no
            assurances to Investors regarding the accuracy or truthfulness of
            information presented by Issuers on the 6ix Platform or in their
            public disclosure records.
          </div>

          <div className="events-page-landing-privacy-text">
            6ix is not licensed to trade, deal or advise in securities in any
            jurisdiction. 6ix does not recommend or endorse any Issuer on the
            6ix Platform, nor does 6ix verify the accuracy of any information
            presented by Issuers to Investors on the 6ix Platform or in their
            public disclosure records. 6ix’s sole responsibility as the operator
            of the 6ix Platform is to provide software to Issuers and Investors
            through which Issuers and Investors communicate directly with each
            other without any intermediation or intervention by 6ix other than
            in respect of technical, logistical or advertising and marketing
            support. Issuers pay 6ix a licensing fee for use of the 6ix
            Platform, and 6ix may also receive payment from Issuers to pay for
            advertising on third-party websites. 6ix and its principals may make
            and hold investments in securities of Issuers on the 6ix Platform
            and 6ix may offer reduced rates for its software services to Issuers
            in which 6ix and/or its principals are invested.
          </div>
          <div className="events-page-landing-privacy-text">
            ALL CONTENT ON THE PLATFORM IS PROVIDED BY THE ISSUERS “AS IS” AND
            “AS AVAILABLE”. 6IX DOES NOT GUARANTEE THE ACCURACY OF ITS CONTENT.
            BY USING THE PLATFORM, EACH INVESTOR AGREES TO ACCEPT ANY RISKS
            ASSOCIATED WITH THE USE OF THE PLATFORM AND ACKNOWLEDGES THAT 6IX IS
            NOT RESPONSIBLE FOR ANY CONTENT PRESENTED BY OR RELATING TO ISSUERS
            ON THE PLATFORM.
          </div>
          <div className="events-page-landing-privacy-text">
            6IX DOES NOT WARRANT THAT THE PROVISION OF INFORMATION BY ISSUERS ON
            THE PLATFORM WILL BE ERROR-FREE, TIMELY, COMPLETE OR ACCURATE. USE
            OF THE PLATFORM AND RELIANCE THEREON IS AT INVESTOR’S SOLE RISK. 6IX
            WILL NOT BE IN ANY WAY BE LIABLE TO ANY INVESTOR OR ISSUER OR ANY
            OTHER ENTITY OR PERSON FOR ANY INACCURACIES, ERRORS, OMISSIONS,
            DELAYS, DAMAGES, CLAIMS, LIABILITIES OR LOSSES, REGARDLESS OF CAUSE,
            IN OR ARISING FROM THE USE OF THE PLATFORM.
          </div>
          <div className="events-page-landing-privacy-text">
            IN NO EVENT WILL 6IX BE LIABLE FOR ANY DAMAGES, INCLUDING WITHOUT
            LIMITATION DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
            DAMAGES, LOSSES OR EXPENSES ARISING IN CONNECTION WITH THE USE OF
            THE PLATFORM BY INVESTORS AND/OR ISSUERS EVEN IF 6IX IS ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES. FURTHER, 6IX
            SHALL NOT BE LIABLE IN ANY MANNER FOR THE PRODUCT OR SERVICES OF
            ANYONE WHO REDISTRIBUTES THE INFORMATION PROVIDED ON THE PLATFORM,
            AND SUCH REDISTRIBUTION IS EXPRESSLY PROHIBITED.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Owner and Data Controller
          </h3>

          <div className="events-page-landing-privacy-text">
            6ix Inc., 1200 Bay Street #202, Toronto, Ontario, Canada, M5R 2A5
          </div>
          <div className="events-page-landing-privacy-text">
            Owner contact email: daniel@6ix.com
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Types of Data collected
          </h3>

          <div className="events-page-landing-privacy-text">
            Among the types of Personal Data that this Application collects, by
            itself or through third parties, there are: Cookies; Usage Data;
            email address; first name; last name; phone number; profession;
            county; state; country; ZIP/Postal code; gender; date of birth;
            city; address; company name; website; username; various types of
            Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Complete details on each type of Personal Data collected are
            provided in the dedicated sections of this privacy policy or by
            specific explanation texts displayed prior to the Data collection.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data may be freely provided by the User, or, in case of
            Usage Data, collected automatically when using this Application.
          </div>
          <div className="events-page-landing-privacy-text">
            Unless specified otherwise, all Data requested by this Application
            is mandatory and failure to provide this Data may make it impossible
            for this Application to provide its services. In cases where this
            Application specifically states that some Data is not mandatory,
            Users are free not to communicate this Data without consequences to
            the availability or the functioning of the Service.
          </div>
          <div className="events-page-landing-privacy-text">
            Users who are uncertain about which Personal Data is mandatory are
            welcome to contact the Owner.
          </div>
          <div className="events-page-landing-privacy-text">
            Any use of Cookies – or of other tracking tools — by this
            Application or by the owners of third-party services used by this
            Application serves the purpose of providing the Service required by
            the User, in addition to any other purposes described in the present
            document and in the Cookie Policy.
          </div>
          <div className="events-page-landing-privacy-text">
            Users are responsible for any third-party Personal Data obtained,
            published or shared through this Application.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Mode and place of processing the Data <br />
            <br />
            Methods of processing
          </h3>

          <div className="events-page-landing-privacy-text">
            The Owner takes appropriate security measures to prevent
            unauthorized access, disclosure, modification, or unauthorized
            destruction of the Data. The Data processing is carried out using
            computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In
            addition to the Owner, in some cases, the Data may be accessible to
            certain types of persons in charge, involved with the operation of
            this Application (administration, sales, marketing, legal, system
            administration) or external parties (such as third-party technical
            service providers, mail carriers, hosting providers, IT companies,
            communications agencies) appointed, if necessary, as Data Processors
            by the Owner. The updated list of these parties may be requested
            from the Owner at any time.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Place</h3>

          <div className="events-page-landing-privacy-text">
            The Data is processed at the Owner's operating offices and in any
            other places where the parties involved in the processing are
            located.
          </div>
          <div className="events-page-landing-privacy-text">
            Depending on the User's location, data transfers may involve
            transferring the User's Data to a country other than their own. To
            find out more about the place of processing of such transferred
            Data, Users can check the section containing details about the
            processing of Personal Data.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Retention time</h3>

          <div className="events-page-landing-privacy-text">
            Unless specified otherwise in this document, Personal Data shall be
            processed and stored for as long as required by the purpose they
            have been collected for and may be retained for longer due to
            applicable legal obligation or based on the Users’ consent.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            The purposes of processing
          </h3>
          <div className="events-page-landing-privacy-text">
            The Data concerning the User is collected to allow the Owner to
            provide its Service, comply with its legal obligations, respond to
            enforcement requests, protect its rights and interests (or those of
            its Users or third parties), detect any malicious or fraudulent
            activity, as well as the following: Analytics, Remarketing and
            behavioral targeting, Tag Management, Infrastructure monitoring,
            Contacting the User, Managing contacts and sending messages,
            Advertising, Registration and authentication, Interaction with
            external social networks and platforms and Displaying content from
            external platforms.
          </div>
          <div className="events-page-landing-privacy-text">
            For specific information about the Personal Data used for each
            purpose, the User may refer to the section “Detailed information on
            the processing of Personal Data”.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Detailed information on the processing of Personal Data
          </h3>
          <div className="events-page-landing-privacy-text">
            Personal Data is collected for the following purposes and using the
            following services:
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Advertising</h3>
          <div className="events-page-landing-privacy-text">
            This type of service allows User Data to be utilized for advertising
            communication purposes. These communications are displayed in the
            form of banners and other advertisements on this Application,
            possibly based on User interests.
          </div>
          <div className="events-page-landing-privacy-text">
            This does not mean that all Personal Data are used for this purpose.
            Information and conditions of use are shown below.
          </div>
          <div className="events-page-landing-privacy-text">
            Some of the services listed below may use Trackers for identifying
            Users, behavioral retargeting i.e. displaying ads tailored to the
            User’s interests and behavior, or to measure ads performance. For
            more information, please check the privacy policies of the relevant
            services.
          </div>
          <div className="events-page-landing-privacy-text">
            Services of this kind usually offer the possibility to opt out of
            such tracking. In addition to any opt-out feature offered by any of
            the services below, Users may learn more on how to generally opt out
            of interest-based advertising within the dedicated section "How to
            opt-out of interest-based advertising" in this document.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Meta ads conversion tracking (Meta pixel) (Meta Platforms, Inc.)
          </h3>
          <div className="events-page-landing-privacy-text">
            Meta ads conversion tracking (Meta pixel) is an analytics service
            provided by Meta Platforms, Inc. that connects data from the Meta
            Audience Network with actions performed on this Application. The
            Meta pixel tracks conversions that can be attributed to ads on
            Facebook, Instagram and Meta Audience Network.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Trackers; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://www.facebook.com/about/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Google Ads conversion tracking (Google LLC)
          </h3>
          <div className="events-page-landing-privacy-text">
            Google Ads conversion tracking is an analytics service provided by
            Google LLC that connects data from the Google Ads advertising
            network with actions performed on this Application.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Trackers; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Microsoft Advertising (Microsoft Corporation)
          </h3>
          <div className="events-page-landing-privacy-text">
            Microsoft Advertising is an advertising service provided by
            Microsoft Corporation.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Tracker; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://privacy.microsoft.com/PrivacyStatement "
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>{" "}
            – .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Analytics</h3>
          <div className="events-page-landing-privacy-text">
            The services contained in this section enable the Owner to monitor
            and analyze web traffic and can be used to keep track of User
            behavior.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Google Analytics (Universal Analytics) (Google LLC)
          </h3>
          <div className="events-page-landing-privacy-text">
            Google Analytics (Universal Analytics) is a web analysis service
            provided by Google LLC (“Google”). Google utilizes the Data
            collected to track and examine the use of this Application, to
            prepare reports on its activities and share them with other Google
            services.
          </div>
          <div className="events-page-landing-privacy-text">
            Google may use the Data collected to contextualize and personalize
            the ads of its own advertising network.
          </div>
          <div className="events-page-landing-privacy-text">
            In order to understand Google's use of Data, consult{" "}
            <a
              href="https://www.google.com/policies/privacy/partners/"
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              Google's partner policy
            </a>
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://business.safety.google/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Display Advertising extension for Google Analytics (Google LLC)
          </h3>
          <div className="events-page-landing-privacy-text">
            Google Analytics on this Application might use Google's
            Interest-based advertising, 3rd-party audience data and information
            from the DoubleClick Cookie to extend analytics with demographics,
            interests and ads interaction data.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">MixPanel (MixPanel)</h3>
          <div className="events-page-landing-privacy-text">
            MixPanel is an analytics service provided by Mixpanel Inc.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://mixpanel.com/legal/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            HubSpot Analytics (HubSpot, Inc.)
          </h3>
          <div className="events-page-landing-privacy-text">
            HubSpot Analytics is an analytics service provided by HubSpot, Inc.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Tracker; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://legal.hubspot.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Quantcast Measure (Quantcast Corporation)
          </h3>
          <div className="events-page-landing-privacy-text">
            Quantcast Measure is an analytics service provided by Quantcast
            Corporation.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Tracker; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://www.quantcast.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Contacting the User <br /> <br /> Mailing list or newsletter (this
            Application)
          </h3>
          <div className="events-page-landing-privacy-text">
            By registering on the mailing list or for the newsletter, the User’s
            email address will be added to the contact list of those who may
            receive email messages containing information of commercial or
            promotional nature concerning this Application. Your email address
            might also be added to this list as a result of signing up to this
            Application or after making a purchase.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: address; city; company name; Cookies;
            country; county; date of birth; email address; first name; gender;
            last name; phone number; profession; state; Usage Data; website;
            ZIP/Postal code.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Displaying content from external platforms
          </h3>
          <div className="events-page-landing-privacy-text">
            This type of service allows you to view content hosted on external
            platforms directly from the pages of this Application and interact
            with them.
          </div>
          <div className="events-page-landing-privacy-text">
            This type of service might still collect web traffic data for the
            pages where the service is installed, even when Users do not use it.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Font Awesome (Fonticons, Inc. )
          </h3>
          <div className="events-page-landing-privacy-text">
            Font Awesome is a typeface visualization service provided by
            Fonticons, Inc. that allows this Application to incorporate content
            of this kind on its pages.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://fontawesome.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Infrastructure monitoring
          </h3>
          <div className="events-page-landing-privacy-text">
            This type of service allows this Application to monitor the use and
            behavior of its components so its performance, operation,
            maintenance and troubleshooting can be improved.
          </div>
          <div className="events-page-landing-privacy-text">
            Which Personal Data are processed depends on the characteristics and
            mode of implementation of these services, whose function is to
            filter the activities of this Application.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Pingdom (Pingdom AB)
          </h3>
          <div className="events-page-landing-privacy-text">
            Pingdom is a monitoring service provided by Pingdom AB.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: Sweden –{" "}
            <a
              href="https://www.pingdom.com/legal/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>

      <section className="events-page-landing-section ">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Interaction with external social networks and platforms
          </h3>
          <div className="events-page-landing-privacy-text">
            This type of service allows interaction with social networks or
            other external platforms directly from the pages of this
            Application.
          </div>
          <div className="events-page-landing-privacy-text">
            The interaction and information obtained through this Application
            are always subject to the User’s privacy settings for each social
            network. This type of service might still collect traffic data for
            the pages where the service is installed, even when Users do not use
            it.
          </div>

          <div className="events-page-landing-privacy-text">
            It is recommended to log out from the respective services in order
            to make sure that the processed data on this Application isn’t being
            connected back to the User’s profile.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            AddToAny (AddToAny LLC)
          </h3>
          <div className="events-page-landing-privacy-text">
            AddToAny is a service provided by AddToAny LLC, which displays a
            widget that allows interaction with social networks and external
            platforms as well as sharing the contents of this Application.
          </div>
          <div className="events-page-landing-privacy-text">
            Depending on the configuration, this service can display widgets
            belonging to third parties such as the managers of social networks
            where interactions are shared. In this case, also the third parties
            that provide the widget will be informed of interactions and Usage
            Data on the pages where this service is installed.
          </div>

          <div className="events-page-landing-privacy-text">
            Data collected through the service may be used to help deliver
            interest-based advertising. Users can opt out of third-party
            interest-based advertising by visiting the
            <a
              href="http://optout.networkadvertising.org/?c=1"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Network Advertising Initiative opt-out page
            </a>
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Tracker; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://www.addtoany.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Managing contacts and sending messages
          </h3>
          <div className="events-page-landing-privacy-text">
            This type of service makes it possible to manage a database of email
            contacts, phone contacts or any other contact information to
            communicate with the User.
          </div>
          <div className="events-page-landing-privacy-text">
            These services may also collect data concerning the date and time
            when the message was viewed by the User, as well as when the User
            interacted with it, such as by clicking on links included in the
            message.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Mailchimp (The Rocket Science Group LLC)
          </h3>
          <div className="events-page-landing-privacy-text">
            Mailchimp is an email address management and message sending service
            provided by The Rocket Science Group LLC.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: address; company name; Cookies; country;
            date of birth; email address; first name; gender; last name; phone
            number; Usage Data; username; various types of Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://www.addtoany.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Registration and authentication
          </h3>
          <div className="events-page-landing-privacy-text">
            By registering or authenticating, Users allow this Application to
            identify them and give them access to dedicated services.
          </div>
          <div className="events-page-landing-privacy-text">
            Depending on what is described below, third parties may provide
            registration and authentication services. In this case, this
            Application will be able to access some Data, stored by these
            third-party services, for registration or identification purposes.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Facebook Authentication (Facebook, Inc.)
          </h3>
          <div className="events-page-landing-privacy-text">
            Facebook Authentication is a registration and authentication service
            provided by Facebook, Inc. and is connected to the Facebook social
            network.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: various types of Data as specified in the
            privacy policy of the service.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://www.facebook.com/help/405977429438260"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Remarketing and behavioral targeting
          </h3>
          <div className="events-page-landing-privacy-text">
            This type of service allows this Application and its partners to
            inform, optimize and serve advertising based on past use of this
            Application by the User.
          </div>
          <div className="events-page-landing-privacy-text">
            This activity is facilitated by tracking Usage Data and by using
            Trackers to collect information which is then transferred to the
            partners that manage the remarketing and behavioral targeting
            activity.
          </div>
          <div className="events-page-landing-privacy-text">
            Some services offer a remarketing option based on email address
            lists.
          </div>
          <div className="events-page-landing-privacy-text">
            This type of service allows this Application and its partners to
            inform, optimize and serve advertising based on past use of this
            Application by the User.
            <br />
            This activity is facilitated by tracking Usage Data and by using
            Trackers to collect information which is then transferred to the
            partners that manage the remarketing and behavioral targeting
            activity.
            <br />
            Some services offer a remarketing option based on email address
            lists.
            <br />
            In addition to any opt-out feature provided by any of the services
            below, Users may opt out by visiting the{" "}
            <a
              href="http://optout.networkadvertising.org/?c=1"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Network Advertising Initiative opt-out page
            </a>
            .
          </div>
          <div className="events-page-landing-privacy-text">
            Users may also opt-out of certain advertising features through
            applicable device settings, such as the device advertising settings
            for mobile phones or ads settings in general.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Remarketing with Google Analytics (Google LLC)
          </h3>
          <div className="events-page-landing-privacy-text">
            Remarketing with Google Analytics is a remarketing and behavioral
            targeting service provided by Google LLC that connects the tracking
            activity performed by Google Analytics and its Cookies with the
            Google Ads advertising network and the Doubleclick Cookie.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>
      <section className="events-page-landing-section ">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            AdRoll (AdRoll, Inc.)
          </h3>
          <div className="events-page-landing-privacy-text">
            AdRoll is an advertising service provided by AdRoll, Inc.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://www.adrollgroup.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>{" "}
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Facebook Custom Audience (Facebook, Inc.)
          </h3>
          <div className="events-page-landing-privacy-text">
            Facebook Custom Audience is a remarketing and behavioral targeting
            service provided by Facebook, Inc. that connects the activity of
            this Application with the Facebook advertising network.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; email address.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –
            <a
              href="https://www.facebook.com/about/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Facebook Remarketing (Facebook, Inc.)
          </h3>
          <div className="events-page-landing-privacy-text">
            Facebook Remarketing is a remarketing and behavioral targeting
            service provided by Facebook, Inc. that connects the activity of
            this Application with the Facebook advertising network.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –
            <a
              href="https://www.facebook.com/about/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Twitter Tailored Audiences (X Corp.)
          </h3>
          <div className="events-page-landing-privacy-text">
            Twitter Tailored Audiences is a remarketing and behavioral targeting
            service provided by Twitter, Inc. that connects the activity of this
            Application with the Twitter advertising network.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; email address.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –
            <a
              href="https://twitter.com/en/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Twitter Remarketing (X Corp.)
          </h3>
          <div className="events-page-landing-privacy-text">
            Twitter Remarketing is a remarketing and behavioral targeting
            service provided by Twitter, Inc. that connects the activity of this
            Application with the Twitter advertising network.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; email address.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –
            <a
              href="https://twitter.com/en/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Google Ads Remarketing (Google LLC)
          </h3>
          <div className="events-page-landing-privacy-text">
            Google Ads Remarketing is a remarketing and behavioral targeting
            service provided by Google LLC that connects the activity of this
            Application with the Google Ads advertising network and the
            DoubleClick Cookie.
          </div>
          <div className="events-page-landing-privacy-text">
            Users can opt out of Google's use of cookies for ads personalization
            by visiting Google's{" "}
            <a
              href="https://adssettings.google.com/authenticated"
              className="text-primary"
              target="_blank"
              rel="noopener  noreferrer"
            >
              Ads Settings
            </a>
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>{" "}
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            LinkedIn Website Retargeting (LinkedIn Corporation)
          </h3>
          <div className="events-page-landing-privacy-text">
            LinkedIn Website Retargeting is a remarketing and behavioral
            targeting service provided by LinkedIn Corporation that connects the
            activity of this Application with the LinkedIn advertising network.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Tracker; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://www.linkedin.com/legal/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Tag Management</h3>
          <div className="events-page-landing-privacy-text">
            This type of service helps the Owner to manage the tags or scripts
            needed on this Application in a centralized fashion.
          </div>
          <div className="events-page-landing-privacy-text">
            This results in the Users' Data flowing through these services,
            potentially resulting in the retention of this Data.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Segment (Segment Inc.)
          </h3>
          <div className="events-page-landing-privacy-text">
            Segment is a tag management service provided by Segment.io, Inc.
          </div>
          <div className="events-page-landing-privacy-text">
            Personal Data processed: Cookies; Usage Data.
          </div>
          <div className="events-page-landing-privacy-text">
            Place of processing: United States –{" "}
            <a
              href="https://segment.com/docs/legal/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Information on opting out of interest-based advertising
          </h3>
          <div className="events-page-landing-privacy-text">
            In addition to any opt-out feature provided by any of the services
            listed in this document, Users may learn more on how to generally
            opt out of interest-based advertising within the dedicated section
            of the Cookie Policy.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Cookie Policy</h3>
          <div className="events-page-landing-privacy-text">
            This Application uses Trackers. To learn more, Users may consult the{" "}
            <a
              href="//www.iubenda.com/privacy-policy/91462635/cookie-policy"
              title="Cookie Policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Cookie Policy
            </a>
            .
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Further Information for Users <br />
            <br /> Legal basis of processing
          </h3>
          <div className="events-page-landing-privacy-text">
            The Owner may process Personal Data relating to Users if one of the
            following applies:
          </div>
          <ul className="events-page-landing-privacy-list">
            <li>
              Users have given their consent for one or more specific purposes.
            </li>
            <li>
              provision of Data is necessary for the performance of an agreement
              with the User and/or for any pre-contractual obligations thereof;
            </li>
            <li>
              processing is necessary for compliance with a legal obligation to
              which the Owner is subject;
            </li>
            <li>
              processing is related to a task that is carried out in the public
              interest or in the exercise of official authority vested in the
              Owner;
            </li>
            <li>
              processing is necessary for the purposes of the legitimate
              interests pursued by the Owner or by a third party.
            </li>
          </ul>
          <div className="events-page-landing-privacy-text">
            In any case, the Owner will gladly help to clarify the specific
            legal basis that applies to the processing, and in particular
            whether the provision of Personal Data is a statutory or contractual
            requirement, or a requirement necessary to enter into a contract.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Further information about retention time
          </h3>
          <div className="events-page-landing-privacy-text">
            Unless specified otherwise in this document, Personal Data shall be
            processed and stored for as long as required by the purpose they
            have been collected for and may be retained for longer due to
            applicable legal obligation or based on the Users’ consent.
          </div>
          <div className="events-page-landing-privacy-text">Therefore:</div>
          <ul className="events-page-landing-privacy-list">
            <li>
              Personal Data collected for purposes related to the performance of
              a contract between the Owner and the User shall be retained until
              such contract has been fully performed.
            </li>
            <li>
              Personal Data collected for the purposes of the Owner’s legitimate
              interests shall be retained as long as needed to fulfill such
              purposes. Users may find specific information regarding the
              legitimate interests pursued by the Owner within the relevant
              sections of this document or by contacting the Owner.
            </li>
          </ul>
          <div className="events-page-landing-privacy-text">
            The Owner may be allowed to retain Personal Data for a longer period
            whenever the User has given consent to such processing, as long as
            such consent is not withdrawn. Furthermore, the Owner may be obliged
            to retain Personal Data for a longer period whenever required to
            fulfil a legal obligation or upon order of an authority.
          </div>
          <div className="events-page-landing-privacy-text">
            Once the retention period expires, Personal Data shall be deleted.
            Therefore, the right of access, the right to erasure, the right to
            rectification and the right to data portability cannot be enforced
            after expiration of the retention period.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            The rights of Users based on the General Data Protection Regulation
            (GDPR)
          </h3>
          <div className="events-page-landing-privacy-text">
            Users may exercise certain rights regarding their Data processed by
            the Owner.
          </div>
          <div className="events-page-landing-privacy-text">
            In particular, Users have the right to do the following, to the
            extent permitted by law:
          </div>
          <ul className="events-page-landing-privacy-list">
            <li>
              Withdraw their consent at any time. Users have the right to
              withdraw consent where they have previously given their consent to
              the processing of their Personal Data.
            </li>
            <li>
              Object to processing of their Data. Users have the right to object
              to the processing of their Data if the processing is carried out
              on a legal basis other than consent.
            </li>
            <li>
              Access their Data. Users have the right to learn if Data is being
              processed by the Owner, obtain disclosure regarding certain
              aspects of the processing and obtain a copy of the Data undergoing
              processing.
            </li>
            <li>
              Verify and seek rectification. Users have the right to verify the
              accuracy of their Data and ask for it to be updated or corrected.
            </li>
            <li>
              Restrict the processing of their Data. Users have the right to
              restrict the processing of their Data. In this case, the Owner
              will not process their Data for any purpose other than storing it
            </li>
            <li>
              Have their Personal Data deleted or otherwise removed. Users have
              the right to obtain the erasure of their Data from the Owner.
            </li>
            <li>
              Receive their Data and have it transferred to another controller.
              Users have the right to receive their Data in a structured,
              commonly used and machine readable format and, if technically
              feasible, to have it transmitted to another controller without any
              hindrance.
            </li>
            <li>
              Lodge a complaint. Users have the right to bring a claim before
              their competent data protection authority.
            </li>
          </ul>
          <div className="events-page-landing-privacy-text">
            Users are also entitled to learn about the legal basis for Data
            transfers abroad including to any international organization
            governed by public international law or set up by two or more
            countries, such as the UN, and about the security measures taken by
            the Owner to safeguard their Data.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Details about the right to object to processing
          </h3>
          <div className="events-page-landing-privacy-text">
            Where Personal Data is processed for a public interest, in the
            exercise of an official authority vested in the Owner or for the
            purposes of the legitimate interests pursued by the Owner, Users may
            object to such processing by providing a ground related to their
            particular situation to justify the objection.
          </div>
          <div className="events-page-landing-privacy-text">
            Users must know that, however, should their Personal Data be
            processed for direct marketing purposes, they can object to that
            processing at any time, free of charge and without providing any
            justification. Where the User objects to processing for direct
            marketing purposes, the Personal Data will no longer be processed
            for such purposes. To learn whether the Owner is processing Personal
            Data for direct marketing purposes, Users may refer to the relevant
            sections of this document.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            How to exercise these rights
          </h3>
          <div className="events-page-landing-privacy-text">
            Any requests to exercise User rights can be directed to the Owner
            through the contact details provided in this document. Such requests
            are free of charge and will be answered by the Owner as early as
            possible and always within one month, providing Users with the
            information required by law. Any rectification or erasure of
            Personal Data or restriction of processing will be communicated by
            the Owner to each recipient, if any, to whom the Personal Data has
            been disclosed unless this proves impossible or involves
            disproportionate effort. At the Users’ request, the Owner will
            inform them about those recipients.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Additional information about Data collection and processing <br />
            Legal action
          </h3>
          <div className="events-page-landing-privacy-text">
            The User's Personal Data may be used for legal purposes by the Owner
            in Court or in the stages leading to possible legal action arising
            from improper use of this Application or the related Services.
          </div>
          <div className="events-page-landing-privacy-text">
            The User declares to be aware that the Owner may be required to
            reveal personal data upon request of public authorities.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Additional information about User's Personal Data
          </h3>
          <div className="events-page-landing-privacy-text">
            In addition to the information contained in this privacy policy,
            this Application may provide the User with additional and contextual
            information concerning particular Services or the collection and
            processing of Personal Data upon request.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            System logs and maintenance
          </h3>
          <div className="events-page-landing-privacy-text">
            For operation and maintenance purposes, this Application and any
            third-party services may collect files that record interaction with
            this Application (System logs) or use other Personal Data (such as
            the IP Address) for this purpose.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Information not contained in this policy
          </h3>
          <div className="events-page-landing-privacy-text">
            More details concerning the collection or processing of Personal
            Data may be requested from the Owner at any time. Please see the
            contact information at the beginning of this document.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Changes to this privacy policy
          </h3>
          <div className="events-page-landing-privacy-text">
            The Owner reserves the right to make changes to this privacy policy
            at any time by notifying its Users on this page and possibly within
            this Application and/or - as far as technically and legally feasible
            - sending a notice to Users via any contact information available to
            the Owner. It is strongly recommended to check this page often,
            referring to the date of the last modification listed at the bottom.
          </div>
          <div className="events-page-landing-privacy-text">
            Should the changes affect processing activities performed on the
            basis of the User’s consent, the Owner shall collect new consent
            from the User, where required.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Definitions and legal references <br />
            <br /> Personal Data (or Data)
          </h3>
          <div className="events-page-landing-privacy-text">
            Any information that directly, indirectly, or in connection with
            other information — including a personal identification number —
            allows for the identification or identifiability of a natural
            person.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Usage Data</h3>
          <div className="events-page-landing-privacy-text">
            Information collected automatically through this Application (or
            third-party services employed in this Application), which can
            include: the IP addresses or domain names of the computers utilized
            by the Users who use this Application, the URI addresses (Uniform
            Resource Identifier), the time of the request, the method utilized
            to submit the request to the server, the size of the file received
            in response, the numerical code indicating the status of the
            server's answer (successful outcome, error, etc.), the country of
            origin, the features of the browser and the operating system
            utilized by the User, the various time details per visit (e.g., the
            time spent on each page within the Application) and the details
            about the path followed within the Application with special
            reference to the sequence of pages visited, and other parameters
            about the device operating system and/or the User's IT environment.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">User</h3>
          <div className="events-page-landing-privacy-text">
            The individual using this Application who, unless otherwise
            specified, coincides with the Data Subject.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Data Subject</h3>
          <div className="events-page-landing-privacy-text">
            The natural person to whom the Personal Data refers.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Data Processor (or Processor)
          </h3>
          <div className="events-page-landing-privacy-text">
            The natural or legal person, public authority, agency or other body
            which processes Personal Data on behalf of the Controller, as
            described in this privacy policy.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Data Controller (or Owner)
          </h3>
          <div className="events-page-landing-privacy-text">
            The natural or legal person, public authority, agency or other body
            which, alone or jointly with others, determines the purposes and
            means of the processing of Personal Data, including the security
            measures concerning the operation and use of this Application. The
            Data Controller, unless otherwise specified, is the Owner of this
            Application.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">This Application</h3>
          <div className="events-page-landing-privacy-text">
            The means by which the Personal Data of the User is collected and
            processed.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Service</h3>
          <div className="events-page-landing-privacy-text">
            The service provided by this Application as described in the
            relative terms (if available) and on this site/application.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            European Union (or EU)
          </h3>
          <div className="events-page-landing-privacy-text">
            Unless otherwise specified, all references made within this document
            to the European Union include all current member states to the
            European Union and the European Economic Area.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Cookie</h3>
          <div className="events-page-landing-privacy-text">
            Cookies are Trackers consisting of small sets of data stored in the
            User's browser.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Tracker</h3>
          <div className="events-page-landing-privacy-text">
            Tracker indicates any technology - e.g Cookies, unique identifiers,
            web beacons, embedded scripts, e-tags and fingerprinting - that
            enables the tracking of Users, for example by accessing or storing
            information on the User’s device.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Legal information</h3>
          <div className="events-page-landing-privacy-text">
            This privacy policy relates solely to this Application, if not
            stated otherwise within this document.
          </div>
          <div className="events-page-landing-privacy-text">
            Latest update: March 07, 2024
          </div>
          <div className="events-page-landing-privacy-text">
            <a
              class="text-primary"
              target="_blank    "
              href="https://www.iubenda.com/en/privacy-and-cookie-policy-generator"
              title="iubenda - Privacy Policy generator"
            >
              iubenda
            </a>{" "}
            hosts this content and only collects{" "}
            <a
              class="text-primary"
              target="_top"
              href="//www.iubenda.com/privacy-policy/65675001"
            >
              the Personal Data strictly necessary
            </a>{" "}
            for it to be provided.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              6ix Meetings
            </h2>
          </div>
          <div className="events-page-landing-privacy-text">
            6ix Meetings use and transfer to any other app of information
            received from Google APIs will adhere to&nbsp;
            <a
              class="c-link"
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
              rel="noopener noreferrer"
              data-stringify-link="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              data-sk="tooltip_parent"
            >
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements
          </div>
          <div className="events-page-landing-privacy-text">
            6ix Meetings is dedicated to improving your scheduling experience.
            To achieve this goal, we require personal information from both you
            and the individuals organizing meetings with you – it’s an essential
            aspect of the process. However, we take this responsibility
            seriously. We understand the significance of privacy and your
            rights, striving to instill confidence in your use of our services
            and interactions with us. While we hope you’ll appreciate 6ix
            Meetings as much as we do, if you disagree with this policy, we
            kindly ask you not to access or utilize the Website.
          </div>
          <div className="events-page-landing-privacy-text">
            The following sections offer detailed information on this matter.
            We’ve aimed for clarity, but acknowledge the volume of information.
            Should you have any questions, please don’t hesitate to reach out to
            friends@6ix.com.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Introduction</h3>

          <div className="events-page-landing-privacy-text">
            This Privacy Notice outlines how 6ix Meetings gather, use, and
            disclose your Personal Data. In this document, “Personal Data”
            refers to any data relating to an identified or identifiable natural
            person processed by 6ix Meetings as described herein when such
            information is safeguarded as “personal data” or “personal
            information” or a similar term under applicable data protection
            laws. All other terms defined in this Privacy Notice are as per our
            Terms.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">Applicability</h3>

          <div className="events-page-landing-privacy-text">
            This Privacy Notice pertains to the Personal Data collected by 6ix
            Meetings when you visit or utilize our Website and Services, as
            detailed in the “Information We Collect” section below. 6ix Meetings
            is the controller of this Personal Data. However, this Privacy
            Notice doesn’t cover personal data within Customer Data (as defined
            in your agreement with 6ix Meetings), such as information collected
            by a User about an Invitee when an Invitee schedules a meeting or
            interview using our Services. We process Personal Data within
            Customer Data on our customers’ behalf as a processor or service
            provider. If you’re an Invitee and have queries about how your data
            is handled by our customers or wish to exercise your rights
            concerning that data, you must contact the User who collected your
            information. Should you contact us regarding Personal Data within
            Customer Data and identify the Entity or User you interacted with,
            we’ll promptly notify the relevant Customer who collected your data
            of your inquiry.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section ">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            Information We Collect
          </h3>

          <div className="events-page-landing-privacy-text">
            We collect information directly from you and automatically through
            your interaction with our Website. To safeguard yourself and your
            information, we encourage you to provide and collect only the
            necessary information for your usage of our Website or Services. For
            instance, when scheduling a meeting, essential information may
            include names, email addresses, dates, and times. Please note that
            not sharing certain Personal Data with us, or declining specific
            contact permissions, may limit access to certain parts of the
            Services.
          </div>
          <div className="events-page-landing-privacy-text">
            6ix Meetings aren’t intended for individuals under eighteen (18)
            years of age, and we don’t knowingly gather Personal Data from those
            under 18. If we discover that a child under 18 has provided us with
            Personal Data, we’ll promptly delete it from our systems.
          </div>
          <div className="events-page-landing-privacy-text">
            6ix Meetings collects the following information, either directly
            from Users, Invitees, or Visitors, or via third parties regarding
            Users, Invitees, or Visitors:
          </div>
          <div className="events-page-landing-privacy-text">
            User-provided Information: Users may voluntarily provide us with
            certain information when scheduling and setting up appointments.
            This may include names, email addresses, phone numbers, email
            addresses of other people and/or Invitees, the subject of the
            meeting, and any other information provided or collected upon
            scheduling, pursuant to the 6ix Meetings Terms or an executed
            agreement with 6ix Meetings. 6ix Meetings processes the information
            an Invitee provides to a User when scheduling with the User on the
            User’s behalf as a processor or service provider.
          </div>
          <div className="events-page-landing-privacy-text">
            A User can link their calendar with 6ix Meetings. Typically, our
            calendar integration only utilizes and displays the duration and
            availability status of events in your calendar to ensure we don’t
            schedule appointments when you’re busy. Some of our features also
            utilize and show the titles of meetings in your calendar. However,
            in all cases, no specifics about the events in your connected
            calendar, such as the identities of people you’re meeting with,
            their email addresses, or the titles of meetings, are stored in 6ix
            Meetings. While connecting your calendar to our services streamlines
            the scheduling process, it’s not mandatory to use our services.
            Further details about accessing the 6ix Meetings app and extensions
            to link your calendar are available here. 6ix Meetings processes
            calendar information on the User’s behalf as a service provider.
          </div>
          <div className="events-page-landing-privacy-text">
            6ix Meetings also allow the calendar owner to delete the calendar
            they created.
          </div>
          <div className="events-page-landing-privacy-text">
            If you decide to link your account with a third-party service, we
            may receive or gain access to information from that third-party
            service, including Personal Data. For instance, if you use the 6ix
            Meetings service and enable integrations with applicant tracking
            systems for recruiting purposes within 6ix Meetings, your ATS may
            send certain Personal Data to 6ix Meetings as determined by you. You
            can cease sharing your information from a third-party service with
            us by revoking our access to that service. 6ix Meetings processes
            such information on the User’s behalf as a service provider.
          </div>
          <div className="events-page-landing-privacy-text">
            Users furnish 6ix Meetings with certain details when setting up
            their account, including name, email address, username, and
            password. If you opt for a premium version of 6ix Meetings, our
            third-party payment processors will collect and store your billing
            address and credit card details. We retain the last four digits of
            your credit card number, card type, and expiration date. Payment and
            billing information will be handled as specified in the services
            agreement between 6ix Meetings and your organization.
          </div>
          <div className="events-page-landing-privacy-text">
            If you interact with our virtual chatbots, we’ll gather the
            information you provide during the chat, including transcripts of
            your conversation with the chatbot.
          </div>
          <div className="events-page-landing-privacy-text">
            Users, Invitees, or Visitors interested in 6ix Meetings’ services
            may reach out to us through forms accessible on the Website and
            voluntarily provide information such as name, work email, phone
            number, company, and role. Those interested in our newsletter may
            also submit their name and email address to join our mailing list.
            We also receive similar information provided by you if you
            participate in an event hosted by or attended by 6ix Meetings or its
            partners (like webinars, conferences, and trade shows) and in your
            interactions with our social media accounts.
          </div>
          <div className="events-page-landing-privacy-text">
            Additional Information: We may also receive specific Personal Data
            as you engage with 6ix Meetings through activities like surveys,
            focus groups, customer support tickets, or any other initiatives
            aimed at gathering User, Invitee, or Viewer feedback.
          </div>
          <div className="events-page-landing-privacy-text">
            We and our authorized third parties utilize Cookies, pixels, web
            beacons, and other technologies (subject to your consent, opt-out
            preferences, or other appropriate legal basis where legally
            required) to collect and store certain types of information when you
            interact with us via your computer or mobile device. Using these
            technologies helps us personalize your experience with our Website
            and Services, enhance your experience, tailor marketing messages,
            and assist in detecting and preventing fraud and security risks.
            Here’s more specific information about the types of information we
            gather:
          </div>
          <div className="events-page-landing-privacy-text">
            When you access the Website or the Services, we and our authorized
            third parties may automatically record certain information (“log
            data”), including details that your browser sends whenever you visit
            our Website. This log data may include the web address you came from
            or are going to, your device model, operating system, browser type,
            unique device identifier, IP address, mobile network carrier, and
            time zone or approximate location. The collection of some or all of
            this information often depends on the type of device you’re using
            and its settings. For instance, different types of information are
            accessible depending on whether you’re using a Mac or PC, or an
            iPhone or an Android phone. To learn more about the information your
            device makes available to us, please refer to the policies of your
            device manufacturer or software provider.
          </div>
          <div className="events-page-landing-privacy-text">
            Depending on how you’re accessing our Services and subject to your
            consent, opt-out preferences, or other appropriate legal basis where
            legally required, we and our authorized third parties may use
            “Cookies” (a small text file sent by your computer each time you
            visit our Website, unique to your 6ix Meetings account or your
            browser) or similar technologies such as pixels, web beacons, or
            local storage to record log data. We may utilize ‘session’ Cookies
            (that last until you close your browser) or ‘persistent’ Cookies
            (that last until you or your browser deletes them). For instance, we
            may use Cookies to keep you logged into 6ix Meetings. Some of the
            Cookies we use are linked with your 6ix Meetings account (including
            Personal Data like the email address you provided us), while others
            are not. We may also use your browser’s local storage under
            “localStorage” to store certain information that enables us to
            recognize you upon your return to our website. You can erase the
            local storage objects by clearing your browser’s history.
          </div>
          <div className="events-page-landing-privacy-text">
            When you utilize our Services either as a User or an Invitee, we and
            our authorized third parties gather certain information about how
            you use the Services. For instance, we collect data on the types of
            meetings our Users frequently engage in, and the number of meetings
            scheduled each day. We aggregate this information and use it to
            monitor and enhance the Services, such as identifying which features
            are most popular among our Users, and which features could be
            enhanced or added.
          </div>
          <div className="events-page-landing-privacy-text">
            We may share information with third parties or permit third parties
            to directly collect information using these technologies on our
            Website, such as social media companies, advertising networks,
            analytics providers including ad tracking and reporting, security
            providers, and others who aid us in operating our Services and
            Website. We utilize such third-party tools subject to your consent,
            opt-out preferences, or other appropriate legal basis where legally
            required. For instance, we utilize third-party providers like Google
            Analytics to provide specific analytics and Visitor interaction
            services to 6ix Meetings in connection with our operation of our
            Website, including the collection and tracking of certain data and
            information regarding the characteristics and activities of Visitors
            to the 6ix Meetings Website. To understand how Google Analytics
            collects and processes data, please visit: “How Google uses data
            when you use our partners’ sites or apps” located at
            www.google.com/policies/privacy/partners. We also utilize session
            replay, session recording, and similar tools provided by third-party
            Service Providers (as defined below) to record your interactions
            with our Website, such as your navigation throughout our Website and
            engagement with our webforms. In addition to analytics, this
            information helps us enhance our Website and Services, our marketing
            activities, and identify and resolve technical issues visitors may
            encounter with our Website.
          </div>
        </div>
      </section>

      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <h3 class="events-page-landing-privacy-title">
            How We Use Your Information:
          </h3>

          <div className="events-page-landing-privacy-text">
            We utilize the information we collect about you, including Personal
            Data, to:
          </div>
          <div className="events-page-landing-privacy-text">
            Provide our Services to you, which includes facilitating scheduling,
            managing your account, addressing inquiries, resolving service
            errors, security or technical issues, analyzing usage, preventing
            spam, fraud, and abuse, verifying your identity, and providing
            customer service and support. Additionally, we use the payment
            information provided to alert you of past, current, and upcoming
            charges, to display billing history in your account, and for
            internal financial processes such as monitoring credit card charges.
            In cases of credit card disputes, we may share account information
            with your bank for charge verification.
          </div>
          <div className="events-page-landing-privacy-text">
            Conduct research and analysis about your usage of, or interest in,
            our products, Services, or content, or those offered by others, to
            enhance existing products and develop new ones.
          </div>
          <div className="events-page-landing-privacy-text">
            Send service and administrative emails necessary for the functioning
            of the service. This includes emails related to your calendar
            appointments, which are considered integral to the Services and
            cannot be opted out of.
          </div>
          <div className="events-page-landing-privacy-text">
            Send promotional emails about new product features or relevant news
            about 6ix Meetings, subject to your opt-out preference and
            subscription. We assure Calendly Invitees that we do not use the
            email addresses provided for scheduling meetings to send direct
            marketing. However, if you’ve used the same email address for other
            purposes, you may receive marketing communications based on those
            interactions.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-privacy-text">
            Respond to your questions or comments.
          </div>
          <div className="events-page-landing-privacy-text">
            Inform you about changes to your account, our Services, and other
            important service-related notices such as updates to the Terms or
            Privacy Notice, or security or fraud alerts.
          </div>
          <div className="events-page-landing-privacy-text">
            Protect our rights and interests, as well as those of our Users and
            others, and enforce our Privacy Notice or Terms.
          </div>
          <div className="events-page-landing-privacy-text">
            Comply with applicable legal or regulatory obligations, including
            requests from law enforcement or other governmental authorities, or
            in legal proceedings involving 6ix Meetings.
          </div>
          <div className="events-page-landing-privacy-text">
            Manage our business or perform functions as described to you at the
            time of collection, subject to your consent where legally required.
          </div>
          <div className="events-page-landing-privacy-text">
            With Whom We May Share Your Information:
          </div>
          <div className="events-page-landing-privacy-text">
            We may share the information we collect about you, including
            Personal Data, in the following ways:
          </div>
          <div className="events-page-landing-privacy-text">
            Engage Service Providers to perform services on our behalf or assist
            us with providing services and communicating with you. This may
            include processing credit card transactions, providing monitoring
            and development services, aiding in communications and IT services,
            customer service, and analyzing data. We ensure Service Providers
            agree to maintain the security of Personal Data and only use it for
            providing their services.
          </div>
          <div className="events-page-landing-privacy-text">
            Share information with current or future affiliates or subsidiaries
            for purposes consistent with this Privacy Notice.
          </div>
          <div className="events-page-landing-privacy-text">
            Disclose information if reasonably necessary to comply with a law,
            regulation, legal or governmental request, respond to a legal
            process, enforce applicable Terms or this Privacy Notice, protect
            public safety or rights, or address security or technical issues.
          </div>
          <div className="events-page-landing-privacy-text">
            Share data within an organization or with an authorized agent upon
            the organization’s request, if you’re using 6ix Meetings with your
            organization’s email domain.
          </div>
          <div className="events-page-landing-privacy-text">
            Transfer or assign Personal Data as part of a Reorganization Event.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-privacy-text">
            No Sharing with Third-Party AI Platforms or Service Providers
          </div>
          <div className="events-page-landing-privacy-text">
            Inform you about changes to your account, our Services, and other
            important service-related notices such as updates to the Terms or
            Privacy Notice, or security or fraud alerts.
          </div>

          <div className="events-page-landing-privacy-text">
            6ix Meetings places the highest priority on maintaining the
            confidentiality of your information. We do not share, sell, rent, or
            otherwise disclose your personal information to any third-party AI
            platforms or AI service providers. This includes, but is not limited
            to:
          </div>
          <ul className="events-page-landing-privacy-list">
            <li>AI data processing services</li>
            <li>AI-driven marketing platforms</li>
            <li>Any third-party AI analytics providers</li>
          </ul>

          <div className="events-page-landing-privacy-text">
            Your information is strictly used within the scope of our services
            and remains protected under our security protocols. to:
          </div>
          <div className="events-page-landing-privacy-text">
            Your Rights and Choices:
          </div>
          <div className="events-page-landing-privacy-text">
            Depending on your relationship with 6ix Meetings, you may exercise
            your rights and choices in various ways:
          </div>
          <div className="events-page-landing-privacy-text">
            Keep your Personal Data accurate and complete by reviewing and
            updating your account information via your account settings page.
          </div>
          <div className="events-page-landing-privacy-text">
            Opt out of receiving promotional emails by following the opt-out
            link contained in the email. However, service-related emails cannot
            be opted out of.
          </div>
          <div className="events-page-landing-privacy-text">
            Exercise rights such as accessing, correcting, deleting, or
            objecting to the processing of your Personal Data, subject to
            applicable laws and exemptions.
          </div>
          <div className="events-page-landing-privacy-text">
            Lodge a complaint with the relevant data protection supervisory
            authority.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-privacy-text">
            For more information on exercising your rights and choices, refer to
            the “Your Rights and Choices” section of the Privacy Notice.
          </div>
          <div className="events-page-landing-privacy-text">
            To exercise any of the rights outlined above, please contact us at
            friends@6ix.com We will verify your identity before processing your
            request, ensuring that you provide sufficient information for us to
            reasonably verify your identity as the individual associated with
            the Personal Data. For users with an Account, we’ll authenticate
            your identity through our existing practices. For those without an
            Account, we may request additional information to verify your
            identity. Any Personal Data provided during the verification process
            will only be used for identity verification and request tracking,
            unless initially provided for another purpose. We retain records
            associated with requests to demonstrate compliance.
          </div>

          <div className="events-page-landing-privacy-text">
            Depending on your jurisdiction, you may authorize an agent to submit
            a rights request on your behalf. We’ll verify both your and your
            agent’s identity, possibly requesting a signed authorization
            document from you. If we deny a request from an agent lacking proper
            authorization, you have the right to appeal. Contact us at
            friends@6ix.com for assistance.
          </div>

          <div className="events-page-landing-privacy-text">
            Our Website may include links to third-party websites and
            applications, and we may use third-party advertisers, ad networks,
            and other promotional companies, subject to your preferences.
            Accessing these links or using such services is governed by the
            third parties’ privacy policies. We don’t endorse their content or
            offerings and aren’t responsible for their information practices.
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-privacy-title">
            Security and Storage of Information:
          </div>
          <div className="events-page-landing-privacy-text">
            While no system can guarantee absolute security, we take extensive
            measures to protect your Personal Data from unauthorized access,
            alteration, or destruction. We’ve implemented robust physical,
            technical, and managerial safeguards and obtained industry
            certifications like ISO/IEC 27001 to validate our security program
            (excluding the Prelude service).
          </div>
          <div className="events-page-landing-privacy-text">
            It’s crucial to safeguard your device and account by using a unique
            and complex password, keeping it confidential, and taking steps to
            prevent unauthorized access. We’re not liable for lost, stolen, or
            compromised passwords or unauthorized account activity.
          </div>

          <div className="events-page-landing-privacy-text">
            We retain collected Personal Data for as long as necessary to
            fulfill the purposes for which it was collected, meet contractual
            and legal obligations, and comply with applicable statute of
            limitations periods for claims.
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixPrivacy;
