import React from "react";
export function QuoteIcon() {
  return (
    <svg
      width="47"
      height="32"
      viewBox="0 0 47 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.3972 32C32.528 32 29.6978 30.6667 27.9065 28C27.1184 26.9189 26.4735 25.4775 25.972 23.6757C25.4704 21.8739 25.2196 19.8919 25.2196 17.7297C25.2196 13.982 26.1869 10.5225 28.1215 7.35135C30.0561 4.18018 32.8863 1.72973 36.6122 0L37.5794 1.94595C35.7165 2.81081 34.0327 4.07207 32.528 5.72973C31.095 7.31531 30.0919 9.00901 29.5187 10.8108C29.1604 11.8198 28.9813 13.009 28.9813 14.3784C28.9813 15.4595 29.053 16.2523 29.1963 16.7568C31.0592 14.5225 33.6745 13.4054 37.0421 13.4054C39.9081 13.4054 42.2009 14.2342 43.9206 15.8919C45.6402 17.4775 46.5 19.7477 46.5 22.7027C46.5 25.5135 45.5685 27.7838 43.7056 29.5135C41.8427 31.1712 39.4065 32 36.3972 32ZM11.6776 32C7.80841 32 4.97819 30.6667 3.18692 28C2.39875 26.9189 1.75389 25.4775 1.25234 23.6757C0.750779 21.8739 0.5 19.8919 0.5 17.7297C0.5 13.982 1.46729 10.5225 3.40187 7.35135C5.33645 4.18018 8.16667 1.72973 11.8925 0L12.8598 1.94595C10.9969 2.81081 9.31308 4.07207 7.80841 5.72973C6.37539 7.31531 5.37227 9.00901 4.79907 10.8108C4.44081 11.8198 4.26168 13.009 4.26168 14.3784C4.26168 15.4595 4.33333 16.2523 4.47664 16.7568C6.33956 14.5225 8.95483 13.4054 12.3224 13.4054C15.1885 13.4054 17.4813 14.2342 19.2009 15.8919C20.9206 17.4775 21.7804 19.7477 21.7804 22.7027C21.7804 25.5135 20.8489 27.7838 18.986 29.5135C17.1231 31.1712 14.6869 32 11.6776 32Z"
        fill="#E3C050"
      />
    </svg>
  );
}
export default QuoteIcon;
