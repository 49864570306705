import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Flex, Input } from "@100mslive/roomkit-react";
import { InfoIcon } from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { AI_SERVICES } from "../../services";

export function EventAiPopup(props) {
  const { open, onClose, type, setContent, searchby, title } = props;
  const [query, setQuery] = useState(searchby || "");
  const [loading, setLoading] = useState(false);
  const [generatedContent, setGeneratedContent] = useState("");

  const generateContent = async () => {
    setLoading(true);
    try {
      const data = await AI_SERVICES.textGenerate({
        query: query,
        type: type,
        title: title,
      });
      if (data.code === 200) {
        setGeneratedContent(data?.data);
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    setLoading(false);
  };
  const onSave = () => {
    setContent(generatedContent);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog eventAiPopup"
    >
      {loading && <FullPageProgress fixed={true} />}

      <DialogTitle className="text-center">
        Generate Event {type}{" "}
        <Tooltip
          placement="top"
          arrow
          title={`Type a prompt for your event ${type} or paste a body of text (e.g., from an article or news release) to have 6ix AI summarize and generate one for you`}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <FormControl className="custom-form-group">
          <Flex css={{ gap: "10px" }}>
            <Input
              placeholder="Input text here"
              className="custom-form-input"
              value={query}
              onChange={e => {
                setQuery(e.target.value);
              }}
              style={{ width: "auto", flex: 1 }}
            />

            <Button
              size="small"
              onClick={generateContent}
              variant="outlined"
              color="primary"
              className="events6ix-btn events6ix-btn-primary"
              type="button"
            >
              {generatedContent ? "Re-Generate" : "Generate"}
            </Button>
          </Flex>
        </FormControl>
        <div className="eventAiPopup-response">
          {generatedContent && (
            <>
              <span className="eventAiPopup-response-title">{type}:</span>
              <span className="eventAiPopup-response-text">
                {generatedContent}
              </span>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          Cancel
        </Button>
        {generatedContent && (
          <Button
            onClick={onSave}
            variant="outlined"
            color="primary"
            className="events6ix-btn events6ix-btn-primary"
            type="button"
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
