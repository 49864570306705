import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FullPageProgress from "../components/FullPageProgress";
import { ToastManager } from "../components/Toast/ToastManager";
import { SESSION_AUTH } from "../common/auth";
import { MAILCHIMP_SERVICES } from "../services";
import { GET_PUBLIC_SESSION, REMOVE_PUBLIC_SESSION } from "../common/constants";

export function MailchimpCallBackPage(props) {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();

  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (!isUserSession || !isUserSession?.isVerify) {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (dataFetchedRef.current || !isUserSession) return;
    dataFetchedRef.current = true;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get("code");

    try {
      MAILCHIMP_SERVICES.accesstoken({
        userId: isUserSession.userId,
        code: code,
        org: isUserSession?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            let url = GET_PUBLIC_SESSION("E6IX_MAILCHIMP");
            REMOVE_PUBLIC_SESSION("E6IX_MAILCHIMP");
            ToastManager.addToast({ title: data.message });
            if (url) {
              return navigate(url + "?MAILCHIMP_C=true");
            } else {
              return navigate("/account/setting");
            }
            //window.location.href = data.url;
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
          return navigate("/");
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  }, [dataFetchedRef, isUserSession, LOGOUT_USER_SESSION, navigate]);
  //accesstoken / generate;

  return (
    <React.Fragment>
      <FullPageProgress fixed={true} />
    </React.Fragment>
  );
}
