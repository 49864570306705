import React from "react";
import { Button, FormControl } from "@mui/material";
import { Box, Flex, Input, Label, Text } from "@100mslive/roomkit-react";

const AccountCalendar = props => {
  const { orgCalLink, setOrgCalLink, isEditAccess, saveSetting } = props;

  return (
    <div className="events-page-account-setting-content-row">
      <Box className="events-page-account-setting-content-box">
        <div className="events-page-account-setting-content-box-title">
          Calendar Link
        </div>
        <FormControl className="events-page-account-setting-content-box-control">
          <Label
            htmlFor="org-calendar-link"
            className="events-page-account-setting-content-box-lable"
          >
            Custom URL
          </Label>
          <Input
            id="org-calendar-link"
            readOnly={!isEditAccess}
            name="orgCalLink"
            type="text"
            className="custom-form-input"
            value={orgCalLink}
            onChange={e => {
              if (isEditAccess) setOrgCalLink(e.target.value);
            }}
          />
        </FormControl>

        {isEditAccess && (
          <>
            <div className="events-page-account-setting-content-box-line"></div>

            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Text
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text"
              >
                This is your calendar link for event meeting.
              </Text>
              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                onClick={() => saveSetting("orgCalLink")}
              >
                Save
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </div>
  );
};

export default AccountCalendar;
