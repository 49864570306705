import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { Input, Label, Text } from "@100mslive/roomkit-react";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../services";

const OnBoardingOrg = props => {
  const {
    onNext,
    onBardingData,
    setOnBardingData,
    billingAddons,
    setOnBoardComplete,
  } = props;
  const { isUserSession, LOGOUT_USER_SESSION, UPDATE_USER_SESSION } =
    SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const continueUrl = queryParameters.get("continueUrl");

  const onSave = () => {
    let parma = {
      userId: isUserSession.userId,
      org: isUserSession?.org?.id,
    };
    let name = onBardingData?.orgName?.trim();
    if (!name) {
      ToastManager.addToast({ title: "Please enter organization name" });
      return false;
    }
    parma["orgName"] = name;
    if (onBardingData?.orgMatters && onBardingData?.orgMatters?.length > 0) {
      parma["orgMatters"] = onBardingData?.orgMatters;
    }

    parma["orgPublic"] = onBardingData?.orgPublic;
    if (onBardingData?.orgPublic === "yes") {
      if (!onBardingData?.orgStock?.trim()) {
        ToastManager.addToast({
          title: "Please enter primary stock exchange",
        });
        return false;
      }
      parma["orgStock"] = onBardingData?.orgStock?.trim();
      if (!onBardingData?.orgTicker?.trim()) {
        ToastManager.addToast({
          title: "Please enter primary ticker",
        });
        return false;
      }
      parma["orgTicker"] = onBardingData?.orgTicker?.trim();
    }
    setLoading(true);
    ACCOUNT_SERVICES.onBoardingSave(parma)
      .then(data => {
        if (data.code === 200) {
          setOnBoardComplete(true);
          if (data?.data) {
            UPDATE_USER_SESSION(data?.data);
          }
          if (!billingAddons) {
            return navigate(continueUrl || "/", { replace: true });
          } else {
            onNext(4);
          }
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else if (data.code === 201) {
          ToastManager.addToast({ title: data.message });
          return navigate("/", { replace: true });
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({
          title: err?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {loading ? <FullPageProgress fixed={true} /> : null}
      <div className="events-page-onboarding-steps-body">
        <div className="events-page-onboarding-steps-body-title">
          What is the name of your organization?
        </div>

        <FormControl className="custom-form-group">
          <Label
            className="custom-form-label big-label"
            htmlFor="onboard-org-name"
          >
            Your Organization
          </Label>
          <Input
            id="onboard-org-name"
            className="custom-form-input"
            type="text"
            value={onBardingData?.orgName}
            onChange={e => {
              if (e.target?.value?.length <= 32)
                setOnBardingData(old => ({
                  ...old,
                  orgName: e.target.value
                    ?.replace(/(<([^>]+)>)/gi, "")
                    ?.replace(/\s+/g, " "),
                }));
            }}
          />
          <Text className="custom-form-label mb-0 mt-5">
            Please use 32 characters at maximum.
          </Text>
        </FormControl>
        <FormControl className="custom-form-group">
          <Label
            className="custom-form-label big-label"
            htmlFor="onboard-org-public"
          >
            Is it a public company?
          </Label>
          <Select
            id="onboard-org-public"
            name="orgPublic"
            className="custom-select"
            value={onBardingData?.orgPublic || ""}
            onChange={e => {
              setOnBardingData(old => ({
                ...old,
                orgPublic: e.target.value,
              }));
            }}
            displayEmpty
          >
            <MenuItem disabled value="">
              <span>Select</span>
            </MenuItem>
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>

        {onBardingData?.orgPublic === "yes" && (
          <div className="events-page-onboarding-steps-body-stocks">
            <FormControl className="custom-form-group">
              <Label
                className="custom-form-label big-label"
                htmlFor="onboard-org-exchange"
              >
                Primary Stock Exchange
              </Label>
              <Input
                id="onboard-org-exchange"
                className="custom-form-input"
                type="text"
                value={onBardingData?.orgStock}
                onChange={e => {
                  if (e.target?.value?.length <= 20)
                    setOnBardingData(old => ({
                      ...old,
                      orgStock: e.target.value
                        ?.replace(/(<([^>]+)>)/gi, "")
                        ?.replace(/\s+/g, " "),
                    }));
                }}
              />
            </FormControl>
            <FormControl className="custom-form-group">
              <Label
                className="custom-form-label big-label"
                htmlFor="onboard-org-ticker"
              >
                Primary Ticker
              </Label>
              <Input
                id="onboard-org-ticker"
                className="custom-form-input"
                type="text"
                value={onBardingData?.orgTicker}
                onChange={e => {
                  if (e.target?.value?.length <= 20)
                    setOnBardingData(old => ({
                      ...old,
                      orgTicker: e.target.value
                        ?.replace(/(<([^>]+)>)/gi, "")
                        ?.replace(/\s+/g, " "),
                    }));
                }}
              />
            </FormControl>
          </div>
        )}

        <Button
          color="primary"
          variant="outlined"
          fullWidth
          className="events6ix-btn events6ix-btn-primary mt-20"
          type="button"
          onClick={() => onSave()}
          endIcon={<ArrowForward />}
          sx={{ textTransform: "none" }}
        >
          Next
        </Button>

        <div className="events-page-onboarding-steps-body-notice">
          By submitting my personal data, I consent to 6ix collecting,
          processing, and storing my information in accordance with the 6ix
          Privacy Notice.
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnBoardingOrg;
