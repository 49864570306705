import React from "react";

export function UserLogin() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3332 14V12.6667C13.3332 11.9594 13.0523 11.2811 12.5522 10.7811C12.0521 10.281 11.3738 10 10.6665 10H5.33318C4.62593 10 3.94765 10.281 3.44755 10.7811C2.94746 11.2811 2.6665 11.9594 2.6665 12.6667V14"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00017 7.33332C9.47293 7.33332 10.6668 6.13941 10.6668 4.66664C10.6668 3.19388 9.47293 1.99997 8.00017 1.99997C6.52741 1.99997 5.3335 3.19388 5.3335 4.66664C5.3335 6.13941 6.52741 7.33332 8.00017 7.33332Z"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default UserLogin;
