import React from "react";
import { useMedia } from "react-use";
import { config as cssConfig, Flex } from "@100mslive/roomkit-react";
import EventFooterContentAttract from "./EventFooterContentAttract";
import EventFooterContentExpertise from "./EventFooterContentExpertise";
import EventFooterContentInvest from "./EventFooterContentInvest";
import EventFooterMenuCMS from "./EventFooterMenuCMS";

const EventFooterContentLeft = () => {
  const isMobile = useMedia(cssConfig.media.lg);
  return (
    <Flex className="events6ix-layout-footer-content-left">
      <EventFooterContentInvest />
      <EventFooterContentAttract />
      <EventFooterContentExpertise />
      {!isMobile ? <EventFooterMenuCMS /> : null}
    </Flex>
  );
};

export default EventFooterContentLeft;
