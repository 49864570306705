import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullPageProgress from "../../../FullPageProgress";
import { ToastManager } from "../../../Toast/ToastManager";
import AccountBillingAddons from "./AccountBillingAddons";
import AccountBillingAddress from "./AccountBillingAddress";
import AccountBillingPaymentMethod from "./AccountBillingPaymentMethod";
import AccountBillingPlan from "./AccountBillingPlan";
import AccountBillingTransactions from "./AccountBillingTransactions";
import { SESSION_AUTH } from "../../../../common/auth";
import { ACCOUNT_SERVICES, USER_SERVICES } from "../../../../services";

const AccountBillingSettings = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [dataFetched, setDataFetached] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [billingData, setBillingData] = useState("");

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      setUserData(isUserSession);

      if (
        isUserSession?.org?.isAccountOwner ||
        isUserSession?.org?.isOwner ||
        isUserSession?.org?.isAdmin
      ) {
        setIsEditAccess(true);
      } else {
        setIsEditAccess(false);
        return navigate("/account/setting", { replace: true });
      }
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched || !isEditAccess) return;
    setDataFetached(true);
    try {
      setLoading(true);
      ACCOUNT_SERVICES.getBilling({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            setBillingData(data.data);
            analyticsUpdate();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    return () => null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, dataFetched, LOGOUT_USER_SESSION, isEditAccess]);

  const analyticsUpdate = () => {
    USER_SERVICES.analytics({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      type: "billing",
    })
      .then(data => {
        if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err?.message });
      });
  };

  return (
    <>
      {loading ? (
        <FullPageProgress fixed={true} />
      ) : isEditAccess ? (
        <div className="events-page-account-setting-content">
          <AccountBillingPlan
            subscription={billingData?.subscription}
            billingPlan={billingData?.billingPlan}
          />
          <AccountBillingAddons
            subscription={billingData?.subscription}
            billingAddons={billingData?.billingAddons}
          />
          <AccountBillingPaymentMethod />
          <AccountBillingAddress billingAddress={billingData?.billingAddress} />
          <AccountBillingTransactions />
        </div>
      ) : null}
    </>
  );
};

export default AccountBillingSettings;
