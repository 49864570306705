import React from "react";

export function GraphIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_465_23555)">
        <path
          d="M12.0938 10.6948L15.1875 7.60107"
          stroke="#8C8E95"
          strokeWidth="0.899998"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.15625 8.44482L10.4062 10.6948"
          stroke="#8C8E95"
          strokeWidth="0.899998"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.8125 12.1011L6.46874 8.44482"
          stroke="#8C8E95"
          strokeWidth="0.899998"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0312 7.60107C16.4972 7.60107 16.8749 7.22331 16.8749 6.75732C16.8749 6.29133 16.4972 5.91357 16.0312 5.91357C15.5652 5.91357 15.1874 6.29133 15.1874 6.75732C15.1874 7.22331 15.5652 7.60107 16.0312 7.60107Z"
          stroke="#8C8E95"
          strokeWidth="0.899998"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 12.3823C11.716 12.3823 12.0937 12.0046 12.0937 11.5386C12.0937 11.0726 11.716 10.6948 11.25 10.6948C10.784 10.6948 10.4062 11.0726 10.4062 11.5386C10.4062 12.0046 10.784 12.3823 11.25 12.3823Z"
          stroke="#8C8E95"
          strokeWidth="0.899998"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3125 8.44482C7.77849 8.44482 8.15625 8.06706 8.15625 7.60107C8.15625 7.13508 7.77849 6.75732 7.3125 6.75732C6.84651 6.75732 6.46875 7.13508 6.46875 7.60107C6.46875 8.06706 6.84651 8.44482 7.3125 8.44482Z"
          stroke="#8C8E95"
          strokeWidth="0.899998"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.96875 13.7886C2.43474 13.7886 2.8125 13.4108 2.8125 12.9448C2.8125 12.4788 2.43474 12.1011 1.96875 12.1011C1.50276 12.1011 1.125 12.4788 1.125 12.9448C1.125 13.4108 1.50276 13.7886 1.96875 13.7886Z"
          stroke="#8C8E95"
          strokeWidth="0.899998"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_465_23555">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.851074)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default GraphIcon();
