import React, { useEffect, useState } from "react";
import { Button, FormControl, IconButton } from "@mui/material";
import { Box, Flex, Input, Label, Text } from "@100mslive/roomkit-react";
import { Edit } from "../../../icons";
import { ToastManager } from "../../Toast/ToastManager";

const AccountSlug = props => {
  const { setOrgSlug, orgSlug, isEditAccess, saveSetting } = props;
  const [editSlug, setEditSlug] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(/^[a-zA-Z0-9-]{3,100}$/.test(orgSlug));
  }, [orgSlug]);

  const onSave = () => {
    if (!isValid) {
      return ToastManager.addToast({ title: "Enter valid organization URL" });
    }
    saveSetting("orgSlug");
  };
  return (
    <div className="events-page-account-setting-content-row">
      <Box className="events-page-account-setting-content-box">
        <div className="events-page-account-setting-content-box-title">
          Public URL
        </div>

        {isEditAccess && editSlug ? (
          <React.Fragment>
            <FormControl className="events-page-account-setting-content-box-control">
              <Label
                htmlFor="org-slug-edit"
                className="events-page-account-setting-content-box-lable"
              >
                {process.env.REACT_APP_PRODUCTION_URL}/company/{orgSlug}
              </Label>

              <Input
                id="org-slug-edit"
                name="orgSlug"
                type="text"
                className="custom-form-input events-page-account-setting-slug-input"
                value={orgSlug}
                onChange={e => {
                  if (e.target?.value?.length <= 32 && isEditAccess)
                    setOrgSlug(e.target?.value?.toLowerCase()?.trim());
                }}
                maxLength={100}
              />
              {!isValid && (
                <Text className="events-page-account-setting-content-box-text text-white mt-5">
                  {orgSlug?.length < 3
                    ? "Enter at least 3 characters"
                    : orgSlug?.length > 100
                    ? "URL max-limit 100 characters "
                    : "Uses characters or words we do not allow. Try a different URL"}
                </Text>
              )}
              <Text className="events-page-account-setting-content-box-text mt-5">
                Organization URL must contain 3-100 letters or numbers. Please
                do not use spaces, symbols, or special characters
              </Text>
            </FormControl>

            <div className="events-page-account-setting-content-box-line"></div>

            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Text
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text"
              >
                Personalize the URL for company profile.
              </Text>
              <div>
                <Button
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-secondary mr-15"
                  onClick={() => setEditSlug(false)}
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary"
                  type="button"
                  onClick={onSave}
                >
                  Save
                </Button>
              </div>
            </Flex>
          </React.Fragment>
        ) : (
          <FormControl className="events-page-account-setting-content-box-control">
            <Label
              htmlFor="org-slug"
              className="events-page-account-setting-content-box-lable"
            >
              Personalize the URL for your profile.
            </Label>
            <Flex className="events-page-account-setting-slug">
              <Text className="events-page-account-setting-content-box-text">
                {process.env.REACT_APP_PRODUCTION_URL}/company/{orgSlug}
              </Text>
              {isEditAccess && (
                <IconButton
                  color="primary"
                  onClick={() => setEditSlug(!editSlug)}
                >
                  <Edit />
                </IconButton>
              )}
            </Flex>
          </FormControl>
        )}
      </Box>
    </div>
  );
};

export default AccountSlug;
