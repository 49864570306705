import { apiCall } from "./servicesCall";

export const COMPANY_SERVICES = {
  profile(data) {
    return apiCall("/company", data);
  },
  events(data) {
    return apiCall("/company/events", data);
  },
  eventUpcoming(data) {
    return apiCall("/company/events/upcoming", data);
  },
  eventDemand(data) {
    return apiCall("/company/events/demand", data);
  },
  eventNotified(data) {
    return apiCall("/company/events/notified", data);
  },
  eventLive(data) {
    return apiCall("/company/events/live", data);
  },
  speakers(data) {
    return apiCall("/company/speakers", data);
  },
};
