import React from "react";

const SixCareersJoin = () => {
  return (
    <section className="events-page-landing-section events-page-careers-section-join">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-careers-section-header">
          <div className="events-page-careers-section-header-heading text-center">
            Join our mission of empowering anyone from anywhere to invest in
            anything.
          </div>
        </div>
      </div>
    </section>
  );
};

export default SixCareersJoin;
