import React from "react";
//import { selectIsLocalVideoEnabled, useHMSStore } from "@100mslive/react-sdk";
//import { VirtualBackgroundIcon } from "@100mslive/react-icons";
import { HMSVirtualBackgroundTypes } from "@100mslive/hms-virtual-background";
import { Box, Text } from "@100mslive/roomkit-react";
//import IconButton from "../../IconButton";
//import { isSafari } from "../../common/constants";
import { VBOption } from "./VBOption";

export const VBCollection = ({ options, title, activeBackground = "" }) => {
  if (options.length === 0) {
    return null;
  }
  //const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);

  return (
    <Box css={{ mt: "$10" }}>
      <Text
        variant="sm"
        css={{ color: "$on_surface_high", fontWeight: "$semiBold" }}
      >
        {title}
      </Text>
      <Box
        css={{
          py: "$4",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gap: "$8",
        }}
      >
        {options.map((option, index) => (
          <VBOption.Root
            key={option.value}
            testid={
              option.value === HMSVirtualBackgroundTypes.IMAGE
                ? `virtual_bg_option-${index}`
                : option.value
            }
            {...option}
            isActive={activeBackground === option.value}
          >
            <VBOption.Icon>{option?.icon}</VBOption.Icon>
            <VBOption.Title>{option?.title}</VBOption.Title>
          </VBOption.Root>
        ))}
      </Box>
    </Box>
  );
};
