import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Flex } from "@100mslive/roomkit-react";
import { AscendingIcon, DescendingIcon, Star } from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { ANALYTICS_SERVICES } from "../../services";

const AnalyticsSpeaker = () => {
  const { eventId } = useParams();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [sorting, setSorting] = useState(false);
  const [sortedField, setSortedField] = React.useState(null);
  const [sortDirection, setSortDirection] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [listData, setListData] = useState([]);

  const columns = [
    { lable: "Name", target: "name" },
    { lable: "Email", target: "email" },
    { lable: "Mobile", target: "phoneNumber" },
    { lable: "Company Name", target: "work" },
    { lable: "Job Title", target: "jobTitle" },

    {
      lable: "Rating",
      target: "rating",
    },
  ];

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!sorting || !sortDirection || !sortedField) return;
    setSorting(false);
    let listDataArray = [...listData];
    listDataArray.sort((a, b) => {
      let aa = a?.[sortedField.target];
      let bb = b?.[sortedField.target];
      aa = aa || "";
      bb = bb || "";

      if (aa < bb) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (aa > bb) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setListData(listDataArray);
  }, [sorting, listData, sortDirection, sortedField]);

  const sortRequest = item => {
    setSortDirection(
      sortedField?.target === item?.target
        ? sortDirection === "descending"
          ? "ascending"
          : "descending"
        : "ascending"
    );

    setSortedField(item);
    setSorting(true);
  };

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoading(true);
    try {
      ANALYTICS_SERVICES.speakers({
        userId: userData.userId,
        org: userData?.org?.id,
        id: eventId,
      })
        .then(data => {
          if (data.code === 200) {
            setListData(data?.data?.list || []);
            setSorting(true);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => setLoading(false));
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, eventId]);

  return (
    <React.Fragment>
      {loading && <FullPageProgress fixed={true} />}
      <div className="relationship">
        <Box className="relationship-usertable" style={{ marginTop: "20px" }}>
          <table className="relationship-usertable-table">
            <thead>
              <tr>
                {columns.map((item, key) => (
                  <th
                    className={
                      item?.target && sortedField?.target === item?.target
                        ? "highlight"
                        : null
                    }
                    key={key}
                    onClick={() => {
                      if (item?.target) {
                        sortRequest(item);
                      }
                    }}
                  >
                    <div>
                      {item?.lable}
                      {item?.target ? (
                        sortDirection === "descending" &&
                        sortedField?.target === item?.target ? (
                          <DescendingIcon />
                        ) : (
                          <AscendingIcon />
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {listData?.map((item, key) => (
                <tr key={key}>
                  <td>
                    <Flex>
                      <div className="relationship-usertable-table-image firstname-image">
                        <img src={item?.profileImage} alt="" />
                      </div>
                      <span>{item?.name}</span>
                    </Flex>
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.phoneNumber || "-"}</td>

                  <td>
                    <span>{item?.work || "-"}</span>
                  </td>
                  <td>{item?.jobTitle || "-"}</td>

                  <td>
                    {item?.rating ? (
                      <Flex title={`Rating ${item?.rating}`}>
                        {Array.from({ length: item?.rating }, (_, index) => {
                          return <Star key={index} active />;
                        })}
                      </Flex>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default AnalyticsSpeaker;
