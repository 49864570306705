import React from "react";
export function AscendingIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.93462 8.73872L9.60129 8.73872"
        stroke="#E3C050"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.93462 4.73872L13.6013 4.73872"
        stroke="#E3C050"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.93459 12.7387L5.60126 12.7387"
        stroke="#E3C050"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default AscendingIcon;
