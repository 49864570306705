import React from "react";
export function OffStageIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.79641 4.21398C4.49024 3.82225 4.11434 3.15535 3.75511 3.49899C2.97826 4.24212 2.33904 5.12017 1.86996 6.09282C1.27022 7.33638 0.964662 8.70125 0.976917 10.0818C0.989172 11.4624 1.31891 12.8218 1.94064 14.0546C2.42691 15.0189 3.08161 15.8856 3.87154 16.6151C4.23681 16.9524 4.80487 16.8699 5.10403 16.4729C5.4032 16.0759 5.31906 15.5152 4.96233 15.1689C4.38867 14.612 3.91022 13.9619 3.54835 13.2443C3.05097 12.258 2.78718 11.1706 2.77737 10.0661C2.76757 8.96162 3.01202 7.86972 3.49181 6.87487C3.84088 6.15106 4.30772 5.49275 4.8714 4.92587C5.22193 4.57335 5.10257 4.6057 4.79641 4.21398Z"
        fill="#C0C0C0"
      />
      <path
        d="M14.851 3.52712C15.1503 3.13012 15.7184 3.04771 16.0836 3.38503C17.3791 4.58174 18.2997 6.13797 18.7205 7.8642C19.1413 9.59044 19.0403 11.3958 18.4408 13.0545C18.2718 13.522 17.7295 13.7103 17.2811 13.4956C16.8327 13.2809 16.6493 12.7445 16.8065 12.2729C17.2331 10.9931 17.2935 9.61291 16.9712 8.2907C16.6489 6.96848 15.96 5.77095 14.9923 4.83115C14.6357 4.48481 14.5517 3.92413 14.851 3.52712Z"
        fill="#C0C0C0"
      />
      <path
        d="M6.87617 6.28183C6.63876 5.87091 6.77414 5.2279 6.40322 5.52393C5.90074 5.92496 5.46775 6.40901 5.124 6.95703C4.62751 7.74858 4.33226 8.64955 4.264 9.58139C4.19575 10.5132 4.35656 11.4476 4.73241 12.3032C4.99263 12.8955 5.35047 13.4375 5.78914 13.9076C6.11296 14.2546 6.65927 14.1882 6.95402 13.8163C7.24878 13.4444 7.17703 12.9085 6.87617 12.5415C6.6456 12.2602 6.45354 11.9478 6.30618 11.6124C6.04309 11.0135 5.93052 10.3594 5.9783 9.70712C6.02608 9.05483 6.23275 8.42415 6.58029 7.87007C6.77495 7.55974 7.01048 7.27872 7.27958 7.03406C7.6307 6.71482 7.11359 6.69275 6.87617 6.28183Z"
        fill="#C0C0C0"
      />
      <path
        d="M12.3404 5.74239C12.5706 5.32738 13.0988 5.17273 13.4749 5.46225C14.2386 6.05025 14.8454 6.82455 15.2333 7.71686C15.6212 8.60917 15.7735 9.58102 15.6825 10.5405C15.6376 11.0129 15.1642 11.2935 14.7037 11.1787C14.2432 11.0638 13.973 10.5956 13.9873 10.1213C14.0051 9.53427 13.8938 8.94692 13.6568 8.40195C13.4199 7.85698 13.0664 7.3749 12.625 6.98744C12.2683 6.67437 12.1102 6.15739 12.3404 5.74239Z"
        fill="#C0C0C0"
      />
      <path
        d="M11.5673 9.85723C11.5673 10.8159 10.7902 11.593 9.83146 11.593C8.87276 11.593 8.09557 10.8159 8.09557 9.85723C8.09557 8.89859 8.87276 8.12145 9.83146 8.12145C10.7902 8.12145 11.5673 8.89859 11.5673 9.85723Z"
        fill="#C0C0C0"
      />
      <path
        d="M1 1L9.75 9.75L18.5 18.5"
        stroke="#C0C0C0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default OffStageIcon;
