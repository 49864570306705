import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export function InvestorChart(props) {
  const { chartData } = props;

  const [chartOptions, setChartOptions] = useState("");
  const dataFeteched = useRef(false);

  useEffect(() => {
    if (!chartData || dataFeteched?.current) return;
    dataFeteched.current = true;
    let seriesData = [];

    for (let row of chartData) {
      seriesData.push({ name: row?.name, y: row?.value });
    }

    let options = {
      chart: {
        plotBackgroundColor: "transparent",
        backgroundColor: "transparent",
        plotBorderWidth: 0,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Investor composition",
        align: "center",
        verticalAlign: "top",
        style: {
          color: "white",
        },
      },

      tooltip: {
        headerFormat: "",
        pointFormat: "{point.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          size: "80%",
          innerSize: "70%",
          showInLegend: true,
          dataLabels: {
            enabled: false,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
            color: "white",
            textShadow: "none",
          },
        },
      },
      legend: {
        align: "right",
        layout: "vertical",
        verticalAlign: "middle",
        itemMarginBottom: 8,
        itemStyle: {
          color: "white",
        },
        itemHoverStyle: {
          color: "#e3c050",
        },
        labelFormat: "<b>{name}</b> {percentage:.1f}%",
        // x: 40,
        // y: 0,
      },
      colors: ["#F5EACA", "#DBB163", "#A78F77", "#60574D", "#575757"],
      series: [
        {
          name: "Investor",
          colorByPoint: true,
          data: seriesData,
        },
      ],
    };
    setChartOptions(options);
    return () => null;
  }, [chartData]);

  return (
    <>
      {chartOptions && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      )}
    </>
  );
}
