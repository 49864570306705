import React from "react";
export function CheckboxIcon(props) {
  return props.active ? (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.664551"
        width="15"
        height="15"
        rx="3.5"
        stroke="#E3C050"
      />
      <path
        d="M12.1686 5.79351L11.8803 5.50526C11.7898 5.41461 11.669 5.36475 11.5401 5.36475C11.4112 5.36475 11.2903 5.41461 11.1997 5.50526L7.14939 9.55555L5.10938 7.5248C5.0188 7.4343 4.89796 7.38436 4.76904 7.38436C4.64018 7.38436 4.51942 7.4343 4.42877 7.5248L4.14044 7.81299C4.0498 7.90378 4 8.02469 4 8.15347C4 8.28232 4.0498 8.40316 4.14044 8.49381L6.50706 10.8509C6.51085 10.8563 6.51486 10.8613 6.51951 10.8659L6.80783 11.1494C6.89841 11.2397 7.01925 11.2849 7.14917 11.2849H7.15067C7.27967 11.2849 7.40051 11.2397 7.49094 11.1494L7.77934 10.8634C7.78399 10.8588 7.78792 10.855 7.79028 10.8512L12.1685 6.47333C12.3563 6.28596 12.3563 5.9811 12.1686 5.79351Z"
        fill="#E3C050"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.4"
        y="1.17637"
        width="15.2"
        height="15.2"
        rx="2.8"
        stroke="#8C8E95"
        strokeWidth="0.8"
      />
    </svg>
  );
}
export default CheckboxIcon;
