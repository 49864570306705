import React from "react";
import { TrustListIcon } from "../../../icons";

const SixSecurityCertification = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-security-section-features2">
            <div className="events-page-security-section-features2-icon">
              <TrustListIcon type="certificate" />
            </div>

            <div className="events-page-security-section-features2-content">
              <h2 class="events-page-landing-section-header-heading">
                Compliance and Certifications
              </h2>
              <div className="events-page-landing-privacy-text">
                6ix Inc. is an Ontario private company headquartered at 202-1200
                Bay Street, Toronto, ON, M5R 2A5.
              </div>
              <div className="events-page-landing-privacy-text">
                We prioritize the trust of investors, analysts, and companies,
                and place an emphasis on data privacy and security. Our security
                program is designed and implemented, throughout our company and
                our platform, in an effort to address the security and
                compliance requirements of data related to investors, analysts,
                companies, and employees.
              </div>
              <div className="events-page-landing-privacy-text">
                We have a team of professionals that focus on application,
                network, and system security, as well as security compliance,
                education, and incident response. We maintain a vulnerability
                management program that includes periodic scans designed to
                identify security vulnerabilities on servers, workstations,
                network equipment and applications, and subsequent remediation
                of vulnerabilities. We also conduct regular internal and
                external penetration tests and remediate according to severity
                for any results found.
              </div>
              <div className="events-page-landing-privacy-text">
                We encrypt investor, analyst, and company data in transit using
                secure transport layer security cryptographic protocols and
                encrypt data at rest as well. We use multi-factor authentication
                and other security controls in order to control access to our
                resources containing personal data or other confidential
                information.
              </div>
              <div className="events-page-landing-privacy-text">
                We design our platform, offerings, and policies to facilitate
                compliance with evolving privacy and data security laws and
                regulations. We post on our website our privacy policies, and we
                maintain certain other policies and practices relating to data
                security and concerning our processing, use, and disclosure of
                personal information. We collect and use aggregated end-user
                information to develop, provide, and improve our platform and
                offerings.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="events-page-landing-section events-page-landing-section-space-top">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-security-section-features2">
            <div className="events-page-security-section-features2-icon">
              <TrustListIcon type="policy" />
            </div>

            <div className="events-page-security-section-features2-content">
              <h2 class="events-page-landing-section-header-heading">
                Vulnerability Disclosure Policy
              </h2>
              <div className="events-page-landing-privacy-text">
                If you believe you have discovered a security vulnerability, we
                encourage you to disclose your discovery to us as quickly as
                possible.
              </div>
              <div className="events-page-landing-privacy-text">
                To participate in our private bug bounty program or learn more
                about the terms of our program, including our scope, bounties,
                or safe harbor guarantee, please email security@6ix.com.
              </div>
              <div className="events-page-landing-privacy-text">
                Our commitment to security researchers is simple: we will not
                take action against anyone who reports an issue in a responsible
                manner. We will do our best to reply to you in a timely fashion
                and periodically update you on our progress with respect to
                investigating or remediating any issues you may have identified.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityCertification;
