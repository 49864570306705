import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";

const SixSecurityAccount = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Account Security
            </h2>
          </div>

          <div className="events-page-security-section-features">
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Passwordless Email Authentication: Our implementation of
                WebAuthn-based passwordless authentication is unphishable,
                keeping our users safer.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Hardware Security Keys: We support Hardware Security Keys via
                WebAuthn so that you can secure your account with the strongest
                2FA protection.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Single Sign On: Simply log in once to use all your applications
                powered by 6ix.
              </div>
            </div>
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> Secure Access: We follow the principle of
                least-privilege by applying tiered, role-based access-controls.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline /> Rate-limiting: In order to thwart brute
                force attacks, rate-limiting is applied to certain account
                operations, such as your login attempts.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityAccount;
