import React from "react";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { Box, Flex, Input, Label, Text } from "@100mslive/roomkit-react";
import { ToastManager } from "../../Toast/ToastManager";

const AccountStock = props => {
  const {
    orgTicker,
    setOrgTicker,
    orgStock,
    setOrgStock,
    setOrgPublic,
    orgPublic,
    isEditAccess,
    saveSetting,
  } = props;

  const onSave = () => {
    if (orgPublic === "yes") {
      if (!orgStock?.trim()) {
        return ToastManager.addToast({
          title: "Please enter primary stock exchange",
        });
      }

      if (!orgTicker?.trim()) {
        return ToastManager.addToast({
          title: "Please enter primary ticker",
        });
      }
    } else {
      setOrgStock("");
      setOrgTicker("");
    }
    saveSetting("stock");
  };

  return (
    <div className="events-page-account-setting-content-row">
      <Box className="events-page-account-setting-content-box">
        <div className="events-page-account-setting-content-box-title">
          Company Type
        </div>
        <FormControl className="events-page-account-setting-content-box-control custom-form-group">
          <Label
            htmlFor="org-public"
            className="events-page-account-setting-content-box-lable"
          >
            Is it a public company
          </Label>

          <Select
            id="org-public"
            name="orgPublic"
            className="custom-select"
            value={orgPublic || ""}
            readOnly={!isEditAccess}
            onChange={e => {
              if (isEditAccess) setOrgPublic(e.target.value);
            }}
            displayEmpty
          >
            <MenuItem disabled value="">
              <span>Select</span>
            </MenuItem>
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
        {orgPublic === "yes" && (
          <div className="events-page-onboarding-steps-body-stocks">
            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                className="custom-form-label big-label"
                htmlFor="onboard-org-exchange"
              >
                Primary Stock Exchange
              </Label>
              <Input
                id="onboard-org-exchange"
                className="custom-form-input"
                type="text"
                value={orgStock}
                readOnly={!isEditAccess}
                onChange={e => {
                  if (e.target?.value?.length <= 20 && isEditAccess)
                    setOrgStock(
                      e.target.value
                        ?.replace(/(<([^>]+)>)/gi, "")
                        ?.replace(/\s+/g, " ")
                    );
                }}
              />
            </FormControl>
            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                className="custom-form-label big-label"
                htmlFor="onboard-org-ticker"
              >
                Primary Ticker
              </Label>
              <Input
                id="onboard-org-ticker"
                className="custom-form-input"
                type="text"
                value={orgTicker}
                readOnly={!isEditAccess}
                onChange={e => {
                  if (e.target?.value?.length <= 20 && isEditAccess)
                    setOrgTicker(
                      e.target.value
                        ?.replace(/(<([^>]+)>)/gi, "")
                        ?.replace(/\s+/g, " ")
                    );
                }}
              />
            </FormControl>
          </div>
        )}
        {isEditAccess && (
          <>
            <div className="events-page-account-setting-content-box-line"></div>

            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Text
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text"
              >
                &nbsp;
              </Text>

              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                onClick={() => onSave()}
              >
                Save
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </div>
  );
};

export default AccountStock;
