import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmDialog = props => {
  const { open, onCancel, data, onAccept, size } = props;
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog"
      maxWidth={size || "xs"}
    >
      <DialogTitle className="text-center">{data?.title || ""}</DialogTitle>
      <DialogContent>
        <DialogContentText className="text-center">
          {data?.text || "Are you sure?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          onClick={onCancel}
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          {data?.cancelLabel || "Cancel"}
        </Button>
        <Button
          onClick={onAccept}
          variant="outlined"
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
        >
          {data?.yesLabel || "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
