import React from "react";
import imgconstturction from "../../../assets/images/six/career-construction.webp";

const SixCareersConstruction = () => {
  return (
    <section className="events-page-landing-section">
      <div className="text-center">
        <img src={imgconstturction} alt="Career 6ix" />
      </div>
    </section>
  );
};

export default SixCareersConstruction;
