import React from "react";
import { CheckCircleOutline } from "@mui/icons-material";

const SixSecurityControls = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header">
            <h2 class="events-page-landing-section-header-heading">
              Internal Controls
            </h2>
          </div>

          <div className="events-page-security-section-features">
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Formal Security Policies: 6ix maintains a set of comprehensive
                security polices that are kept up to date to meet the changing
                security environment. These materials are made available to all
                employees during training and through the company’s knowledge
                base.
              </div>

              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Continuous Security Training: 6ix provides employees with
                continuous education on emerging security threats, performs
                phishing awareness campaigns, and communicates with stakeholders
                regularly.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Application Monitoring and Protection: All app access is logged
                and audited. We also use a wide variety of solutions to quickly
                identify and eliminate threats.
              </div>
            </div>
            <div className="events-page-security-section-features-col">
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Strict Onboarding and Offboarding: All employees undergo
                criminal and credit background checks and are subject to ongoing
                background checks throughout their employment. Additionally, we
                instantly disable departing employee’s devices, apps, and access
                during offboarding.
              </div>
              <div className="events-page-security-section-features-list">
                <CheckCircleOutline />
                Incident Response Plan: Security incidents are documented and we
                adjust our policies accordingly.
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityControls;
