import React from "react";

const SixSecurityLine = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-landing-section-space">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-security-section-line"></div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityLine;
