import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FullPageProgress from "../components/FullPageProgress";
import { signInWithUserEmailLinkVerify } from "../firebase";
import { useGlobalValue } from "../GlobalContext";
import { SESSION_AUTH } from "../common/auth";
let reqCheck = true;

export function AuthPage() {
  const { setLoggedinNotified } = useGlobalValue();
  const navigate = useNavigate();
  const { SET_USER_SESSION } = SESSION_AUTH();

  const VerifyLink = async (mode, actionCode, continueUrl) => {
    if (reqCheck && mode) {
      let email;
      let url;
      let dataReturn;
      try {
        if (continueUrl) {
          url = new URL(continueUrl);
          email = url?.searchParams?.get("ref_l_email");
        }
      } catch (err) {
        email = "";
      }
      switch (mode) {
        case "signIn":
          reqCheck = false;
          dataReturn = await signInWithUserEmailLinkVerify(email);
          if (dataReturn?.code === 200 && dataReturn?.data) {
            SET_USER_SESSION(dataReturn?.data);
            setLoggedinNotified(new Date());
          }
          if (url) {
            if (process.env.REACT_APP_PRODUCTION_URL === url?.origin) {
              navigate(url?.pathname || "/");
            } else if (url?.origin && url?.pathname) {
              window.location.href = url?.origin + url?.pathname;
            } else {
              navigate(url?.pathname || "/");
            }
          } else {
            navigate("/");
          }
          break;
        case "resetPassword":
          navigate(
            "/password/reset/" +
              actionCode +
              "?email=" +
              email +
              "&continueUrl=" +
              (url?.pathname || "")
          );
          break;
        case "recoverEmail":
          navigate("/");
          break;
        case "verifyEmail":
          navigate("/");
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const mode = params.get("mode");
    const actionCode = params.get("oobCode");
    const continueUrl = params.get("continueUrl");
    VerifyLink(mode, actionCode, continueUrl);
  });

  return (
    <React.Fragment>
      <FullPageProgress fixed={true} />
    </React.Fragment>
  );
}
