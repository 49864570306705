import CryptoJS from "crypto-js";
import { parsedUserAgent } from "@100mslive/react-sdk";
const ENCRYPT_SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const defaultAudioList = [
  {
    name: "Audio1",
    id: "audio1",
    metadata: {
      description: "Artist1",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio1.mp3",
    type: "audio",
  },
  {
    name: "Audio2",
    id: "audio2",
    metadata: {
      description: "Artist2",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio2.mp3",
    type: "audio",
  },
  {
    name: "Audio3",
    id: "audio3",
    metadata: {
      description: "Artist3",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio3.mp3",
    type: "audio",
  },
  {
    name: "Audio4",
    id: "audio4",
    metadata: {
      description: "Artist4",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio4.mp3",
    type: "audio",
  },
  {
    name: "Audio5",
    id: "audio5",
    metadata: {
      description: "Artist5",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio5.mp3",
    type: "audio",
  },
  {
    name: "Audio6",
    id: "audio6",
    metadata: {
      description: "Artist6",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/audio6.mp3",
    type: "audio",
  },
];

export const defaultVideoList = [
  {
    name: "Video1",
    id: "video1",
    metadata: {
      description: "Artist1",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/video1.mp4",
    type: "video",
  },
  {
    name: "Video2",
    id: "video2",
    metadata: {
      description: "Artist2",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/video2.mp4",
    type: "video",
  },
  {
    name: "Video3",
    id: "video3",
    metadata: {
      description: "Artist2",
    },
    url: "https://bc-public-static-assets.s3.ap-south-1.amazonaws.com/webapp/playlist/video3.mp4",
    type: "video",
  },
];

export const emojiIdMapping = [
  { emojiId: "+1", emoji: "👍" },
  { emojiId: "-1", emoji: "👎" },
  { emojiId: "wave", emoji: "👋" },
  { emojiId: "clap", emoji: "👏" },
  { emojiId: "fire", emoji: "🔥" },
  { emojiId: "tada", emoji: "🎉" },
  { emojiId: "heart_eyes", emoji: "😍" },
  { emojiId: "joy", emoji: "😂" },
  { emojiId: "open_mouth", emoji: "😮" },
  { emojiId: "sob", emoji: "😭" },
];

export const DEFAULT_HLS_ROLE_KEY = "HLS_VIEWER_ROLE";
export const DEFAULT_HLS_VIEWER_ROLE = "hls-viewer";
export const DEFAULT_WAITING_VIEWER_ROLE = "waiting-room";
export const QUERY_PARAM_SKIP_PREVIEW = "skip_preview";
export const QUERY_PARAM_SKIP_PREVIEW_HEADFUL = "skip_preview_headful";
export const QUERY_PARAM_NAME = "name";
export const QUERY_PARAM_VIEW_MODE = "ui_mode";
export const QUERY_PARAM_AUTH_TOKEN = "auth_token";
export const UI_MODE_ACTIVE_SPEAKER = "activespeaker";
export const UI_MODE_GRID = "grid";
export const MAX_TOASTS = 5;
export const RTMP_RECORD_RESOLUTION_MIN = 480;
export const RTMP_RECORD_RESOLUTION_MAX = 1920;
export const RTMP_RECORD_DEFAULT_RESOLUTION = {
  width: 1920,
  height: 1080,
};

export const CHAT_SELECTOR = {
  PEER_ID: "peer_id",
  ROLE: "role",
};

export const EVENT_CONFIG = {
  eventName: "Quarterly Investor Day",
  eventDate: "TBD",
  eventDescription: "Join us for a great event!",
  eventCover:
    "https://6ix-events-pro.s3.amazonaws.com/6ixvideo/cover/event-cover1685163992306Cover-Banner.png",
};
export const locale_month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const locale_days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const EVENT_DATE_SHOW = data => {
  let year = data.$y;
  let days = locale_days[data.$W];
  let month = locale_month[data.$M];
  let date = data.$D;
  let hour = data.$H < 10 ? "0" + data.$H : data.$H;
  let minutes = data.$m < 10 ? "0" + data.$m : data.$m;
  // let second = data.$s < 10 ? "0" + data.$s : data.$s;
  return (
    days + ", " + month + " " + date + ", " + year + " " + hour + ":" + minutes
  );
};
const ENCRYPT_DATA = data => {
  const encrypted = CryptoJS.AES.encrypt(data, ENCRYPT_SECRET_KEY).toString();
  return encrypted;
};
const DECRYPT_DATA = data => {
  try {
    const decrypted = CryptoJS.AES.decrypt(data, ENCRYPT_SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decrypted;
  } catch (error) {
    return;
  }
};

export const GET_ENCRYPT_DATA = value => {
  try {
    return ENCRYPT_DATA(value);
  } catch (err) {
    return "";
  }
};
export const GET_DECRYPT_DATA = value => {
  console.log(value, "value");
  try {
    let d = DECRYPT_DATA(value);
    return d;
  } catch (err) {
    console.log(err, "err");
    return "";
  }
};

export const SET_USER_SESSION = user => {
  let data = ENCRYPT_DATA(JSON.stringify(user));
  localStorage.setItem("EU6IX", data);
};

export const SET_PUBLIC_SESSION = (name, value) => {
  if (value && name) {
    let data = ENCRYPT_DATA(value);
    localStorage.setItem(name, data);
  }
};

export const GET_PUBLIC_SESSION = name => {
  if (name && localStorage.getItem(name)) {
    try {
      let data = DECRYPT_DATA(localStorage.getItem(name));
      return data;
    } catch (err) {
      return "";
    }
  }
  return;
};
export const REMOVE_PUBLIC_SESSION = name => {
  localStorage.removeItem(name);
  return;
};

export const CREATE_MEETING_TOKEN = data => {
  return encodeURIComponent(ENCRYPT_DATA(JSON.stringify(data)));
};
export const GET_MEETING_TOKEN = token => {
  try {
    let data = DECRYPT_DATA(decodeURIComponent(token));
    return data ? JSON.parse(data) : null;
  } catch (error) {
    return;
  }
};

export const APP_DATA = {
  uiSettings: "uiSettings",
  chatOpen: "chatOpen",
  chatSelector: "chatSelector",
  chatDraft: "chatDraft",
  appConfig: "appConfig",
  recordingUrl: "recordingUrl",
  sidePane: "sidePane",
  hlsStats: "hlsStats",
  hlsViewerRole: "hlsViewerRole",
  waitingViewerRole: "waitingViewerRole",
  subscribedNotifications: "subscribedNotifications",
  logo: "logo",
  tokenEndpoint: "tokenEndpoint",
  appLayout: "appLayout",
  hlsStarted: "hlsStarted",
  rtmpStarted: "rtmpStarted",
  recordingStarted: "recordingStarted",
  embedConfig: "embedConfig",
  pinnedTrackId: "pinnedTrackId",
  dropdownList: "dropdownList",
  widgetState: "widgetState",
};
export const UI_SETTINGS = {
  isAudioOnly: "isAudioOnly",
  isHeadless: "isHeadless",
  maxTileCount: "maxTileCount",
  uiViewMode: "uiViewMode",
  showStatsOnTiles: "showStatsOnTiles",
  enableAmbientMusic: "enableAmbientMusic",
  mirrorLocalVideo: "mirrorLocalVideo",
  activeSpeakerSorting: "activeSpeakerSorting",
  hideLocalVideo: "hideLocalVideo",
};

export const WIDGET_STATE = {
  pollInView: "pollInView",
  view: "view",
};

export const WIDGET_VIEWS = {
  LANDING: "LANDING",
  CREATE_POLL_QUIZ: "CREATE_POLL_QUIZ",
  CREATE_QUESTIONS: "CREATE_QUESTIONS",
  VOTE: "VOTE",
  RESULTS: "RESULTS",
};

export const SIDE_PANE_OPTIONS = {
  PARTICIPANTS: "Participants",
  CHAT: "Chat",
  STREAMING: "STREAMING",
  TILES: "TILES",
  SCREEN_TILES: "SCREEN_TILES",
  WIDGET: "widget",
  POLLQUIZ: "poll-quiz",
  VB: "VB",
  ABOUT: "ABOUT",
};

export const SUBSCRIBED_NOTIFICATIONS = {
  PEER_JOINED: "PEER_JOINED",
  PEER_LEFT: "PEER_LEFT",
  METADATA_UPDATED: "METADATA_UPDATED",
  NEW_MESSAGE: "NEW_MESSAGE",
  ERROR: "ERROR",
};

export const REMOTE_STOP_SCREENSHARE_TYPE = "REMOTE_STOP_SCREENSHARE";

export const FEATURE_LIST = {
  AUDIO_ONLY_SCREENSHARE: "audioscreenshare",
  AUDIO_PLAYLIST: "audioplaylist",
  VIDEO_PLAYLIST: "videoplaylist",
  EMOJI_REACTION: "emojireaction",
  AUDIO_PLUGINS: "audioplugins",
  VIDEO_PLUGINS: "videoplugins",
  CHANGE_NAME: "changename",
  FULLSCREEN: "fullscreen",
  PICTURE_IN_PICTURE: "pip",
  STARTS_FOR_NERDS: "statsfornerds",
  EMBED_URL: "embedurl",
  BRB: "brb",
  HAND_RAISE: "handraise",
  CHAT: "chat",
  PIN_TILE: "pintile",
};

export const isChrome =
  parsedUserAgent.getBrowser()?.name?.toLowerCase() === "chrome";
export const isFirefox =
  parsedUserAgent.getBrowser()?.name?.toLowerCase() === "firefox";
export const isSafari =
  parsedUserAgent.getBrowser()?.name?.toLowerCase() === "safari";
export const isIOS = parsedUserAgent.getOS()?.name?.toLowerCase() === "ios";
export const isMacOS =
  parsedUserAgent.getOS()?.name?.toLowerCase() === "mac os";
export const isAndroid =
  parsedUserAgent.getOS()?.name?.toLowerCase() === "android";
export const isIPadOS = false;

export const SESSION_STORE_KEY = {
  TRANSCRIPTION_STATE: "transcriptionState",
  PINNED_MESSAGE: "pinnedMessage",
  SPOTLIGHT: "spotlight",
};

export const INTERACTION_TYPE = {
  POLL: "Poll",
  QUIZ: "Quiz",
};

export const QUESTION_TYPE_TITLE = {
  "single-choice": "Single Choice",
  "multiple-choice": "Multiple Choice",
};

export const QUESTION_TYPE = {
  SINGLE_CHOICE: "single-choice",
  MULTIPLE_CHOICE: "multiple-choice",
};

export const CREATE_ROOM_DOC_URL = "https://6ix.com/";

export const MEETING_URL =
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? "https://6ix.com/event/"
    : "http://localhost:3001/event/";

export const API_URL =
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? "https://eventsbackendapi.6ix.com/api"
    : "http://localhost:3000/api";

export const PlatfomUrl =
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? "https://6ix.com"
    : "http://localhost:3001";

export const EVENT_TYPE = {
  public: {
    name: "Public",
    value: "public",
  },
  private: {
    name: "Private",
    value: "private",
  },
};
export const ROOM_TYPE = {
  10000: {
    name: "10K",
    value: 10000,
    text: "Attendee mics & cameras will be off",
    attendes: {
      mic: false,
      camera: false,
    },
  },
  200: {
    name: "200",
    value: 200,
    confirm: true,
    text: "Attendee mics & cameras will be on",
    attendes: {
      mic: true,
      camera: true,
    },
  },
};
export const EVENT_STATUS = {
  published: {
    name: "Published",
    value: "published",
  },
  ended: {
    name: "Ended",
    value: "ended",
  },
};

export const SUBSCRIPTION_STATUS_LIST = {
  1: {
    name: "Active",
    value: 1,
  },
  2: {
    name: "Expired",
    value: 2,
  },
  3: {
    name: "Incomplete",
    value: 3,
  },
  4: {
    name: "Past due",
    value: 4,
  },
  5: {
    name: "Unpaid",
    value: 5,
  },
};
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;
