import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import { Button, TablePagination } from "@mui/material";
import { Box, config as cssConfig, Flex } from "@100mslive/roomkit-react";
import { AscendingIcon, DescendingIcon } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import InvestorComment from "./InvestorComment";
import { SESSION_AUTH } from "../../../common/auth";
import { INVESTOR_SERVICES } from "../../../services";
import {
  GET_PUBLIC_SESSION,
  SET_PUBLIC_SESSION,
} from "../../../common/constants";

const InvestorTable = props => {
  const { isSubscription, totalRows } = props;
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const isMobile = useMedia(cssConfig.media.md);
  const [listData, setListData] = useState([]);
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const rowsPerPageOptions = [50, 100, 250, 500, 1000];
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(() => {
    let v = parseInt(GET_PUBLIC_SESSION("EU_DEFAULT_LIST"));
    return rowsPerPageOptions.includes(v) ? v : isMobile ? 50 : 250;
  });
  const [sortedField, setSortedField] = React.useState(null);
  const [sortDirection, setSortDirection] = React.useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [sorting, setSorting] = useState(false);
  const [commentAccess, setCommentAccess] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");

  const columns = [
    { lable: "Name", name: "name" },
    { lable: "Email", name: "email" },
    { lable: "Mobile", name: "phoneNumber" },
    { lable: "Investor Type", name: "investorType" },
    { lable: "Cheque Size", name: "totalChequeSize" },
    {
      lable: "Total Requested Meeting",
      name: "totalMeetingReq",
    },
    { lable: "Company Name", name: "work" },
    { lable: "Job Title", name: "jobTitle" },
    {
      lable: "Total Registers",
      name: "totalEvent",
    },
    {
      lable: "Shareholder",
      name: "currentlyInvested",
    },
  ];

  useEffect(() => {
    if (isUserSession) {
      setDataFetched(false);
      setUserData(isUserSession);
      setCommentAccess(false);
      setSelectedMember("");
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!sorting) return;
    setSorting(false);
    let listDataArray = [...listData];
    listDataArray.sort((a, b) => {
      let aa = a?.[sortedField?.name];
      let bb = b?.[sortedField?.name];
      aa = aa || "";
      bb = bb || "";

      if (aa < bb) {
        return sortDirection === "ascending" ? -1 : 1;
      }
      if (aa > bb) {
        return sortDirection === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setListData(listDataArray);
  }, [sorting, listData, sortDirection, sortedField]);

  const sortRequest = item => {
    let sortType =
      sortedField?.name === item?.name
        ? sortDirection === "descending"
          ? "ascending"
          : "descending"
        : "ascending";
    setSortedField(item);
    setSortDirection(sortType);
    setSorting(true);
  };

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoading(true);
    try {
      INVESTOR_SERVICES.list({
        userId: userData.userId,
        org: userData?.org?.id,
        page: page,
        limit: limit,
      })
        .then(data => {
          if (data.code === 200) {
            setListData(data?.data?.list || []);
            setSorting(true);
            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, limit, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setDataFetched(false);
  };
  const handleChangeRowsPerPage = event => {
    let value = event.target.value;
    setLimit(value);
    setPage(0);
    setDataFetched(false);
    SET_PUBLIC_SESSION("EU_DEFAULT_LIST", JSON.stringify(value));
  };
  const actionComment = item => {
    setSelectedMember(item);
    setCommentAccess(true);
  };
  return (
    <>
      {loading && (
        <Box
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            left: "0px",
            top: "0px",
            zIndex: 999,
          }}
        >
          <FullPageProgress />
        </Box>
      )}
      <div className="relationship">
        <Box className="relationship-usertable">
          <table className="relationship-usertable-table">
            <thead>
              <tr>
                {columns.map((item, key) => (
                  <th
                    className={
                      item?.name && sortedField?.name === item?.name
                        ? "highlight"
                        : null
                    }
                    key={key}
                    onClick={() => {
                      if (item?.name) {
                        sortRequest(item);
                      }
                    }}
                  >
                    <div>
                      {item?.lable}
                      {item?.name ? (
                        sortDirection === "descending" &&
                        sortedField?.name === item?.name ? (
                          <DescendingIcon />
                        ) : (
                          <AscendingIcon />
                        )
                      ) : null}
                    </div>
                  </th>
                ))}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {listData.map((item, key) => (
                <tr key={key}>
                  <td>
                    <Flex>
                      <div className="relationship-usertable-table-image firstname-image">
                        <img src={item?.profileImage} alt="" />
                      </div>
                      <span>{item?.name}</span>
                    </Flex>
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.phoneNumber || "-"}</td>
                  <td>{item?.investorType || "-"}</td>
                  <td>
                    <NumericFormat
                      value={item?.totalChequeSize}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$"
                      renderText={(value, props) => (
                        <span {...props}>{value}</span>
                      )}
                    />
                  </td>

                  <td>{item?.totalMeetingReq}</td>

                  <td>
                    <span>{item?.work || "-"}</span>
                  </td>
                  <td>{item?.jobTitle || "-"}</td>
                  <td>{item?.totalEvent || "0"}</td>
                  <td className="text-center text-capitalize">
                    {item?.currentlyInvested}
                  </td>
                  <td>
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      className="events6ix-btn events6ix-btn-primary"
                      type="button"
                      onClick={() => {
                        actionComment(item);
                      }}
                    >
                      Comment
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        {totalRows > 0 && isSubscription > 0 ? (
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showLastButton={true}
            showFirstButton={true}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        ) : null}

        {!isSubscription ? (
          <div className="relationship-upgrade-overlay"></div>
        ) : null}
      </div>

      {commentAccess && selectedMember && (
        <InvestorComment
          open={setCommentAccess ? true : false}
          onClose={() => {
            setCommentAccess(false);
          }}
          member={selectedMember}
          onUpdate={() => {
            setSelectedMember("");
          }}
        />
      )}
    </>
  );
};

export default InvestorTable;
