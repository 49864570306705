import React, { useEffect, useState } from "react";
import { Box, Flex } from "@100mslive/roomkit-react";
import { auth, signInWithUserEmailLink } from "../../../firebase";
import { useGlobalValue } from "../../../GlobalContext";
import { ArrowLeft, Cross, GoogleIcon, Outlook } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";

const LoginVerifyPopup = props => {
  const { isUserSession } = SESSION_AUTH();
  const { setLoginUserVerify } = useGlobalValue();
  const { cancel } = props;
  const [email, setEmail] = useState("");
  const [, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [loginLinkMSG, setLoginLinkMSG] = useState("");

  useEffect(() => {
    if ((isUserSession && isUserSession?.isVerify) || !isUserSession) {
      setLoginUserVerify(false);
    }
    if (isUserSession) {
      setEmail(isUserSession?.email);
    } else {
      setEmail("");
    }
  }, [isUserSession, setLoginUserVerify]);

  const loginWIthEmail = async () => {
    if (email) {
      setLoader(true);
      const data = await signInWithUserEmailLink(email);
      if (data) {
        setLoginLinkMSG(data);
      }
      setLoader(false);
    } else {
      ToastManager.addToast({ title: "Enter your email address" });
    }
  };

  return (
    <>
      <Flex
        align="center"
        direction="column"
        className="eventsPopup eventsPopup-xs userLogin"
        onClick={() => {
          if (cancel) {
            setLoginUserVerify(false);
          }
        }}
      >
        <Box
          className="eventsPopup-inner"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {(loading || loader) && <FullPageProgress fixed={true} />}
          <>
            <div className="eventsPopup-header">
              <div className="eventsPopup-header-heading">Verify Account</div>
              {!loginLinkMSG && (
                <div className="eventsPopup-header-desc">
                  Welcome to the restricted area of the 6ix Events platform! To
                  ensure the security of your account:
                </div>
              )}
            </div>
            {cancel && (
              <div
                className="eventsPopup-close"
                onClick={() => {
                  setLoginUserVerify(false);
                }}
              >
                <Cross />
              </div>
            )}
            <div className="eventsPopup-content">
              {loginLinkMSG ? (
                <>
                  <div className="userLogin-email custom-form">
                    <div className="userLogin-email-msg">{loginLinkMSG}</div>
                    <div className="userLogin-line"></div>
                    <div className="userLogin-email-msg">
                      Open this email in
                    </div>
                    <Flex
                      className="userLogin-social mt-20"
                      style={{ gap: "20px" }}
                    >
                      <a
                        href="https://mail.google.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="userLogin-social-btn"
                      >
                        <GoogleIcon /> Gmail
                      </a>
                      <a
                        href="https://outlook.live.com/mail/0/"
                        target="_blank"
                        rel="noreferrer"
                        className="userLogin-social-btn mt-0"
                      >
                        <Outlook /> Outlook
                      </a>
                    </Flex>
                  </div>
                  <Flex className="userLogin-email-footer">
                    <div
                      className="userLogin-social-btn userLogin-email-footer-col ml-auto mr-auto"
                      onClick={() => {
                        setLoginLinkMSG("");
                      }}
                    >
                      <ArrowLeft /> Go Back
                    </div>
                  </Flex>
                </>
              ) : (
                <div className="userLogin-social">
                  <button
                    type="button"
                    className="userLogin-social-btn mt-20 mb-20"
                    onClick={loginWIthEmail}
                  >
                    Verify with Email Magic Link
                  </button>
                </div>
              )}
            </div>
          </>
        </Box>
      </Flex>
    </>
  );
};
export default LoginVerifyPopup;
