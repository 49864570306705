import React from "react";

const SixCareersCapitalism = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-careers-section-build">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-careers-section-build-row">
          <div className="events-page-careers-section-build-content">
            <div className="events-page-careers-section-header">
              <div className="events-page-about-section-header-heading">
                The 6ix ethic & our spirit towards capitalism
              </div>
            </div>
          </div>

          <div className="events-page-careers-section-build-content">
            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Craft & mastery
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                We believe in honing our skills to perfection, constantly
                pushing the boundaries of what's possible.
              </div>
            </div>

            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Urgency & intentionality
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                In a fast-moving market, we act with purpose and speed, making
                every decision count as we race to democratize global investment
                opportunities.
              </div>
            </div>

            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Energy & curiosity
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                We approach each day with enthusiasm and a thirst for knowledge,
                always seeking new ways to innovate and improve our platform and
                services.
              </div>
            </div>

            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Individual responsibility & leadership
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                Every team member is empowered to take ownership of their role
                and lead by example, driving our mission forward at every level
                of the organization.
              </div>
            </div>
            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Impact & vocation
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                We view our work not just as a job, but as a calling to create
                meaningful change in the global financial landscape, unlocking
                opportunities for billions worldwide.
              </div>
            </div>
            <div className="events-page-careers-section-build-content-row">
              <div className="events-page-careers-section-build-content-row-heading">
                Hard work & customer obsession
              </div>
              <div className="events-page-careers-section-build-content-row-text">
                We're relentless in our pursuit of excellence, always going the
                extra mile to ensure our platform delivers exceptional value to
                investors, companies, and experts alike.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SixCareersCapitalism;
