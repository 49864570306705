import React, { useEffect, useRef } from "react";
import { ToastManager } from "../../Toast/ToastManager";
import PricingContent from "./PricingContent";
import { SESSION_AUTH } from "../../../common/auth";
import { USER_SERVICES } from "../../../services";
import "./style.scss";
const SixPricing = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const dataFetched = useRef();
  useEffect(() => {
    if (!isUserSession || dataFetched?.current) return;
    dataFetched.current = true;
    USER_SERVICES.analytics({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      type: "price",
    })
      .then(data => {
        if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err?.message });
      });
  }, [isUserSession, LOGOUT_USER_SESSION]);

  return (
    <section className="event-page-pricing-section">
      <div className="events6ix-layout-page-middle">
        <div className="event-page-pricing-section-header">
          <h1 className="event-page-pricing-section-header-heading">Pricing</h1>
          <div className="event-page-pricing-section-header-text">
            6ix builds modular capital markets software that helps you
            <br />
            <span className="text-primary">
              invest capital, attract capital{" "}
              <span className="text-white">and</span> share expertise.
            </span>
          </div>
        </div>
        <PricingContent />
      </div>
    </section>
  );
};

export default SixPricing;
