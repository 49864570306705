import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex } from "@100mslive/roomkit-react";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import InvestorInvitedTable from "./InvestorInvitedTable";
import { SESSION_AUTH } from "../../../common/auth";
import { INVESTOR_SERVICES } from "../../../services";

const InvestorInvited = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [dataFetched, setDataFetched] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [totalInvited, setTotalInvited] = useState(0);

  const [loading, setLoading] = useState(false);

  const [isSubscription, setIsSubscription] = useState("");

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    setLoading(true);
    try {
      INVESTOR_SERVICES.invited({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            setIsSubscription(data.data?.isSubscription);
            setTotalInvited(data.data?.totalInvited || 0);
            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
            navigate("/dashboard");
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, navigate]);

  return (
    <React.Fragment>
      <Flex className="events6ix-layout-page-top">
        <h1 className="events6ix-layout-page-top-heading events6ix-layout-page-top-heading-line">
          Investors Report:Invited Users
        </h1>
      </Flex>
      {loading ? (
        <FullPageProgress fixed={true} />
      ) : (
        <Box className="relationship-container">
          {totalInvited && totalInvited > 0 && isSubscription ? (
            <InvestorInvitedTable totalRows={totalInvited} />
          ) : null}
        </Box>
      )}
    </React.Fragment>
  );
};

export default InvestorInvited;
