import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { Cross, SearchIcon } from "../../icons";

const ExploreFilter = props => {
  const { textSearch, setTextSearch, onSubmit, onReset } = props;
  return (
    <div className="event-page-explore-filter">
      <div className="event-page-explore-filter-col event-page-explore-filter-search">
        <span className="event-page-explore-filter-search-icon">
          <SearchIcon />
        </span>
        <input
          className="event-page-explore-filter-search-input"
          type="search"
          value={textSearch}
          onChange={e => {
            setTextSearch(e.target.value);
          }}
          onKeyDown={e => {
            if (e.which === 13 && !e.shiftKey) {
              onSubmit();
              e.preventDefault();
            }
          }}
          placeholder="Search events... "
        />
        {textSearch && (
          <button
            onClick={onReset}
            className="event-page-explore-filter-search-btn"
            type="button"
          >
            <Cross />
          </button>
        )}
      </div>
      <div className="event-page-explore-filter-col">
        <Button
          component={Link}
          size="small"
          to="/create"
          color="primary"
          variant="outlined"
          className="events6ix-btn events6ix-btn-primary"
        >
          Create Event
        </Button>
      </div>
    </div>
  );
};

export default ExploreFilter;
