import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Flex } from "@100mslive/roomkit-react";
import { LoginPopup } from "../Account/Login/LoginPopup";
import ProfileBox from "../Common/ProfileBox";
import { SESSION_AUTH } from "../../common/auth";

const ExploreInvestContent = props => {
  const { listType } = useParams();
  const { isUserSession } = SESSION_AUTH();
  const [userData, setUserData] = useState("");
  const [loginRequest, setLoginRequest] = useState(
    () => !isUserSession && listType === "login"
  );

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [isUserSession]);
  return (
    <>
      <Flex className="event-page-explore-investment">
        <Flex direction="column" className="event-page-explore-investment-col">
          <div className="event-page-explore-investment-line">
            GROW YOUR NET WORTH AND MARKET CAP ON 6IX
          </div>
          <h1 className="event-page-explore-investment-heading">
            Attend & Host Interactive Investor Presentations
          </h1>
          {userData ? (
            <>
              <div className="event-page-explore-investment-text">
                Chat with CEOs, ask questions, get answers.
              </div>
              <div className="event-page-explore-investment-action">
                <ProfileBox boxBorder={true} editAllow={true} />
              </div>
            </>
          ) : (
            <>
              <div className="event-page-explore-investment-text">
                Chat with CEOs, ask questions, get answers.
                <span> Free for investors, paid plans for companies.</span>
              </div>
              <div className="event-page-explore-investment-action">
                <button
                  type="button"
                  className="event-page-explore-investment-btn"
                  onClick={() => setLoginRequest(true)}
                >
                  SIGNUP OR LOGIN
                </button>
              </div>
            </>
          )}
        </Flex>
        <Flex direction="row" className="event-page-explore-investment-col">
          <div className="event-page-explore-investment-videos">
            <iframe
              loading="lazy"
              src="https://videos.6ix.com/embed/66a8c10bf1da1b4282710077"
              autoPlay
              allowFullScreen
              muted
              title="Explore 6ix Events"
            ></iframe>
          </div>
        </Flex>
      </Flex>

      {loginRequest && !userData ? (
        <LoginPopup
          dropOut={true}
          cancel={true}
          handleLogin={() => setLoginRequest(false)}
        />
      ) : null}
    </>
  );
};

export default ExploreInvestContent;
