import React from "react";
export function MailIcon(props) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_170_10854)">
        <path
          d="M3.76045 4.16553H18.555C19.5722 4.16553 20.4043 4.99772 20.4043 6.01485V17.1108C20.4043 18.1279 19.5722 18.9601 18.555 18.9601H3.76045C2.74333 18.9601 1.91113 18.1279 1.91113 17.1108V6.01485C1.91113 4.99772 2.74333 4.16553 3.76045 4.16553Z"
          stroke={props.stroke}
          strokeWidth="1.85302"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4043 6.01514L11.1577 12.4878L1.91113 6.01514"
          stroke={props.stroke}
          strokeWidth="1.85302"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_170_10854">
          <rect
            width="22.1919"
            height="22.1919"
            fill="white"
            transform="translate(0.0615234 0.467285)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default MailIcon;
