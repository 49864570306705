import React from "react";
import SixCareersAt from "./SixCareersAt";
import SixCareersBuild from "./SixCareersBuild";
import SixCareersCalculator from "./SixCareersCalculator";
import SixCareersCapitalism from "./SixCareersCapitalism";
import SixCareersConstruction from "./SixCareersConstruction";
import SixCareersHighlights from "./SixCareersHighlights";
import SixCareersJoin from "./SixCareersJoin";
import SixCareersMission from "./SixCareersMission";
import SixCareersQuote from "./SixCareersQuote";
import "./style.scss";

const SixCareers = () => {
  return (
    <React.Fragment>
      <SixCareersMission />
      <SixCareersBuild />
      <SixCareersQuote />
      <SixCareersCapitalism />
      <SixCareersConstruction />
      <SixCareersAt />
      <SixCareersHighlights />
      <SixCareersCalculator />
      <SixCareersJoin />
    </React.Fragment>
  );
};

export default SixCareers;
