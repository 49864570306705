import {
  selectUnreadHMSMessagesCount,
  useHMSStore,
} from "@100mslive/react-sdk";
import { ChatIcon, ChatUnreadIcon } from "@100mslive/react-icons";
import { Tooltip } from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import {
  useIsSidepaneTypeOpen,
  useSidepaneToggle,
} from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../common/constants";

export const ChatToggle = () => {
  const countUnreadMessages = useHMSStore(selectUnreadHMSMessagesCount);
  const isChatOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.CHAT);
  const toggleChat = useSidepaneToggle(SIDE_PANE_OPTIONS.CHAT);

  return (
    <Tooltip key="chat" title={`${isChatOpen ? "Close" : "Open"} chat`}>
      <div className="preview_tile_footer_frame">
        <IconButton
          onClick={toggleChat}
          active={!isChatOpen}
          data-testid="chat_btn"
          className={`preview_chat_unread_btn preview_tile_footer_btn ${
            isChatOpen ? "active" : ""
          }`}
        >
          {countUnreadMessages === 0 ? (
            <ChatIcon />
          ) : (
            <ChatUnreadIcon data-testid="chat_unread_btn" />
          )}
        </IconButton>
        <span className="preview_tile_footer_indicator">Chat</span>
      </div>
    </Tooltip>
  );
};
