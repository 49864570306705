import React from "react";
import SEO from "../components/Common/SEO";
import { Dashboard } from "../components/Dashboard/Dashboard";
import EventFooter from "../components/Event/EventFooter";
import PageHeader from "../components/PageHeader";

export function DashboardPage() {
  return (
    <React.Fragment>
      <SEO title="Dashboard | 6ix Events" />
      <main className="events-page-dashboard events6ix-layout-page" role="main">
        <PageHeader />
        <div className="events6ix-layout-page-content">
          <Dashboard />
        </div>
      </main>
      <EventFooter />
    </React.Fragment>
  );
}
