import React from "react";
import ExploreListItem from "./ExploreListItem";

const ExploreList = props => {
  const { listData, loading } = props;

  return (
    <>
      <div className="event-page-explore-content-items">
        {listData.map((listItem, key) => {
          return <ExploreListItem {...listItem} key={key} />;
        })}
        {listData?.length === 0 && !loading && (
          <div className="event-page-explore-content-empty">
            Events not found, try again.
          </div>
        )}
      </div>
    </>
  );
};
export default ExploreList;
