import React from "react";

export function ChatIcon() {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_264_84675)">
        <path
          d="M27.9 15.0501V14.3001C27.7275 11.1736 26.4079 8.22053 24.1937 6.00638C21.9796 3.79223 19.0265 2.47258 15.9 2.30009H15.15C13.1702 2.29493 11.2172 2.75749 9.45002 3.65009C7.33241 4.70844 5.55126 6.33546 4.3061 8.34892C3.06094 10.3624 2.40094 12.6827 2.40002 15.0501C2.39486 17.0299 2.85742 18.9829 3.75002 20.7501L0.900024 29.3001L9.45002 26.4501C11.2172 27.3427 13.1702 27.8053 15.15 27.8001"
          stroke="#E9E9E9"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4001 23.3999C14.3966 24.7198 14.705 26.0218 15.3001 27.1999C16.0056 28.6117 17.0903 29.7991 18.4326 30.6292C19.7749 31.4593 21.3218 31.8993 22.9 31.8999C24.2199 31.9033 25.5219 31.595 26.7 30.9999L32.4 32.8999L30.5 27.1999C31.0951 26.0218 31.4035 24.7198 31.4 23.3999C31.3994 21.8217 30.9594 20.2748 30.1293 18.9325C29.2992 17.5902 28.1118 16.5055 26.7 15.7999C25.5219 15.2049 24.2199 14.8965 22.9 14.8999H22.4C20.3157 15.0149 18.347 15.8947 16.8709 17.3708C15.3948 18.8469 14.515 20.8156 14.4001 22.8999V23.3999Z"
          stroke="#E9E9E9"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_264_84675">
          <rect
            width="36"
            height="36"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
