import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export function DashboardEventDelete(props) {
  const { deleteEventConfirm, closeDeleteEvent, selectedEvent, deleteEvent } =
    props;
  return (
    <Dialog
      open={deleteEventConfirm}
      onClose={closeDeleteEvent}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog"
    >
      <DialogTitle className="text-center">
        {selectedEvent.eventName}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="text-center">
          Do you want to delete this event?
        </DialogContentText>
      </DialogContent>

      <DialogActions className="custom-dialog-action">
        <Button
          variant="outlined"
          color="secondary"
          onClick={closeDeleteEvent}
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          No, thanks
        </Button>
        <Button
          variant="outlined"
          onClick={deleteEvent}
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
