import React from "react";
import SixDemoSidebar from "./SixDemoSidebar";

const SixMeeting = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-demo-section">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-demo-section-row">
          <div className="events-page-demo-section-row-content">
            <div className="events-page-demo-section-header">
              <h2 className="events-page-demo-section-header-heading">
                Request a 1-on-1 meeting
              </h2>
              <div className="events-page-demo-section-header-text">
                Let us know who you would like to meet with by using the form.
              </div>
            </div>

            <iframe
              src="https://videos.6ix.com/embed/661964bda988b9ebbedf2ec0"
              title="6ix Pricing 12 Apr 2024 | 6ix Videos"
              frameBorder="0"
              autoPlay
              allowFullScreen
              muted
            ></iframe>
          </div>
          <SixDemoSidebar />
        </div>
      </div>
    </section>
  );
};

export default SixMeeting;
