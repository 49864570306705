import React from "react";
import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ToastManager } from "../../Toast/ToastManager";

const OnBoardingMatters = props => {
  const matters = [
    "Reach new investors",
    "Engage existing shareholders",
    "Attract capital",
    "Earn a premium valuation",
    "Get coverage",
    "Grow your reputation in the capital markets",
    "Network with peers",
    "Prove an ROI on your IR program",
  ];
  const { onNext, onBardingData, setOnBardingData } = props;
  const chooseMatter = m => {
    let d = { ...onBardingData };
    const index = d?.orgMatters?.indexOf(m);
    if (index > -1) {
      d.orgMatters.splice(index, 1);
    } else {
      d.orgMatters.push(m);
    }
    setOnBardingData(d);
  };
  const onSave = () => {
    if (!onBardingData?.orgMatters || onBardingData?.orgMatters?.length <= 0) {
      ToastManager.addToast({ title: "Please select at least one matters" });
      return false;
    }
    onNext(3);
  };
  return (
    <div className="events-page-onboarding-steps-body">
      <div className="events-page-onboarding-steps-body-title">
        What matters most to you?
      </div>

      <div className="events-page-onboarding-steps-body-text">
        This will help us guide you to the most relevant features.
      </div>
      <div className="events-page-onboarding-steps-body-text">
        <i>Select all that apply:</i>
      </div>
      <div className="events-page-onboarding-steps-body-matters">
        {matters?.map((m, key) => (
          <Button
            key={key}
            color="secondary"
            variant="outlined"
            size="small"
            className={`events6ix-btn events6ix-btn-secondary ${
              onBardingData?.orgMatters?.includes(m) ? " active " : ""
            }`}
            type="button"
            onClick={() => chooseMatter(m)}
            sx={{ textTransform: "none" }}
          >
            {m}
          </Button>
        ))}
      </div>

      <Button
        color="primary"
        variant="outlined"
        fullWidth
        className="events6ix-btn events6ix-btn-primary mt-20"
        type="button"
        onClick={() => onSave()}
        endIcon={<ArrowForward />}
        sx={{ textTransform: "none" }}
      >
        Next
      </Button>
    </div>
  );
};

export default OnBoardingMatters;
