import React, { useEffect } from "react";
import {
  auth,
  //  signInWithApple, signInWithGoogle
} from "../../../firebase";
//import { AppleIcon, EmailIcon, GoogleIcon } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import LoginWithPassword from "./LoginWithPassword";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";

const Login = props => {
  const { isUserSession } = SESSION_AUTH();
  const { handleLogin, setPasswordLogin, email, setEmail } = props;
  const [, loading, error] = useAuthState(auth);
  //const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (isUserSession) {
      if (handleLogin) handleLogin();
    }
  }, [isUserSession, handleLogin]);

  // const loginWithGoogle = async () => {
  //   setLoader(true);
  //   const data = await signInWithGoogle();
  //   if (data?.code === 200 && data?.data) {
  //     SET_USER_SESSION(data?.data);
  //     if (handleLogin) handleLogin();
  //   }

  //   setLoader(false);
  // };
  // const loginWithApple = async () => {
  //   setLoader(true);
  //   const data = await signInWithApple();
  //   if (data?.code === 200 && data?.data) {
  //     SET_USER_SESSION(data?.data);
  //     if (handleLogin) handleLogin();
  //   }
  //   setLoader(false);
  // };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);
  return (
    <>
      {loading && <FullPageProgress fixed={true} />}

      {/* <div className="userLogin-social">
        <button
          onClick={loginWithGoogle}
          type="button"
          className="userLogin-social-btn"
        >
          <GoogleIcon /> Continue with Google
        </button>
        <button
          onClick={loginWithApple}
          type="button"
          className="userLogin-social-btn"
        >
          <AppleIcon /> Continue with Apple
        </button>
        <button
          type="button"
          className="userLogin-social-btn"
          onClick={() => setPasswordLogin(1)}
        >
          <EmailIcon /> Continue with Email
        </button>
      </div>
      <div className="userLogin-or">
        <span className="userLogin-or-text">or</span>
      </div> */}

      <LoginWithPassword
        email={email}
        setEmail={setEmail}
        handleLogin={handleLogin}
        setPasswordLogin={setPasswordLogin}
      />
    </>
  );
};
export default Login;
