import React from "react";
export function BannerIcon() {
  return (
    <svg
      width="1110"
      height="360"
      viewBox="0 0 1110 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1110" height="360" fill="#1F1F1F" fillOpacity="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M531.667 153.34C529.829 153.34 528.34 154.829 528.34 156.667V203.333C528.34 204.758 529.236 205.974 530.495 206.448L565.971 170.971C567.276 169.667 569.39 169.667 570.695 170.971L581.66 181.936V156.667C581.66 154.829 580.171 153.34 578.333 153.34H531.667ZM581.66 191.383L568.333 178.056L539.73 206.66H578.333C580.171 206.66 581.66 205.171 581.66 203.333V191.383ZM521.66 156.667C521.66 151.14 526.14 146.66 531.667 146.66H578.333C583.86 146.66 588.34 151.14 588.34 156.667V203.333C588.34 208.86 583.86 213.34 578.333 213.34H531.667C526.14 213.34 521.66 208.86 521.66 203.333V156.667ZM543.333 173.333C546.095 173.333 548.333 171.094 548.333 168.333C548.333 165.571 546.095 163.333 543.333 163.333C540.572 163.333 538.333 165.571 538.333 168.333C538.333 171.094 540.572 173.333 543.333 173.333Z"
        fill="#2B2B2B"
      />
    </svg>
  );
}
export default BannerIcon;
