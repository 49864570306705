import React from "react";

export function Bell() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2882 2.28767C6.27275 1.30312 7.6081 0.75 9.00047 0.75C10.3928 0.75 11.7282 1.30312 12.7127 2.28767C13.6973 3.27223 14.2504 4.60757 14.2504 5.99994C14.2504 8.51441 14.7882 10.0759 15.2838 10.9845C15.5327 11.4407 15.775 11.74 15.9433 11.9177C16.0277 12.0067 16.0939 12.0657 16.1339 12.099C16.1538 12.1156 16.1672 12.1259 16.173 12.1302C16.1739 12.1308 16.1746 12.1313 16.1751 12.1317C16.444 12.3164 16.563 12.6543 16.4682 12.9672C16.3724 13.2835 16.0809 13.4999 15.7504 13.4999H2.25054C1.92004 13.4999 1.6285 13.2835 1.53273 12.9672C1.43797 12.6543 1.55695 12.3164 1.82581 12.1317C1.82634 12.1314 1.82705 12.1308 1.82795 12.1302C1.83373 12.1259 1.84712 12.1156 1.86708 12.099C1.907 12.0657 1.97328 12.0067 2.05764 11.9177C2.22596 11.74 2.46827 11.4407 2.71711 10.9845C3.21273 10.0759 3.75052 8.51441 3.75052 5.99994C3.75052 4.60757 4.30364 3.27223 5.2882 2.28767ZM1.83339 12.1266C1.83345 12.1266 1.83351 12.1265 1.83357 12.1265C1.83357 12.1265 1.83356 12.1265 1.83356 12.1265L1.83339 12.1266ZM3.86251 11.9999H14.1384C14.0814 11.9064 14.0241 11.8074 13.967 11.7028C13.3376 10.5489 12.7504 8.73542 12.7504 5.99994C12.7504 5.00539 12.3553 4.05158 11.6521 3.34832C10.9488 2.64507 9.99502 2.24998 9.00047 2.24998C8.00592 2.24998 7.0521 2.64507 6.34885 3.34832C5.64559 4.05158 5.25051 5.00539 5.25051 5.99994C5.25051 8.73542 4.66331 10.5489 4.03394 11.7028C3.97687 11.8074 3.91958 11.9064 3.86251 11.9999Z"
        fill="#C0C0C0"
        stroke="#C0C0C0"
        strokeWidth="0.224998"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.32643 15.1014C7.68472 14.8935 8.14366 15.0155 8.3515 15.3738C8.41742 15.4874 8.51205 15.5818 8.62591 15.6474C8.73976 15.7129 8.86885 15.7475 9.00024 15.7475C9.13163 15.7475 9.26072 15.7129 9.37457 15.6474C9.48842 15.5818 9.58305 15.4874 9.64898 15.3738C9.85682 15.0155 10.3158 14.8935 10.6741 15.1014C11.0323 15.3092 11.1543 15.7681 10.9465 16.1264C10.7487 16.4674 10.4648 16.7504 10.1232 16.9472C9.78167 17.1439 9.39441 17.2474 9.00024 17.2474C8.60607 17.2474 8.21881 17.1439 7.87725 16.9472C7.53568 16.7504 7.25179 16.4674 7.05401 16.1264C6.84617 15.7681 6.96814 15.3092 7.32643 15.1014Z"
        fill="#C0C0C0"
        stroke="#C0C0C0"
        strokeWidth="0.224998"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default Bell;
