import React from "react";
export function ReplayIcon() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.7998 4.5L13.5498 9L6.7998 13.5V4.5Z"
        stroke="#E9E9E9"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default ReplayIcon;
