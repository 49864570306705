import React from "react";
export function DocIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 1.49997H4.5C4.10218 1.49997 3.72064 1.658 3.43934 1.93931C3.15804 2.22061 3 2.60214 3 2.99997V15C3 15.3978 3.15804 15.7793 3.43934 16.0606C3.72064 16.3419 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.3419 14.5607 16.0606C14.842 15.7793 15 15.3978 15 15V5.99997L10.5 1.49997Z"
        stroke="white"
        strokeWidth="1.503"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 1.49997V5.99997H15"
        stroke="white"
        strokeWidth="1.503"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.75003H6"
        stroke="white"
        strokeWidth="1.503"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.75H6"
        stroke="white"
        strokeWidth="1.503"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 6.75H6.75H6"
        stroke="white"
        strokeWidth="1.503"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default DocIcon;
