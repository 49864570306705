import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const LandingIntro = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space-top events-page-landing-intro">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-landing-intro-content">
          <h1 className="events-page-landing-intro-content-heading">
            Inspire your investors <br />
            with <span className="text-primary">6ix Events</span>
          </h1>
          <div className="events-page-landing-intro-content-text">
            Host your next earnings call, investor day or digital roadshow with
            6ix Events, the only digital roadshow platform with comprehensive
            investor profiles, inbound investor meetings and data-driven
            investor reports.
          </div>
          <div className="events-page-landing-intro-content-action">
            <Button
              component={Link}
              to="/create"
              color="primary"
              variant="outlined"
              type="button"
              className="events6ix-btn events6ix-btn-primary"
            >
              CREATE EVENT
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingIntro;
