import React, { useEffect, useRef } from "react";
import { ToastManager } from "../../Toast/ToastManager";
import SixDemoSidebar from "./SixDemoSidebar";
import { SESSION_AUTH } from "../../../common/auth";
import { USER_SERVICES } from "../../../services";

const SixDemo = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const dataFetched = useRef();
  useEffect(() => {
    if (!isUserSession || dataFetched?.current) return;
    dataFetched.current = true;
    USER_SERVICES.analytics({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      type: "demo",
    })
      .then(data => {
        if (data.code === 600) {
          LOGOUT_USER_SESSION();
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err?.message });
      });
  }, [isUserSession, LOGOUT_USER_SESSION]);

  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-demo-section">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-demo-section-row">
          <div className="events-page-demo-section-row-content">
            <div className="events-page-demo-section-header">
              <h2 className="events-page-demo-section-header-heading">
                See 6ix in Action
              </h2>
              <div className="events-page-demo-section-header-text">
                6ix helps you invest capital, attract capital and share your
                expertise.
              </div>
            </div>

            <iframe
              src="https://videos.6ix.com/embed/661964bda988b9ebbedf2ec0"
              title="6ix Pricing 12 Apr 2024 | 6ix Videos"
              frameBorder="0"
              autoPlay
              allowFullScreen
              muted
            ></iframe>
          </div>
          <SixDemoSidebar />
        </div>
      </div>
    </section>
  );
};

export default SixDemo;
