import { apiCall, apiCallMultipart } from "./servicesCall";

export const EVENT_SERVICES = {
  createEvent(data) {
    return apiCallMultipart("/event/create", data);
  },
  editEvent(data) {
    return apiCall("/event/edit", data);
  },
  updateEvent(data) {
    return apiCallMultipart("/event/update", data);
  },
  eventDetail(data) {
    return apiCall("/event/detail", data);
  },
  meetingRole(data) {
    return apiCall("/event/meeting/role", data);
  },
  meetingJoin(data) {
    return apiCall("/event/meeting/join", data);
  },

  hostEventList(data) {
    return apiCall("/event/list/host", data);
  },
  upcomingEventList(data) {
    return apiCall("/event/list/upcoming", data);
  },
  attendedEventList(data) {
    return apiCall("/event/list/attended", data);
  },
  deleteEvent(data) {
    return apiCall("/event/delete", data);
  },
  getEventReplay(data) {
    return apiCall("/event/replay", data);
  },
  addReplay(data, onUploadProgress) {
    return apiCallMultipart("/event/replay/add", data, onUploadProgress);
  },
  deleteReplay(data) {
    return apiCall("/event/replay/delete", data);
  },
  speakersList(data) {
    return apiCall("/event/speakers", data);
  },

  surveySave(data) {
    return apiCall("/event/survey/save", data);
  },
  analyticsUpdate(data) {
    return apiCall("/event/survey/list/update", data);
  },

  presenterdocumentsUpload(data) {
    return apiCallMultipart("/event/presenterdocuments/upload", data);
  },
  speakerProfile(data) {
    return apiCall("/event/speaker/profile", data);
  },
  speakerProfileUpdate(data) {
    return apiCallMultipart("/event/speaker/profile/update", data);
  },

  ratingSave(data) {
    return apiCall("/event/rating/save", data);
  },
};
