import React from "react";
export function Ellipsis() {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99922 9.29922C8.44105 9.29922 8.79922 8.94105 8.79922 8.49922C8.79922 8.05739 8.44105 7.69922 7.99922 7.69922C7.55739 7.69922 7.19922 8.05739 7.19922 8.49922C7.19922 8.94105 7.55739 9.29922 7.99922 9.29922Z"
          stroke="#E9E9E9"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99922 3.70156C8.44105 3.70156 8.79922 3.34339 8.79922 2.90156C8.79922 2.45973 8.44105 2.10156 7.99922 2.10156C7.55739 2.10156 7.19922 2.45973 7.19922 2.90156C7.19922 3.34339 7.55739 3.70156 7.99922 3.70156Z"
          stroke="#E9E9E9"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99922 14.9008C8.44105 14.9008 8.79922 14.5426 8.79922 14.1008C8.79922 13.659 8.44105 13.3008 7.99922 13.3008C7.55739 13.3008 7.19922 13.659 7.19922 14.1008C7.19922 14.5426 7.55739 14.9008 7.99922 14.9008Z"
          stroke="#E9E9E9"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default Ellipsis;
