import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export function StatusChart(props) {
  const { chartData } = props;

  const [chartOptions, setChartOptions] = useState("");

  useEffect(() => {
    if (!chartData) return;
    let categories = chartData?.categories || [];
    let investedAmount = chartData?.investedAmount || [];

    let options = {
      chart: {
        plotBackgroundColor: "transparent",
        backgroundColor: "transparent",
        plotBorderWidth: 0,
        plotShadow: false,
        type: "column",
      },
      colors: ["#F5EACA", "#DBB163", "#A78F77", "#60574D", "#575757"],
      title: {
        text: "Invested Amount",
        align: "center",
        verticalAlign: "top",
        style: {
          color: "white",
        },
      },
      xAxis: {
        categories: categories,
        crosshair: true,
        accessibility: {
          description: "Countries",
        },
        labels: {
          style: {
            color: "white",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Amount in USD ($)",
          style: {
            color: "white",
          },
        },
        labels: {
          style: {
            color: "white",
          },
        },
        gridLineColor: "#4e4e4e",
      },
      tooltip: {
        valuePrefix: "$",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      legend: {
        // align: "right",
        // layout: "vertical",
        //verticalAlign: "top",
        // itemMarginBottom: 8,
        itemStyle: {
          color: "white",
          fontWeight: "bold",
        },
        itemHoverStyle: {
          color: "#e3c050",
        },
      },
      series: [
        {
          name: "Invested Amount",
          data: investedAmount,
          showInLegend: false,
        },
      ],
    };

    setChartOptions(options);
    return () => null;
  }, [chartData]);

  return (
    <>
      {chartOptions && (
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      )}
    </>
  );
}
