import React from "react";
import SixSecurityAccount from "./SixSecurityAccount";
import SixSecurityCertification from "./SixSecurityCertification";
import SixSecurityContacting from "./SixSecurityContacting";
import SixSecurityControls from "./SixSecurityControls";
import SixSecurityCrypto from "./SixSecurityCrypto";
import SixSecurityInfra from "./SixSecurityInfra";
import SixSecurityLine from "./SixSecurityLine";
import SixSecurityStorage from "./SixSecurityStorage";
import SixSecurityTrust from "./SixSecurityTrust";
import SixSecurityWallet from "./SixSecurityWallet";
import "./style.scss";

const SixSecurity = () => {
  return (
    <React.Fragment>
      <SixSecurityTrust />
      <SixSecurityCrypto />
      <SixSecurityLine />
      <SixSecurityStorage />
      <SixSecurityLine />
      <SixSecurityWallet />
      <SixSecurityLine />
      <SixSecurityAccount />
      <SixSecurityLine />
      <SixSecurityInfra />
      <SixSecurityLine />
      <SixSecurityControls />
      <SixSecurityLine />
      <SixSecurityCertification />
      <SixSecurityLine />
      <SixSecurityContacting />
    </React.Fragment>
  );
};

export default SixSecurity;
