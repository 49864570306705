import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import { Button } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { config as cssConfig } from "@100mslive/roomkit-react";
import { CheckMark } from "../../../icons";
import ConfirmDialog from "../../Common/ConfirmDialog";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import AccountBillingCardPopup from "../Settings/AccountBillingSettings/AccountBillingCardPopup";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../services";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const OnBoardingSubscription = props => {
  const { billingAddons } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const isMobile = useMedia(cssConfig.media.md);
  const [selectedNewPlan, setSelectedNewPlan] = useState("");
  const [confirmPlan, setConfirmPlan] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  const queryParameters = new URLSearchParams(window.location.search);
  const continueUrl = queryParameters.get("continueUrl");

  const plansOnboard = {
    bronze: {
      type: "bronze",
      heading:
        "Host your first investor event for free to engage your community",
      price: "$0",
      text: "Attend and host investor days, townhalls, press release updates, VR tours,panels and more.",
      feature: [
        "Attend & host unlimited events for unlimited speakers, registrants and attendees.",
        "Automated recordings, replay functionalities & live editing.",
        "Automatic landing page creation,email & SMS reminders.",
        "Simple investor surveys & basic shareholder analytics.",
      ],
      current: true,
    },
    silver: {
      type: "silver",
      heading:
        "Host your first investor event for free to engage your community",
      price: "$ 1,000/mo",
      tag: "+ $500 per thousand investor profiles per month",
      text: "Everything in the Bronze plan, plus:",
      feature: [
        "Customize your investor registration process and investor surveys.",
        "Send emails from your company domain.",
        "Integrate your investor data with marketing automation tools.",
        "Comprehensive investor surveys & advanced shareholder analytics.",
      ],
    },
    gold: {
      type: "gold",
      heading:
        "Host your first investor event for free to engage your community",
      price: "$ 5,000/mo",
      text: "Everything in the Silver plan, plus 6ix will proactively set up events and panels for you to participate in and coordinating with other speakers to make the process as smooth and effective as possible.",
      feature: [
        "A dedicated account manager.",
        "A 6ix moderator for your events.",
        "Behind-the-scenes marketing & logistics support.",
        "Proactive invitations to panel events and other opportunities.",
      ],
    },
  };

  const onChoosePlan = item => {
    if (item) {
      setConfirmPlan(true);
      setSelectedNewPlan(item);
    }
  };

  const onCancelPlan = () => {
    setConfirmPlan(false);
    setSelectedNewPlan("");
  };

  const onCancelNewCard = () => {
    setAddNewCard(false);
  };

  const onAcceptPlan = () => {
    setConfirmPlan(false);

    if (selectedNewPlan?.id) {
      setLoading(true);
      ACCOUNT_SERVICES.createSubscription({
        userId: isUserSession.userId,
        org: isUserSession?.org?.id,
        plan: selectedNewPlan,
      })
        .then(async data => {
          if (data.code === 200) {
            if (data?.data?.clientSecret) {
              const confirmPayment = await stripe?.confirmCardPayment(
                data.data?.clientSecret
              );

              if (confirmPayment?.error) {
                ToastManager.addToast({
                  title: confirmPayment.error.message,
                });
              } else {
                ToastManager.addToast({
                  title: data.message,
                });
              }
            } else {
              ToastManager.addToast({
                title: data.message,
              });
            }
            return navigate(continueUrl || "/", { replace: true });
          } else if (data.code === 201) {
            setAddNewCard(true);
            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);
          ToastManager.addToast({ title: error.message });
        });
    }
  };
  const onNewCard = () => {
    setAddNewCard(false);
    onAcceptPlan();
  };

  const onSkip = () => {
    navigate(continueUrl || "/", { replace: true });
  };

  return (
    <React.Fragment>
      {loading ? <FullPageProgress fixed={true} /> : null}
      <div className="events-page-onboarding-steps-body">
        <div className="events-page-onboarding-steps-header2">
          <div className="events-page-onboarding-steps-header2-label">
            CHOOSE YOUR PLAN
          </div>
          <div className="events-page-onboarding-steps-header2-title">
            Only pay for what you use
          </div>
          <div className="events-page-onboarding-steps-header2-text">
            Owners possess the ability to manage teams, billing, reset the
            organization,
          </div>
        </div>

        <div className="events-page-onboarding-steps-price-content">
          {isMobile ? (
            <Carousel
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={50}
              showThumbs={false}
              showArrows={true}
              className="events-page-onboarding-steps-slider"
            >
              {Object.keys(plansOnboard).map((item, key) => (
                <div
                  key={key}
                  className="events-page-onboarding-steps-price-content-box"
                >
                  <div className="events-page-onboarding-steps-price-content-box-type">
                    {plansOnboard?.[item]?.type}
                  </div>
                  <div className="events-page-onboarding-steps-price-content-box-title">
                    {plansOnboard?.[item]?.heading}
                  </div>
                  <div className="events-page-onboarding-steps-price-content-box-price">
                    <h2 className="events-page-onboarding-steps-price-content-box-price-title">
                      {plansOnboard?.[item]?.price}
                    </h2>
                    <div className="events-page-onboarding-steps-price-content-box-price-text">
                      {plansOnboard?.[item]?.tag}
                    </div>
                  </div>
                  <div className="events-page-onboarding-steps-price-content-box-text">
                    {plansOnboard?.[item]?.text}
                  </div>

                  {plansOnboard?.[item]?.feature.map((f, fkey) => (
                    <div
                      key={fkey}
                      className="events-page-onboarding-steps-price-content-box-list"
                    >
                      <CheckMark />
                      {f}
                    </div>
                  ))}

                  <div className="events-page-onboarding-steps-price-content-box-action">
                    {plansOnboard?.[item]?.current ? (
                      <Button
                        color="secondary"
                        fullWidth
                        variant="outlined"
                        className="events6ix-btn events6ix-btn-light"
                        disabled
                      >
                        Current plan
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        fullWidth
                        variant="outlined"
                        className="events6ix-btn events6ix-btn-primary"
                        onClick={() => onChoosePlan(billingAddons?.[item])}
                      >
                        Subscribe
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </Carousel>
          ) : (
            Object.keys(plansOnboard).map((item, key) => (
              <div
                key={key}
                className="events-page-onboarding-steps-price-content-box"
              >
                <div className="events-page-onboarding-steps-price-content-box-inner">
                  <div className="events-page-onboarding-steps-price-content-box-type">
                    {plansOnboard?.[item]?.type}
                  </div>
                  <div className="events-page-onboarding-steps-price-content-box-title">
                    {plansOnboard?.[item]?.heading}
                  </div>
                  <div className="events-page-onboarding-steps-price-content-box-price">
                    <h2 className="events-page-onboarding-steps-price-content-box-price-title">
                      {plansOnboard?.[item]?.price}
                    </h2>
                    <div className="events-page-onboarding-steps-price-content-box-price-text">
                      {plansOnboard?.[item]?.tag}
                    </div>
                  </div>
                  <div className="events-page-onboarding-steps-price-content-box-text">
                    {plansOnboard?.[item]?.text}
                  </div>

                  {plansOnboard?.[item]?.feature.map((f, fkey) => (
                    <div
                      key={fkey}
                      className="events-page-onboarding-steps-price-content-box-list"
                    >
                      <CheckMark />
                      {f}
                    </div>
                  ))}
                </div>
                <div className="events-page-onboarding-steps-price-content-box-action">
                  {plansOnboard?.[item]?.current ? (
                    <Button
                      color="secondary"
                      fullWidth
                      variant="outlined"
                      className="events6ix-btn events6ix-btn-light"
                      disabled
                    >
                      Current plan
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      fullWidth
                      variant="outlined"
                      className="events6ix-btn events6ix-btn-primary"
                      onClick={() => onChoosePlan(billingAddons?.[item])}
                    >
                      Subscribe
                    </Button>
                  )}
                </div>
              </div>
            ))
          )}
        </div>

        <div className="text-center mt-20">
          <Button
            color="secondary"
            variant="outlined"
            className="events6ix-btn events6ix-btn-light"
            sx={{ textTransform: "none" }}
            onClick={onSkip}
          >
            &nbsp; Skip for now &nbsp;
          </Button>
        </div>
      </div>
      {confirmPlan && selectedNewPlan && (
        <ConfirmDialog
          open={confirmPlan ? true : false}
          data={{
            title: selectedNewPlan?.name,
            text: `By clicking "Confirm," you acknowledge and agree to the changes. If you have any questions, feel free to contact our support team ${process.env.REACT_APP_SUPPORT_EMAIL}`,
            yesLabel: "Confirm",
          }}
          onCancel={onCancelPlan}
          onAccept={onAcceptPlan}
        />
      )}
      {addNewCard && (
        <AccountBillingCardPopup
          onClose={onCancelNewCard}
          onUpdate={onNewCard}
        />
      )}
    </React.Fragment>
  );
};

export default OnBoardingSubscription;
