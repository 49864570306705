import { apiCall } from "./servicesCall";

export const MAILCHIMP_SERVICES = {
  connect(data) {
    return apiCall("/mailchimp/connect", data);
  },
  list(data) {
    return apiCall("/mailchimp/list", data);
  },
  accesstoken(data) {
    return apiCall("/mailchimp/accesstoken/generate", data);
  },
  syncAnalytics(data) {
    return apiCall("/mailchimp/sync/analytics", data);
  },
  syncInvestors(data) {
    return apiCall("/mailchimp/sync/investors", data);
  },
};
