import { CircularProgress } from "@mui/material";
import { Box, Flex } from "@100mslive/roomkit-react";

const FullPageProgress = props => {
  return props.fixed ? (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        left: "0px",
        top: "0px",
        zIndex: props?.zindex || 999,
        background: props?.background || "",
        backdropFilter: props?.backdropfilter || "",
      }}
    >
      <Flex
        justify="center"
        align="center"
        css={{ size: "100%" }}
        className="text-white"
      >
        <CircularProgress size={90} thickness={3.8} color="inherit" />
      </Flex>
    </Box>
  ) : (
    <Flex
      justify="center"
      align="center"
      css={{ size: "100%" }}
      className="text-white"
    >
      <CircularProgress size={90} thickness={3.8} color="inherit" />
    </Flex>
  );
};

export default FullPageProgress;
