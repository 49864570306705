import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useMedia } from "react-use";
import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material";
import { config as cssConfig } from "@100mslive/roomkit-react";
import { LogoIcon } from "../../../icons";
import { SESSION_AUTH } from "../../../common/auth";

const AccountSettingsSidebar = () => {
  const { isUserSession } = SESSION_AUTH();
  const [userData, setUserData] = useState(null);
  const { settingType } = useParams();
  const navigate = useNavigate();
  const isMobile = useMedia(cssConfig.media.md);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    setIsEditAccess(
      userData?.org?.isAccountOwner ||
        userData?.org?.isOwner ||
        userData?.org?.isAdmin
        ? true
        : false
    );
    return () => null;
  }, [userData]);

  return (
    <>
      <div className="events-page-account-setting-sidebar">
        <div
          className="events-page-account-setting-sidebar-header"
          onClick={() => {
            setToggleMenu(!toggleMenu);
          }}
        >
          <div
            className="events-page-account-setting-sidebar-header-logo"
            style={{
              backgroundColor: userData?.org?.orgLogo
                ? userData?.org?.orgLogoColor
                : "",
            }}
          >
            {userData?.org?.orgLogo ? (
              <img src={userData?.org?.orgLogo} alt="Logo" />
            ) : (
              <LogoIcon />
            )}
          </div>
          <div className="events-page-account-setting-sidebar-header-name">
            {userData?.org?.orgName}
          </div>
          {isMobile && (
            <div className="events-page-account-setting-sidebar-header-action">
              {toggleMenu ? <CloseIcon /> : <MenuIcon />}
            </div>
          )}
        </div>
        {((toggleMenu && isMobile) || !isMobile) && (
          <div className="events-page-account-setting-sidebar-links">
            <NavLink
              to="/account/setting/"
              className={`events-page-account-setting-sidebar-links-row ${
                settingType === "setting" ? "active" : ""
              } `}
              onClick={() => {
                setToggleMenu(false);
              }}
            >
              General
            </NavLink>
            <NavLink
              to="/account/setting/members"
              className="events-page-account-setting-sidebar-links-row"
              onClick={() => {
                setToggleMenu(false);
              }}
            >
              Members
            </NavLink>
            {isEditAccess && (
              <NavLink
                to="/account/setting/billing"
                className="events-page-account-setting-sidebar-links-row"
                onClick={() => {
                  setToggleMenu(false);
                }}
              >
                Billing
              </NavLink>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AccountSettingsSidebar;
