// @ts-check
import { useCallback } from "react";
import {
  selectLocalPeerID,
  selectPeerNameByID,
  selectSessionMetadata,
  useHMSActions,
  useHMSStore,
  useHMSVanillaStore,
} from "@100mslive/react-sdk";
import {
  METADATA_MESSAGE_TYPE,
  REFRESH_MESSAGE,
} from "./useRefreshSessionMetadata";

/**
 * set pinned chat message by updating the session metadata
 * and broadcasting metadata refresh message to other peers
 */
export const useSetPinnedMessage = () => {
  const hmsActions = useHMSActions();
  const vanillaStore = useHMSVanillaStore();
  const sessionMetaData = useHMSStore(selectSessionMetadata);
  const localPeerId = useHMSStore(selectLocalPeerID);

  const setPinnedMessage = useCallback(
    /**
     * @param {import("@100mslive/react-sdk").HMSMessage | undefined} message
     */

    async message => {
      const peerName =
        vanillaStore.getState(selectPeerNameByID(message?.sender)) ||
        message?.senderName;
      const newPinnedMessage = message
        ? peerName
          ? `${peerName}: ${message.message}`
          : message.message
        : null;
      if (newPinnedMessage !== sessionMetaData?.pin) {
        await hmsActions.setSessionMetadata({
          pin: newPinnedMessage,
        });
        await hmsActions.sendBroadcastMessage(
          REFRESH_MESSAGE,
          METADATA_MESSAGE_TYPE
        );
      }
    },
    [hmsActions, vanillaStore, sessionMetaData]
  );

  const setMessageAction = useCallback(
    /**
     * @param {import("@100mslive/react-sdk").HMSMessage | undefined} message
     */

    async (message, action = "") => {
      if (!action) return;
      let newStarMessage = {
        sender: message?.sender,
        senderUserId: message?.senderUserId,
        time: message?.time.getTime(),
        updateAt: new Date().getTime(),
        localPeerId: localPeerId,
        action: action,
      };
      if (action === "star") {
        newStarMessage["type"] = message?.["hasStar"] ? "Remove" : "Add";
      } else if (action === "answer") {
        newStarMessage["type"] = message?.["hasAnswer"] ? "Remove" : "Add";
      } else if (action === "like") {
        newStarMessage["type"] = message?.["like"]?.["hasLike"]
          ? "Remove"
          : "Add";
      }

      await hmsActions.setSessionMetadata({
        ...sessionMetaData,
        actionData: newStarMessage,
      });
      await hmsActions.sendBroadcastMessage(
        REFRESH_MESSAGE,
        METADATA_MESSAGE_TYPE
      );
    },
    [hmsActions, sessionMetaData, localPeerId]
  );

  return { setPinnedMessage, setMessageAction };
};
