import React from "react";

export function Colorchecked() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="14" rx="7" fill="#10B981" />
      <path
        d="M10.5 4.375L5.6875 9.1875L3.5 7"
        stroke="white"
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
