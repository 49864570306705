import React, { useEffect, useState } from "react";
import { FormControl } from "@mui/material";
import { Flex, Input, Label } from "@100mslive/roomkit-react";
import { auth, sendForgotPasswordEmail } from "../../../firebase";
import { ArrowLeft } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { isValidEmail } from "../../../common/utils";

const ForgotPassword = props => {
  const { email, setEmail, setPasswordLogin } = props;
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [loginLinkMSG, setLoginLinkMSG] = useState("");

  const passwordRequest = async () => {
    setLoader(true);
    if (!email || (email && !isValidEmail(email))) {
      ToastManager.addToast({ title: "Enter your registered email address" });
    } else {
      const data = await sendForgotPasswordEmail(email);
      if (data) {
        setLoginLinkMSG(data);
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);
  return (
    <>
      {(loading || loader) && <FullPageProgress fixed={true} />}
      <div className="userLogin-email custom-form">
        {loginLinkMSG ? (
          <>
            <div className="userLogin-email-msg">{loginLinkMSG}</div>
            <div
              className="userLogin-email-link text-underline"
              onClick={() => {
                setLoginLinkMSG("");
              }}
            >
              Did not get the link?
            </div>
          </>
        ) : (
          <>
            <FormControl className="custom-form-group">
              <Label className="custom-form-label" htmlFor="login-email">
                Email
              </Label>
              <Input
                id="login-email"
                placeholder="your@email.com"
                className="custom-form-input"
                type="email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>

            <button
              type="button"
              className="userLogin-email-btn mt-10"
              onClick={passwordRequest}
              disabled={loader || loading}
            >
              Send
            </button>
          </>
        )}
      </div>

      <Flex className="userLogin-email-footer">
        <div
          onClick={() => {
            if (loginLinkMSG) {
              setLoginLinkMSG("");
            } else {
              setPasswordLogin(0);
            }
          }}
          className="userLogin-email-link userLogin-email-footer-col"
        >
          <ArrowLeft /> Go Back
        </div>
      </Flex>
    </>
  );
};
export default ForgotPassword;
