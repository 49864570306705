import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, FormControl } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Box, Flex, Label } from "@100mslive/roomkit-react";
import { Cross } from "../../../../icons";
import FullPageProgress from "../../../FullPageProgress";
import { ToastManager } from "../../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../../services";

const AccountBillingCardPopup = props => {
  const { onClose, onUpdate } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [cardFocusElemet, setCardFocusElemet] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    setIsEditAccess(
      userData?.org?.isAccountOwner ||
        userData?.org?.isOwner ||
        userData?.org?.isAdmin
        ? true
        : false
    );
    return () => null;
  }, [userData]);

  const onSave = () => {
    try {
      setLoading(true);
      stripe
        ?.createPaymentMethod({
          type: "card",
          card: elements?.getElement(
            CardNumberElement,
            CardExpiryElement,
            CardCvcElement
          ),
          billing_details: {
            name: userData?.name,
            email: userData?.email,
          },
        })
        .then(function (result) {
          if (result?.error) {
            ToastManager.addToast({
              title: result?.error?.message,
            });
            setLoading(false);
            return;
          }
          if (result?.paymentMethod) {
            ACCOUNT_SERVICES.paymentMethodAdd({
              userId: userData.userId,
              org: userData?.org?.id,
              paymentMethod: result?.paymentMethod?.id,
            })
              .then(data => {
                if (data.code === 200) {
                  ToastManager.addToast({ title: data.message });
                  if (data?.data?.client_secret) {
                    stripe
                      .confirmCardPayment(data?.data?.client_secret)
                      .then(function (confirmPayment) {
                        if (confirmPayment?.error) {
                          ToastManager.addToast({
                            title: confirmPayment?.error?.message,
                          });
                        }
                        onUpdate();
                      });
                  } else {
                    onUpdate();
                  }
                } else if (data.code === 600) {
                  LOGOUT_USER_SESSION();
                } else {
                  ToastManager.addToast({ title: data.message });
                }
              })
              .catch(error => {
                ToastManager.addToast({ title: error.message });
              })
              .finally(() => {
                setLoading(false);
              });
          }
        });
    } catch (error) {
      setLoading(false);
      ToastManager.addToast({ title: error.message });
    }
  };

  const inputStyle = {
    iconColor: "#c4f0ff",
    color: "#fff",
    fontWeight: "500",
    fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
    fontSize: "16px",
    lineHeight: "25px",
    fontSmoothing: "antialiased",
    padding: "10px",
    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#8c8e95",
    },
  };
  if (!isEditAccess) return;
  return (
    <Flex
      align="center"
      direction="column"
      className="eventsPopup eventsPopup-sm"
      onClick={() => {
        onClose(false);
      }}
    >
      <Box
        className="eventsPopup-inner"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {(loading || !stripe) && <FullPageProgress fixed={true} />}
        <div
          className="eventsPopup-close"
          onClick={() => {
            onClose(false);
          }}
        >
          <Cross />
        </div>
        <div className="eventsPopup-header">
          <div className="eventsPopup-header-heading">Add your credit card</div>
        </div>
        <div className="eventsPopup-content">
          <FormControl className="events-page-account-setting-content-box-control custom-form-group">
            <Label
              htmlFor="creditcard-number"
              className="events-page-account-setting-content-box-lable"
            >
              Credit Card Number
            </Label>

            <CardNumberElement
              id="creditcard-number"
              className={`custom-form-input ${
                cardFocusElemet === "card" ? "active" : ""
              }`}
              options={{
                style: {
                  base: inputStyle,
                },
              }}
              onFocus={() => {
                setCardFocusElemet("card");
              }}
              onBlur={() => {
                setCardFocusElemet("");
              }}
            />
          </FormControl>

          <Flex
            align="center"
            className="events-page-account-setting-content-box-row"
            css={{ gap: "20px" }}
          >
            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                htmlFor="creditcard-date"
                className="events-page-account-setting-content-box-lable"
              >
                Expiry Date
              </Label>
              <CardExpiryElement
                id="creditcard-date"
                className={`custom-form-input ${
                  cardFocusElemet === "date" ? "active" : ""
                }`}
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
                onFocus={() => {
                  setCardFocusElemet("date");
                }}
                onBlur={() => {
                  setCardFocusElemet("");
                }}
              />
            </FormControl>

            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                htmlFor="creditcard-cvv"
                className="events-page-account-setting-content-box-lable"
              >
                CVV
              </Label>
              <CardCvcElement
                className={`custom-form-input ${
                  cardFocusElemet === "cvv" ? "active" : ""
                }`}
                id="creditcard-cvv"
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
                onFocus={() => {
                  setCardFocusElemet("cvv");
                }}
                onBlur={() => {
                  setCardFocusElemet("");
                }}
              />
            </FormControl>
          </Flex>

          <div className="events-page-account-setting-content-box-line"></div>
          <Flex align="center" justify="center">
            <Button
              color="primary"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary"
              type="button"
              onClick={onSave}
            >
              ADD CARD
            </Button>
          </Flex>
        </div>
      </Box>
    </Flex>
  );
};

export default AccountBillingCardPopup;
