import React from "react";
export function VerticalDots() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 9.074C8.69182 9.074 9.05 8.71583 9.05 8.274C9.05 7.83217 8.69182 7.474 8.25 7.474C7.80817 7.474 7.45 7.83217 7.45 8.274C7.45 8.71583 7.80817 9.074 8.25 9.074Z"
        stroke="#8C8E95"
        strokeWidth="1.67765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 3.47399C8.69182 3.47399 9.05 3.11582 9.05 2.67399C9.05 2.23217 8.69182 1.87399 8.25 1.87399C7.80817 1.87399 7.45 2.23217 7.45 2.67399C7.45 3.11582 7.80817 3.47399 8.25 3.47399Z"
        stroke="#8C8E95"
        strokeWidth="1.67765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 14.674C8.69182 14.674 9.05 14.3158 9.05 13.874C9.05 13.4321 8.69182 13.074 8.25 13.074C7.80817 13.074 7.45 13.4321 7.45 13.874C7.45 14.3158 7.80817 14.674 8.25 14.674Z"
        stroke="#8C8E95"
        strokeWidth="1.67765"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default VerticalDots;
