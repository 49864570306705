import React from "react";

const SixCareersCalculator = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-careers-section-calculator">
      <div className="events6ix-layout-page-middle text-center">
        <div className="events-page-careers-section-calculator-media">
          <iframe
            loading="lazy"
            title="Total Rewards Calculator"
            src="https://by.6ix.com/total-rewards-calculator"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default SixCareersCalculator;
