// @ts-check
import React from "react";
import {
  InteractionClosedIcon,
  InteractionOpenIcon,
} from "@100mslive/react-icons";
import { Tooltip } from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import { useWidgetToggle } from "../AppData/useSidepane";
import { useShowPolls, useWidgetState } from "../AppData/useUISettings";

export const ToggleWidgets = () => {
  const { showPolls } = useShowPolls();

  const toggle = useWidgetToggle();
  const { widgetView } = useWidgetState();

  if (!showPolls) {
    return null;
  }
  return (
    <Tooltip
      title={widgetView ? "Close Poll" : "Open Poll"}
      boxCss={{
        zIndex: "100",
      }}
    >
      <div className="preview_tile_footer_frame">
        <IconButton
          data-testid="get_widgets"
          onClick={() => toggle()}
          css={{ color: "$on_surface_high" }}
          className={`preview_tile_footer_btn ${widgetView ? "active" : ""}`}
        >
          {widgetView ? (
            <InteractionOpenIcon style={{ color: "inherit" }} />
          ) : (
            <InteractionClosedIcon style={{ color: "inherit" }} />
          )}
        </IconButton>
        <span className="preview_tile_footer_indicator">Poll</span>
      </div>
    </Tooltip>
  );
};
