import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Box, Flex } from "@100mslive/roomkit-react";
import CompanyAbout from "./CompanyAbout";
import CompanyEvents from "./CompanyEvents";
import CompanySpeakers from "./CompanySpeakers";

const ComoanyTabs = props => {
  const { id, orgAbout, orgName, hostedEvent } = props;
  const { orgSlug, tabType } = useParams();
  const [selectedTab, setSelectedTab] = useState(tabType || "events");
  const [tabList, setTabList] = useState("");

  useEffect(() => {
    setSelectedTab(
      tabType ? tabType : hostedEvent ? "events" : orgAbout ? "about" : ""
    );
    let list = {};
    if (hostedEvent || tabType === "events") {
      list["events"] = {
        name: "Events",
        to: "events",
        component: <CompanyEvents orgId={id} orgName={orgName} />,
      };
    }
    if (hostedEvent || tabType === "speakers") {
      list["speakers"] = {
        name: "Speakers",
        to: "speakers",
        component: <CompanySpeakers orgId={id} orgName={orgName} />,
      };
    }
    if (orgAbout || tabType === "about") {
      list["about"] = {
        name: "About",
        to: "about",
        component: <CompanyAbout orgAbout={orgAbout} orgName={orgName} />,
      };
    }
    setTabList(list);
  }, [tabType, id, orgAbout, hostedEvent, orgName]);
  return (
    tabList?.[selectedTab] && (
      <React.Fragment>
        <Flex className="event-page-company-tabs">
          {Object.keys(tabList).map((item, key) => (
            <Link
              key={key}
              to={`/company/${orgSlug}/${tabList?.[item]?.to || ""}`}
              className={`event-page-company-tabs-link ${
                tabList?.[item]?.to === selectedTab ? "active" : ""
              }`}
            >
              {tabList?.[item]?.name}
            </Link>
          ))}
        </Flex>
        <Box className="event-page-company-content">
          {tabList?.[selectedTab]?.component}
        </Box>
      </React.Fragment>
    )
  );
};

export default ComoanyTabs;
