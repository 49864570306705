import React, { useEffect, useState } from "react";
import { FormControl, Input } from "@mui/material";
import { auth, signUpWithEmailPassword } from "../../../firebase";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";
import { isValidEmail } from "../../../common/utils";

const LoginWithPassword = props => {
  const { SET_USER_SESSION } = SESSION_AUTH();
  const { handleLogin } = props;
  const [user, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const login = async () => {
    setLoader(true);
    if (!name || !name.trim()) {
      ToastManager.addToast({ title: "Enter your name" });
    } else if (name.length > 42) {
      ToastManager.addToast({ title: "Your name very large" });
    } else if (!email || (email && !isValidEmail(email))) {
      ToastManager.addToast({ title: "Enter your email address" });
    } else if (!password) {
      ToastManager.addToast({ title: "Enter your password" });
    } else {
      setLoader(true);
      const data = await signUpWithEmailPassword(name, email, password);
      if (data?.code === 200 && data?.data) {
        SET_USER_SESSION(data?.data);
        if (handleLogin) handleLogin();
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);
  return (
    <>
      {(loading || loader) && <FullPageProgress fixed={true} />}
      <div className="userLogin-email custom-form">
        <FormControl className="custom-form-group">
          <Input
            placeholder="Your Name"
            className="custom-form-input"
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className="custom-form-group">
          <Input
            placeholder="your@email.com"
            className="custom-form-input"
            type="email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
        </FormControl>
        <FormControl className="custom-form-group">
          <Input
            placeholder="Your Password"
            className="custom-form-input"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
            }}
            type="password"
          />
        </FormControl>
        <button
          type="button"
          className="userLogin-email-btn"
          onClick={login}
          disabled={loader || loading}
        >
          Sign up
        </button>
      </div>
    </>
  );
};
export default LoginWithPassword;
