import { apiCall } from "./servicesCall";

export const INVESTOR_SERVICES = {
  investor(data) {
    return apiCall("/investor", data);
  },
  list(data) {
    return apiCall("/investor/list", data);
  },
  csv(data) {
    return apiCall("/investor/csv", data);
  },
  invited(data) {
    return apiCall("/investor/invited", data);
  },
  invitedList(data) {
    return apiCall("/investor/invited/list", data);
  },
  comment(data) {
    return apiCall("/investor/comment", data);
  },
  commentSave(data) {
    return apiCall("/investor/comment/save", data);
  },
};
