import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  OAuthProvider,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { ToastManager } from "./components/Toast/ToastManager";
import { USER_SERVICES } from "./services";

const firebaseConfig = {
  apiKey: "AIzaSyA7yb0AANWCaBiIDk5kpH-lyQeHeOtcLgM",
  authDomain: "authentication.6ix.com",
  projectId: "six-events",
  storageBucket: "six-events.appspot.com",
  messagingSenderId: "215382211252",
  appId: "1:215382211252:web:b3bbf8418e33745faf75e1",
  measurementId: "G-YGEEQET0LL",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
getAnalytics(app);

const googleProvider = new GoogleAuthProvider({
  prompt: "select_account",
});
const appleProvider = new OAuthProvider("apple.com");

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    if (auth.currentUser) {
      const user = auth.currentUser;
      const DATA = {
        tokenID: user.accessToken,
        email: user.email,
      };

      let data = await USER_SERVICES.loginUser(DATA);
      if (data.code === 200) {
        let d = data.data;
        d["uid"] = DATA?.uid;
        ToastManager.addToast({ title: "You are now logged in" });
        return { code: 200, data: d };
      } else {
        ToastManager.addToast({ title: data.message });
        return false;
      }
    } else {
      ToastManager.addToast({ title: "Error:  Login faild" });
      return false;
    }
  } catch (err) {
    ToastManager.addToast({ title: errorSplit(err.message) });
    return false;
  }
};
const signInWithApple = async () => {
  try {
    await signInWithPopup(auth, appleProvider);
    if (auth.currentUser) {
      const user = auth.currentUser;
      const DATA = {
        tokenID: user.accessToken,
        email: user.email,
      };
      let data = await USER_SERVICES.loginUser(DATA);
      if (data.code === 200) {
        let d = data.data;
        d["uid"] = DATA?.uid;
        ToastManager.addToast({ title: "You are now logged in" });
        return { code: 200, data: d };
      } else {
        ToastManager.addToast({ title: data.message });
        return false;
      }
    } else {
      ToastManager.addToast({ title: "Error:  Login faild" });
      return false;
    }
  } catch (err) {
    ToastManager.addToast({ title: errorSplit(err.message) });
    return false;
  }
};

const signInWithCredentialResponse = async response => {
  if (response) {
    try {
      const cred = GoogleAuthProvider.credential(response.credential);
      await signInWithCredential(auth, cred);
      if (auth.currentUser) {
        const user = auth.currentUser;
        const DATA = {
          tokenID: user.accessToken,
          email: user.email,
        };

        let data = await USER_SERVICES.loginUser(DATA);
        if (data.code === 200) {
          let d = data.data;
          d["uid"] = DATA?.uid;
          ToastManager.addToast({ title: "You are now logged in" });
          return { code: 200, data: d };
        } else {
          ToastManager.addToast({ title: data.message });
          return false;
        }
      } else {
        ToastManager.addToast({ title: "Error:  Login faild" });
        return false;
      }
    } catch (err) {
      ToastManager.addToast({ title: errorSplit(err.message) });
      return false;
    }
  } else {
    ToastManager.addToast({ title: "Error, something went wrong" });
    return false;
  }
};

const signInWithUserEmailLink = async email => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        ToastManager.addToast({
          title: "An email with the link has been sent to " + email,
        });
        return "An email with the link has been sent to " + email;
      })
      .catch(err => {
        ToastManager.addToast({ title: errorSplit(err.message) });
        return false;
      });
  } catch (err) {
    ToastManager.addToast({ title: errorSplit(err.message) });
    return false;
  }
};
const signInWithUserEmailLinkVerify = email => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }

    return signInWithEmailLink(auth, email, window.location.href)
      .then(async result => {
        if (auth.currentUser) {
          const user = auth.currentUser;
          const DATA = {
            tokenID: user.accessToken,
            email: user.email,
          };

          let data = await USER_SERVICES.loginUser(DATA);
          if (data.code === 200) {
            let d = data.data;
            d["uid"] = DATA?.uid;
            ToastManager.addToast({ title: "You are now logged in" });
            return { code: 200, data: d };
          } else {
            ToastManager.addToast({ title: data.message });
            return false;
          }
        } else {
          ToastManager.addToast({ title: "Error:  Login faild" });
          return false;
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: errorSplit(err.message) });
        return false;
      });
  } else {
    ToastManager.addToast({ title: "Error:  Something went wrong" });
    return false;
  }
};

const signInWithEmailPassword = async (email, password, reset) => {
  try {
    return signInWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        if (auth.currentUser) {
          const user = auth.currentUser;

          const DATA = {
            tokenID: user.accessToken,
            email: user.email,
            reset: reset,
          };
          let data = await USER_SERVICES.loginUser(DATA);
          if (data.code === 200) {
            let d = data.data;
            d["uid"] = DATA?.uid;

            ToastManager.addToast({ title: "You are now logged in" });
            return { code: 200, data: d };
          } else {
            ToastManager.addToast({ title: data.message });
            return false;
          }
        } else {
          ToastManager.addToast({ title: "Error:  Login faild" });
          return false;
        }
      })
      .catch(async error => {
        if (error.message === "Firebase: Error (auth/user-not-found).") {
          return await signUpWithEmailPassword("", email, password);
        } else {
          ToastManager.addToast({ title: errorSplit(error.message) });
          return false;
        }
      });
  } catch (err) {
    ToastManager.addToast({ title: errorSplit(err.message) });
    return false;
  }
};
const signUpWithEmailPassword = async (name, email, password) => {
  try {
    return createUserWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        if (auth.currentUser) {
          const user = auth.currentUser;

          const DATA = {
            tokenID: user.accessToken,
            name: name,
            email: user.email,
          };

          let data = await USER_SERVICES.loginUser(DATA);
          if (data.code === 200) {
            let d = data.data;
            d["uid"] = DATA?.uid;
            ToastManager.addToast({ title: "You are now logged in" });
            return { code: 200, data: d };
          } else {
            ToastManager.addToast({ title: data.message });
            return false;
          }
        } else {
          ToastManager.addToast({ title: "Error:  Login faild" });
          return false;
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: errorSplit(error.message) });
        return false;
      });
  } catch (err) {
    ToastManager.addToast({ title: errorSplit(err.message) });
    return false;
  }
};

const sendForgotPasswordEmail = async email => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    return sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        ToastManager.addToast({
          title: "An email with the link has been sent to " + email,
        });
        return "An email with the link has been sent to " + email;
      })
      .catch(err => {
        ToastManager.addToast({ title: errorSplit(err.message) });
        return false;
      });
  } catch (err) {
    ToastManager.addToast({ title: errorSplit(err.message) });
    return false;
  }
};

const userLogout = user => {
  signOut(auth);
  if (user?.userId) {
    USER_SERVICES.logoutUser({
      userId: user?.userId,
      org: user?.org?.id,
    });
  }
};

const errorSplit = error => {
  if (error) {
    if (error.indexOf("/") > -1) {
      error = error.split("/")[1];
      if (error.indexOf(")") > -1) {
        error = error.split(")")[0];
      }
      error = "Error : " + error;
    } else if (error.indexOf("Firebase:") > -1) {
      error = error.split("Firebase:")[1];
    }
  }
  return error;
};

export {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
  signInWithUserEmailLinkVerify,
  userLogout,
  signInWithCredentialResponse,
  signInWithApple,
  signInWithEmailPassword,
  signUpWithEmailPassword,
  sendForgotPasswordEmail,
};
