import React, { useState } from "react";
import { Link } from "react-router-dom";
import { List, ListItemButton, ListItemText, Menu } from "@mui/material";
import { AngleIcon } from "../../../icons";

const EventHeaderInvest = () => {
  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const headerMenuOpenReq = event => {
    setHeaderMenuEl(event.currentTarget);
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  return (
    <>
      <div
        className={`events6ix-layout-header-right-item-btn events6ix-layout-header-right-item-btn-full ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-investor-menu-button"
        aria-controls={headerMenuOpen ? "header-investor-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        <div className="events6ix-layout-header-right-item-btn-text">
          Research Companies
        </div>
        {headerMenuOpen ? <AngleIcon up /> : <AngleIcon small />}
      </div>

      <Menu
        id="header-investor-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-investor-menu-button",
        }}
        className="events6ix-layout-header-right-item-dropdown events6ix-layout-header-right-item-dropdown-users"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          component="nav"
          className="events6ix-layout-header-right-item-dropdown-users-list"
        >
          <ListItemButton
            className="events6ix-layout-header-right-item-dropdown-users-list-item"
            component={Link}
            to="/explore"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="events6ix-layout-header-right-item-dropdown-users-list-item-text"
              primary="Explore the Marketplace"
            />
          </ListItemButton>

          <ListItemButton
            className="events6ix-layout-header-right-item-dropdown-users-list-item"
            component={Link}
            to="https://ai.6ix.com"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="events6ix-layout-header-right-item-dropdown-users-list-item-text"
              primary="Chat with 6ix AI"
            />
          </ListItemButton>
          <ListItemButton
            className="events6ix-layout-header-right-item-dropdown-users-list-item"
            component={Link}
            to="/request-meeting"
            onClick={headerMenuClose}
          >
            <ListItemText
              className="events6ix-layout-header-right-item-dropdown-users-list-item-text"
              primary="Book 1:1 Meetings"
            />
          </ListItemButton>
        </List>
      </Menu>
    </>
  );
};

export default EventHeaderInvest;
