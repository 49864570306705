import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, TextareaAutosize } from "@mui/material";
import { RefreshIcon } from "@100mslive/react-icons";
import { Flex, Input } from "@100mslive/roomkit-react";
import { CheckmarkCicle, Colorchecked, Edit, UploadFIle } from "../../icons";
import ColorPicker from "../Common/ColorPicker";
import ImageCropper from "../Common/ImageCropper";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { USER_SERVICES } from "../../services";

export default function EventSpeakerEditProfile(props) {
  const { isUserSession } = SESSION_AUTH();
  const {
    setEditProfile,
    profileToEdit,
    speakersData,
    updateSpeakers,
    refreshProfileData,
  } = props;

  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    name: "",
    jobTitle: "",
    work: "",
    bio: "",
    companyLogoColor: process.env.REACT_APP_COLOR_DEFAULT,
    companyLogo: "",
    profileImage: "",
  });
  const [companyLogoPreview, setCompanyLogoPreview] = useState("");
  const [companyLogoColor, setCompanyLogoColor] = useState(
    speakersData?.[profileToEdit]?.companyLogoColor ||
      formValues?.companyLogoColor
  );
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const [cropProfileImage, setCropProfileImage] = useState("");
  const [cropCompanyLogo, setCropCompanyLogo] = useState("");

  useEffect(() => {
    setFormValues(speakersData[profileToEdit]);
    if (
      !speakersData[profileToEdit]?.name &&
      speakersData[profileToEdit]?.email
    ) {
      if (speakersData[profileToEdit]?.email.indexOf("@") > -1) {
        let name = speakersData[profileToEdit]?.email.split("@")[0];
        setFormValues(old => ({
          ...old,
          name: name.replace(/@.*$/, ""),
        }));
      }
    }
  }, [speakersData, profileToEdit]);
  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!companyLogo?.name) {
      setCompanyLogoPreview(formValues?.companyLogo);
      return;
    }
    const objectUrl = URL.createObjectURL(companyLogo);
    setCompanyLogoPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [companyLogo, formValues?.companyLogo]);

  const onSelectEventLogo = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setCompanyLogo("");
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropCompanyLogo(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  useEffect(() => {
    if (!profileImage?.name) {
      setProfileImagePreview(formValues?.profileImage);
      return;
    }
    const objectUrl = URL.createObjectURL(profileImage);
    setProfileImagePreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [profileImage, formValues?.profileImage]);

  const onSelectProfileImage = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setProfileImage("");
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropProfileImage(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  const saveProfile = async () => {
    if (!formValues?.name) {
      ToastManager.addToast({ title: "Please fill speaker name" });
      return;
    }
    setLoading(true);
    if (refreshProfileData?.companyLogo) {
      updateSpeakers(
        profileToEdit,
        "companyLogo",
        refreshProfileData?.companyLogo
      );
    }
    if (refreshProfileData?.profileImage) {
      updateSpeakers(
        profileToEdit,
        "profileImage",
        refreshProfileData.profileImage
      );
    }
    if (companyLogo?.name || profileImage?.name) {
      const uploaddData = new FormData();

      if (companyLogo?.name) {
        uploaddData.append("companyLogo", companyLogo, companyLogo.name);
      }
      if (profileImage?.name) {
        uploaddData.append("profileImage", profileImage, profileImage.name);
      }
      uploaddData.append("userId", userData?.userId);
      uploaddData.append("org", userData?.org?.id);

      try {
        const data = await USER_SERVICES.speakersUploadMedia(uploaddData);

        if (data.code === 200) {
          if (data.companyLogo) {
            updateSpeakers(profileToEdit, "companyLogo", data.companyLogo);
          }
          if (data.profileImage) {
            updateSpeakers(profileToEdit, "profileImage", data.profileImage);
          }
        } else if (data.code === 600) {
          navigate(0);
        }
        if (data.message) {
          ToastManager.addToast({ title: data.message });
        }
      } catch (e) {
        ToastManager.addToast({ title: "Error Something went wrong" });
        navigate(0);
      }
    }

    updateSpeakers(profileToEdit, "userId", formValues.userId);
    updateSpeakers(profileToEdit, "name", formValues.name);
    updateSpeakers(profileToEdit, "phoneNumber", formValues.phoneNumber);
    updateSpeakers(profileToEdit, "jobTitle", formValues.jobTitle);
    updateSpeakers(profileToEdit, "work", formValues.work);
    updateSpeakers(profileToEdit, "bio", formValues.bio);
    updateSpeakers(profileToEdit, "companyLogoColor", companyLogoColor);
    setEditProfile(false);
  };

  const updateRefreshProfile = () => {
    setFormValues({
      ...formValues,
      name: refreshProfileData?.name,
      bio: refreshProfileData?.bio,
      companyLogo: refreshProfileData?.companyLogo,
      jobTitle: refreshProfileData?.jobTitle,
      phoneNumber: refreshProfileData?.phoneNumber,
      profileImage: refreshProfileData?.profileImage,
      userId: refreshProfileData?.userId,
      work: refreshProfileData?.work,
    });
    setCompanyLogoColor(refreshProfileData?.companyLogoColor);
  };
  return (
    <React.Fragment>
      <Flex
        align="center"
        direction="column"
        className="eventsPopup eventsPopup-sm"
      >
        <div className="eventsPopup-inner">
          {loading && <FullPageProgress fixed={true} />}
          {refreshProfileData ? (
            <div className="eventsPopup-header text-right">
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                type="button"
                className="events6ix-btn events6ix-btn-secondary events6ix-btn-sm"
                onClick={() => updateRefreshProfile()}
                startIcon={<RefreshIcon />}
              >
                Refresh Profile
              </Button>
            </div>
          ) : null}
          <div className="eventsPopup-content">
            <form className="custom-form">
              <FormControl className="custom-form-group">
                <label htmlFor="email" className="custom-form-label big-label">
                  Email
                </label>
                <Input
                  id="email"
                  name="email"
                  disabled
                  placeholder="Enter Your EMail"
                  className="custom-form-input"
                  value={speakersData[profileToEdit].email}
                />
                <div
                  className="custom-form-icon"
                  title={
                    formValues?.userId
                      ? "Registered on 6ix.com"
                      : "Not registered on 6ix.com"
                  }
                >
                  {formValues?.userId ? <Colorchecked /> : <CheckmarkCicle />}
                </div>
              </FormControl>
              <FormControl className="custom-form-group">
                <label htmlFor="name" className="custom-form-label big-label">
                  Name
                </label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  className="custom-form-input"
                  value={formValues?.name}
                  onChange={e =>
                    setFormValues({ ...formValues, name: e.target.value })
                  }
                />
              </FormControl>

              <FormControl className="custom-form-group">
                <label
                  htmlFor="phoneNumber"
                  className="custom-form-label big-label"
                >
                  Phone Number
                </label>
                <Input
                  id="phoneNumber"
                  placeholder="Phone number? (+1xxxxxxxxxx)"
                  name="phoneNumber"
                  className="custom-form-input"
                  value={formValues?.phoneNumber}
                  onChange={e =>
                    setFormValues({
                      ...formValues,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </FormControl>
              <FormControl className="custom-form-group">
                <label
                  htmlFor="jobTitle"
                  className="custom-form-label big-label"
                >
                  Job title
                </label>
                <Input
                  id="jobTitle"
                  name="jobTitle"
                  placeholder="Job title"
                  className="custom-form-input"
                  value={formValues?.jobTitle}
                  onChange={e =>
                    setFormValues({ ...formValues, jobTitle: e.target.value })
                  }
                />
              </FormControl>
              <FormControl className="custom-form-group">
                <label htmlFor="work" className="custom-form-label big-label">
                  Company
                </label>
                <Input
                  id="work"
                  name="work"
                  placeholder="Company"
                  className="custom-form-input"
                  value={formValues?.work}
                  onChange={e =>
                    setFormValues({ ...formValues, work: e.target.value })
                  }
                />
              </FormControl>

              <FormControl className="custom-form-group">
                <label
                  htmlFor="event-name"
                  className="custom-form-label big-label"
                >
                  Bio
                </label>

                <TextareaAutosize
                  id="user-bio"
                  name="bio"
                  value={formValues?.bio}
                  onChange={e =>
                    setFormValues({
                      ...formValues,
                      bio: e.target.value,
                    })
                  }
                  className="custom-form-input"
                  placeholder="Enter Bio"
                  minRows={2}
                  maxRows={3}
                />
              </FormControl>

              <Flex css={{ margin: "10px 0px" }}>
                <FormControl className="custom-form-group">
                  <label
                    htmlFor="event-name"
                    className="custom-form-label big-label"
                  >
                    Profile Image
                  </label>

                  <label
                    htmlFor="profile-image"
                    className="custom-form-file mt-10"
                    translate="no"
                  >
                    <Input
                      name="profileImage"
                      type="file"
                      id="profile-image"
                      accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                      className="custom-form-file-input"
                      onChange={onSelectProfileImage}
                    />

                    {profileImagePreview ? (
                      <>
                        <div className="custom-form-file-preview custom-form-file-preview-round">
                          <img src={profileImagePreview} alt="" />
                        </div>
                        <Edit />
                      </>
                    ) : (
                      <>
                        <UploadFIle /> <span translate="yes">Upload</span>
                      </>
                    )}
                  </label>
                </FormControl>

                <FormControl className="custom-form-group">
                  <label
                    htmlFor="event-name"
                    className="custom-form-label big-label"
                  >
                    Company Logo
                  </label>
                  <Flex align="center" css={{ gap: "15px" }}>
                    <label
                      translate="no"
                      htmlFor="company-logo"
                      className="custom-form-file mt-10"
                    >
                      <Input
                        type="file"
                        name="companyLogo"
                        id="company-logo"
                        accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                        className="custom-form-file-input"
                        onChange={onSelectEventLogo}
                      />

                      {companyLogoPreview ? (
                        <>
                          <div
                            className="custom-form-file-preview"
                            style={{ backgroundColor: companyLogoColor }}
                          >
                            <img src={companyLogoPreview} alt="" />
                          </div>
                          <Edit />
                        </>
                      ) : (
                        <>
                          <UploadFIle /> <span translate="yes">Upload</span>
                        </>
                      )}
                    </label>
                    {companyLogoPreview ? (
                      <ColorPicker
                        title="Logo Background Color"
                        selectedColor={companyLogoColor}
                        setSelectedColor={setCompanyLogoColor}
                      />
                    ) : null}
                  </Flex>
                </FormControl>
              </Flex>
            </form>
          </div>
          <div className="eventsPopup-footer">
            <Button
              color="secondary"
              variant="outlined"
              type="button"
              className="events6ix-btn events6ix-btn-secondary"
              onClick={() => setEditProfile(false)}
            >
              &nbsp; Cancel &nbsp;
            </Button>
            <Button
              color="primary"
              variant="outlined"
              type="button"
              className="events6ix-btn events6ix-btn-primary ml-20"
              onClick={saveProfile}
            >
              &nbsp; Save &nbsp;
            </Button>
          </div>
        </div>
      </Flex>

      {cropProfileImage && (
        <ImageCropper
          open={true}
          data={{
            image: cropProfileImage,
            title: "Profile image",
            minWidth: 80,
            minHeight: 80,
            ratio: 1 / 1,
            feature: ["circle", "square", "freedom"],
          }}
          onCancel={() => {
            setCropProfileImage("");
            onSelectProfileImage();
          }}
          onUpdate={image => {
            setProfileImage(image);
            setCropProfileImage("");
          }}
        />
      )}
      {cropCompanyLogo && (
        <ImageCropper
          open={true}
          data={{
            image: cropCompanyLogo,
            title: "Company logo",
            minWidth: 60,
            minHeight: 60,
            ratio: 1 / 1,
            feature: ["square", "freedom"],
          }}
          onCancel={() => {
            setCropCompanyLogo("");
            onSelectEventLogo();
          }}
          onUpdate={image => {
            setCompanyLogo(image);
            setCropCompanyLogo("");
          }}
        />
      )}
    </React.Fragment>
  );
}
