import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import { Box } from "@100mslive/roomkit-react";
import { LogoIcon, YourLogo } from "../../icons";

const EventPreviewBox = props => {
  const {
    eventCoverPreview,
    eventName,
    eventDate,
    eventTBD,
    hostedBy,
    eventHostBy,
    eventLogo,
    eventLogoColor,
  } = props;
  const [hostedLabel, setHostedLabel] = useState("");
  const [hostedLogos, setHostedLogos] = useState("");

  useEffect(() => {
    let hostText = "";
    let hostLogo = [];
    if (hostedBy?.length > 0) {
      for (let value of hostedBy) {
        if (value?.allow) {
          hostText += hostText ? ", " + value?.orgName : value?.orgName;
          hostLogo.push(value);
        }
      }
    } else {
      if (eventHostBy) {
        hostText = eventHostBy;
      }

      if (eventLogo) {
        hostLogo.push({
          orgLogo: eventLogo,
          orgLogoColor: eventLogoColor,
        });
      }
    }
    setHostedLabel(hostText);
    setHostedLogos(hostLogo);
  }, [hostedBy, eventHostBy, eventLogo, eventLogoColor]);
  return (
    <Box
      className="home-page-layout-content-preview"
      style={{
        backgroundImage: eventCoverPreview ? `url(${eventCoverPreview})` : "",
      }}
    >
      <div className="home-page-layout-content-preview-logo">
        {hostedLogos?.length > 0 ? (
          hostedLogos.map((item, key) => {
            return (
              <Link
                title={item?.orgName}
                to={item?.orgSlug ? `/company/${item?.orgSlug}` : null}
                key={key}
                className="home-page-layout-content-preview-logo-img"
                style={{ backgroundColor: item?.orgLogoColor }}
              >
                {item?.orgLogo ? (
                  <img
                    src={item?.orgLogo}
                    alt={`Logo - ${item?.orgName} `}
                    width={50}
                    height={50}
                  />
                ) : (
                  <LogoIcon />
                )}
              </Link>
            );
          })
        ) : (
          <YourLogo />
        )}
      </div>
      <div className="home-page-layout-content-preview-content">
        <div className="home-page-layout-content-preview-content-title">
          {eventName ? eventName : "Your Title"}
        </div>
        <div className="home-page-layout-content-preview-content-text">
          {eventDate
            ? Moment(eventDate).format("ddd, MMM DD, YYYY : hh:mm A")
            : eventTBD
            ? "TBD"
            : null}
        </div>
      </div>
      {(hostedLabel || hostedBy) && (
        <div className="home-page-layout-content-preview-host">
          Hosted by{" "}
          <b>
            {hostedBy
              ? hostedBy?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Link
                      className="text-link"
                      to={`/company/${item?.orgSlug}`}
                    >
                      {item?.orgName}
                    </Link>
                    {index !== hostedBy.length - 1 && ", "}
                  </React.Fragment>
                ))
              : hostedLabel}
          </b>
        </div>
      )}
    </Box>
  );
};

export default EventPreviewBox;
