import { Fragment, useState } from "react";
import { useMedia } from "react-use";
import { CrossIcon } from "@100mslive/react-icons";
import { config as cssConfig, Flex, Text } from "@100mslive/roomkit-react";
import IconButton from "../../IconButton";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { SIDE_PANE_OPTIONS } from "../../common/constants";

export const WidgetScreen = props => {
  const { hasWidget } = props;
  const [selected, setSelected] = useState(0);
  const toggleWidget = useSidepaneToggle(SIDE_PANE_OPTIONS.WIDGET);

  const isMobile = useMedia(cssConfig.media.md);
  return (
    <Fragment>
      <Flex direction="column" css={{ size: "100%" }}>
        {isMobile && (
          <IconButton
            onClick={toggleWidget}
            css={{ w: "$11", h: "$11", ml: "auto", mt: "-10px", mb: "10px" }}
          >
            <CrossIcon />
          </IconButton>
        )}
        <Flex align="center" css={{ w: "100%", mb: "$10" }}>
          {/* <Text css={{ fontWeight: "$semiBold", mr: "$4" }}>Widget</Text> */}
          <div className="preview-widget-menu">
            {hasWidget?.map((m, index) => (
              <div
                key={index}
                className={`preview-widget-menu-link ${
                  selected === index ? " active " : ""
                } `}
                onClick={() => setSelected(index)}
              >
                {m?.name}
              </div>
            ))}
          </div>
        </Flex>
        <Flex
          css={{
            width: "100%",
            height: "100%",
            textAlign: "center",
          }}
          align="center"
          justify="center"
        >
          {hasWidget?.[selected]?.url ? (
            <iframe
              title="Event Widget"
              width="100%"
              height="100%"
              src={hasWidget?.[selected]?.url}
            ></iframe>
          ) : (
            <Text>There are no widget here</Text>
          )}
        </Flex>
      </Flex>
    </Fragment>
  );
};
