import React from "react";
export function LinkedInIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3361_104678)">
        <path
          d="M6.74687 15.801H6.91354V15.6344V7.99687V7.83021H6.74687H4.37188H4.20521V7.99687V15.6344V15.801H4.37188H6.74687ZM15.6344 15.801H15.801V15.6344V11.4438C15.801 10.412 15.6916 9.46041 15.2531 8.76501C14.8035 8.052 14.0301 7.63958 12.7875 7.63958C11.8278 7.63958 11.1088 8.0564 10.6792 8.55227V7.99687V7.83021H10.5125H8.2375H8.07083V7.99687V15.6344V15.801H8.2375H10.6062H10.7729V15.6344V11.8594C10.7729 11.3639 10.8211 10.9107 11.0004 10.5846C11.0878 10.4254 11.2058 10.298 11.3661 10.2091C11.5273 10.1196 11.7412 10.0635 12.0281 10.0635C12.3062 10.0635 12.5004 10.1283 12.6397 10.2255C12.7793 10.3231 12.878 10.4634 12.9473 10.6389C13.0898 10.9997 13.0958 11.4723 13.0958 11.9219V15.6344V15.801H13.2625H15.6344ZM3.18125 2.16667H16.8156C17.3811 2.16667 17.8333 2.61199 17.8333 3.15313V16.8469C17.8333 17.3852 17.3808 17.8333 16.8156 17.8333H3.18125C2.61696 17.8333 2.16667 17.386 2.16667 16.8438V3.15313C2.16667 2.61143 2.61637 2.16667 3.18125 2.16667ZM4.01458 5.58125C4.01458 6.43292 4.70507 7.12292 5.55938 7.12292C6.41104 7.12292 7.10104 6.4293 7.10104 5.58125C7.10104 4.72983 6.4108 4.03958 5.55938 4.03958C4.70507 4.03958 4.01458 4.72958 4.01458 5.58125Z"
          fill="#C0C0C0"
          stroke="#C0C0C0"
          strokeWidth="0.333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_3361_104678">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default LinkedInIcon;
