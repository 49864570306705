import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { config as cssConfig, Flex } from "@100mslive/roomkit-react";
import { SESSION_AUTH } from "../../common/auth";
import "./style.scss";

const CommonMenu = () => {
  const { isUserSession } = SESSION_AUTH();
  const isMobile = useMedia(cssConfig.media.md);
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <Flex align="center" className="events6ix-layout-page-header-left">
        <div className="events6ix-layout-page-header-menu">
          <NavLink
            to="/overview"
            className="events6ix-layout-page-header-menu-link"
          >
            Overview
          </NavLink>
          {isUserSession ? (
            <NavLink
              to="/dashboard"
              className="events6ix-layout-page-header-menu-link"
            >
              Dashboard
            </NavLink>
          ) : null}
          <NavLink
            to="/explore"
            className={`events6ix-layout-page-header-menu-link ${
              ["/", "/explore"].includes(pathname) ? "active" : ""
            }`}
          >
            Explore
          </NavLink>
          {isUserSession ? (
            <NavLink
              to="/investors"
              className="events6ix-layout-page-header-menu-link"
            >
              Report
            </NavLink>
          ) : null}
        </div>
      </Flex>
      {!isMobile ? (
        <Flex className="events6ix-layout-page-header-right" align="center">
          <Button
            component={Link}
            to="/create"
            color="primary"
            variant="outlined"
            className="events6ix-btn events6ix-btn-primary"
          >
            Create Event
          </Button>
        </Flex>
      ) : null}
    </React.Fragment>
  );
};
const SixMenu = () => {
  const isMobile = useMedia(cssConfig.media.md);

  return (
    <React.Fragment>
      <Flex align="center" className="events6ix-layout-page-header-left">
        <div className="events6ix-layout-page-header-menu">
          <NavLink
            to="/"
            className="events6ix-layout-page-header-menu-link"
            relative="route"
          >
            Why 6ix?
          </NavLink>

          <NavLink
            to="/pricing"
            className="events6ix-layout-page-header-menu-link"
          >
            Pricing
          </NavLink>

          <NavLink
            to="/about"
            className="events6ix-layout-page-header-menu-link"
          >
            About
          </NavLink>
        </div>
      </Flex>
      {!isMobile ? (
        <Flex className="events6ix-layout-page-header-right" align="center">
          <NavLink
            to="/demo"
            className="events6ix-layout-page-header-menu-link"
          >
            View Demo
          </NavLink>
          <Button
            component={Link}
            to="/create"
            color="primary"
            variant="outlined"
            className="events6ix-btn events6ix-btn-primary"
          >
            GET STARTED
          </Button>
        </Flex>
      ) : null}
    </React.Fragment>
  );
};
const CustomMenu = ({ text }) => {
  return (
    <React.Fragment>
      <Flex align="center" className="events6ix-layout-page-header-left">
        <div className="events6ix-layout-page-header-menu">
          <NavLink
            to={null}
            className="events6ix-layout-page-header-menu-link active"
          >
            {text}
          </NavLink>
        </div>
      </Flex>
    </React.Fragment>
  );
};

const DashboardMenu = () => {
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <React.Fragment>
      <Flex align="center" className="events6ix-layout-page-header-left">
        <Button
          component={Link}
          to="/dashboard"
          color="light"
          variant="outlined"
          className="events6ix-btn events6ix-btn-light"
          startIcon={<ArrowBack />}
        >
          Back To Dashboard
        </Button>
      </Flex>
      {!isMobile ? (
        <Flex className="events6ix-layout-page-header-right" align="center">
          <Button
            component={Link}
            to="/create"
            color="primary"
            variant="outlined"
            className="events6ix-btn events6ix-btn-primary"
          >
            Create Event
          </Button>
        </Flex>
      ) : null}
    </React.Fragment>
  );
};
const OverviewMenu = () => {
  return (
    <React.Fragment>
      <Flex align="center" className="events6ix-layout-page-header-left">
        <Button
          component={Link}
          to="/overview"
          color="light"
          variant="outlined"
          className="events6ix-btn events6ix-btn-light"
          startIcon={<ArrowBack />}
        >
          Back To Overview
        </Button>
      </Flex>
    </React.Fragment>
  );
};

const PageHeader = ({ type, text = "" }) => {
  return (
    <Flex
      justify="between"
      align="center"
      className="events6ix-layout-page-header"
    >
      {type === "dashboard" ? (
        <DashboardMenu />
      ) : type === "overview" ? (
        <OverviewMenu />
      ) : type === "6ix" ? (
        <SixMenu />
      ) : text ? (
        <CustomMenu text={text} />
      ) : (
        <CommonMenu />
      )}
    </Flex>
  );
};

export default PageHeader;
