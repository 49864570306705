import React from "react";

export function StockIcon() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.25" y="4.5" width="2" height="9" rx="0.75" fill="#C0C0C0" />
      <rect x="5.75" y="0.5" width="2" height="13" rx="0.75" fill="#C0C0C0" />
      <rect x="10.25" y="6.5" width="2" height="7" rx="0.75" fill="#C0C0C0" />
      <rect x="14.75" y="3.5" width="2" height="10" rx="0.75" fill="#C0C0C0" />
      <rect y="15" width="18" height="1.5" rx="0.5" fill="#C0C0C0" />
    </svg>
  );
}

export default StockIcon;
