import React from "react";
import { useMedia } from "react-use";
import { Button, FormControl } from "@mui/material";
import {
  Box,
  config as cssConfig,
  Flex,
  Input,
  Label,
  Text,
} from "@100mslive/roomkit-react";
import { Edit, UploadFIle } from "../../../icons";
import ColorPicker from "../../Common/ColorPicker";
import ImageCropper from "../../Common/ImageCropper";

const AccountLogo = props => {
  const {
    selectLogo,
    orgLogoColor,
    setOrgLogoColor,
    onSelectOrgLogo,
    orgLogoPreview,
    isEditAccess,
    saveSetting,
    settingData,
    cropOrgLogo,
    setOrgLogo,
    setCropOrgLogo,
    setSelectLogo,
  } = props;

  const isMobile = useMedia(cssConfig.media.md);

  return (
    <>
      <div className="events-page-account-setting-content-row">
        <Box className="events-page-account-setting-content-box">
          <div className="events-page-account-setting-content-box-title">
            Organization Logo
          </div>
          <FormControl className="events-page-account-setting-content-box-control">
            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Label
                css={{ flex: 1 }}
                className="events-page-account-setting-content-box-lable"
              >
                This is your organization’s logo.
                {!isMobile && <br />}
                {isEditAccess && (
                  <>
                    Click on the avatar to upload a custom one from your files.
                  </>
                )}
              </Label>
              <Flex align="center" css={{ gap: "15px" }}>
                {isEditAccess && (
                  <ColorPicker
                    title="Logo Background Color"
                    selectedColor={orgLogoColor}
                    setSelectedColor={setOrgLogoColor}
                  />
                )}
                <label
                  htmlFor="org-logo"
                  className="custom-form-file events-page-account-setting-content-box-logo"
                  translate="no"
                >
                  {isEditAccess && (
                    <Input
                      type="file"
                      id="org-logo"
                      accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                      className="custom-form-file-input"
                      onChange={onSelectOrgLogo}
                    />
                  )}

                  {orgLogoPreview ? (
                    <>
                      <div
                        className="custom-form-file-preview events-page-account-setting-content-box-logo-preview"
                        style={{ backgroundColor: orgLogoColor }}
                      >
                        <img src={orgLogoPreview} alt="logo" />
                      </div>
                      {isEditAccess && <Edit />}
                    </>
                  ) : isEditAccess ? (
                    <>
                      <UploadFIle /> <span translate="yes">Upload logo</span>
                    </>
                  ) : null}
                </label>
              </Flex>
            </Flex>
          </FormControl>
          {isEditAccess && (
            <>
              <div className="events-page-account-setting-content-box-line"></div>

              <Flex
                align="center"
                className="events-page-account-setting-content-box-row"
              >
                <Text
                  css={{
                    flex: "1 1 0",
                  }}
                  className="events-page-account-setting-content-box-text"
                >
                  An avatar is optional but strongly recommended.
                </Text>
                {selectLogo ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    className="events6ix-btn events6ix-btn-primary"
                    type="button"
                    onClick={() => saveSetting("orgLogo")}
                  >
                    Save
                  </Button>
                ) : settingData?.orgLogoColor !== orgLogoColor ? (
                  <Button
                    color="primary"
                    variant="outlined"
                    className="events6ix-btn events6ix-btn-primary"
                    type="button"
                    onClick={() => saveSetting("orgLogoColor")}
                  >
                    Save
                  </Button>
                ) : null}
              </Flex>
            </>
          )}
        </Box>
      </div>
      {cropOrgLogo && (
        <ImageCropper
          open={true}
          data={{
            image: cropOrgLogo,
            title: "Organization logo",
            minWidth: 60,
            minHeight: 60,
            ratio: 1 / 1,
            feature: ["circle", "square", "freedom"],
          }}
          onCancel={() => {
            setCropOrgLogo("");
            onSelectOrgLogo();
          }}
          onUpdate={image => {
            setOrgLogo(image);
            setCropOrgLogo("");
            setSelectLogo(true);
          }}
        />
      )}
    </>
  );
};

export default AccountLogo;
