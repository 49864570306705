import React, { useRef, useState } from "react";
import ColorPickerMenu from "color-picker-menu";

const ColorPicker = props => {
  const { title, selectedColor, setSelectedColor, itemsPerRow, defaultFormat } =
    props;
  const colorsList = [
    "#e3C050",
    "#66FF7D",
    "#131313",
    "#FFFFFF",
    "#A366FF",
    "#FF66D9",
  ];
  const colorPickerButtonRef = useRef(null);
  const [displayColorPickerMenu, setDisplayColorPickerMenu] = useState(false);
  const handleColorChange = color => {
    setSelectedColor(color.hex);
  };
  const hideColorPickerMenu = () => {
    setDisplayColorPickerMenu(false);
  };
  return (
    <div className="eventsColorPicker">
      <div
        title={title || ""}
        style={{ backgroundColor: selectedColor }}
        className="eventsColorPicker-btn"
        ref={colorPickerButtonRef}
        onClick={() => {
          setDisplayColorPickerMenu(!displayColorPickerMenu);
        }}
      ></div>
      {displayColorPickerMenu && (
        <ColorPickerMenu
          className="eventsColorPicker-menu"
          ref={colorPickerButtonRef}
          theme="dark"
          colors={colorsList || []}
          selected={selectedColor}
          removeColor="transparent"
          //enableAddNew={false}
          defaultFormat={defaultFormat || "hex"}
          allowedFormats={["hex", "rgb", "hsl"]}
          itemsPerRow={itemsPerRow || 6}
          onChange={handleColorChange}
          onHide={hideColorPickerMenu}
        />
      )}
    </div>
  );
};

export default ColorPicker;
