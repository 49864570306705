import React from "react";

export function BarChartIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.56804 7.03076L8.40137 7.03076"
        stroke="white"
        strokeWidth="0.868603"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.56803 10.5308L11.9014 10.5308"
        stroke="white"
        strokeWidth="0.868603"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.56803 3.53125L4.90137 3.53125"
        stroke="white"
        strokeWidth="0.868603"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default BarChartIcon;
