import React from "react";
import { Button, FormControl, TextareaAutosize } from "@mui/material";
import { Box, Flex, Text } from "@100mslive/roomkit-react";

const AccountAbout = props => {
  const { orgAbout, setOrgAbout, isEditAccess, saveSetting } = props;

  return (
    <div className="events-page-account-setting-content-row">
      <Box className="events-page-account-setting-content-box">
        <div className="events-page-account-setting-content-box-title">
          About Organization
        </div>
        <FormControl className="events-page-account-setting-content-box-control">
          <TextareaAutosize
            readOnly={!isEditAccess}
            id="org-about"
            name="orgAbout"
            value={orgAbout}
            onChange={e => {
              if (isEditAccess)
                setOrgAbout(e.target.value?.replace(/(<([^>]+)>)/gi, ""));
            }}
            className="custom-form-input"
            placeholder="Enter about organization "
            minRows={3}
            maxRows={5}
            maxLength={1000}
          />
        </FormControl>

        {isEditAccess && (
          <>
            <div className="events-page-account-setting-content-box-line"></div>

            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Text
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text"
              >
                Please use 1000 characters at maximum.
              </Text>
              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                onClick={() => saveSetting("orgAbout")}
              >
                Save
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </div>
  );
};

export default AccountAbout;
