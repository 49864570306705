import { apiCall } from "./servicesCall";

export const SENDGRID_SERVICES = {
  senderList(data) {
    return apiCall("/sendgrid/sender", data);
  },
  list(data) {
    return apiCall("/sendgrid/list", data);
  },
  verifykey(data) {
    return apiCall("/sendgrid/verifykey", data);
  },
  save(data) {
    return apiCall("/sendgrid/save", data);
  },
};
