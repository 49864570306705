import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { Box } from "@100mslive/roomkit-react";
import { CheckMark, GlobeIcon } from "../../../icons";
import FullPageProgress from "../../FullPageProgress";
import { SESSION_AUTH } from "../../../common/auth";
import { GOOGLE_LANGUAGE_LIST } from "../../../common/utils";
const POPULAR_LANG = GOOGLE_LANGUAGE_LIST.filter(item => {
  return item?.popular;
});
const EventFooterLanguage = () => {
  const navigate = useNavigate();
  const [headerOrgEl, setHeaderOrgEl] = useState(null);
  const headerOrgOpen = Boolean(headerOrgEl);
  const scriptRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const { translateCookies, setTranslateCookies } = SESSION_AUTH();
  const [selectedLang, setSlectedLang] = useState("en");
  const headerOrgMenuOpen = event => {
    setHeaderOrgEl(event.currentTarget);
  };
  const headerOrgMenuClose = () => {
    setHeaderOrgEl(null);
  };
  useEffect(() => {
    if (translateCookies) {
      setLoading(true);
      if (translateCookies.indexOf("/") > -1) {
        let s = translateCookies.split("/");
        setSlectedLang(s[s.length - 1]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    } else {
      setLoading(false);
    }
  }, [translateCookies, navigate]);

  useEffect(() => {
    if (!scriptRef.current) {
      const script = document.createElement("script");
      script.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
      scriptRef.current = script;
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, []);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  const switchLanguage = item => {
    headerOrgMenuClose();
    if (item?.lang) {
      //setLoading(true);
      setTranslateCookies("/en/" + item?.lang);
      let container = document.getElementById("google_translate_element");
      let select = container.getElementsByTagName("select")[0];
      select.value = item?.lang;
      select.dispatchEvent(new Event("change"));
      //setSlectedLang(item?.lang);

      // setTimeout(() => {
      //   setLoading(false);
      // }, 2000);
    }
  };

  return (
    <>
      <Box id="google_translate_element" css={{ display: "none" }} />

      <div
        className={`events6ix-layout-footer-cmsmenu-link-btn ${
          headerOrgOpen ? "active" : ""
        }`}
        id="footer-language-menu-button"
        aria-controls={headerOrgOpen ? "footer-language-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerOrgOpen ? "true" : undefined}
        onClick={headerOrgMenuOpen}
        translate="no"
      >
        <GlobeIcon />
        {selectedLang ? <span>{selectedLang}</span> : <span>EN</span>}
        {headerOrgOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      </div>

      <Menu
        id="footer-language-menu"
        anchorEl={headerOrgEl}
        open={headerOrgOpen}
        onClose={headerOrgMenuClose}
        MenuListProps={{
          "aria-labelledby": "footer-language-menu-button",
        }}
        className="events6ix-layout-footer-cmsmenu-link-dropdown events6ix-layout-footer-cmsmenu-link-dropdown-language"
        elevation={0}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div
          className="events6ix-layout-footer-cmsmenu-link-dropdown-language-header"
          translate="no"
        >
          Most Popular
        </div>
        <div className="events6ix-layout-footer-cmsmenu-link-dropdown-language-inner">
          {POPULAR_LANG.map((item, key) => (
            <MenuItem
              key={key}
              className={`events6ix-layout-footer-cmsmenu-link-dropdown-language-item ${
                selectedLang === item?.lang ? "active" : ""
              } `}
              onClick={() => {
                if (selectedLang !== item?.lang) {
                  switchLanguage(item);
                }
              }}
              title={item?.name}
              translate="no"
            >
              <div className="events6ix-layout-footer-cmsmenu-link-dropdown-language-item-check">
                {selectedLang === item?.lang && <CheckMark />}{" "}
              </div>
              {item?.name}
            </MenuItem>
          ))}
        </div>
        <div
          className="events6ix-layout-footer-cmsmenu-link-dropdown-language-header"
          translate="no"
        >
          All languages
        </div>
        <div className="events6ix-layout-footer-cmsmenu-link-dropdown-language-inner">
          {GOOGLE_LANGUAGE_LIST.map((item, key) => (
            <MenuItem
              key={key}
              className={`events6ix-layout-footer-cmsmenu-link-dropdown-language-item ${
                selectedLang === item?.lang ? "active" : ""
              } `}
              onClick={() => {
                if (selectedLang !== item?.lang) {
                  switchLanguage(item);
                }
              }}
              title={item?.name}
              translate="no"
            >
              <div className="events6ix-layout-footer-cmsmenu-link-dropdown-language-item-check">
                {selectedLang === item?.lang && <CheckMark />}{" "}
              </div>
              {item?.name}
            </MenuItem>
          ))}
        </div>
      </Menu>

      {loading && (
        <FullPageProgress
          background="#000"
          zindex={99999}
          backdropfilter="blur(3px)"
          fixed={true}
        />
      )}
    </>
  );
};

export default EventFooterLanguage;
