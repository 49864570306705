import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Flex } from "@100mslive/roomkit-react";
import { SESSION_AUTH } from "../../../common/auth";
import "./style.scss";

const AccountSettingsTopbar = () => {
  const { isUserSession } = SESSION_AUTH();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [isUserSession]);

  return userData ? (
    <Flex className="events6ix-layout-page-top">
      <h1 className="events6ix-layout-page-top-heading events6ix-layout-page-top-heading-normal">
        Organization Settings
      </h1>
      <Button
        component={Link}
        size="small"
        to={`/company/${userData?.org?.orgSlug}`}
        target="_blank"
        variant="text"
        endIcon={<OpenInNew />}
        sx={{ textTransform: "none" }}
        className="events6ix-btn events6ix-btn-sm"
      >
        Public view
      </Button>
    </Flex>
  ) : null;
};

export default AccountSettingsTopbar;
