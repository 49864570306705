import React from "react";
export function Phone(props) {
  const { stroke } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.596 15.2864L18.596 17.5597C18.5968 17.7708 18.5536 17.9797 18.469 18.173C18.3845 18.3664 18.2605 18.54 18.105 18.6827C17.9495 18.8253 17.7659 18.9339 17.566 19.0016C17.366 19.0692 17.1542 19.0943 16.944 19.0753C14.6123 18.8219 12.3724 18.0251 10.4045 16.7489C8.5736 15.5855 7.02132 14.0332 5.85789 12.2023C4.57724 10.2255 3.78027 7.97473 3.53154 5.63248C3.5126 5.42293 3.53751 5.21173 3.60467 5.01234C3.67182 4.81294 3.77976 4.62971 3.92161 4.47432C4.06346 4.31893 4.23612 4.19477 4.42858 4.10976C4.62104 4.02475 4.8291 3.98074 5.0395 3.98054L7.3128 3.98054C7.68055 3.97692 8.03707 4.10715 8.31591 4.34695C8.59474 4.58675 8.77687 4.91976 8.82834 5.2839C8.92429 6.01141 9.10223 6.72573 9.35878 7.41323C9.46073 7.68445 9.48279 7.97922 9.42236 8.2626C9.36192 8.54598 9.22152 8.80609 9.01778 9.01212L8.05541 9.97449C9.13414 11.8716 10.7049 13.4424 12.602 14.5211L13.5644 13.5587C13.7704 13.355 14.0305 13.2146 14.3139 13.1542C14.5973 13.0937 14.8921 13.1158 15.1633 13.2177C15.8508 13.4743 16.5651 13.6522 17.2926 13.7482C17.6607 13.8001 17.9969 13.9855 18.2372 14.2691C18.4775 14.5528 18.6052 14.9148 18.596 15.2864Z"
        stroke={stroke}
        strokeWidth="1.51554"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default Phone;
