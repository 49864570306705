import React from "react";
export function VideoIcon({ on }) {
  return on ? (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3322 5.16565L10.6655 8.49898L15.3322 11.8323V5.16565Z"
        stroke="#E9E9E9"
        strokeWidth="0.999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33218 3.83435H1.99886C1.26248 3.83435 0.665527 4.4313 0.665527 5.16768V11.8343C0.665527 12.5707 1.26248 13.1677 1.99886 13.1677H9.33218C10.0686 13.1677 10.6655 12.5707 10.6655 11.8343V5.16768C10.6655 4.4313 10.0686 3.83435 9.33218 3.83435Z"
        stroke="#E9E9E9"
        strokeWidth="0.999999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1185_68611)">
        <path
          d="M15.3322 5.16565L10.6655 8.49898L15.3322 11.8323V5.16565Z"
          stroke="#E64135"
          strokeWidth="0.999999"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.48636 3.83435H2.45719H1.99886C1.26248 3.83435 0.665527 4.4313 0.665527 5.16768V11.8343C0.665527 12.5707 1.26248 13.1677 1.99886 13.1677H9.33218C10.0686 13.1677 10.6655 12.5707 10.6655 11.8343V11.001M6.12385 3.83435H6.58219H7.49885H9.33218C10.0686 3.83435 10.6655 4.4313 10.6655 5.16768V8.50101"
          stroke="#E64135"
          strokeWidth="0.999999"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.06543 1.16565L14.1321 14.2323"
          stroke="#E64135"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1185_68611">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default VideoIcon;
