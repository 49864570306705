import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormControl } from "@mui/material";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { Input } from "@100mslive/roomkit-react";
import { auth, signInWithEmailPassword } from "../../../firebase";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { useAuthState } from "react-firebase-hooks/auth";
import { SESSION_AUTH } from "../../../common/auth";

const PasswordReset = props => {
  const { isUserSession, SET_USER_SESSION } = SESSION_AUTH();
  const [, loading, error] = useAuthState(auth);
  const [loader, setLoader] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [continueUrl, setContinueUrl] = useState("");
  const { actionCode } = useParams();
  const navigate = useNavigate();
  const dataFetched = useRef(null);

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      navigate("/");
    }
  }, [isUserSession, navigate]);

  const savePassword = async () => {
    if (!password) {
      ToastManager.addToast({ title: "Enter new password" });
    } else if (password && password?.length < 6) {
      ToastManager.addToast({
        title: "Weak Password",
      });
    } else if (!confirmPassword) {
      ToastManager.addToast({
        title: "Enter confirm password",
      });
    } else if (password !== confirmPassword) {
      ToastManager.addToast({
        title: "Password and confirm password did not match",
      });
    } else {
      setLoader(true);
      confirmPasswordReset(auth, actionCode, password)
        .then(async () => {
          const data = await signInWithEmailPassword(email, password, true);
          if (data?.code === 200 && data?.data) {
            SET_USER_SESSION(data?.data);
            navigate(continueUrl || "/");
          } else {
            navigate(continueUrl || "/");
          }
          setLoader(false);
        })
        .catch(error => {
          ToastManager.addToast({
            title: error?.message,
          });
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (error) {
      ToastManager.addToast({ title: error });
    }
  }, [error]);

  useEffect(() => {
    if (actionCode && !loading && !dataFetched.current) {
      dataFetched.current = true;
      const search = window.location.search;
      const params = new URLSearchParams(search);

      if (params.get("continueUrl")) {
        setContinueUrl(params.get("continueUrl"));
      }
      const confirmEmail = params.get("email");

      verifyPasswordResetCode(auth, actionCode)
        .then(email => {
          if (confirmEmail === email) {
            setEmail(email);
          } else {
            ToastManager.addToast({ title: "Email mismatch" });
            navigate("/");
          }
          setLoader(false);
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
          navigate("/");
        });
    }
    return () => null;
  }, [actionCode, loading, navigate]);
  return (
    <>
      {loading || loader ? (
        <FullPageProgress fixed={true} />
      ) : email ? (
        <div className="userLogin-email custom-form">
          <FormControl className="custom-form-group">
            <Input
              placeholder="New Password"
              className="custom-form-input"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
              type="password"
            />
            <div className="userLogin-email-text mb-20 mt-5">
              Your password must be at least 6 characters
            </div>
          </FormControl>

          <FormControl className="custom-form-group">
            <Input
              placeholder="Confirm Password"
              className="custom-form-input"
              value={confirmPassword}
              onChange={e => {
                setConfirmPassword(e.target.value);
              }}
              type="password"
            />
          </FormControl>

          <button
            type="button"
            className="userLogin-email-btn mt-20"
            onClick={savePassword}
            disabled={loader || loading}
          >
            Save password
          </button>
        </div>
      ) : null}
    </>
  );
};
export default PasswordReset;
