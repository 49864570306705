import React from "react";

const SixCareersHighlights = () => {
  return (
    <section className="events-page-landing-section events-page-landing-section-space events-page-careers-section-highlight">
      <div className="events6ix-layout-page-middle">
        <div className="events-page-careers-section-highlight-row">
          <div className="events-page-careers-section-highlight-row-col">
            <div className="events-page-careers-section-header-heading">
              Highlights
            </div>
          </div>

          <div className="events-page-careers-section-highlight-row-col">
            <div className="events-page-careers-section-highlight-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-heading">
                Employee Equity Plan
              </div>
              <div className="events-page-careers-section-highlight-row-col-list-text">
                Align your success with ours through our Employee Equity Plan.
                As we democratize global investing, you'll be an owner in this
                revolution.
              </div>
            </div>

            <div className="events-page-careers-section-highlight-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-heading">
                Global Impact, Remote Freedom
              </div>
              <div className="events-page-careers-section-highlight-row-col-list-text">
                Be part of a worldwide movement while working from anywhere. Our
                remote-first culture reflects our mission to break down
                geographical barriers.
              </div>
            </div>

            <div className="events-page-careers-section-highlight-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-heading">
                Leadership Without Limits
              </div>
              <div className="events-page-careers-section-highlight-row-col-list-text">
                We're building the future of global investing, and we need
                future leaders. Our comprehensive Leadership Development program
                is designed to unlock your full potential, preparing you to lead
                in a rapidly evolving financial landscape.
              </div>
            </div>
          </div>
          <div className="events-page-careers-section-highlight-row-col">
            <div className="events-page-careers-section-highlight-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-heading">
                Cutting-Edge Technology Exposure
              </div>
              <div className="events-page-careers-section-highlight-row-col-list-text">
                Work with state-of-the-art technologies including AI,
                blockchain, and advanced data analytics as we build the central
                hub for global investments.
              </div>
            </div>

            <div className="events-page-careers-section-highlight-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-heading">
                Cross-Functional Collaboration
              </div>
              <div className="events-page-careers-section-highlight-row-col-list-text">
                In our fast-paced, innovative environment, you'll have the
                opportunity to work across teams, gaining exposure to all
                aspects of our platform.
              </div>
            </div>

            <div className="events-page-careers-section-highlight-row-col-list">
              <div className="events-page-careers-section-at-row-col-list-heading">
                Performance-Driven Rewards
              </div>
              <div className="events-page-careers-section-highlight-row-col-list-text">
                Your contributions to democratizing investment opportunities
                won't go unnoticed. We offer competitive performance bonuses
                that celebrate individual and team achievements in advancing our
                platform and mission.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SixCareersHighlights;
