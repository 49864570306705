import React from "react";
export function UserPlus() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33332 12.2501V11.0834C9.33332 10.4646 9.08749 9.87106 8.6499 9.43348C8.21232 8.99589 7.61883 8.75006 6.99999 8.75006H2.91665C2.29782 8.75006 1.70432 8.99589 1.26674 9.43348C0.829153 9.87106 0.583321 10.4646 0.583321 11.0834V12.2501"
        stroke="#8C8E95"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.95832 6.41673C6.24698 6.41673 7.29165 5.37206 7.29165 4.08339C7.29165 2.79473 6.24698 1.75006 4.95832 1.75006C3.66965 1.75006 2.62498 2.79473 2.62498 4.08339C2.62498 5.37206 3.66965 6.41673 4.95832 6.41673Z"
        stroke="#8C8E95"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6667 4.66673V8.16673"
        stroke="#8C8E95"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4167 6.41673H9.91665"
        stroke="#8C8E95"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default UserPlus;
