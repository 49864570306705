import { apiCall } from "./servicesCall";

export const ANALYTICS_SERVICES = {
  analytics(data) {
    return apiCall("/analytics", data);
  },
  list(data) {
    return apiCall("/analytics/list", data);
  },
  speakers(data) {
    return apiCall("/analytics/speakers", data);
  },
  csv(data) {
    return apiCall("/analytics/csv", data);
  },
  invited(data) {
    return apiCall("/analytics/invited", data);
  },
  invitedList(data) {
    return apiCall("/analytics/invited/list", data);
  },
};
