import React from "react";
import { FormControl } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Input } from "@100mslive/roomkit-react";
export const SendgridConnectPopup = props => {
  const {
    setSendGridConOpen,
    sendGridApiKey,
    setsendGridApiKey,
    requestSendgridVerifyKey,
  } = props;
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog mailchimpListModal"
    >
      <DialogTitle className="text-center">Sendgrid</DialogTitle>
      <DialogContent>
        <FormControl className="custom-form-group">
          <label
            htmlFor="sendgrid-api-key"
            className="custom-form-label big-label"
          >
            Api Key
          </label>
          <Input
            id="sendgrid-api-key"
            placeholder="Enter Api Key"
            className="custom-form-input"
            value={sendGridApiKey}
            onChange={e => setsendGridApiKey(e.target.value)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          variant="outlined"
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
          onClick={() => {
            requestSendgridVerifyKey();
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
          onClick={() => {
            setSendGridConOpen(false);
          }}
        >
          No, thanks
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SendgridConnectPopup;
