import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material/";

export function MailChimpListPopup(props) {
  const {
    listData,
    syncListSelected,
    setSyncListSelected,
    setSyncListOpen,
    syncListOpen,
    startSYNCData,
  } = props;
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog mailchimpListModal"
    >
      <DialogTitle className="text-center">{syncListOpen} List</DialogTitle>
      <DialogContent>
        <div className="mailchimpListModal-list">
          {listData &&
            listData?.map((listItem, key) => (
              <div
                className={`mailchimpListModal-list-item ${
                  syncListSelected === listItem?.id ? "selected" : ""
                }`}
                key={key}
                onClick={() => {
                  setSyncListSelected(listItem?.id);
                }}
              >
                <div className="mailchimpListModal-list-item-name">
                  {listItem.name}
                </div>
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          variant="outlined"
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
          onClick={() => {
            startSYNCData();
          }}
        >
          Start sync
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
          onClick={() => {
            setSyncListOpen("");
          }}
        >
          No, thanks
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default MailChimpListPopup;
