import React from "react";
export function InfoIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4423_783)">
        <path
          d="M8.00065 14.6667C11.6825 14.6667 14.6673 11.682 14.6673 8.00006C14.6673 4.31817 11.6825 1.3334 8.00065 1.3334C4.31875 1.3334 1.33398 4.31817 1.33398 8.00006C1.33398 11.682 4.31875 14.6667 8.00065 14.6667Z"
          stroke="#8C8E95"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.6667V8"
          stroke="#8C8E95"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.3334H8.00719"
          stroke="#8C8E95"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4423_783">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default InfoIcon;
