import React from "react";
import { Button, FormControl } from "@mui/material";
import { Box, Flex, Input, Label, Text } from "@100mslive/roomkit-react";

const AccountName = props => {
  const { setOrgName, orgName, isEditAccess, saveSetting } = props;

  return (
    <div className="events-page-account-setting-content-row">
      <Box className="events-page-account-setting-content-box">
        <div className="events-page-account-setting-content-box-title">
          Organization Name
        </div>
        <FormControl className="events-page-account-setting-content-box-control">
          <Label
            htmlFor="org-name"
            className="events-page-account-setting-content-box-lable"
          >
            This is your org’s visible name within 6ix. For example, the name of
            your company.
          </Label>
          <Input
            id="org-name"
            readOnly={!isEditAccess}
            name="companyName"
            type="text"
            className="custom-form-input"
            value={orgName}
            onChange={e => {
              if (e.target?.value?.length <= 32 && isEditAccess)
                setOrgName(
                  e.target?.value
                    ?.replace(/(<([^>]+)>)/gi, "")
                    ?.replace(/\s+/g, " ")
                );
            }}
            maxLength={32}
          />
        </FormControl>

        {isEditAccess && (
          <>
            <div className="events-page-account-setting-content-box-line"></div>

            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Text
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text"
              >
                Please use 32 characters at maximum.
              </Text>

              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                onClick={() => saveSetting("orgName")}
              >
                Save
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </div>
  );
};

export default AccountName;
