import React from "react";
import Conference from "../components/conference";
//const Conference = React.lazy(() => import("./components/conference"));

const ConferencePage = props => {
  return (
    <React.Fragment>
      <Conference {...props} />
    </React.Fragment>
  );
};

export default ConferencePage;
