import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RadioButtonChecked } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import FullPageProgress from "../../../FullPageProgress";
import { ToastManager } from "../../../Toast/ToastManager";
import AccountBillingCardPopup from "./AccountBillingCardPopup";
import { SESSION_AUTH } from "../../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../../services";

const AccountBillingPaymentMethod = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [paymentMethodList, setPaymentMethodList] = useState(null);
  const [addNewCard, setAddNewCard] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [dataFetched, setDataFetached] = useState();

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetached(true);
    try {
      setLoading(true);
      ACCOUNT_SERVICES.paymentMethodList({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            if (data?.data?.list?.length > 0) {
              setPaymentMethodList(data.data.list);
            } else {
              setPaymentMethodList(null);
            }
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION]);

  useEffect(() => {
    setIsEditAccess(
      userData?.org?.isAccountOwner ||
        userData?.org?.isOwner ||
        userData?.org?.isAdmin
        ? true
        : false
    );
    return () => null;
  }, [userData]);

  const [anchorEl, setAnchorEl] = useState(null);
  const rowAction = Boolean(anchorEl);
  const openRowAction = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedMethod(item);
  };
  const closeRowAction = () => {
    setAnchorEl(null);
  };
  const makeDefaultPaymentMethod = () => {
    setAnchorEl(null);
    if (selectedMethod?.id) {
      setLoading(true);
      ACCOUNT_SERVICES.paymentMethodDefault({
        userId: userData.userId,
        org: userData?.org?.id,
        paymentMethod: selectedMethod.id,
      })
        .then(data => {
          if (data.code === 200) {
            setDataFetached(false);
            ToastManager.addToast({ title: data.message });
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const removePaymentMethod = () => {
    setAnchorEl(null);
    if (selectedMethod?.id) {
      setLoading(true);
      ACCOUNT_SERVICES.paymentMethodRemove({
        userId: userData.userId,
        org: userData?.org?.id,
        paymentMethod: selectedMethod.id,
      })
        .then(data => {
          if (data.code === 200) {
            setDataFetached(false);
            ToastManager.addToast({ title: data.message });
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const onNewCard = () => {
    setAddNewCard(false);
    setDataFetached(false);
  };
  const onCancelNewCard = () => {
    setAddNewCard(false);
  };
  if (!isEditAccess) return;
  return (
    <>
      {loading && <FullPageProgress fixed={true} />}
      {paymentMethodList ? (
        <div className="events-page-account-setting-content-row">
          <Box className="events-page-account-setting-content-box">
            <div className="events-page-account-setting-content-box-title">
              Payment Method
            </div>
            <Text
              css={{
                flex: "1 1 0",
              }}
              className="events-page-account-setting-content-box-text"
            >
              Your charges will be deducted from the default card shown below.
              This can be changed by adding a new card and making it the default
              using the menu on the right.
            </Text>

            <Box className="events-page-account-setting-content-billing-plan">
              <TableContainer>
                <Table sx={{ minWidth: 550 }} aria-label="members table">
                  <TableHead>
                    <TableRow>
                      <TableCell>BRAND</TableCell>
                      <TableCell>TYPE</TableCell>
                      <TableCell>NUMBER (LAST 4)</TableCell>
                      <TableCell>EXP. DATE</TableCell>
                      <TableCell align="center">DEFAULT</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentMethodList?.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {row?.brand}
                        </TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {row?.funding}
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          {row?.last4}
                        </TableCell>
                        <TableCell>
                          {row?.exp_month}/{row?.exp_year}
                        </TableCell>
                        <TableCell align="center">
                          {row?.default ? (
                            <RadioButtonChecked
                              className="text-primary"
                              title="Default"
                            />
                          ) : (
                            <div>
                              <Button
                                id={`payment-method-dropdown-button-${row.id}`}
                                aria-controls={
                                  rowAction
                                    ? `payment-method-dropdown-menu-${row.id}`
                                    : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={rowAction ? "true" : undefined}
                                onClick={e => openRowAction(e, row)}
                              >
                                <MoreHorizIcon />
                              </Button>
                              <Menu
                                id={`payment-method-dropdown-menu-${row.id}`}
                                aria-labelledby={`payment-method-dropdown-menu-${row.id}`}
                                anchorEl={anchorEl}
                                open={rowAction}
                                onClose={closeRowAction}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem onClick={makeDefaultPaymentMethod}>
                                  Make Default
                                </MenuItem>

                                <MenuItem onClick={removePaymentMethod}>
                                  Remove
                                </MenuItem>
                              </Menu>
                            </div>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <div className="events-page-account-setting-content-box-line"></div>
            <Flex
              align="center"
              className="events-page-account-setting-content-box-row"
            >
              <Box
                css={{
                  flex: "1 1 0",
                }}
                className="events-page-account-setting-content-box-text"
              >
                &nbsp;
              </Box>

              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                onClick={() => setAddNewCard(true)}
              >
                ADD NEW CARD
              </Button>
            </Flex>
          </Box>
        </div>
      ) : (
        <div className="events-page-account-setting-content-row">
          <Box className="events-page-account-setting-content-box">
            <div className="events-page-account-setting-content-box-title">
              Payment Method
            </div>
            <Text
              css={{
                flex: "1 1 0",
              }}
              className="events-page-account-setting-content-box-text"
            >
              The subscription cannot be activated as there is no linked payment
              method.
            </Text>
            <div className="events-page-account-setting-content-box-line"></div>
            <Flex align="center" justify="center">
              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                onClick={() => setAddNewCard(true)}
              >
                ADD NEW CARD
              </Button>
            </Flex>
          </Box>
        </div>
      )}

      {addNewCard && (
        <AccountBillingCardPopup
          onClose={onCancelNewCard}
          onUpdate={onNewCard}
        />
      )}
    </>
  );
};

export default AccountBillingPaymentMethod;
