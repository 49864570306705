import React from "react";
export function LineRow() {
  return (
    <svg
      width="1110"
      height="2"
      viewBox="0 0 1110 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_864_42183)">
        <path
          d="M0 1.84961L265.263 1.84961V0.849609L0 0.849609V1.84961Z"
          fill="url(#paint0_linear_864_42183)"
        />
        <path
          d="M265.263 1.84961L844.737 1.84961V0.849744L265.263 0.849744V1.84961Z"
          fill="#373737"
        />
        <path
          d="M1110 0.849609H844.737V1.84961H1110V0.849609Z"
          fill="url(#paint1_linear_864_42183)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_864_42183"
          x1="265.263"
          y1="0.849609"
          x2="0"
          y2="0.849609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#373737" />
          <stop offset="1" stopColor="#373737" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_864_42183"
          x1="844.737"
          y1="1.84961"
          x2="1110"
          y2="1.84961"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#373737" />
          <stop offset="1" stopColor="#373737" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_864_42183">
          <rect
            width="1110"
            height="1"
            fill="white"
            transform="translate(0 0.849609)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export default LineRow;
