import React from "react";
import { TrustListIcon } from "../../../icons";

const SixSecurityTrust = () => {
  return (
    <React.Fragment>
      <section className="events-page-landing-section events-page-security-section-trust events-page-landing-section-space-bottom">
        <div className="events6ix-layout-page-middle">
          <div className="events-page-landing-section-header text-center">
            <div class="events-page-security-section-trust-tag">SECURITY</div>
            <h2 class="events-page-landing-section-header-heading">
              Trust is Our Product
            </h2>
            <div class="events-page-landing-section-header-text">
              6ix Inc. has operated with a security-first mentality from day
              one. Our security philosophy adheres to three principles:
            </div>
          </div>
          <div class="events-page-security-section-trust-row">
            <div class="events-page-security-section-trust-row-box">
              <div class="events-page-security-section-trust-row-box-icon">
                <TrustListIcon type="globe" />
              </div>
              <div class="events-page-security-section-trust-row-box-text">
                Defending against external threats
              </div>
            </div>
            <div class="events-page-security-section-trust-row-box">
              <div class="events-page-security-section-trust-row-box-icon">
                <TrustListIcon type="human" />
              </div>
              <div class="events-page-security-section-trust-row-box-text">
                Protecting against human error
              </div>
            </div>
            <div class="events-page-security-section-trust-row-box">
              <div class="events-page-security-section-trust-row-box-icon">
                <TrustListIcon type="access" />
              </div>
              <div class="events-page-security-section-trust-row-box-text">
                Guarding against misuse of access
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default SixSecurityTrust;
