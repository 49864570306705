import React from "react";
import { FormControl } from "@mui/material";
import { Box, Flex, Label, Text } from "@100mslive/roomkit-react";
import { CopyIcon } from "../../../icons";
import { ToastManager } from "../../Toast/ToastManager";

const AccountTeamID = props => {
  const { teamId } = props;
  const CopyText = text => {
    navigator.clipboard.writeText(text);
    ToastManager.addToast({ title: "Team ID Copied!" });
  };

  return (
    <>
      <Box className="events-page-account-setting-content-box">
        <div className="events-page-account-setting-content-box-title">
          Team ID
        </div>

        <FormControl className="events-page-account-setting-content-box-control">
          <Label className="events-page-account-setting-content-box-lable">
            This is your team's ID within 6ix.
          </Label>

          <div className="events-page-account-setting-content-box-linkBox">
            <div className="events-page-account-setting-content-box-linkBox-link">
              {teamId}
            </div>
            <button
              className="events-page-account-setting-content-box-linkBox-button"
              onClick={() => {
                CopyText(teamId);
              }}
            >
              <CopyIcon />
            </button>
          </div>
        </FormControl>

        <div className="events-page-account-setting-content-box-line"></div>

        <Flex
          align="center"
          className="events-page-account-setting-content-box-row"
        >
          <Text
            css={{
              flex: "1 1 0",
            }}
            className="events-page-account-setting-content-box-text"
            translate="no"
          >
            Used when interacting with the 6ix API.
          </Text>
        </Flex>
      </Box>
    </>
  );
};

export default AccountTeamID;
