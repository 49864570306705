import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Box, Flex } from "@100mslive/roomkit-react";
import FullPageProgress from "../../../components/FullPageProgress";
import { ToastManager } from "../../../components/Toast/ToastManager";
import constantContactLogo from "../../../assets/images/other/constant-contact-logo.webp";
import hubSpotLogo from "../../../assets/images/other/hubspot-logo.webp";
import mailchimpLogo from "../../../assets/images/other/mailchimp-logo.webp";
import sendGridLogo from "../../../assets/images/other/sendgrid-logo.webp";
import SendgridConnectPopup from "./SendgridConnectPopup";
import SendgridListPopup from "./SendgridListPopup";
import { SESSION_AUTH } from "../../../common/auth";
import {
  ACCOUNT_SERVICES,
  CONSTANTCONTACT_SERVICES,
  HUBSPOT_SERVICES,
  MAILCHIMP_SERVICES,
  SENDGRID_SERVICES,
} from "../../../services";

const AccountConnect = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const [accounts, setAccounts] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendGridConOpen, setSendGridConOpen] = useState(false);
  const [sendGridListOpen, setSendGridListOpen] = useState(false);
  const [sendGridApiKey, setsendGridApiKey] = useState("");
  const [sendGridList, setSendGridList] = useState("");
  const [sendGridSelectedList, setSendGridSelectedList] = useState("");
  const dataFetched = useRef();
  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  const getAccounts = useCallback(() => {
    ACCOUNT_SERVICES.getSettingAccounts({
      userId: userData.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setAccounts(data.data);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userData, LOGOUT_USER_SESSION]);

  const disconnectAccounts = type => {
    setLoading(true);
    ACCOUNT_SERVICES.disconnectSettingAccounts({
      userId: userData.userId,
      org: userData?.org?.id,
      accountType: type,
    })
      .then(data => {
        if (data.code === 200) {
          ToastManager.addToast({ title: data.message });
          getAccounts();
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const requestMailchimpConnect = async () => {
    if (!userData) {
      return;
    }
    setLoading(true);

    try {
      const data = await MAILCHIMP_SERVICES.connect({
        userId: userData.userId,
        org: userData?.org?.id,
      });
      if (data.code === 200) {
        window.location.href = data.url;
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
        setLoading(false);
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
  };

  const requestConstantContactConnect = async () => {
    setLoading(true);
    try {
      const data = await CONSTANTCONTACT_SERVICES.connect({
        userId: userData.userId,
        org: userData?.org?.id,
      });
      if (data.code === 200) {
        window.location.href = data.url;
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
        setLoading(false);
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
  };
  const requestHubSpotConnect = async () => {
    setLoading(true);
    try {
      const data = await HUBSPOT_SERVICES.connect({
        userId: userData.userId,
        org: userData?.org?.id,
      });
      if (data.code === 200) {
        window.location.href = data.url;
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
        setLoading(false);
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
  };
  const requestSendgridVerifyKey = async () => {
    if (!sendGridApiKey) return;
    setLoading(true);
    setSendGridConOpen(false);
    try {
      const data = await SENDGRID_SERVICES.verifykey({
        userId: userData.userId,
        apiKey: sendGridApiKey,
        org: userData?.org?.id,
      });
      if (data.code === 200) {
        getSendgridList();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
        setLoading(false);
        setSendGridConOpen(true);
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
  };
  const sendGridSave = async () => {
    if (!sendGridApiKey) return;
    if (!sendGridSelectedList?.id) {
      ToastManager.addToast({ title: `Choose sender list` });
      return;
    }
    if (!sendGridSelectedList?.from_email) {
      ToastManager.addToast({
        title: `${sendGridSelectedList?.name}  list from email does not exist`,
      });
      return;
    }

    setLoading(true);
    setSendGridListOpen(false);

    try {
      const data = await SENDGRID_SERVICES.save({
        userId: userData.userId,
        org: userData?.org?.id,
        apiKey: sendGridApiKey,
        sender: sendGridSelectedList,
      });
      if (data.code === 200) {
        ToastManager.addToast({ title: data.message });
        getAccounts();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
        setLoading(false);
        setSendGridListOpen(true);
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      setLoading(false);
    }
    setLoading(false);
  };

  const getSendgridList = () => {
    setLoading(true);
    SENDGRID_SERVICES.list({
      userId: userData.userId,
      apiKey: sendGridApiKey,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setSendGridListOpen(true);
          setSendGridList(data.data || []);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          requestMailchimpConnect();
          ToastManager.addToast({ title: data.message });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!userData || dataFetched?.current) return;
    dataFetched.current = true;
    getAccounts();

    return () => null;
  }, [userData, getAccounts]);
  return (
    <>
      <Box className="events-page-account-setting-content-box">
        {loading && <FullPageProgress fixed={true} />}

        <div className="events-page-account-setting-connect-account">
          <Flex className="events-page-account-setting-connect-account-row">
            <div className="events-page-account-setting-connect-account-logo">
              <img src={sendGridLogo} alt="Sendgrid" />
            </div>
            <div className="events-page-account-setting-connect-account-action">
              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                title="Connect with Sendgrid"
                onClick={() => {
                  setsendGridApiKey(accounts?.sendgrid?.apiKey || "");
                  setSendGridSelectedList("");
                  setSendGridConOpen(true);
                }}
                sx={{ textTransform: "none" }}
              >
                {accounts?.sendgrid?.apiKey ? "Re-Connect" : "Connect"}
              </Button>

              {accounts?.sendgrid?.apiKey ? (
                <Button
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary"
                  type="button"
                  onClick={() => {
                    disconnectAccounts("sendgrid");
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Disconnect
                </Button>
              ) : null}
            </div>
          </Flex>
          <Flex className="events-page-account-setting-connect-account-row">
            <div className="events-page-account-setting-connect-account-logo">
              <img src={mailchimpLogo} alt="Mailchimp" />
            </div>
            <div className="events-page-account-setting-connect-account-action">
              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                title="Connect with Mailchimp"
                onClick={requestMailchimpConnect}
                sx={{ textTransform: "none" }}
              >
                {accounts?.mailchimp?.accessToken ? "Re-Connect" : "Connect"}
              </Button>

              {accounts?.mailchimp?.accessToken ? (
                <Button
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary"
                  type="button"
                  onClick={() => {
                    disconnectAccounts("mailchimp");
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Disconnect
                </Button>
              ) : null}
            </div>
          </Flex>
          <Flex className="events-page-account-setting-connect-account-row">
            <div className="events-page-account-setting-connect-account-logo">
              <img src={constantContactLogo} alt="Constant contact" />
            </div>
            <div className="events-page-account-setting-connect-account-action">
              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                title="Connect with Constant Contact"
                onClick={requestConstantContactConnect}
                sx={{ textTransform: "none" }}
              >
                {accounts?.constantContact?.accessToken
                  ? "Re-Connect"
                  : "Connect"}
              </Button>

              {accounts?.constantContact?.accessToken ? (
                <Button
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary"
                  type="button"
                  onClick={() => {
                    disconnectAccounts("constantContact");
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Disconnect
                </Button>
              ) : null}
            </div>
          </Flex>
          <Flex className="events-page-account-setting-connect-account-row">
            <div className="events-page-account-setting-connect-account-logo">
              <img src={hubSpotLogo} alt="HubSpot" />
            </div>
            <div className="events-page-account-setting-connect-account-action">
              <Button
                color="primary"
                variant="outlined"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
                title="Connect with Constant Contact"
                onClick={requestHubSpotConnect}
                sx={{ textTransform: "none" }}
              >
                {accounts?.hubSpot?.accessToken ? "Re-Connect" : "Connect"}
              </Button>

              {accounts?.hubSpot?.accessToken ? (
                <Button
                  color="primary"
                  variant="outlined"
                  className="events6ix-btn events6ix-btn-primary"
                  type="button"
                  onClick={() => {
                    disconnectAccounts("hubSpot");
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Disconnect
                </Button>
              ) : null}
            </div>
          </Flex>
        </div>
      </Box>

      {sendGridConOpen && (
        <SendgridConnectPopup
          sendGridApiKey={sendGridApiKey}
          setsendGridApiKey={setsendGridApiKey}
          setSendGridConOpen={setSendGridConOpen}
          requestSendgridVerifyKey={requestSendgridVerifyKey}
        />
      )}
      {sendGridListOpen && (
        <SendgridListPopup
          listData={sendGridList}
          setSendGridListOpen={setSendGridListOpen}
          selectedList={sendGridSelectedList}
          setSelectedListd={setSendGridSelectedList}
          saveData={sendGridSave}
        />
      )}
    </>
  );
};
export default AccountConnect;
