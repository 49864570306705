import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export function SendgridListPopup(props) {
  const {
    listData,
    saveData,
    selectedList,
    setSelectedListd,
    setSendGridListOpen,
  } = props;
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog mailchimpListModal"
    >
      <DialogTitle className="text-center">Senders List</DialogTitle>
      <DialogContent>
        <div className="mailchimpListModal-list">
          {listData &&
            listData?.map((listItem, key) => (
              <div
                className={`mailchimpListModal-list-item ${
                  selectedList?.id === listItem?.id ? "selected" : ""
                }`}
                key={key}
                onClick={() => {
                  setSelectedListd(listItem);
                }}
              >
                <div className="mailchimpListModal-list-item-name">
                  {listItem.name} &lt;
                  {listItem.from_email}&gt;
                </div>
              </div>
            ))}
        </div>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          variant="outlined"
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
          onClick={() => {
            saveData();
          }}
        >
          Submit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
          onClick={() => {
            setSendGridListOpen(false);
          }}
        >
          No, thanks
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default SendgridListPopup;
