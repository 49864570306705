import React from "react";
import { Box, Flex, Text } from "@100mslive/roomkit-react";

const Root = ({ onClick, mediaURL, isActive, children, testid = "" }) => (
  <Flex
    data-testid={testid}
    direction="column"
    align="center"
    css={{
      p: "$5",
      borderRadius: "$1",
      bg: "$surface_bright",
      border: `4px solid ${isActive ? "$primary_default" : "$surface_dim"}`,
      cursor: "pointer",
      "&:hover": { border: "4px solid $primary_dim" },
      ...(mediaURL
        ? {
            height: "$20",
            backgroundImage: `url(${mediaURL})`,
            backgroundSize: "cover",
          }
        : {}),
    }}
    onClick={async () => await onClick?.()}
  >
    {children}
  </Flex>
);

const Title = ({ children }) => {
  return children ? (
    <Text variant="xs" css={{ color: "$on_surface_medium" }}>
      {children}
    </Text>
  ) : null;
};

const Icon = ({ children }) => {
  return children ? (
    <Box css={{ color: "$on_surface_high" }}>{children}</Box>
  ) : null;
};

export const VBOption = {
  Root,
  Title,
  Icon,
};
