import React from "react";
import Analytics from "../components/Analytics";
import AnalyticsInvited from "../components/Analytics/invited";
import SEO from "../components/Common/SEO";
import PageHeader from "../components/PageHeader";

export function AnalyticsPage() {
  return (
    <React.Fragment>
      <SEO title="Analytics | 6ix Events" />
      <div className="events-page-analytics events6ix-layout-page">
        <PageHeader type="dashboard" />
        <div className="events6ix-layout-page-content">
          <Analytics />
        </div>
      </div>
    </React.Fragment>
  );
}
export function AnalyticsInvitedPage() {
  return (
    <React.Fragment>
      <SEO title="Analytics | 6ix Events" />
      <main className="events-page-analytics events6ix-layout-page">
        <PageHeader type="dashboard" />
        <div className="events6ix-layout-page-content">
          <AnalyticsInvited />
        </div>
      </main>
    </React.Fragment>
  );
}
