import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FullPageProgress from "../components/FullPageProgress";
import { ToastManager } from "../components/Toast/ToastManager";
import { useGlobalValue } from "../GlobalContext";
import { SESSION_AUTH } from "../common/auth";
import { USER_SERVICES } from "../services";
import { GET_DECRYPT_DATA } from "../common/constants";

const AccessTeamPage = () => {
  const { setLoginUserVerify } = useGlobalValue();
  const { isUserSession, UPDATE_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    } else if (!isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate, setLoginUserVerify]);

  useEffect(() => {
    if (dataFetchedRef.current || !isUserSession) return;
    dataFetchedRef.current = true;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const code = params.get("code");
    const switchCode = GET_DECRYPT_DATA(decodeURIComponent(code));
    if (!switchCode) {
      ToastManager.addToast({ title: "Invalid action code" });
      return navigate("/");
    }
    try {
      USER_SERVICES.switchAccount({
        userId: isUserSession?.userId,
        org: isUserSession?.org?.id,
        switch: switchCode,
      })
        .then(data => {
          if (data.code === 200) {
            UPDATE_USER_SESSION({ org: data?.data });
            return navigate("/account/setting", { state: { replace: true } });
          } else {
            ToastManager.addToast({ title: data.message });
          }
          return navigate("/", { state: { replace: true } });
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  }, [dataFetchedRef, isUserSession, navigate, UPDATE_USER_SESSION]);

  return (
    <React.Fragment>
      <FullPageProgress fixed={true} />
    </React.Fragment>
  );
};

export default AccessTeamPage;
