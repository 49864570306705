import { saveAs } from "file-saver";
import { createEvent } from "ics";
import Moment from "moment";

export const EVENT_CALENDER_ADD = (type, event) => {
  if (!event?.eventDate) return;
  const startdate = Moment(event.eventDate).format("YYYYMMDTHHmmss");
  const enddate = Moment(event.eventDate).add(1, "h").format("YYYYMMDTHHmmss");

  if (type === "GOOGLE") {
    const Link =
      "http://www.google.com/calendar/event?action=TEMPLATE&text=" +
      event.eventName +
      "&dates=" +
      startdate +
      "/" +
      enddate +
      "&details=" +
      event.eventDescription +
      "&location=" +
      event.eventMeetingLink +
      "&trp=false&sprop=name:webinars.6ix.com";
    window.open(Link, "_blank");
  } else {
    const icsevent = {
      start: [
        new Date(event.eventDate).getFullYear(),
        new Date(event.eventDate).getMonth() + 1,
        new Date(event.eventDate).getDate(),
        new Date(event.eventDate).getHours(),
        new Date(event.eventDate).getMinutes(),
      ],
      duration: { hours: 1, minutes: 0 },
      title: event.eventName,
      description: event.eventDescription,
      location: event.eventMeetingLink,
      url: event.eventMeetingLink,
      status: "CONFIRMED",
      productId: "-//Session//EN",
      busyStatus: "BUSY",
      organizer: {
        name: event?.hostedLabel,
        email: process.env.REACT_APP_SUPPORT_EMAIL,
      },
      uid: "events-" + event?.id,
    };
    const filename = event.eventName + ".ics";
    createEvent(icsevent, (error, value) => {
      const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
      saveAs(blob, filename);
    });
  }
};
