import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import { Flex, Input } from "@100mslive/roomkit-react";
import { Edit, UploadFIle } from "../../icons";
import ColorPicker from "../Common/ColorPicker";
import ImageCropper from "../Common/ImageCropper";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { EVENT_SERVICES } from "../../services";

const EventSpeakerProfile = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { open, onClose, eventId, onUpdate } = props;
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  const [dataFetched, setDataFetached] = useState(false);
  const [profileData, setProfileData] = useState("");
  const [name, setName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [work, setWork] = useState("");
  const [bio, setBio] = useState("");
  const [companyLogoPreview, setCompanyLogoPreview] = useState("");
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [companyLogoColor, setCompanyLogoColor] = useState("");
  const [access, setAccess] = useState(false);
  const [cropProfileImage, setCropProfileImage] = useState("");
  const [cropCompanyLogo, setCropCompanyLogo] = useState("");

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      navigate("/");
    }
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetached(true);
    try {
      EVENT_SERVICES.speakerProfile({
        userId: userData?.userId,
        org: userData?.org?.id,
        id: eventId,
      })
        .then(data => {
          if (data.code === 200) {
            setAccess(true);
            setProfileData(data?.data);
            setName(data?.data?.name || "");
            setJobTitle(data?.data?.jobTitle || "");
            setWork(data?.data?.work || "");
            setBio(data?.data?.bio || "");
            setCompanyLogo(data?.data?.companyLogo || "");
            setProfileImage(data?.data?.profileImage || "");
            setCompanyLogoColor(
              data?.data?.companyLogoColor ||
                process.env.REACT_APP_COLOR_DEFAULT
            );
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
            onClose();
          } else {
            ToastManager.addToast({ title: data.message });
            onClose();
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  }, [userData, dataFetched, eventId, LOGOUT_USER_SESSION, onClose]);

  useEffect(() => {
    if (companyLogo?.name) {
      const objectUrl = URL.createObjectURL(companyLogo);
      setCompanyLogoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setCompanyLogoPreview(companyLogo);
    }
  }, [companyLogo]);

  const onSave = async () => {
    try {
      if (!name) {
        ToastManager.addToast({ title: "Please enter your name" });
        return;
      }
      setLoading(true);
      const profileData = new FormData();
      profileData.append("name", name?.trim());
      profileData.append("jobTitle", jobTitle?.trim());
      profileData.append("work", work?.trim());
      profileData.append("bio", bio?.trim());
      profileData.append("companyLogoColor", companyLogoColor);
      if (companyLogo?.name) {
        profileData.append("companyLogo", companyLogo, companyLogo.name);
      }
      if (profileImage?.name) {
        profileData.append("profileImage", profileImage, profileImage.name);
      }
      profileData.append("userId", userData.userId);
      profileData.append("org", userData?.org?.id);
      profileData.append("id", eventId);
      const data = await EVENT_SERVICES.speakerProfileUpdate(profileData);
      if (data.code === 200) {
        ToastManager.addToast({ title: data.message });
        onClose();
        if (data?.data) {
          onUpdate(data?.data);
        }
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    setLoading(false);
  };

  const onSelectProfileImage = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setProfileImage(profileData?.profileImage);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropProfileImage(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  const onSelectEventLogo = e => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      setCompanyLogo(profileData?.companyLogo);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setCropCompanyLogo(reader.result?.toString() || "")
    );
    reader.readAsDataURL(e.target.files[0]);
    e.target.value = "";
  };

  useEffect(() => {
    if (profileImage?.name) {
      const objectUrl = URL.createObjectURL(profileImage);
      setProfileImagePreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      setProfileImagePreview(profileImage);
    }
  }, [profileImage]);

  if (!access) {
    return <FullPageProgress fixed={true} />;
  }
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-dialog eventAiPopup"
      >
        {loading && <FullPageProgress fixed={true} />}

        <DialogTitle className="text-center">Edit Profile</DialogTitle>
        <DialogContent>
          <FormControl className="custom-form-group">
            <label htmlFor="email" className="custom-form-label big-label">
              Email
            </label>
            <Input
              id="email"
              name="email"
              disabled
              placeholder="Enter Your EMail"
              className="custom-form-input"
              value={profileData?.email}
            />
          </FormControl>

          <FormControl className="custom-form-group">
            <label htmlFor="name" className="custom-form-label big-label">
              Name
            </label>
            <Input
              id="name"
              name="name"
              placeholder="Enter Your Name"
              className="custom-form-input"
              value={name}
              onChange={e => setName(e.target.value)}
              autoComplete="none"
            />
          </FormControl>

          <FormControl className="custom-form-group">
            <label htmlFor="jobTitle" className="custom-form-label big-label">
              Job title
            </label>
            <Input
              id="jobTitle"
              name="jobTitle"
              placeholder="Job title"
              className="custom-form-input"
              value={jobTitle}
              onChange={e => setJobTitle(e.target.value)}
            />
          </FormControl>
          <FormControl className="custom-form-group">
            <label
              htmlFor="company-name"
              className="custom-form-label big-label"
            >
              Company
            </label>
            <Input
              id="company-name"
              name="work"
              placeholder="Company"
              className="custom-form-input"
              value={work}
              onChange={e => setWork(e.target.value)}
            />
          </FormControl>

          <FormControl className="custom-form-group">
            <label htmlFor="user-bio" className="custom-form-label big-label">
              Bio (Optional)
            </label>
            <TextareaAutosize
              id="user-bio"
              name="bio"
              value={bio}
              onChange={e => setBio(e.target.value)}
              className="custom-form-input"
              placeholder="Enter Bio"
              minRows={2}
              maxRows={3}
            />
          </FormControl>

          <Flex css={{ margin: "10px 0px" }}>
            <FormControl className="custom-form-group">
              <label className="custom-form-label big-label">
                Profile Image
              </label>
              <label
                htmlFor="speaker-profile-image"
                className="custom-form-file mt-10"
                translate="no"
              >
                <Input
                  type="file"
                  id="speaker-profile-image"
                  accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                  className="custom-form-file-input"
                  onChange={onSelectProfileImage}
                />

                {profileImagePreview ? (
                  <>
                    <div className="custom-form-file-preview custom-form-file-preview-round">
                      <img src={profileImagePreview} alt="" />
                    </div>
                    <Edit />
                  </>
                ) : (
                  <>
                    <UploadFIle /> <span translate="yes">Upload</span>
                  </>
                )}
              </label>
            </FormControl>

            <FormControl className="custom-form-group">
              <label className="custom-form-label big-label">
                Company Logo
              </label>
              <Flex align="center" css={{ gap: "15px" }} translate="no">
                <label
                  htmlFor="company-logo"
                  className="custom-form-file mt-10"
                  translate="no"
                >
                  <Input
                    type="file"
                    id="company-logo"
                    accept="image/x-png,image/gif,image/jpeg,image/jpg,image/webp"
                    className="custom-form-file-input"
                    onChange={onSelectEventLogo}
                  />

                  {companyLogoPreview ? (
                    <>
                      <div
                        className="custom-form-file-preview"
                        style={{ backgroundColor: companyLogoColor }}
                      >
                        <img src={companyLogoPreview} alt="company logo" />
                      </div>
                      <Edit />
                    </>
                  ) : (
                    <>
                      <UploadFIle /> <span translate="yes"> Upload</span>
                    </>
                  )}
                </label>
                {companyLogoPreview ? (
                  <ColorPicker
                    title="Logo Background Color"
                    selectedColor={companyLogoColor}
                    setSelectedColor={setCompanyLogoColor}
                  />
                ) : null}
              </Flex>
            </FormControl>
          </Flex>
        </DialogContent>
        <DialogActions className="custom-dialog-action">
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            className="events6ix-btn events6ix-btn-light"
            type="button"
          >
            Cancel
          </Button>

          <Button
            onClick={onSave}
            variant="outlined"
            color="primary"
            className="events6ix-btn events6ix-btn-primary"
            type="button"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {cropProfileImage && (
        <ImageCropper
          open={true}
          data={{
            image: cropProfileImage,
            title: "Profile image",
            minWidth: 80,
            minHeight: 80,
            ratio: 1 / 1,
            feature: ["circle", "square", "freedom"],
          }}
          onCancel={() => {
            setCropProfileImage("");
            onSelectProfileImage();
          }}
          onUpdate={image => {
            setProfileImage(image);
            setCropProfileImage("");
          }}
        />
      )}
      {cropCompanyLogo && (
        <ImageCropper
          open={true}
          data={{
            image: cropCompanyLogo,
            title: "Company logo",
            minWidth: 60,
            minHeight: 60,
            ratio: 1 / 1,
            feature: ["square", "freedom"],
          }}
          onCancel={() => {
            setCropCompanyLogo("");
            onSelectEventLogo();
          }}
          onUpdate={image => {
            setCompanyLogo(image);
            setCropCompanyLogo("");
          }}
        />
      )}
    </React.Fragment>
  );
};
export default EventSpeakerProfile;
