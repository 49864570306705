import React from "react";
export function LevelIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0938 9.84375L15.1875 6.75"
        stroke="white"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.15625 7.59375L10.4062 9.84375"
        stroke="white"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.8125 11.25L6.46875 7.59375"
        stroke="white"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0312 6.75C16.4972 6.75 16.875 6.37224 16.875 5.90625C16.875 5.44026 16.4972 5.0625 16.0312 5.0625C15.5653 5.0625 15.1875 5.44026 15.1875 5.90625C15.1875 6.37224 15.5653 6.75 16.0312 6.75Z"
        stroke="white"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 11.5312C11.716 11.5312 12.0938 11.1535 12.0938 10.6875C12.0938 10.2215 11.716 9.84375 11.25 9.84375C10.784 9.84375 10.4062 10.2215 10.4062 10.6875C10.4062 11.1535 10.784 11.5312 11.25 11.5312Z"
        stroke="white"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3125 7.59375C7.77849 7.59375 8.15625 7.21599 8.15625 6.75C8.15625 6.28401 7.77849 5.90625 7.3125 5.90625C6.84651 5.90625 6.46875 6.28401 6.46875 6.75C6.46875 7.21599 6.84651 7.59375 7.3125 7.59375Z"
        stroke="white"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.96875 12.9375C2.43474 12.9375 2.8125 12.5597 2.8125 12.0938C2.8125 11.6278 2.43474 11.25 1.96875 11.25C1.50276 11.25 1.125 11.6278 1.125 12.0938C1.125 12.5597 1.50276 12.9375 1.96875 12.9375Z"
        stroke="white"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default LevelIcon;
