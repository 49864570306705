import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  CopyIcon,
  EmailIcon,
  FacebookIcon,
  LinkedInIcon,
  TwitterIcon,
} from "../../icons";
import { ToastManager } from "../Toast/ToastManager";

const EventShare = props => {
  const { eventName, eventMeetingLink, openPromotionPopup } = props;

  const CopyText = text => {
    navigator.clipboard.writeText(text);
    ToastManager.addToast({ title: "Meeting Link Copied!" });
  };

  const socialUrl = eventMeetingLink;

  return (
    <>
      <div className="home-page-layout-content-actionBox-share">
        <div className="home-page-layout-content-actionBox-share-heading">
          Share
        </div>
        <div className="home-page-layout-content-actionBox-share-linkBox">
          <div className="home-page-layout-content-actionBox-share-linkBox-link">
            {eventMeetingLink}
          </div>
          <div
            className="home-page-layout-content-actionBox-share-linkBox-button"
            onClick={() => {
              CopyText(eventMeetingLink);
            }}
          >
            <CopyIcon /> COPY LINK
          </div>
        </div>

        <div className="home-page-layout-content-actionBox-share-social">
          <LinkedinShareButton
            className="home-page-layout-content-actionBox-share-social-btn"
            url={socialUrl}
            title={eventName}
            summary={eventName}
          >
            <LinkedInIcon />
          </LinkedinShareButton>

          <TwitterShareButton
            className="home-page-layout-content-actionBox-share-social-btn"
            url={socialUrl}
            title={eventName}
            via="6ix"
            hashtags={["6ixevents"]}
          >
            <TwitterIcon />
          </TwitterShareButton>

          <FacebookShareButton
            hashtag="#6ixevents"
            url={socialUrl}
            quote={eventName}
            className="home-page-layout-content-actionBox-share-social-btn"
          >
            <FacebookIcon />
          </FacebookShareButton>
          {openPromotionPopup ? (
            <button
              type="button"
              className="home-page-layout-content-actionBox-share-social-btn"
              onClick={() => {
                openPromotionPopup();
              }}
            >
              <EmailIcon />
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default EventShare;
