import React, { useEffect, useState } from "react";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { Box, Flex, Input } from "@100mslive/roomkit-react";
import { Cross } from "../../../icons";
import ProfileBox from "../../Common/ProfileBox";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { INVESTOR_TYPE_LIST } from "../../../common/utils";
import { USER_SERVICES } from "../../../services";

const ProfilePopup = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { onClose, cancel } = props;
  const [userData, setUserData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [profileData, setProfileData] = useState("");

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    }
  }, [isUserSession]);

  const [name, setName] = useState();
  const [jobTitle, setJobTitle] = useState("");
  const [work, setWork] = useState("");
  const [investorType, setInvestorType] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetched(true);
    try {
      setLoading(true);
      USER_SERVICES.getProfile({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            setProfileData(data?.data);
            setName(data?.data?.name || "");
            setJobTitle(data?.data?.jobTitle || "");
            setWork(data?.data?.work || "");
            setInvestorType(data?.data?.investorType || "");
            if (
              data?.data?.name &&
              data?.data?.jobTitle &&
              data?.data?.work &&
              data?.data?.investorType
            ) {
              onClose();
            }
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION]);

  const saveProfile = async () => {
    if (!name) {
      ToastManager.addToast({ title: "Please fill your name" });
      return false;
    }

    setLoading(true);
    const profileData = new FormData();
    profileData.append("name", name?.trim());
    profileData.append("jobTitle", jobTitle?.trim());
    profileData.append("work", work?.trim());
    profileData.append("investorType", investorType);
    profileData.append(
      "typicalSize",
      INVESTOR_TYPE_LIST?.[investorType]?.size || 0
    );
    profileData.append("userId", userData.userId);
    profileData.append("org", userData?.org?.id);

    try {
      USER_SERVICES.updateProfile(profileData)
        .then(data => {
          if (data.code === 200) {
            // setIsUserReinitialize(new Date());
            onClose();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else if (data.message) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(e => {
          ToastManager.addToast({ title: "Error Something went wrong" });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
  };
  return (
    <Flex
      align="center"
      direction="column"
      className="eventsPopup eventsPopup-sm"
    >
      {loading && <FullPageProgress fixed={true} />}
      {profileData ? (
        <Box className="eventsPopup-inner">
          <div className="eventsPopup-header">
            <div className="eventsPopup-header-heading2">
              Please confirm your profile details
            </div>
          </div>
          {cancel && (
            <div className="eventsPopup-close" onClick={onClose}>
              <Cross />
            </div>
          )}
          <div className="eventsPopup-content">
            <Flex className="mb-20" justify="center">
              <ProfileBox />
            </Flex>

            <form className="custom-form">
              <FormControl className="custom-form-group">
                <label htmlFor="name" className="custom-form-label big-label">
                  Name
                </label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  className="custom-form-input"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  autoComplete="none"
                />
              </FormControl>
              {!jobTitle ? (
                <FormControl className="custom-form-group">
                  <label
                    htmlFor="jobTitle"
                    className="custom-form-label big-label"
                  >
                    Job title
                  </label>
                  <Input
                    id="jobTitle"
                    name="jobTitle"
                    placeholder="Job title"
                    className="custom-form-input"
                    value={jobTitle}
                    onChange={e => setJobTitle(e.target.value)}
                  />
                </FormControl>
              ) : null}
              {!work ? (
                <FormControl className="custom-form-group">
                  <label
                    htmlFor="company-name"
                    className="custom-form-label big-label"
                  >
                    Company
                  </label>
                  <Input
                    id="company-name"
                    name="work"
                    placeholder="Company"
                    className="custom-form-input"
                    value={work}
                    onChange={e => setWork(e.target.value)}
                  />
                </FormControl>
              ) : null}
              {!investorType ? (
                <FormControl className="custom-form-group mb-0">
                  <label
                    htmlFor="event-investorType"
                    className="custom-form-label big-label"
                  >
                    Investor Type
                  </label>

                  <Select
                    id="event-investorType"
                    name="investorType"
                    className="custom-select"
                    sx={{
                      height: 45,
                      background: "#131313",
                      borderRadius: "8px",
                    }}
                    value={investorType}
                    onChange={e => {
                      setInvestorType(e.target.value);
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <span>Select</span>
                    </MenuItem>
                    {Object.keys(INVESTOR_TYPE_LIST).map((item, index) => (
                      <MenuItem
                        key={index}
                        value={INVESTOR_TYPE_LIST[item].value}
                      >
                        {INVESTOR_TYPE_LIST[item].name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </form>
          </div>
          <div className="eventsPopup-footer">
            <Button
              color="primary"
              variant="outlined"
              className="events6ix-btn events6ix-btn-primary"
              type="button"
              onClick={saveProfile}
            >
              &nbsp; Continue &nbsp;
            </Button>
          </div>
        </Box>
      ) : null}
    </Flex>
  );
};

export default ProfilePopup;
