import React from "react";
export function DescendingIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0654 8.80926L6.39868 8.80926"
        stroke="#E3C050"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0654 12.8092L2.39868 12.8092"
        stroke="#E3C050"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0653 4.80928L10.3987 4.80928"
        stroke="#E3C050"
        strokeWidth="1.336"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default DescendingIcon;
