import React, { useEffect, useState } from "react";
import { GetCity, GetCountries, GetState } from "react-country-state-city";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { Flex, Label } from "@100mslive/roomkit-react";

const InvestorExportFilter = props => {
  const { open, onClose, onUpdate } = props;

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState([]);

  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [cityDisable, setCityDisable] = useState(true);
  const [stateDisable, setStateDisable] = useState(true);

  useEffect(() => {
    GetCountries().then(result => {
      setCountriesList(result);
    });
  }, []);
  const countryChange = value => {
    setCountry(value);
    setState("");
    setCity("");
    setStatesList([]);
    setCityList([]);
    setStateDisable(true);
    setCityDisable(true);
  };
  const stateChange = value => {
    setState(value);
    setCity("");
    setCityList([]);
    setCityDisable(true);
  };

  useEffect(() => {
    if (!country?.[0] || !countriesList?.[0]) return;
    let selected = countriesList
      .filter(item => {
        return country.indexOf(item.iso2) > -1;
      })
      .map(function (item) {
        return item.id;
      });
    if (selected?.[0] && selected?.length === 1) {
      GetState(selected[0]).then(result => {
        setStatesList(result);
        setStateDisable(result.length > 0 ? false : true);
      });
    }
  }, [country, countriesList]);

  useEffect(() => {
    if (!country?.[0] || !state || !countriesList?.[0] || !statesList?.[0])
      return;

    let selectedCountry = countriesList
      .filter(item => {
        return country.indexOf(item.iso2) > -1;
      })
      .map(function (item) {
        return item.id;
      });
    let selectedState = statesList.filter(item => {
      return item.state_code === state;
    });

    if (selectedCountry?.[0] && selectedState?.[0]) {
      GetCity(selectedCountry?.[0], selectedState?.[0]?.id).then(result => {
        setCityList(result);
        setCityDisable(result.length > 0 ? false : true);
      });
    }
  }, [country, state, countriesList, statesList]);

  const onDownload = () => {
    onUpdate({ country, state, city });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog eventPopupAi"
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle className="text-center">Investor CSV Filter</DialogTitle>
      <DialogContent>
        <Flex
          align="center"
          className="events-page-account-setting-content-box-row"
          css={{ gap: "20px" }}
        >
          <FormControl className="events-page-account-setting-content-box-control custom-form-group">
            <Label
              htmlFor="investor-filter-country"
              className="events-page-account-setting-content-box-lable"
            >
              Country
            </Label>

            <Select
              id="investor-filter-country"
              name="country"
              className="custom-select"
              value={country}
              onChange={e => countryChange(e.target.value)}
              displayEmpty
              multiple
              renderValue={selected => {
                if (selected.length === 0) {
                  return <MenuItem>All</MenuItem>;
                }
                let sl = countriesList
                  .filter(item => {
                    return selected.indexOf(item.iso2) > -1;
                  })
                  .map(function (item) {
                    return item.name;
                  });
                return sl.join(", ");
              }}
            >
              {/* <MenuItem value="">All</MenuItem> */}
              {countriesList.map((item, key) => (
                <MenuItem key={key} value={item?.iso2}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Flex>
        {!stateDisable ? (
          <Flex align="center" css={{ gap: "20px" }}>
            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                htmlFor="billing-address-state"
                className="events-page-account-setting-content-box-lable"
              >
                State
              </Label>
              <Select
                id="billing-address-state"
                name="billingaddressstate"
                className="custom-select"
                value={state}
                onChange={e => stateChange(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">All</MenuItem>
                {statesList.map((item, key) => (
                  <MenuItem key={key} value={item?.state_code}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Flex>
        ) : null}

        {!cityDisable ? (
          <Flex align="center" css={{ gap: "20px" }}>
            <FormControl className="events-page-account-setting-content-box-control custom-form-group">
              <Label
                htmlFor="billing-address-city"
                className="events-page-account-setting-content-box-lable"
              >
                City
              </Label>
              <Select
                id="billing-address-city"
                name="billingaddresscity"
                className="custom-select"
                value={city}
                onChange={e => setCity(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">All</MenuItem>
                {cityList.map((item, key) => (
                  <MenuItem key={key} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Flex>
        ) : null}
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          Cancel
        </Button>

        <Button
          onClick={onDownload}
          variant="outlined"
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InvestorExportFilter;
