import React from "react";
export function UploadFIle() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="3.5"
        fill="#131313"
        stroke="#373737"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.668 20C26.668 20.4612 26.2941 20.835 25.833 20.835L14.1663 20.835C13.7051 20.835 13.3313 20.4612 13.3313 20C13.3313 19.5389 13.7051 19.165 14.1663 19.165L25.833 19.165C26.2941 19.165 26.668 19.5389 26.668 20Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0004 13.332C20.4616 13.332 20.8354 13.7059 20.8354 14.167L20.8354 25.8337C20.8354 26.2949 20.4616 26.6687 20.0004 26.6687C19.5393 26.6687 19.1654 26.2949 19.1654 25.8337L19.1654 14.167C19.1654 13.7059 19.5393 13.332 20.0004 13.332Z"
        fill="white"
      />
    </svg>
  );
}
export default UploadFIle;
