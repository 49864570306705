import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { v4 } from "uuid";
import { Flex, styled } from "@100mslive/roomkit-react";
import { LoginPopup } from "./Account/Login/LoginPopup";
import SEO from "./Common/SEO";
import EventAttendee from "./Event/EventAttendee";
import EventSpeakerProfile from "./Event/EventSpeakerMeetingProfile";
import EventSpeakers from "./Event/EventSpeakers";
import EventSurveypopup from "./Event/EventSurveypopup";
import Promoteventpopup from "./Event/Promoteventpopup";
import PreviewContainer from "./Preview/PreviewContainer";
import { ToastManager } from "./Toast/ToastManager";
import SidePane from "../layouts/SidePane";
import { ErrorDialog } from "../primitives/DialogContent";
import FullPageProgress from "./FullPageProgress";
import { useSidepaneReset } from "./AppData/useSidepane";
import { useSetUiSettings, useTokenEndpoint } from "./AppData/useUISettings";
import { useNavigation } from "./hooks/useNavigation";
/**
 * query params exposed -
 * skip_preview=true => used by recording and streaming service, skips preview and directly joins
 *                      header and footer don't show up in this case
 * skip_preview_headful=true => used by automation testing to skip preview without impacting the UI
 * name=abc => gives the initial name for the peer joining
 * auth_token=123 => uses the passed in token to join instead of fetching from token endpoint
 * ui_mode=activespeaker => lands in active speaker mode after joining the room
 */
import { SESSION_AUTH } from "../common/auth";
import { ROLES } from "../common/roles";
import { EVENT_SERVICES, ROOM_SERVICES } from "../services";
import getToken from "../services/tokenService";
import {
  EVENT_STATUS,
  GET_MEETING_TOKEN,
  QUERY_PARAM_SKIP_PREVIEW,
  QUERY_PARAM_SKIP_PREVIEW_HEADFUL,
  SET_PUBLIC_SESSION,
  UI_SETTINGS,
} from "../common/constants";

const env = process.env.REACT_APP_ENV;
const PreviewScreen = React.memo(props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { getUserToken } = props;
  const navigate = useNavigation();
  const resetSidePane = useSidepaneReset();
  const tokenEndpoint = useTokenEndpoint();
  const [, setIsHeadless] = useSetUiSettings(UI_SETTINGS.isHeadless);
  const { roomId: urlSlug } = useParams(); // from the url
  const [urlRoomId, setUrlRoomId] = useState(""); // from the url
  const dataFetchedRef = useRef(false);
  const meetingRoleRef = useRef(false);
  const [userRole, setUserRole] = useState(null);
  const [eventOwner, setEventOwner] = useState(null);
  const [isSpeaker, setIsSpeaker] = useState(null);
  const [isAnalytics, setIsAnalytics] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState({ title: "", body: "" });
  const [userData, setUserData] = useState(null);
  const [loginRequest, setLoginRequest] = useState(false);
  const [loading, setLoading] = useState(true);
  const requestEventStart = useSearchParam("E_S");
  const urlMeetingToken = useSearchParam("meetingToken");
  // way to skip preview for automated tests, beam recording and streaming
  const [skipPreview, setSkipPreview] = useState(
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW) === "true"
  );
  const [meetingLevel, setMeetingLevel] = useState(1);
  const [eventDetail, setEventDetail] = useState("");
  const [meetingEnable, setMeetingEnable] = useState(false);
  const [participants, setParticipants] = useState(2);
  const [promotionPopup, setPromotionPopup] = useState(false);
  const [surveyData, setSurveyData] = useState({
    likelyNumber: 7,
    currentlyInvested: "no",
    interestedMeeting: "no",
  });
  const [visibleSurveyField, setVisibleSurveyField] = useState("");

  const [profile, setProfile] = useState("");
  const [editPropfile, setEditPropfile] = useState(false);
  const [eventWidget, setEventWidget] = useState("");

  // use this field to join directly for quick testing while in local

  const directJoinHeadfulFromEnv =
    process.env.REACT_APP_HEADLESS_JOIN === "true";
  const [directJoinHeadful, setDirectJoinHeadful] = useState(
    useSearchParam(QUERY_PARAM_SKIP_PREVIEW_HEADFUL) === "true" ||
      directJoinHeadfulFromEnv
  );

  useEffect(() => {
    if (directJoinHeadful) {
      setSkipPreview(directJoinHeadful);
    }
  }, [directJoinHeadful]);
  useEffect(() => {
    setDirectJoinHeadful(ROLES.ATTENDE === userRole);
  }, [userRole]);

  const updateMeetingLevel = (name, value) => {
    let setLevelData = JSON.parse(localStorage.getItem("meetingLevel")) || {};
    setLevelData["id"] = eventDetail?._id;
    setLevelData[name] = value;
    localStorage.setItem("meetingLevel", JSON.stringify(setLevelData));
  };

  const removeMeetingLevel = () => {
    localStorage.removeItem("meetingLevel");
  };

  const getMeetingLevel = () => {
    let setLevelData = JSON.parse(localStorage.getItem("meetingLevel")) || {};

    if (setLevelData.id === eventDetail?._id) {
      return setLevelData;
    } else {
      removeMeetingLevel();
      return {};
    }
  };

  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
    }
    return () => null;
  }, [isUserSession]);

  const getMeetingDetail = async () => {
    setLoading(true);
    try {
      let parmaRole = {};
      parmaRole["urlSlug"] = urlSlug;
      const data = await EVENT_SERVICES.eventDetail(parmaRole);
      if (data.code === 200) {
        let de = data.data;
        if (de?.eventDate && de?.remainingTime) {
          de["eventStartTime"] = Date.now() + (de?.remainingTime || 0);
        } else if (de?.eventDate) {
          de["eventStartTime"] = de?.eventDate;
        }
        setEventDetail(de);
        setUrlRoomId(de?.eventMeetingRoomID);
      } else {
        ToastManager.addToast({
          title: data?.message || "Someting went wrong",
        });
        //return navigate("/");
      }
      setLoading(false);
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
  };

  const getRole = (registerAccept, joinReq = false) => {
    if (!userData || !eventDetail?._id || meetingRoleRef?.current) return;

    try {
      setLoading(true);
      meetingRoleRef.current = true;
      let parmaRole = {};
      parmaRole["id"] = eventDetail?._id;
      parmaRole["userId"] = userData.userId;
      parmaRole["org"] = userData?.org?.id;
      if (registerAccept) {
        parmaRole["registerAccept"] = registerAccept;
      }
      EVENT_SERVICES.meetingRole(parmaRole)
        .then(data => {
          if (data.code === 200) {
            if (data?.data?.isSurvey && data?.data?.participant) {
              removeMeetingLevel();
              setUserRole(data.data.eventRole);
              if (joinReq && meetingEnable) {
                goToMeeting(data.data.eventRole);
              }

              if (data?.data?.eventOwner) {
                setEventOwner(data?.data?.eventOwner);
              }
              if (data?.data?.isSpeaker) {
                setIsSpeaker(data?.data?.isSpeaker);
              }
              if (data?.data?.isEdit) {
                setIsEdit(data?.data?.isEdit);
              }
              if (data?.data?.isAnalytics) {
                setIsAnalytics(data?.data?.isAnalytics);
              }
              setParticipants(1);
            } else if (
              !data?.data?.isSurvey &&
              (registerAccept || data?.data?.participant)
            ) {
              setParticipants(3);
              if (data?.data?.profile) {
                setSurveyData({
                  ...surveyData,
                });
                setVisibleSurveyField({
                  ...visibleSurveyField,
                  ...data.data.profile,
                });
              }
            } else {
              setParticipants(0);
            }
            setProfile(data.data?.profile);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          meetingRoleRef.current = false;
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
      meetingRoleRef.current = false;
    }
  };

  useEffect(() => {
    if (!userData || !eventDetail?._id) return;
    if (getMeetingLevel()?.onRegister) {
      getRole(true);
    } else {
      getRole();
    }
    if (eventDetail?.hostedBy) {
      let wl = [];
      eventDetail?.hostedBy.map(h => {
        if (h?.widget?.status === 1) {
          wl.push({ name: h?.orgName, url: h?.widget?.url });
        }
      });
      setEventWidget(wl?.length > 0 ? wl : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, eventDetail]);

  useEffect(() => {
    if (!urlSlug || dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    getMeetingLevel();
    getMeetingDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlSlug, dataFetchedRef]);

  useEffect(() => {
    if (!userData) return;
    getMeetingLevel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (
      !userRole ||
      eventDetail?.eventStatus !== EVENT_STATUS?.published?.value ||
      !eventDetail?.eventDate
    ) {
      return;
    }

    if (!tokenEndpoint || !urlRoomId || token) {
      return;
    }
    const getTokenFn = !userRole
      ? () => getUserToken(userData?.email || v4())
      : () =>
          getToken(tokenEndpoint, userData?.email || v4(), userRole, urlRoomId);

    ROOM_SERVICES.createToken({
      role: userRole,
      userId: userData?.email || v4(),
      room: urlRoomId,
    })
      .then(data => {
        if (data?.code === 200 && data?.data?.token) {
          setToken(data?.data?.token);
        } else {
          getTokenFn()
            .then(token => {
              setToken(token);
            })
            .catch(error => {
              setError(convertPreviewError(error));
            });
        }
      })
      .catch(err => {
        getTokenFn()
          .then(token => {
            setToken(token);
          })
          .catch(error => {
            setError(convertPreviewError(error));
          });
      });

    // eslint-disable-next-line
  }, [userRole, tokenEndpoint, urlRoomId, getUserToken, eventDetail]);

  const onJoin = () => {
    resetSidePane();
    !directJoinHeadful && setIsHeadless(skipPreview);
    let meetingURL = `/meeting/${urlSlug}`;
    if (userRole) {
      meetingURL += `/${userRole}`;
    }
    navigate(meetingURL);
  };
  const onRegister = () => {
    if (!userData) {
      updateMeetingLevel("onRegister", true);
      handleLogin();
    } else {
      getRole(true);
    }
  };
  const goToMeeting = role => {
    if (!userData) {
      return navigate(0);
    }
    if (eventDetail?.eventStatus === EVENT_STATUS?.published?.value) {
      setLoading(true);
      EVENT_SERVICES.meetingJoin({
        id: eventDetail?._id,
        userId: userData.userId,
        org: userData?.org?.id,
        role: userRole || role,
      })
        .then(data => {
          if (data.code === 200) {
            setMeetingLevel(2);
            SET_PUBLIC_SESSION(
              "EU_M_Event",
              JSON.stringify({
                eventName: eventDetail?.eventName,
                eventDate: eventDetail?.eventStartTime,
                widget: eventWidget,
                eventChatQA: eventDetail?.eventChatQA,
              })
            );
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({
              title: data.message || "Error Something went wrong",
            });
            navigate("/R_C_P");
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const openPromotionPopup = () => {
    setPromotionPopup(true);
  };
  const saveSurvey = async () => {
    setLoading(true);
    let parma = { ...surveyData };
    parma["userId"] = userData.userId;
    parma["org"] = userData?.org?.id;
    parma["id"] = eventDetail?._id;
    setPromotionPopup(false);

    try {
      const data = await EVENT_SERVICES.surveySave(parma);
      setLoading(false);
      if (data && data.code === 200) {
        getRole(true, true);
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      }
      if (data.message) {
        ToastManager.addToast({ title: data.message });
      }
    } catch (e) {
      setLoading(false);
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
  };

  useEffect(() => {
    if (!requestEventStart || !userRole || participants !== 1) return;
    if (
      eventDetail?.eventStartTime &&
      userRole &&
      participants &&
      new Date(eventDetail?.eventStartTime) <= new Date()
    ) {
      goToMeeting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetail, userRole, participants]);

  useEffect(() => {
    if (!urlMeetingToken || !eventDetail || !urlRoomId || userData) return;
    let MT = GET_MEETING_TOKEN(urlMeetingToken);
    if (MT && MT?.roomId === urlRoomId && MT?.role === ROLES?.RECORDING) {
      setUserRole(ROLES?.RECORDING);
      if (MT?.profile) {
        setProfile(MT?.profile);
      }
      setTimeout(function () {
        setMeetingLevel(2);
      }, 1000);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetail, urlMeetingToken, urlRoomId, userData]);

  if (error.title) {
    return <ErrorDialog title={error.title}>{error.body}</ErrorDialog>;
  }

  return (
    <>
      <div className="events-page-preview events6ix-layout-page">
        {eventDetail ? (
          <SEO
            title={eventDetail?.eventName}
            url={eventDetail?.eventMeetingLink}
            description={eventDetail?.eventDescription}
            image={eventDetail?.eventCover}
          />
        ) : null}

        {loading ? <FullPageProgress fixed={true} /> : null}
        {meetingLevel === 2 ? (
          <Flex
            direction="column"
            css={{
              size: "100%",
              width: "100%",
              height: "100%",
            }}
          >
            {userRole ? (
              <Flex
                css={{
                  flex: "1 1 0",
                  position: "relative",
                  "padding-top": "30px",
                }}
                justify="center"
                align="center"
              >
                {editPropfile ? (
                  <EventSpeakerProfile
                    open={editPropfile}
                    onClose={() => {
                      setEditPropfile(false);
                    }}
                    onUpdate={data => {
                      setProfile(data);
                    }}
                    eventId={eventDetail?._id}
                  />
                ) : token ? (
                  <PreviewContainer
                    skipPreview={skipPreview}
                    env={env}
                    onJoin={onJoin}
                    token={token}
                    profile={{
                      ...profile,
                    }}
                    editProfile={
                      eventOwner || isSpeaker ? setEditPropfile : null
                    }
                  />
                ) : (
                  <FullPageProgress />
                )}
                <SidePane
                  css={{
                    position: "unset",
                    mr: "$10",
                    "@lg": { position: "fixed", mr: "$0" },
                  }}
                />
              </Flex>
            ) : null}
          </Flex>
        ) : userData && participants === 3 ? (
          <EventSurveypopup
            surveyData={surveyData}
            saveSurvey={saveSurvey}
            setSurveyData={setSurveyData}
            eventDetail={eventDetail}
            visibleSurveyField={visibleSurveyField}
            loading={loading}
          />
        ) : eventDetail && !(participants === 2 && userData) ? (
          <div className="events6ix-layout-page-content events6ix-layout-page-content-space-top">
            <Flex
              className="attendee-area events6ix-layout-page-middle"
              align="center"
              flex="1"
              direction="column"
            >
              <EventAttendee
                {...eventDetail}
                goToMeeting={goToMeeting}
                userData={userData}
                isEdit={isEdit}
                eventOwner={eventOwner}
                isSpeaker={isSpeaker}
                isAnalytics={isAnalytics}
                openPromotionPopup={openPromotionPopup}
                onRegister={onRegister}
                participants={participants}
                meetingEnable={meetingEnable}
                setMeetingEnable={setMeetingEnable}
              />

              {promotionPopup && eventDetail && userData ? (
                <Promoteventpopup
                  setPromotionPopup={setPromotionPopup}
                  eventDetail={eventDetail}
                  isShare={true}
                />
              ) : null}
              {eventDetail && <EventSpeakers eventId={eventDetail._id} />}
            </Flex>
          </div>
        ) : !loading ? (
          <FullPageProgress />
        ) : null}
      </div>
      {loginRequest && !userData ? (
        <LoginPopup handleLogin={handleLogin} cancel={true} />
      ) : null}
    </>
  );
});

const convertPreviewError = error => {
  if (error.response && error.response.status === 404) {
    return {
      title: "Room does not exist",
      body: ErrorWithSupportLink(
        "We could not find a room corresponding to this link."
      ),
    };
  } else if (error.response && error.response.status === 403) {
    return {
      title: "Accessing room using this link format is disabled",
      body: ErrorWithSupportLink(
        "You can re-enable this from the developer section in Dashboard."
      ),
    };
  } else {
    return {
      title: "Error fetching token",
      body: ErrorWithSupportLink(
        "An error occurred while fetching the app token. Please look into logs for more details."
      ),
    };
  }
};

const Link = styled("a", {
  color: "#2f80e1",
});

export const ErrorWithSupportLink = errorMessage => (
  <div>
    {errorMessage} If you think this is a mistake on our side, please create{" "}
    <Link target="_blank" href="https://irm.6ix.com" rel="noreferrer">
      an issue
    </Link>{" "}
    or reach out over{" "}
    <Link target="_blank" href="https://chat.6ix.com" rel="noreferrer">
      Chat
    </Link>
    .
  </div>
);

export default PreviewScreen;
