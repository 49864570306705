import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import { Input, Label } from "@100mslive/roomkit-react";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { accountTeamRoleList, isValidEmail } from "../../../common/utils";
import { ACCOUNT_SERVICES } from "../../../services";
import { GET_ENCRYPT_DATA } from "../../../common/constants";

const OnBoardingTeam = props => {
  const navigate = useNavigate();
  const { onNext } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState([
    { email: "", role: "" },
    { email: "", role: "" },
    { email: "", role: "" },
  ]);

  const changeTeam = (value, key, type) => {
    let d = [...team];
    d[key][type] = value;
    setTeam(d);
  };
  const onSave = () => {
    let ut = [];
    let isValid = true;
    for (let t of team) {
      if (t?.email) {
        if (isValidEmail(t?.email)) {
          if (t?.role) {
            ut.push(t);
          } else {
            ToastManager.addToast({
              title: t?.email + " please  select role",
            });
            isValid = false;
          }
        } else {
          ToastManager.addToast({
            title: t?.email + "  invalid email address",
          });
          isValid = false;
          break;
        }
      }
    }
    if (!isValid) return false;

    if (ut?.length <= 0) {
      ToastManager.addToast({ title: "Please enter team email address" });
      return false;
    }
    let parma = {
      userId: isUserSession.userId,
      org: isUserSession?.org?.id,
      team: ut,
      code: encodeURIComponent(GET_ENCRYPT_DATA(isUserSession?.org?.id)),
    };
    setLoading(true);
    ACCOUNT_SERVICES.onBoardingTeam(parma)
      .then(data => {
        if (data.code === 200) {
          onNext(5);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else if (data.code === 201) {
          ToastManager.addToast({ title: data.message });
          return navigate("/", { replace: true });
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({
          title: err?.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <React.Fragment>
      {loading ? <FullPageProgress fixed={true} /> : null}
      <div className="events-page-onboarding-steps-body">
        <div className="events-page-onboarding-steps-header2">
          <div className="events-page-onboarding-steps-header2-label">
            INVITE YOUR TEAM
          </div>
          <div className="events-page-onboarding-steps-header2-title">
            Would you like to invite your organization’s CEO, CFO, VPs &
            marketing personnel?
          </div>
          <div className="events-page-onboarding-steps-header2-text">
            Invite everyone in your org that would use the 6ix platform.
          </div>
        </div>

        <div className="events-page-onboarding-steps-body-team">
          <div className="events-page-onboarding-steps-body-team-row">
            <FormControl className="custom-form-group">
              <Label className="custom-form-label big-label">Email</Label>
            </FormControl>
            <FormControl className="custom-form-group">
              <Label className="custom-form-label big-label">Role</Label>
            </FormControl>
          </div>
          {team?.map((item, key) => (
            <div
              key={key}
              className="events-page-onboarding-steps-body-team-row"
            >
              <FormControl className="custom-form-group">
                <Input
                  id={`onboard-org-team-email${key}`}
                  className="custom-form-input"
                  type="text"
                  placeholder="Email"
                  value={item?.email || ""}
                  onChange={e => changeTeam(e.target.value, key, "email")}
                />
              </FormControl>
              <FormControl className="custom-form-group">
                <Select
                  id={`onboard-org-team-role${key}`}
                  name="role"
                  className="custom-select"
                  value={item?.role || ""}
                  displayEmpty
                  onChange={e => changeTeam(e.target.value, key, "role")}
                >
                  <MenuItem disabled value="">
                    <span>Select Role</span>
                  </MenuItem>
                  {Object.keys(accountTeamRoleList).map((item, index) => (
                    <MenuItem
                      key={index}
                      value={accountTeamRoleList[item].value}
                    >
                      {accountTeamRoleList[item].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
        </div>

        <Button
          color="primary"
          variant="outlined"
          fullWidth
          className="events6ix-btn events6ix-btn-primary"
          type="button"
          onClick={() => onSave()}
        >
          Submit
        </Button>

        <div className="events-page-onboarding-steps-body-notice">
          Owners possess the ability to manage teams, billing, reset the
          organization, and events. Admins have the ability to manage teams,
          billing, and events. Members have the ability to manage events.
        </div>
        <div className="text-center mt-20">
          <Button
            color="secondary"
            variant="outlined"
            className="events6ix-btn events6ix-btn-light"
            type="button"
            onClick={() => onNext(5)}
            sx={{ textTransform: "none" }}
          >
            Skip for now
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnBoardingTeam;
