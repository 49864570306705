import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Flex } from "@100mslive/roomkit-react";
import { UserIcon } from "../../icons";
import { SESSION_AUTH } from "../../common/auth";
const ProfileBox = props => {
  const { boxBorder, editAllow } = props;
  const { isUserSession } = SESSION_AUTH();
  const location = useLocation();
  const [continueUrl, setContinueUrl] = useState();
  useEffect(() => {
    setContinueUrl(location?.pathname);
  }, [location]);

  if (!isUserSession) {
    return <></>;
  }
  return (
    <Flex
      className={`custom-profileBox ${
        boxBorder ? "custom-profileBox-box" : ""
      } `}
    >
      <div className="custom-profileBox-img">
        {isUserSession?.profileImage ? (
          <img src={isUserSession?.profileImage} alt="Profile" />
        ) : (
          <UserIcon />
        )}
      </div>
      <div className="custom-profileBox-content">
        <div className="custom-profileBox-name">{isUserSession?.name}</div>
        {isUserSession?.email ? (
          <div className="custom-profileBox-email">{isUserSession?.email}</div>
        ) : null}
      </div>

      {isUserSession?.isVerify && editAllow ? (
        <Link
          to={`/profile?continueUrl=${continueUrl}`}
          className="custom-profileBox-btn"
        >
          Edit profile
        </Link>
      ) : null}
    </Flex>
  );
};

export default ProfileBox;
