import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { Download as DownloadIcon } from "@mui/icons-material";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import Moment from "moment";
import { Box, Flex } from "@100mslive/roomkit-react";
import { ToastManager } from "../../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../../services";

const AccountBillingTransactions = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isEditAccess, setIsEditAccess] = useState(false);
  const [dataFetched, setDataFetached] = useState(false);
  const [transactionList, setTransactionList] = useState([]);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    setIsEditAccess(
      userData?.org?.isAccountOwner ||
        userData?.org?.isOwner ||
        userData?.org?.isAdmin
        ? true
        : false
    );
    return () => null;
  }, [userData]);

  useEffect(() => {
    if (!userData || dataFetched || !isEditAccess) return;
    setDataFetached(true);
    try {
      ACCOUNT_SERVICES.getTransactions({
        userId: userData.userId,
        org: userData?.org?.id,
      })
        .then(data => {
          if (data.code === 200) {
            setTransactionList(data.data?.list || []);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, isEditAccess]);

  if (!isEditAccess || !transactionList || transactionList?.length <= 0) {
    return <></>;
  }

  return (
    <>
      <div className="events-page-account-setting-content-row">
        <Box className="events-page-account-setting-content-box">
          <div className="events-page-account-setting-content-box-title">
            Transaction History
          </div>
          <div className="events-page-account-setting-content-box-line"></div>

          <Box className="events-page-account-setting-content-billing-plan">
            <TableContainer>
              <Table sx={{ minWidth: 550 }} aria-label="members table">
                <TableHead>
                  <TableRow>
                    <TableCell>AMOUNT</TableCell>
                    <TableCell>DATE</TableCell>
                    <TableCell>TYPE</TableCell>
                    <TableCell>TRANSACTION ID</TableCell>
                    <TableCell>INVOICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionList?.map((row, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <NumericFormat
                          value={row?.amount || 0}
                          displayType="text"
                          thousandSeparator={true}
                          prefix={getSymbolFromCurrency(row?.currency)}
                          renderText={(value, props) => (
                            <span {...props}>{value}</span>
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <sm style={{ "white-space": "nowrap" }}>
                          {Moment(row?.createdAt).format("MMM DD YYYY hh:mm A")}
                        </sm>
                      </TableCell>
                      <TableCell>
                        <sm>{row?.reason}</sm>
                      </TableCell>
                      <TableCell>
                        <sm> {row?.transactionId || "-"}</sm>
                      </TableCell>

                      <TableCell align="center">
                        {row?.hosted_invoice_url && (
                          <Button
                            components="a"
                            color="primary"
                            href={row?.hosted_invoice_url}
                            target="_blank"
                            variant="outlined"
                            className="events6ix-btn events6ix-btn-primary"
                            endIcon={<DownloadIcon />}
                          >
                            Download
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Flex
            align="center"
            className="events-page-account-setting-content-box-row"
          >
            <Box
              css={{
                flex: "1 1 0",
              }}
              className="events-page-account-setting-content-box-text"
            >
              &nbsp;
            </Box>
          </Flex>
        </Box>
      </div>
    </>
  );
};

export default AccountBillingTransactions;
