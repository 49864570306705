import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextareaAutosize,
} from "@mui/material";
import { Box } from "@100mslive/roomkit-react";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { INVESTOR_SERVICES } from "../../../services";

const InvestorComment = props => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const { open, onClose, member, onUpdate } = props;
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState("");
  const navigate = useNavigate();
  const [dataFetched, setDataFetached] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      navigate("/");
    }
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;
    setDataFetached(true);
    try {
      INVESTOR_SERVICES.comment({
        userId: userData?.userId,
        org: userData?.org?.id,
        id: member._id,
      })
        .then(data => {
          if (data.code === 200) {
            setComment(data?.data?.comment || "");
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
            onClose();
          } else {
            ToastManager.addToast({ title: data.message });
            onClose();
            onUpdate();
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message, member });
    }
  }, [userData, dataFetched, member, LOGOUT_USER_SESSION, onClose, onUpdate]);

  const onSave = async () => {
    setLoading(true);
    try {
      const profileData = new FormData();
      profileData.append("comment", comment?.trim());
      profileData.append("userId", userData.userId);
      profileData.append("org", userData?.org?.id);
      profileData.append("id", member?._id);
      const data = await INVESTOR_SERVICES.commentSave(profileData);
      if (data.code === 200) {
        ToastManager.addToast({ title: data.message });
        onClose();
        onUpdate();
      } else if (data.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        ToastManager.addToast({ title: data.message });
      }
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }
    setLoading(false);
  };

  return loading ? (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        position: "fixed",
        left: "0px",
        top: "0px",
        zIndex: 999,
      }}
    >
      <FullPageProgress />
    </Box>
  ) : (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="custom-dialog eventAiPopup"
    >
      <DialogTitle className="text-center">
        Comment <br />
        <small>{member?.email}</small>
      </DialogTitle>
      <DialogContent>
        <FormControl className="custom-form-group">
          <TextareaAutosize
            name="comment"
            value={comment}
            onChange={e => setComment(e.target.value)}
            className="custom-form-input"
            placeholder="Enter comment"
            minRows={3}
            maxRows={5}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className="custom-dialog-action">
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          className="events6ix-btn events6ix-btn-light"
          type="button"
        >
          Cancel
        </Button>

        <Button
          onClick={onSave}
          variant="outlined"
          color="primary"
          className="events6ix-btn events6ix-btn-primary"
          type="button"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InvestorComment;
