import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import {
  selectHMSMessages,
  selectLocalPeerID,
  selectLocalPeerRoleName,
  selectMessagesByPeerID,
  selectMessagesByRole,
  selectPeerNameByID,
  selectPermissions,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { HorizontalMenuIcon, PinIcon } from "@100mslive/react-icons";
import {
  Box,
  Dropdown,
  Flex,
  IconButton,
  styled,
  Text,
  Tooltip,
} from "@100mslive/roomkit-react";
import { CheckboxIcon, DeleteIcon, Heart, Star } from "../../icons";

const formatTime = date => {
  if (!(date instanceof Date)) {
    return "";
  }
  let hours = date.getHours();
  let mins = date.getMinutes();
  const suffix = hours > 11 ? "PM" : "AM";
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (mins < 10) {
    mins = "0" + mins;
  }
  return `${hours}:${mins} ${suffix}`;
};

const MessageTypeContainer = ({ left, right }) => {
  return (
    <Flex
      align="center"
      css={{
        ml: "auto",
        mr: "$4",
        p: "$2 $4",
        border: "1px solid $textDisabled",
        r: "$0",
      }}
    >
      {left && (
        <SenderName variant="tiny" as="span" css={{ color: "$textMedEmp" }}>
          {left}
        </SenderName>
      )}
      {left && right && (
        <Box
          css={{ borderLeft: "1px solid $textDisabled", mx: "$4", h: "$8" }}
        />
      )}
      {right && (
        <SenderName as="span" variant="tiny">
          {right}
        </SenderName>
      )}
    </Flex>
  );
};

const MessageType = ({ roles, hasCurrentUserSent, receiver }) => {
  const peerName = useHMSStore(selectPeerNameByID(receiver));
  const localPeerRoleName = useHMSStore(selectLocalPeerRoleName);
  if (receiver) {
    return (
      <MessageTypeContainer
        left={
          hasCurrentUserSent ? `${peerName ? `TO ${peerName}` : ""}` : "TO YOU"
        }
        right="PRIVATE"
      />
    );
  }

  if (roles && roles.length) {
    return (
      <MessageTypeContainer
        left="TO"
        right={hasCurrentUserSent ? roles.join(",") : localPeerRoleName}
      />
    );
  }
  return null;
};

const URL_REGEX =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const Link = styled("a", {
  color: "$brandDefault",
  wordBreak: "break-word",
  "&:hover": {
    textDecoration: "underline",
  },
});

export const AnnotisedMessage = ({ message }) => {
  if (!message) {
    return <Fragment />;
  }

  return (
    <Fragment>
      {message
        .trim()
        .split(/(\s)/)
        .map(part =>
          URL_REGEX.test(part) ? (
            <Link
              href={part}
              key={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </Link>
          ) : (
            part
          )
        )}
    </Fragment>
  );
};

const getMessageType = ({ roles, receiver }) => {
  if (roles && roles.length > 0) {
    return "role";
  }
  return receiver ? "private" : "";
};

const ChatCustomActions = ({
  showPinAction,
  onStar,
  onAnswer,
  onLike,
  onDelete,
  message,
  hasCurrentUserSent,
  selection,
}) => {
  return (
    <Flex
      variant="body2"
      css={{
        w: "100%",
        mt: "$2",
        pl: "$2",
        gap: "$10",
      }}
    >
      <IconButton onClick={onLike}>
        <Tooltip title={message?.like?.hasLike ? "Unlike" : "Like"}>
          <Flex css={{ gap: "$5", alignItems: "center", lineHeight: "15px" }}>
            <Heart active={message?.like?.hasLike} />{" "}
            {message?.like?.count || ""}
          </Flex>
        </Tooltip>
      </IconButton>
      {showPinAction ? (
        <>
          <IconButton onClick={onStar}>
            <Tooltip title={message?.hasStar ? "Remove Q&A" : "Add Q&A"}>
              <Box>
                <Star active={message?.hasStar} />
              </Box>
            </Tooltip>
          </IconButton>
          {selection === "Q&A" && (
            <IconButton onClick={onAnswer}>
              <Tooltip
                title={message?.hasAnswer ? "Unanswer" : "Mark as answered"}
              >
                <Box>
                  <CheckboxIcon active={message?.hasAnswer} />
                </Box>
              </Tooltip>
            </IconButton>
          )}

          <IconButton onClick={onDelete}>
            <Tooltip title="Delete Message">
              <Box>
                <DeleteIcon />
              </Box>
            </Tooltip>
          </IconButton>
        </>
      ) : hasCurrentUserSent ? (
        <IconButton onClick={onDelete}>
          <Tooltip title="Delete Message">
            <Box>
              <DeleteIcon />
            </Box>
          </Tooltip>
        </IconButton>
      ) : null}
    </Flex>
  );
};

const ChatActions = ({ onPin }) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown.Root open={open} onOpenChange={setOpen}>
      <Dropdown.Trigger asChild>
        <IconButton>
          <Tooltip title="More options">
            <Box>
              <HorizontalMenuIcon />
            </Box>
          </Tooltip>
        </IconButton>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content sideOffset={5} align="end" css={{ width: "$48" }}>
          <Dropdown.Item data-testid="pin_message_btn" onClick={onPin}>
            <PinIcon />
            <Text variant="sm" css={{ ml: "$4" }}>
              Pin Message
            </Text>
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const SenderName = styled(Text, {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "24ch",
  minWidth: 0,
});

const ChatMessage = React.memo(
  ({
    index,
    style = {},
    message,
    setRowHeight,
    onPin,
    onStar,
    onAnswer,
    onLike,
    onDelete,
    selection,
  }) => {
    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });
    const rowRef = useRef(null);

    const hmsActions = useHMSActions();
    const localPeerId = useHMSStore(selectLocalPeerID);
    const permissions = useHMSStore(selectPermissions);
    const messageType = getMessageType({
      roles: message.recipientRoles,
      receiver: message.recipientPeer,
    });
    // show pin action only if peer has remove others permission and the message is of broadcast type
    const showPinAction = permissions.removeOthers && !messageType;

    useEffect(() => {
      if (message.id && !message.read && inView) {
        hmsActions.setMessageRead(true, message.id);
      }
    }, [message.read, hmsActions, inView, message.id]);

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [index, setRowHeight]);

    return (
      <Box ref={ref} as="div" css={{ mb: "$10", pr: "$10" }} style={style}>
        <Flex
          ref={rowRef}
          align="center"
          css={{
            flexWrap: "wrap",
            bg: messageType ? "$surfaceLight" : undefined,
            r: messageType ? "$1" : undefined,
            px: messageType ? "$4" : "$2",
            py: messageType ? "$4" : 0,
          }}
          key={message.time}
          data-testid="chat_msg"
        >
          <Text
            css={{
              color: "$textHighEmp",
              fontWeight: "$semiBold",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
            as="div"
          >
            <Flex align="center">
              {message.senderName === "You" || !message.senderName ? (
                <SenderName as="span">
                  {message.senderName || "Anonymous"}
                </SenderName>
              ) : (
                <Tooltip title={message.senderName} side="top" align="start">
                  <SenderName as="span">{message.senderName}</SenderName>
                </Tooltip>
              )}
              <Text
                as="span"
                variant="sm"
                css={{
                  ml: "$4",
                  color: "$textSecondary",
                  flexShrink: 0,
                }}
              >
                {formatTime(message.time)}
              </Text>
            </Flex>
            <MessageType
              hasCurrentUserSent={message.sender === localPeerId}
              receiver={message.recipientPeer}
              roles={message.recipientRoles}
            />
            {showPinAction && <ChatActions onPin={onPin} />}
          </Text>
          <Text
            variant="body2"
            css={{
              w: "100%",
              mt: "$2",
              wordBreak: "break-word",
              whiteSpace: "pre-wrap",
            }}
          >
            <AnnotisedMessage message={message.message} />
          </Text>

          <ChatCustomActions
            showPinAction={showPinAction}
            onStar={onStar}
            onAnswer={onAnswer}
            onLike={onLike}
            onDelete={onDelete}
            message={message}
            hasCurrentUserSent={message.sender === localPeerId}
            selection={selection}
          />
        </Flex>
      </Box>
    );
  }
);
const VirtualizedChatMessages = React.forwardRef(
  ({ messages, selection, setPinnedMessage, setMessageAction }, listRef) => {
    const rowHeights = useRef({});
    function getRowHeight(index) {
      // 72 will be default row height for any message length
      // 16 will add margin value as clientHeight don't include margin
      return rowHeights.current[index] + 16 || 72;
    }

    const setRowHeight = useCallback(
      (index, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
      },
      [listRef]
    );

    return (
      <Box
        css={{
          mr: "-$10",
          h: "100%",
        }}
        as="div"
      >
        <AutoSizer
          style={{
            width: "90%",
            height: "100%",
          }}
        >
          {({ height, width }) => (
            <VariableSizeList
              ref={listRef}
              itemCount={messages.length}
              itemSize={getRowHeight}
              width={width}
              height={height - 1}
              style={{
                overflowX: "hidden",
              }}
            >
              {({ index, style }) => (
                <ChatMessage
                  style={style}
                  index={index}
                  key={messages[index].id}
                  message={messages[index]}
                  setRowHeight={setRowHeight}
                  onPin={() => setPinnedMessage(messages[index])}
                  onStar={() => setMessageAction(messages[index], "star")}
                  onAnswer={() => setMessageAction(messages[index], "answer")}
                  onLike={() => setMessageAction(messages[index], "like")}
                  onDelete={() => setMessageAction(messages[index], "delete")}
                  selection={selection}
                />
              )}
            </VariableSizeList>
          )}
        </AutoSizer>
      </Box>
    );
  }
);

export const ChatBody = React.forwardRef(
  (
    {
      role,
      peerId,
      selection,
      setPinnedMessage,
      setMessageAction,
      starData,
      likeData,
      deleteMsgData,
    },
    listRef
  ) => {
    const storeMessageSelector = role
      ? selectMessagesByRole(role)
      : peerId
      ? selectMessagesByPeerID(peerId)
      : selectHMSMessages;
    let messagesList = useHMSStore(storeMessageSelector) || [];
    const localPeerId = useHMSStore(selectLocalPeerID);
    let messages = [];
    messagesList.forEach(message => {
      if (starData && starData?.length > 0) {
        const starIndex = starData?.findIndex(
          ({ sender, senderUserId, time }) =>
            sender === message?.sender &&
            senderUserId === message?.senderUserId &&
            time === message?.time?.getTime()
        );
        if (starIndex >= 0) {
          if (starData?.[starIndex]?.actionValue === 2) {
            message = { ...message, hasStar: true, hasAnswer: true };
          } else {
            message = { ...message, hasStar: true };
          }
        }
      }
      if (likeData && likeData?.length > 0) {
        const likeIndex = likeData?.findIndex(
          ({ sender, senderUserId, time }) =>
            sender === message?.sender &&
            senderUserId === message?.senderUserId &&
            time === message?.time?.getTime()
        );
        if (likeIndex !== -1) {
          let hasLike = likeData?.[likeIndex]?.user?.some(
            u => u === localPeerId
          );
          message = {
            ...message,
            like: {
              hasLike: hasLike,
              count: likeData?.[likeIndex]?.user?.length,
            },
          };
        }
      }

      if (deleteMsgData && deleteMsgData?.length > 0) {
        let hasDelete = deleteMsgData?.some(
          s =>
            s?.sender === message?.sender &&
            s?.senderUserId === message?.senderUserId &&
            s?.time === message?.time?.getTime()
        );
        if (hasDelete) {
          return;
        }
      }

      if (selection === "Q&A") {
        if (message?.hasStar) {
          messages.push(message);
        }
      } else {
        messages.push(message);
      }
    });

    if (messages?.length <= 0) {
      return (
        <Flex
          css={{
            width: "100%",
            height: "100%",
            textAlign: "center",
            px: "$4",
          }}
          align="center"
          justify="center"
        >
          <Text>There are no messages here</Text>
        </Flex>
      );
    }

    return (
      <Fragment>
        <VirtualizedChatMessages
          messages={messages}
          selection={selection}
          setMessageAction={setMessageAction}
          setPinnedMessage={setPinnedMessage}
          ref={listRef}
        />
      </Fragment>
    );
  }
);
