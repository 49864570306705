import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { OTHER_SERVICES } from "../../services";

export const RequestADemo = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    }
    return () => null;
  }, [navigate, isUserSession]);

  const demoRequest = () => {
    if (!isUserSession) {
      return;
    }
    setLoading(true);
    OTHER_SERVICES.demoRequestMeeting({
      userId: isUserSession?.userId,
      org: isUserSession?.org?.id,
      email: isUserSession?.email,
      currentUrl: window.location.href,
    })
      .then(data => {
        if (data.code === 200) {
          ToastManager.addToast({ title: data?.message });
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data?.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({ title: err?.message });
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        variant="outlined"
        type="button"
        className="events6ix-btn events6ix-btn-primary"
        onClick={demoRequest}
      >
        REQUEST A 6ix DEMO
      </Button>
      {loading && <FullPageProgress fixed />}
    </>
  );
};
