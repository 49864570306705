import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";
import { Button } from "@mui/material";
import { AppsListIcon, ChatIcon } from "../../icons";
import { SESSION_AUTH } from "../../common/auth";
import { APPS_LIST } from "../../common/utils";
import "./style.scss";

const StartWith = () => {
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  return (
    <React.Fragment>
      <div className="events-page-start-content-heading">
        Where do you want to start?
      </div>
      <div className="events-page-start-content-apps">
        {APPS_LIST.map((item, key) => (
          <Link
            to={item?.link}
            className="events-page-start-content-apps-item"
            key={key}
          >
            <AppsListIcon type={item?.icon} />
            {item?.title}
          </Link>
        ))}
      </div>
      <div className="events-page-start-content-bottom">
        <ChatIcon />
        <div className="events-page-start-content-bottom-text">
          Need some help getting started?
        </div>
        <Button
          component="a"
          size="small"
          href="/demo"
          target="_blank"
          variant="text"
          endIcon={<OpenInNew />}
          sx={{ textTransform: "none" }}
          className="events6ix-btn events6ix-btn-sm"
        >
          Live chat with a product specialist
        </Button>
      </div>
    </React.Fragment>
  );
};

export default StartWith;
