import React from "react";
import { Box, Flex } from "@100mslive/roomkit-react";
import SEO from "../Common/SEO";

const CompanyAbout = props => {
  const { orgAbout, orgName } = props;
  return (
    <React.Fragment>
      <SEO title={`${orgName || "Profile"} | About on 6ix Events`} />
      <Flex className="featured-presenters  w-100" direction="column">
        <Box className="featured-presenters-heading">About</Box>
        <Box className="featured-presenters-text mt-0">
          {orgAbout ? (
            <div
              dangerouslySetInnerHTML={{
                __html: orgAbout.replace(/(?:\r\n|\r|\n)/g, "<br />"),
              }}
            ></div>
          ) : (
            <div className="event-page-explore-content-empty">
              About content not available, try again later!
            </div>
          )}
        </Box>
      </Flex>
    </React.Fragment>
  );
};

export default CompanyAbout;
