import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from "@mui/material";
import {
  Box,
  config as cssConfig,
  Flex,
  Label,
  Text,
} from "@100mslive/roomkit-react";
import { useGlobalValue } from "../../../GlobalContext";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../services";

const AccountResetTeam = props => {
  const { onUpdate } = props;
  const { setIsUserReinitialize } = useGlobalValue();
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resetSetting, setResetSetting] = useState(false);
  const isMobile = useMedia(cssConfig.media.md);

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  const onReset = () => {
    setLoading(true);
    ACCOUNT_SERVICES.resetSetting({
      userId: userData.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setIsUserReinitialize(new Date());
          onClose();
          onUpdate();
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          ToastManager.addToast({ title: data.message });
          navigate("/dashboard");
        }
      })
      .catch(error => {
        ToastManager.addToast({ title: error.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    setResetSetting(false);
  };
  return (
    <>
      <div className="events-page-account-setting-content-row">
        <Box className="events-page-account-setting-content-box">
          <div className="events-page-account-setting-content-box-title">
            Reset Team
          </div>
          <FormControl className="events-page-account-setting-content-box-control">
            <Label className="events-page-account-setting-content-box-lable">
              Permanently remove your Team and all of its contents from the
              Vercel platform. This action is not reversible —
              {!isMobile && <br />}
              please continue with caution.
            </Label>
          </FormControl>

          <div className="events-page-account-setting-content-box-line"></div>

          <Flex
            align="center"
            className="events-page-account-setting-content-box-row"
          >
            <Text
              css={{
                flex: "1 1 0",
              }}
              className="events-page-account-setting-content-box-text"
            />

            <Button
              color="error"
              variant="outlined"
              className="events6ix-btn events6ix-btn-danger"
              type="button"
              onClick={() => setResetSetting(true)}
            >
              RESET TEAM
            </Button>
          </Flex>
        </Box>
      </div>

      {resetSetting && (
        <Dialog
          open={resetSetting}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-dialog eventAiPopup"
        >
          {loading && <FullPageProgress fixed={true} />}

          <DialogTitle className="text-center">Reset Team</DialogTitle>
          <DialogContent>
            <DialogContentText className="text-center">
              Are You sure ?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="custom-dialog-action">
            <Button
              onClick={onClose}
              variant="outlined"
              color="secondary"
              className="events6ix-btn events6ix-btn-light"
              type="button"
            >
              Cancel
            </Button>

            <Button
              onClick={onReset}
              variant="outlined"
              color="primary"
              className="events6ix-btn events6ix-btn-primary"
              type="button"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default AccountResetTeam;
