import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "moment";
import { Flex } from "@100mslive/roomkit-react";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import AnalyticsInvitedTable from "./AnalyticsInvitedTable";
import { SESSION_AUTH } from "../../../common/auth";
import { ANALYTICS_SERVICES } from "../../../services";

const AnalyticsInvited = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [dataFetched, setDataFetched] = useState(false);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [totalInvited, setTotalInvited] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  useEffect(() => {
    if (!userData || dataFetched) return;

    setDataFetched(true);
    setLoading(true);

    try {
      ANALYTICS_SERVICES.invited({
        userId: userData.userId,
        org: userData?.org?.id,
        id: eventId,
      })
        .then(data => {
          if (data.code === 200) {
            setEventName(data?.data?.eventName);
            setEventDate(data?.data?.eventDate);
            setTotalInvited(data?.data?.totalInvited || 0);
            setLoading(false);
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            ToastManager.addToast({ title: data.message });
            navigate("/dashboard");
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        });
    } catch (error) {
      ToastManager.addToast({ title: error.message });
    }

    return () => null;
  }, [userData, dataFetched, LOGOUT_USER_SESSION, eventId, navigate]);

  return loading ? (
    <FullPageProgress fixed={true} />
  ) : (
    <div className=" relationship-container">
      <Flex className="events6ix-layout-page-top">
        <h1 className="events6ix-layout-page-top-heading events6ix-layout-page-top-heading-line">
          {eventName}{" "}
          {eventDate
            ? " - " + Moment(eventDate).format("MMM DD, YYYY hh:mm A")
            : null}{" "}
          : Invited Users
        </h1>
      </Flex>

      {totalInvited > 0 ? (
        <>
          <AnalyticsInvitedTable totalRows={totalInvited} />
        </>
      ) : null}
    </div>
  );
};

export default AnalyticsInvited;
