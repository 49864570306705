import React from "react";

export function Enter() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2H12.6666C13.0203 2 13.3594 2.14047 13.6094 2.39052C13.8595 2.64057 14 2.9797 14 3.33332V12.6666C14 13.0202 13.8595 13.3593 13.6094 13.6094C13.3594 13.8594 13.0203 13.9999 12.6666 13.9999H10"
        stroke="#fff"
        strokeWidth="1.33332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66602 11.3326L9.99931 7.99931L6.66602 4.66602"
        stroke="#fff"
        strokeWidth="1.33332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99992 8H2"
        stroke="#fff"
        strokeWidth="1.33332"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default Enter;
