import React from "react";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { createTheme, ThemeProvider } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import LogRocket from "logrocket";
// import setupLogRocketReact from "logrocket-react";
import { PostHogProvider } from "posthog-js/react";
import { init } from "zipyai";
import App from "./App";
import { GlobalProvider } from "./GlobalContext";
import reportWebVitals from "./reportWebVitals";
import { STRIPE_PUBLISHABLE_KEY } from "./common/constants";
import "./base.css";
import "./index.css";
import "./assets/css/style.scss";
import "./assets/css/event.scss";

const theme = createTheme({
  palette: {
    mode: "dark",

    primary: {
      main: "#e3c050",
    },
    secondary: {
      main: "#8c8e95",
    },
    light: {
      main: "#C0C0C0",
    },
    error: {
      main: "#e64135",
    },
  },
});

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_LOGROCKET_ID
) {
  // LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
  // setupLogRocketReact(LogRocket);
}

if (process.env.REACT_APP_ZIPY_KEY) {
  init(process.env.REACT_APP_ZIPY_KEY);
}
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const root = createRoot(document.getElementById("six-events-directory"));
const helmetContext = {};
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={{
          api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
          session_recording: {
            maskAllInputs: false,
            recordCrossOriginIframes: true,
          },
          enable_heatmaps: true,
        }}
      >
        <HelmetProvider context={helmetContext}>
          <Elements
            appearance={{
              theme: "stripe",
            }}
            stripe={stripePromise}
            options={{ themes: "stripe" }}
          >
            <ThemeProvider theme={theme}>
              <GlobalProvider>
                <App />
              </GlobalProvider>
            </ThemeProvider>
          </Elements>
        </HelmetProvider>
      </PostHogProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
