import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Moment from "moment";
import { Box, Label, Switch } from "@100mslive/roomkit-react";
import { ReplayIcon } from "../../icons";
import FullPageProgress from "../FullPageProgress";
import { ToastManager } from "../Toast/ToastManager";
import { SESSION_AUTH } from "../../common/auth";
import { sizeFormatBytes } from "../../common/utils";
import { EVENT_SERVICES } from "../../services";

export function DashboardEventReplay(props) {
  const {
    selectedEvent,
    selectReplayEvent,
    replayEventConfirm,
    replayEvent,
    setReplayEventView,
  } = props;
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const [userData, setUserData] = useState(null);
  const [replayChoose, setReplayChoose] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [notifyReply, setNotifyReply] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  const eventReplaySave = async () => {
    if (!replayChoose) {
      ToastManager.addToast({ title: "Select your replay file " });
      return false;
    }

    const parma = new FormData();
    parma.append("userId", userData.userId);
    parma.append("org", userData?.org?.id);
    parma.append("id", selectedEvent._id);
    parma.append("eventReplay", replayChoose, replayChoose.name);
    parma.append("notify", notifyReply);

    try {
      setLoading(true);
      setFileProgress(0);
      EVENT_SERVICES.addReplay(parma, onUploadProgress)
        .then(data => {
          if (data.code === 200) {
            selectReplayEvent();
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          }
          if (data.message) {
            ToastManager.addToast({ title: data.message });
          }
        })
        .catch(error => {
          ToastManager.addToast({ title: error.message });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      ToastManager.addToast({ title: "Error Something went wrong" });
    }
  };

  const onUploadProgress = data => {
    setFileProgress(Math.round((100 * data.loaded) / data.total));
  };

  const onChooseReplay = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setReplayChoose("");
      return;
    }
    if (e.target.files[0]?.size > 10 * 1024 * 1024 * 1024) {
      ToastManager.addToast({
        title: "Error! Your file is greater than 10GB",
      });
      setReplayChoose("");
      return;
    }
    setReplayChoose(e.target.files[0]);
  };

  const [deleteReplayConfirm, setDeleteReplayConfirm] = useState(false);

  const eventReplayDeleteConfirm = event => {
    if (event) {
      setDeleteReplayConfirm(true);
    } else {
      setDeleteReplayConfirm("");
    }
  };

  const eventReplayView = () => {
    setReplayEventView(true);
    selectReplayEvent("");
  };
  const replayDelete = async () => {
    if (deleteReplayConfirm) {
      setDeleteReplayConfirm(false);

      const parma = {};
      parma["userId"] = userData.userId;
      parma["org"] = userData?.org?.id;
      parma["id"] = selectedEvent._id;
      parma["replay"] = replayEvent?.replay?.link;
      try {
        setLoading(true);
        EVENT_SERVICES.deleteReplay(parma)
          .then(data => {
            if (data.code === 200) {
              selectReplayEvent();
            } else if (data.code === 600) {
              LOGOUT_USER_SESSION();
            }
            if (data.message) {
              ToastManager.addToast({ title: data.message });
            }
          })
          .catch(error => {
            ToastManager.addToast({ title: error.message });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (e) {
        ToastManager.addToast({ title: "Error Something went wrong" });
      }
    }
  };

  return (
    <>
      {!deleteReplayConfirm ? (
        <Dialog
          open={replayEventConfirm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-dialog download-recording-popup"
        >
          {loading ? (
            <Box
              style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                left: "0px",
                top: "0px",
                zIndex: 999999999,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              {fileProgress && fileProgress < 100 ? (
                <div className="eventsUploadProgress">
                  <CircularProgress
                    className="eventsUploadProgress-circle"
                    variant="determinate"
                    value={fileProgress}
                    size={100}
                  />
                  <div className="eventsUploadProgress-text">{`${Math.round(
                    fileProgress
                  )}%`}</div>
                </div>
              ) : (
                <FullPageProgress />
              )}
            </Box>
          ) : null}
          <DialogTitle className="text-center">
            {selectedEvent.eventName}
          </DialogTitle>

          <DialogContent>
            {replayEvent?.replay?.status === 1 ? (
              <>
                <div className="download-recording-popup-replay">
                  <div className="download-recording-popup-row text-center mb-20">
                    Last updated:{" "}
                    {Moment(replayEvent?.replay?.date).format(
                      "MMM DD, YYYY hh:mm A"
                    )}
                  </div>

                  <div className="download-recording-popup-row text-center">
                    <Button
                      onClick={() => {
                        eventReplayView();
                      }}
                      variant="outlined"
                      color="secondary"
                      className="events6ix-btn events6ix-btn-light"
                      type="button"
                    >
                      <ReplayIcon /> View Replay
                    </Button>

                    <Button
                      onClick={() => {
                        eventReplayDeleteConfirm(selectedEvent);
                      }}
                      variant="outlined"
                      color="primary"
                      className="events6ix-btn events6ix-btn-primary"
                      type="button"
                    >
                      Delete Replay
                    </Button>
                  </div>
                </div>
              </>
            ) : replayEvent?.replay?.status === 2 ? (
              <>
                <div className="download-recording-popup-replay">
                  <div className="download-recording-popup-row text-center mb-20">
                    Last updated:{" "}
                    {Moment(replayEvent?.replay?.date).format(
                      "MMM DD, YYYY, hh:mm A"
                    )}
                  </div>

                  <div className="download-recording-popup-row text-center download-recording-popup-replay-wait">
                    Replay is under progress. Please wait!
                  </div>
                </div>
              </>
            ) : (
              <div className="download-recording-popup-uploader">
                <label
                  className={`download-recording-popup-uploader-inner ${
                    replayChoose?.name ? " active " : ""
                  }`}
                  translate="no"
                >
                  <div className="download-recording-popup-uploader-inner-name">
                    <ReplayIcon />{" "}
                    {replayChoose?.name ? (
                      <span>{replayChoose?.name}</span>
                    ) : (
                      <span translate="yes">Select your replay file </span>
                    )}
                    <input
                      accept="video/mp4, video/webm, video/x-m4v"
                      className="download-recording-popup-uploader-inner-file"
                      type="file"
                      onChange={onChooseReplay}
                    />
                  </div>
                  {replayChoose?.size ? (
                    <div
                      className="download-recording-popup-uploader-inner-size"
                      translate="yes"
                    >
                      Size : {sizeFormatBytes(replayChoose?.size)}
                    </div>
                  ) : (
                    <div
                      className="download-recording-popup-uploader-inner-size"
                      translate="yes"
                    >
                      Max File Size : 10GB
                    </div>
                  )}
                </label>
              </div>
            )}

            {replayChoose?.name && (
              <Label className="download-recording-popup-uploader-notify">
                <Switch
                  checked={notifyReply}
                  onCheckedChange={() => {
                    setNotifyReply(!notifyReply);
                  }}
                  className="download-recording-popup-uploader-notify-button"
                />
                <span className="download-recording-popup-uploader-notify-text">
                  Send email to registrants, attendees and speakers about
                  available replay
                </span>
              </Label>
            )}
          </DialogContent>
          {replayChoose?.name ? (
            <DialogActions className="custom-dialog-action text-center mt-20">
              <Button
                onClick={() => {
                  eventReplaySave();
                }}
                variant="outlined"
                color="primary"
                className="events6ix-btn events6ix-btn-primary"
                type="button"
              >
                Upload
              </Button>
              <Button
                onClick={() => selectReplayEvent("")}
                variant="outlined"
                color="secondary"
                className="events6ix-btn events6ix-btn-light"
                type="button"
              >
                Cancel
              </Button>
            </DialogActions>
          ) : (
            <DialogActions className="custom-dialog-action">
              <Button
                onClick={() => selectReplayEvent("")}
                variant="outlined"
                color="secondary"
                className="events6ix-btn events6ix-btn-light"
                type="button"
              >
                OK
              </Button>
            </DialogActions>
          )}
        </Dialog>
      ) : (
        <Dialog
          open={deleteReplayConfirm}
          onClose={() => {
            eventReplayDeleteConfirm();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-dialog"
        >
          {loading && <FullPageProgress zindex={999999999} fixed={true} />}

          <DialogTitle className="text-center">Replay</DialogTitle>
          <DialogContent>
            <DialogContentText className="text-center">
              Do you want to delete this replay?
            </DialogContentText>
          </DialogContent>
          <DialogActions className="custom-dialog-action">
            <Button
              onClick={() => {
                eventReplayDeleteConfirm();
              }}
              variant="outlined"
              color="secondary"
              className="events6ix-btn events6ix-btn-light"
              type="button"
            >
              No, thanks
            </Button>
            <Button
              onClick={replayDelete}
              variant="outlined"
              color="primary"
              className="events6ix-btn events6ix-btn-primary"
              type="button"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
