import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullPageProgress from "../../FullPageProgress";
import { ToastManager } from "../../Toast/ToastManager";
import OnBoardingHeader from "./OnBoardingHeader";
import OnBoardingIntro from "./OnBoardingIntro";
import OnBoardingMatters from "./OnBoardingMatters";
import OnBoardingOrg from "./OnBoardingOrg";
import OnBoardingSubscription from "./OnBoardingSubscription";
import OnBoardingTeam from "./OnBoardingTeam";
import { SESSION_AUTH } from "../../../common/auth";
import { ACCOUNT_SERVICES } from "../../../services";
import "./style.scss";

const OnBoarding = () => {
  const { isUserSession, LOGOUT_USER_SESSION } = SESSION_AUTH();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dataFetched, setDataFetched] = useState(false);
  const [userData, setUserData] = useState(null);
  const [billingAddons, setBillingAddons] = useState(null);
  const [onBoardComplete, setOnBoardComplete] = useState(false);
  const [onBardingData, setOnBardingData] = useState({
    orgMatters: [],
    orgName: "",
    orgPublic: "no",
    orgStock: "",
    orgTicker: "",
  });

  useEffect(() => {
    if (isUserSession && isUserSession?.isVerify) {
      setDataFetched(false);
      setUserData(isUserSession);
    } else {
      return navigate("/");
    }
    return () => null;
  }, [isUserSession, navigate]);

  const onNext = s => {
    setCurrentStep(s);
  };
  useEffect(() => {
    if (!userData || dataFetched || onBoardComplete) return;
    setDataFetched(true);
    setLoading(true);
    ACCOUNT_SERVICES.onBoarding({
      userId: userData.userId,
      org: userData?.org?.id,
    })
      .then(data => {
        if (data.code === 200) {
          setBillingAddons(data?.data?.billingAddons);
          setOnBardingData(old => ({
            ...old,
            orgName: data?.data?.orgName || userData?.org?.orgName,
          }));
          setLoading(false);
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else if (data.code === 201) {
          ToastManager.addToast({ title: data.message });
          return navigate("/", { replace: true });
        } else {
          ToastManager.addToast({ title: data.message });
        }
      })
      .catch(err => {
        ToastManager.addToast({
          title: err?.message,
        });
      });
  }, [userData, LOGOUT_USER_SESSION, dataFetched, navigate, onBoardComplete]);

  return loading ? (
    <FullPageProgress fixed={true} />
  ) : (
    <div
      className={`events-page-onboarding-steps ${
        currentStep > 4
          ? "events-page-onboarding-steps-full"
          : currentStep > 3
          ? "events-page-onboarding-steps-md"
          : ""
      } `}
    >
      <OnBoardingHeader
        currentStep={currentStep}
        totalStep={billingAddons ? [1, 2, 3, 4, 5] : [1, 2, 3]}
      />
      {currentStep === 1 ? (
        <OnBoardingIntro onNext={onNext} />
      ) : currentStep === 2 ? (
        <OnBoardingMatters
          onNext={onNext}
          onBardingData={onBardingData}
          setOnBardingData={setOnBardingData}
        />
      ) : currentStep === 3 ? (
        <OnBoardingOrg
          onNext={onNext}
          onBardingData={onBardingData}
          setOnBardingData={setOnBardingData}
          billingAddons={billingAddons}
          setOnBoardComplete={setOnBoardComplete}
        />
      ) : currentStep === 4 && billingAddons ? (
        <OnBoardingTeam onNext={onNext} />
      ) : currentStep === 5 && billingAddons ? (
        <OnBoardingSubscription billingAddons={billingAddons} />
      ) : null}
    </div>
  );
};

export default OnBoarding;
