import React, { useEffect, useState } from "react";
import Moment from "moment";
import { ShieldAlertIcon } from "@100mslive/react-icons";
import { Box, Flex, Text } from "@100mslive/roomkit-react";
import { GET_PUBLIC_SESSION } from "../common/constants";
export const NonPublisherView = React.memo(({ message }) => {
  const [meetingEvent, setMeetingEvent] = useState("");
  useEffect(() => {
    let event = GET_PUBLIC_SESSION("EU_M_Event");
    setMeetingEvent(event ? JSON.parse(event) : "");
    return () => null;
  }, []);
  return (
    <Box
      css={{
        overflow: "hidden",
        w: "96%",
        maxWidth: "96%",
        h: "100%",
        m: "auto",
        background: "$surfaceDefault",
        borderRadius: "$3",
      }}
      data-testid="non_publisher_view"
    >
      <Flex
        align="center"
        direction="column"
        css={{
          w: "$96",
          textAlign: "center",
          margin: "auto",
          h: "100%",
          justifyContent: "center",
          gap: "$8",
        }}
      >
        <ShieldAlertIcon color="#C3D0E5" width="80px" height="80px" />
        <Flex
          direction="column"
          css={{
            w: "$96",
            p: "$1",
            gap: "$4",
          }}
        >
          <Text color="white" variant="h6" css={{ "@md": { fontSize: "$md" } }}>
            You are the first one here for the event
          </Text>
          {meetingEvent ? (
            <Text
              color="$textMedEmp"
              css={{ mt: "$4", "@md": { fontSize: "$sm" } }}
            >
              {meetingEvent?.eventName} is scheduled to begin on{" "}
              {Moment(meetingEvent?.eventDate).format(
                "ddd, MMM DD, YYYY hh:mm A"
              )}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </Box>
  );
});
