import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SEO from "../components/Common/SEO";
import StartWith from "../components/Start";
import { useGlobalValue } from "../GlobalContext";
import { SESSION_AUTH } from "../common/auth";

const StartPage = () => {
  const { isUserSession } = SESSION_AUTH();
  const navigate = useNavigate();
  const { setLoginUserVerify } = useGlobalValue();

  useEffect(() => {
    if (!isUserSession) {
      return navigate("/");
    } else if (!isUserSession?.isVerify) {
      setLoginUserVerify(true);
      return navigate("/");
    } else if (
      isUserSession?.org?.isAccountOwner &&
      !isUserSession?.org?.onBoarding
    ) {
      return navigate("/on-boarding?continueUrl=/start");
    }
    return () => null;
  }, [isUserSession, navigate, setLoginUserVerify]);

  return (
    <React.Fragment>
      <SEO title="Start | 6ix Events" />
      <main className="events-page-start events6ix-layout-page" role="main">
        <div className="events6ix-layout-page-content events-page-start-content">
          <StartWith />
        </div>
      </main>
    </React.Fragment>
  );
};

export default StartPage;
