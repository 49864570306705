import React from "react";
export function AppsIcon() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 2.75H2.25V8H7.5V2.75Z"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 2.75H10.5V8H15.75V2.75Z"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 10.9998H10.5V16.2498H15.75V10.9998Z"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 10.9998H2.25V16.2498H7.5V10.9998Z"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default AppsIcon;
